import { useContext, useState } from "react";
import OptionsContext from "../../../../OptionsContext";
import C_ProductCard4 from "../../2_components/productCard4/C_ProductCard4";
import C_ProductSort from "../../2_components/productSort/C_ProductSort";

const PGTab1 = ({ sort }) => {
  const [activeCard, setActiveCard] = useState(null);
  const { contentOptions } = useContext(OptionsContext);
  const [expanded, setExpanded] = useState(false);

  function handleExpandedActive(tf) {
    setExpanded(tf);
  }
  // useEffect(() => {
  //   setContentOptions({
  //     name: {
  //       label: "Product Name",
  //       checkbox: null,
  //       field: "DDR4 3200MHz Non-ECC Unbuffered SODIMM",
  //     },
  //     part: { label: "Part Number", checkbox: null, field: "KTH-PL426/16G" },
  //     details1: {
  //       label: "Details 1",
  //       checkbox: null,
  //       field: "This is Bullet Number One and it goes on for a little while here.",
  //     },
  //     details2: {
  //       label: "Details 2",
  //       checkbox: null,
  //       field: "Second Bullet Point Goes Here, thank you very much for not keeping it short.",
  //     },
  //     price: { label: "Price", checkbox: null, field: "$66.98" },
  //   });
  // }, []);

  return (
    <div className="s-productGallery3__filterLayout__view">
      <C_ProductSort sort={sort} />

      <ul
        className={`l-gridFlex s-productGallery3__grid${
          expanded ? " s-productGallery3__grid--active" : ""
        }`}
        data-min="288"
        data-max="480"
      >
        <C_ProductCard4
          type="tab"
          id="productCard4-0"
          pname={contentOptions.name ? contentOptions.name.field : "Product Name"}
          tag={"Sale"}
          partNumber={contentOptions.part ? contentOptions.part.field : "KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
          }
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={
            contentOptions.details1
              ? contentOptions.details1.field
              : "This is Bullet Number One and it goes on for a little while here."
          }
          details2={
            contentOptions.details2
              ? contentOptions.details2.field
              : "Second Bullet Point Goes Here, thank you very much for not keeping it short."
          }
          buyBtn={true}
          currentPrice={contentOptions.price ? contentOptions.price.field : "$66.98"}
          panelCopy={
            contentOptions.copy
              ? contentOptions.copy.field
              : "Product scheduled to ship 1 - 2 weeks"
          }
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"quantitypartners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-1"
          kit={"4"}
          pname="2GB Module - DDR3 1600MHz"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
          }
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          // No currentPrice will make product "out of stock"
          buyBtn={true}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"partners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-2"
          pname='"DC450R 2.5" Enterprise SSD'
          partNumber={"KTH-PL426/16G"}
          imgSrc={"https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"}
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={true}
          form="where"
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-3"
          pname="UV500 SATA SSD"
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
          }
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={true}
          form="quantity
          "
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          btns={{ main: "Buy" }}
        />
        <C_ProductCard4
          //this class centers a "Temporarile Out of Stock" when there is no buy button
          pclasses=" c-productCard4--singleCTA"
          type="tab"
          id="productCard4-4"
          pname="4GB Module - DDR4 2666MHz"
          partNumber={"KTH-PL426/16G"}
          imgSrc={"https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"}
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={false}
          // No current price value adds a "temorarily out of stock" span.
          //
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-5"
          pname="DRAM"
          imgSrc={
            "https://media.kingston.com/kingston/product/ktc-product-usb-dtmicroduo3c-dtduo3c128gb-1-tn.png"
          }
          partNumber={"KTH-PL426/16G"}
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={true}
          form={"partners"}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-1"
          kit={"4"}
          pname="DataTraveler MicroDuo 3C USB Flash Drive"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-d300-standard-ikd300128gb-1-tn.png"
          }
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={true}
          form="where"
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          type="tab"
          id="productCard4-0"
          pname="NV1 NVMe PCIe SSD"
          partNumber={"KTH-PL426/16G"}
          tag={"Sale"}
          imgSrc={"https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"}
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={true}
          originalPrice={"$122.54"}
          currentPrice={"$66.98"}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"quantitypartners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--singleCTA"
          type="tab"
          id="productCard4-0"
          pname="Canvas Go! Plus SD Memory Card"
          partNumber={"KTH-PL426/16G"}
          tag={"Sale"}
          imgSrc={
            "https://media.kingston.com/kingston/product/ktc-product-flash-sdcards-sdg3-512gb-1-tn.png"
          }
          selections={[
            { name: "size16", label: "16 GB", disabled: false, checked: true },
            { name: "size32", label: "32 GB", disabled: false, checked: false },
            { name: "size64", label: "64 GB", disabled: true, checked: false },
            { name: "size128", label: "128 GB", disabled: false, checked: false },
          ]}
          details1={"This is Bullet Number One and it goes on for a little while here."}
          details2={"Second Bullet Point Goes Here, thank you very much for not keeping it short."}
          buyBtn={false}
          replaced={{ name: "KTH-PL426/16G", url: "#link" }}
        />
      </ul>
      <div className="s-productGallery3__filterLayout__view__loadMore">
        <a className="e-btn" target="_self" title="Button Title" href="#loadmore">
          <span>Load More</span>
        </a>
      </div>
    </div>
  );
};

export default PGTab1;
