/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_FilterGallery from "../../2_components/filterGallery/C_FilterGallery";
import C_FilterGallerySort from "../../2_components/filterGallerySort/C_FilterGallerySort";
import C_SupportGalleryCard from "../../2_components/supportGalleryCard/C_SupportGalleryCard";
import SGCTab1 from "./SGCTab1";
import SGCTab2 from "./SGCTab2";
import supportGalleryCardsOptions from "./supportGalleryCardsOpts";

const S_SupportGalleryCards = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/ui/support-gallery-cards",
      outputName: "SupportGalleryCards",
      headerName: "Support Gallery Cards",
      tags: null,
      description: "The Support Gallery Cards displays support cards.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/dropDown.component.js",
        "../../js/accordion.component.js",
        "../../js/slideMenu.component.js",
        "../../js/filterCard.component.js",
        "../../js/tabs.layout.js",
        "../../js/gridFlex.layout.js",
        "../../js/filterGallery.layout.js",
      ],
      specs: [
        [
          "Heading Title",
          ["The heading defaults to a <h2> tag, Character limit is recommended to be < 50"],
        ],
        [
          "Description",
          [
            "The copy should be strictly text keeping the character count to under 300 for best results.",
          ],
        ],
        [
          "Image (mobile, tablet, desktop)",
          ["*1 Image Required", "Small, Medium, Large: Depends on the product"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Product Name", ["Character Limit: none"]],
        ["Part Number", ["Character Limit: none"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EWnEwhvrie1DosfqK8TkzWYB_Jmj50o3sSjHwBLRsQz0Eg?e=SPxsJB",
        comments: null,
      },
    });
    setContentOptions(supportGalleryCardsOptions);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!appState) return "...Loading Support Gallery Cards Section";
  return (
    <section
      className={`s-supportGalleryCards ${
        contentOptions.numtabs && contentOptions.numtabs.selected == 0 ? "t-silver" : "t-pearl"
      }`}
    >
      {contentOptions.heading && contentOptions.heading.checkbox == true ? (
        <div className="c-heading">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h2>{contentOptions.heading.field[contentOptions.currentLang]}</h2>
              {contentOptions.headingcopy && contentOptions.headingcopy.checkbox ? (
                <p>{contentOptions.headingcopy.field[contentOptions.currentLang]}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {contentOptions.numtabs && contentOptions.numtabs.selected == 0 ? (
        <>
          {contentOptions.filter && contentOptions.filter.checkbox ? (
            <div className="l-filterGallery">
              <div className="l-filterGallery__layout">
                <C_FilterGallery
                  fid="0"
                  sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                />
                <div className="l-filterGallery__layout__view">
                  <C_FilterGallerySort
                    fid="0"
                    sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                    sortlayout={false}
                    sortoptions={true}
                  />

                  <ul className={`l-gridFlex`} data-min="288" data-max="480">
                    <li>
                      <C_SupportGalleryCard
                        name={
                          contentOptions.supportgallerycard &&
                          contentOptions.supportgallerycard[0] &&
                          contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                        }
                        partnumber={
                          contentOptions.supportgallerycard &&
                          contentOptions.supportgallerycard[1] &&
                          contentOptions.supportgallerycard[1].field[contentOptions.currentLang]
                        }
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a400-sa400s37-1-tn.png"
                        url="#supportGalleryCardLink1"
                      />
                    </li>

                    {(contentOptions.numCards && contentOptions.numCards.selected == 6) ||
                    (contentOptions.numCards && contentOptions.numCards.selected == 12) ? (
                      <>
                        <li>
                          <C_SupportGalleryCard
                            name="UV500 SSD"
                            partnumber="SUV500"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                            url="#supportGalleryCardLink2"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="A1000 SSD"
                            partnumber="SA1000M8"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                            url="#supportGalleryCardLink3"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="KC1000"
                            partnumber="SKC1000"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                            url="#supportGalleryCardLink4"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="DC500R SSD"
                            partnumber="SEDC500R"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                            url="#supportGalleryCardLink5"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="V300"
                            partnumber="SV300S3"
                            image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                            url="#supportGalleryCardLink6"
                          />
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {contentOptions.numCards && contentOptions.numCards.selected == 12 ? (
                      <>
                        <li>
                          <C_SupportGalleryCard
                            name="UV500 SSD2"
                            partnumber="SUV500"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                            url="#supportGalleryCardLink2"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="A1000 SSD"
                            partnumber="SA1000M8"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                            url="#supportGalleryCardLink3"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="KC1000"
                            partnumber="SKC1000"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                            url="#supportGalleryCardLink4"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="DC500R SSD"
                            partnumber="SEDC500R"
                            image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                            url="#supportGalleryCardLink5"
                          />
                        </li>
                        <li>
                          <C_SupportGalleryCard
                            name="V300"
                            partnumber="SV300S3"
                            image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                            url="#supportGalleryCardLink6"
                          />
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <ul className={`l-gridFlex`} data-min="288" data-max="480">
              <li>
                <C_SupportGalleryCard
                  name={
                    contentOptions.supportgallerycard &&
                    contentOptions.supportgallerycard[0] &&
                    contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                  }
                  partnumber="SA400"
                  image="https://media.kingston.com/kingston/product/ktc-product-ssd-a400-sa400s37-1-tn.png"
                  url="#supportGalleryCardLink1"
                />
              </li>

              {(contentOptions.numCards && contentOptions.numCards.selected == 6) ||
              (contentOptions.numCards && contentOptions.numCards.selected == 12) ? (
                <>
                  <li>
                    <C_SupportGalleryCard
                      name="UV500 SSD"
                      partnumber="SUV500"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                      url="#supportGalleryCardLink2"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="A1000 SSD"
                      partnumber="SA1000M8"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                      url="#supportGalleryCardLink3"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="KC1000"
                      partnumber="SKC1000"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                      url="#supportGalleryCardLink4"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="DC500R SSD"
                      partnumber="SEDC500R"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                      url="#supportGalleryCardLink5"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="V300"
                      partnumber="SV300S3"
                      image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                      url="#supportGalleryCardLink6"
                    />
                  </li>
                </>
              ) : (
                ""
              )}

              {contentOptions.numCards && contentOptions.numCards.selected == 12 ? (
                <>
                  <li>
                    <C_SupportGalleryCard
                      name="UV500 SSD2"
                      partnumber="SUV500"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                      url="#supportGalleryCardLink2"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="A1000 SSD"
                      partnumber="SA1000M8"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                      url="#supportGalleryCardLink3"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="KC1000"
                      partnumber="SKC1000"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                      url="#supportGalleryCardLink4"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="DC500R SSD"
                      partnumber="SEDC500R"
                      image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                      url="#supportGalleryCardLink5"
                    />
                  </li>
                  <li>
                    <C_SupportGalleryCard
                      name="V300"
                      partnumber="SV300S3"
                      image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                      url="#supportGalleryCardLink6"
                    />
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          )}
        </>
      ) : (
        <div className="l-filterGallery">
          <div className="l-tabView">
            <div className="u-invisible l-filterGallery__tabLeft"></div>
            <ul className="l-tabView__tabs" role="tablist">
              <li
                className={`l-tabView__tabs__tab ${
                  activeTab === "0" ? "l-tabView__tabs__tab--active" : ""
                }`}
                role="tab"
                data-parent="0"
                id="tab0_0"
                data-tab="tabContent0_0"
                aria-controls="tabContent0_0"
                aria-selected="true"
                tabIndex="0"
                data-id="productGallery30"
                onClick={() => setActiveTab("0")}
                onKeyDown={() => setActiveTab("0")}
              >
                <span>
                  {contentOptions.tabs &&
                    contentOptions.tabs[0] &&
                    contentOptions.tabs[0].field[contentOptions.currentLang]}
                </span>
              </li>

              {(contentOptions.numtabs && contentOptions.numtabs.selected == 2) ||
              (contentOptions.numtabs && contentOptions.numtabs.selected == 3) ? (
                <li
                  className={`l-tabView__tabs__tab ${
                    activeTab === "1" ? "l-tabView__tabs__tab--active" : ""
                  }`}
                  role="tab"
                  data-parent="0"
                  id="tab0_1"
                  data-tab="tabContent0_1"
                  aria-controls="tabContent0_1"
                  aria-selected="false"
                  tabIndex="-1"
                  data-id="productGallery30"
                  onClick={() => setActiveTab("1")}
                  onKeyDown={() => setActiveTab("1")}
                >
                  <span>
                    {contentOptions.tabs &&
                      contentOptions.tabs[1] &&
                      contentOptions.tabs[1].field[contentOptions.currentLang]}
                  </span>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="u-invisible l-filterGallery__tabRight"></div>
            <div className="l-tabView__panels">
              <div
                className={`l-tabView__panels__panel ${
                  activeTab === "0" ? "l-tabView__panels__panel--active" : ""
                }`}
                role="tabpanel"
                aria-labelledby="tab0_0"
                id="tabContent0_0"
              >
                {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                  <div className="l-filterGallery__layout">
                    <C_FilterGallery
                      fid="0"
                      fType="menus"
                      fOptions={{ Lorem: "2", Ipsum: "3", Dolor: "2" }}
                      sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                    />
                    <SGCTab1
                      sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                      sortlayout={false}
                      sortoptions={true}
                      name={
                        contentOptions.supportgallerycard &&
                        contentOptions.supportgallerycard[0] &&
                        contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                      }
                      partnumber={
                        contentOptions.supportgallerycard &&
                        contentOptions.supportgallerycard[1] &&
                        contentOptions.supportgallerycard[1].field[contentOptions.currentLang]
                      }
                    />
                  </div>
                ) : (
                  <ul className={`l-gridFlex`} data-min="288" data-max="480">
                    <li>
                      <C_SupportGalleryCard
                        name={
                          contentOptions.supportgallerycard &&
                          contentOptions.supportgallerycard[0] &&
                          contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                        }
                        partnumber="SA400"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a400-sa400s37-1-tn.png"
                        url="#supportGalleryCardLink1"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="UV500 SSD"
                        partnumber="SUV500"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                        url="#supportGalleryCardLink2"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="A1000 SSD"
                        partnumber="SA1000M8"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                        url="#supportGalleryCardLink3"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="KC1000"
                        partnumber="SKC1000"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                        url="#supportGalleryCardLink4"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="DC500R SSD"
                        partnumber="SEDC500R"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                        url="#supportGalleryCardLink5"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="V300"
                        partnumber="SV300S3"
                        image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                        url="#supportGalleryCardLink6"
                      />
                    </li>

                    <li>
                      <C_SupportGalleryCard
                        name="UV500 SSD2"
                        partnumber="SUV500"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                        url="#supportGalleryCardLink2"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="A1000 SSD"
                        partnumber="SA1000M8"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                        url="#supportGalleryCardLink3"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="KC1000"
                        partnumber="SKC1000"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                        url="#supportGalleryCardLink4"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="DC500R SSD"
                        partnumber="SEDC500R"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                        url="#supportGalleryCardLink5"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="V300"
                        partnumber="SV300S3"
                        image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                        url="#supportGalleryCardLink6"
                      />
                    </li>
                  </ul>
                )}
              </div>
              <div
                className={`l-tabView__panels__panel ${
                  activeTab === "1" ? "l-tabView__panels__panel--active" : ""
                }`}
                role="tabpanel"
                aria-labelledby="tab0_1"
                id="tabContent0_1"
              >
                {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                  <div className="l-filterGallery__layout">
                    <C_FilterGallery
                      fid="1"
                      fType="menus"
                      fOptions={{ Lorem: "2", Ipsum: "3", Dolor: "2" }}
                      sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                    />
                    <SGCTab2
                      sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                      sortlayout={false}
                      sortoptions={true}
                      name={
                        contentOptions.supportgallerycard &&
                        contentOptions.supportgallerycard[0] &&
                        contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                      }
                      partnumber={
                        contentOptions.supportgallerycard &&
                        contentOptions.supportgallerycard[1] &&
                        contentOptions.supportgallerycard[1].field[contentOptions.currentLang]
                      }
                    />
                  </div>
                ) : (
                  <ul className={`l-gridFlex`} data-min="288" data-max="480">
                    <li>
                      <C_SupportGalleryCard
                        name={
                          contentOptions.supportgallerycard &&
                          contentOptions.supportgallerycard[0] &&
                          contentOptions.supportgallerycard[0].field[contentOptions.currentLang]
                        }
                        partnumber="SA400"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a400-sa400s37-1-tn.png"
                        url="#supportGalleryCardLink1"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="UV500 SSD"
                        partnumber="SUV500"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
                        url="#supportGalleryCardLink2"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="A1000 SSD"
                        partnumber="SA1000M8"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
                        url="#supportGalleryCardLink3"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="KC1000"
                        partnumber="SKC1000"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
                        url="#supportGalleryCardLink4"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="DC500R SSD"
                        partnumber="SEDC500R"
                        image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
                        url="#supportGalleryCardLink5"
                      />
                    </li>
                    <li>
                      <C_SupportGalleryCard
                        name="V300"
                        partnumber="SV300S3"
                        image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
                        url="#supportGalleryCardLink6"
                      />
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "support-gallery-cards",
  component: S_SupportGalleryCards,
  navtxt: "Support Gallery Cards",
  htmlName: "SupportGalleryCards",
  categoryType: ["internal"],
};
