import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_Card from "../../2_components/card/C_Card";
import blurbOpts from "./blurbOpts";

const S_Blurb = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  let blurbRef = useRef();

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let blurbVars = {
      ...appState,
      currentPath: "/ui/blurb",
      outputName: "Blurb", //html name
      headerName: "Blurb",
      tags: null,
      description:
        "The Blurb Section is a short promotional description. The Blurb transitions onto the screen once it is in view.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-pearl",
      scripts: ["../../js/rexusManager.component.js", "../../js/blurb.component.js"],
      specs: [
        [
          "Title",
          [
            "String text, no character limit but recommend max 2 lines. Title should be concise.",
            "Note: Default is h2 but can change between h2 to h6.",
          ],
        ],
        ["Copy", ["The copy appears as a paragraph. No Character limit."]],
        [
          "CTA",
          [
            "Standard Text, recommend: refer to list of approved CTA text, otherwise max of two words. If there's no CTA, there is no link. If there's a CTA, then there's a link (Optional)",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EaevQO_4s4tBu7tgWhXebQYBBSyyP3AwJFnjUFugSB176A?e=VzbKT0",
        comments: [["Template Location", ["Notification Admin >> Blurb Template"]]],
      },
    };
    setAppState(blurbVars);
    setContentOptions(blurbOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "" });
  }, []);

  useEffect(() => {
    let blurb = blurbRef.current;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    let observer = new IntersectionObserver(animate, options);
    blurb && observer ? observer.observe(blurb) : "";
  }, [blurbRef]);

  function animate(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("c-blurb--active");
      }
    });
  }

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading Blurb";
  return (
    <div
      style={{
        paddingTop: `${
          contentOptions.padding && contentOptions.padding.checkbox == true ? "100em" : ""
        }`,
        paddingBottom: `${
          contentOptions.padding && contentOptions.padding.checkbox == true ? "100em" : ""
        }`,
      }}
    >
      <section className={"s-blurb " + appState.currentTheme}>
        <div className="l-inner">
          <div
            className={`c-blurb ${
              contentOptions.padding && contentOptions.padding.checkbox == true
                ? ""
                : "c-blurb--active"
            }`}
            ref={blurbRef}
          >
            <div className="c-blurb__title">
              {contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h5 className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </h5>
              ) : (
                <div className="c-blurb__title__copy">
                  {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
                </div>
              )}
            </div>
            {contentOptions.cta && contentOptions.cta.checkbox == true ? (
              <C_Card
                type="blurb"
                radius={"small"}
                url="#link"
                copy={contentOptions.copy && contentOptions.copy.field[contentOptions.currentLang]}
                cta={contentOptions.cta.field[contentOptions.currentLang]}
              />
            ) : (
              <C_Card
                type="blurb"
                radius={"small"}
                copy={contentOptions.copy && contentOptions.copy.field[contentOptions.currentLang]}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_.
export default {
  path: "blurb",
  component: S_Blurb,
  navtxt: "Blurb",
  htmlName: "Blurb",
  categoryType: ["web"],
};
