import { useContext, useEffect, useState } from "react";
import DimensionsContext from "../../../../DimensionsContext";

const C_ProgressBar = ({ duration, paused, setDuration, slide }) => {
  const [pbDuration, setPbDuration] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [pbStyle, setPbStyle] = useState({ width: "2%" });

  const { dimensions } = useContext(DimensionsContext);

  useEffect(() => {
    pbDuration ? setSeconds(pbDuration * 1000) : setSeconds(5000);
  }, [duration]);

  useEffect(() => {
    setPbDuration(duration);
    let timer = null;

    //if seconds are set and carousel paused is false start counting
    if (seconds > 0 && !paused) {
      timer = setTimeout(() => {
        setSeconds((seconds) => seconds - 100);

        // width of progessbar is 30% of window
        let w = (dimensions.width / 30) * 10;

        // 1 - seconds passed / duration multiplied x 100 to make a percent,
        let timePercent = 1 - seconds / 1000 / pbDuration;

        // multiply by 100 for hundredths of secs
        let pixels = w * timePercent;
        pixels = pixels.toFixed(4);

        setPbStyle({ width: `${pixels}px` });
      }, 100);

      //when seconds run out send notice to parent
    } else if (seconds == 0) {
      setDuration(0);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [seconds, paused, duration, slide, dimensions.width]);

  return <div className="s-carousel__progress" style={pbStyle}></div>;
};

export default C_ProgressBar;
