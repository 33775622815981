export default {
  numFeatures: {
    label: "Num of items:",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    selected: 10,
  },
  Title: {
    label: "Title",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題 ",
      Jp: "見出し",
    },
  },
  link1: {
    label: "Link 1",
    checkbox: null,
    field: {
      Eng: "Sed",
      Span: "Hacía",
      ChS: "那个天气很热",
      Gr: "Tochter",
      Ru: "Лорем",
      Fr: "Il",
      It: "Faceva ",
      Pl: "Byłe",
      Port: "Fazia",
      Viet: "Thời",
      Turk: "O hava.",
      Ukrn: "У таку",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  link2: {
    label: "Link 2",
    checkbox: null,
    field: {
      Eng: "Ut",
      Span: "Hacía",
      ChS: "那个天气很热",
      Gr: "Tochter",
      Ru: "Лорем",
      Fr: "Il",
      It: "Faceva ",
      Pl: "Byłe",
      Port: "Fazia",
      Viet: "Thời",
      Turk: "O hava.",
      Ukrn: "У таку",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  link3: {
    label: "Link 3",
    checkbox: null,
    field: {
      Eng: "Perspiciatis",
      Span: "Hacía",
      ChS: "那个天气很热",
      Gr: "Tochter",
      Ru: "Лорем",
      Fr: "Il",
      It: "Faceva ",
      Pl: "Byłe",
      Port: "Fazia",
      Viet: "Thời",
      Turk: "O hava.",
      Ukrn: "У таку",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  link4: {
    label: "Link 4",
    checkbox: null,
    field: {
      Eng: "Unde",
      Span: "Hacía",
      ChS: "那个天气很热",
      Gr: "Tochter",
      Ru: "Лорем",
      Fr: "Il",
      It: "Faceva ",
      Pl: "Byłe",
      Port: "Fazia",
      Viet: "Thời",
      Turk: "O hava.",
      Ukrn: "У таку",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  link5: {
    label: "Link 5",
    checkbox: null,
    field: {
      Eng: "Omnis",
      Span: "Hacía",
      ChS: "那个天气很热",
      Gr: "Tochter",
      Ru: "Лорем",
      Fr: "Il",
      It: "Faceva ",
      Pl: "Byłe",
      Port: "Fazia",
      Viet: "Thời",
      Turk: "O hava.",
      Ukrn: "У таку",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  link6: {
    label: "Link 6",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Tabla de almacenamiento",
      ChS: "存储图表",
      Gr: "Speicherdiagramm",
      Ru: "График хранения",
      Fr: "Tableau de stockage",
      It: "Grafico di archiviazione",
      Pl: "Wykres przechowywania",
      Port: "Gráfico de armazenamento",
      Viet: "Biểu đồ lưu trữ",
      Turk: "Depolama tablosu",
      Ukrn: "Діаграма зберігання",
      Thai: "แผนภูมิการจัดเก็บ",
      Kor: "스토리지 차트",
      ChT: "存儲圖表",
      Jp: "収納チャート",
    },
  },
  discount: {
    label: "Discount",
    checkbox: true,
    field: "$499.99",
  },
  price: {
    label: "Price",
    checkbox: true,
    field: "$99.99 - $139.99",
  },
  cta: {
    label: "CTA Button",
    checkbox: true,
    field: {
      Eng: "Iste",
      Span: "Ver más",
      ChS: "查看更多",
      Gr: "Mehr sehen",
      Ru: "Узнать больше",
      Fr: "Voir plus",
      It: "Vedi altro",
      Pl: "Zobacz więcej",
      Port: "Ver mais",
      Viet: "Xem thêm",
      Turk: "Daha fazla gör",
      Ukrn: "Побачити більше",
      Thai: "ดูเพิ่มเติม",
      Kor: "더보기",
      ChT: "查看更多",
      Jp: "続きを見る",
    },
    charLimit: 10,
  },
  currentLang: "Eng",
};
