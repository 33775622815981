/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import modules from "../../../workers/index.cjs";
import E_logoKingston from "../../1_elements/logoKingston/E_LogoKingston";
import C_SiteSearch from "../../2_components/Search/C_SiteSearch";
import keywords from "../../2_components/Search/navkeywords.json";

const S_Masthead = () => {
  const { appState, setAppState } = useContext(AppContext);
  const toggle = appState.navVisible;

  const clickFunction = () => {
    setAppState({ ...appState, navVisible: !toggle });
  };

  return (
    <div className="s-masthead" role="marquee">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`s-masthead__toggle ${toggle ? "s-masthead__toggle--active" : ""}`}
        aria-hidden="true"
        onClick={clickFunction}
      >
        <svg
          className="s-masthead__toggle__hamburger u-animated u-animated--slowest a-fadeInLeft"
          viewBox="0 0 100 80"
        >
          <rect width="100" height="10" rx="4"></rect>
          <rect width="100" height="10" rx="4" y="30"></rect>
          <rect width="100" height="10" rx="4" y="60"></rect>
        </svg>
      </a>
      <Link to="/">
        <div className="s-masthead__logo u-animated u-animated--slowest u-animated--delay a-fadeIn">
          <E_logoKingston version={"white"} />
          <span className="s-masthead__logo__divider"></span>
          <h2 className="s-masthead__logo__title">Interactive Guidelines</h2>
        </div>
      </Link>
      <C_SiteSearch keywords={keywords} modules={modules} />
    </div>
  );
};

export default S_Masthead;
