// import React, { useState, useEffect, useRef } from "react";
import React, { useState, useRef } from "react";
// import E_NavigationArrow from "../../1_elements/navigationArrow/E_NavigationArrow";

const C_CardScroller = ({ children, ...props }) => {
  let cclasses = props.classes ? props.classes : "";
  let largescrollState = props.largegrid ? props.largegrid : null;
  let loadMoreState = props.loadmore ? props.loadmore : null;

  // ref the scroller
  const scrollerRef = useRef(null);

  // ref scrolling layout
  const scrollerLayoutRef = useRef(null);

  // button hidden states
  const [leftHidden, setLeftHidden] = useState("true");
  const [rightHidden, setRightHidden] = useState("false");

  function clickNext() {
    scrollerLayoutRef.current.scrollLeft += 512;
    detectScroll();
  }

  function clickPrev() {
    scrollerLayoutRef.current.scrollLeft += -512;
    detectScroll();
  }

  function detectScroll() {
    //-- If it's at the start of the scroll, the previous button is hidden
    if (scrollerLayoutRef.current.scrollLeft == 0) {
      setLeftHidden("true");
    }

    //-- If it's at the end of the scroll, the next button is hidden
    if (
      scrollerLayoutRef.current.offsetWidth + scrollerLayoutRef.current.scrollLeft ==
      scrollerLayoutRef.current.scrollWidth
    ) {
      setRightHidden("true");
    }

    //-- During scroll, check to see if the buttons are hidden and make them enabled
    if (
      scrollerLayoutRef.current.scrollLeft > 0 &&
      scrollerLayoutRef.current.offsetWidth + scrollerLayoutRef.current.scrollLeft !=
        scrollerLayoutRef.current.scrollWidth
    ) {
      setLeftHidden("false");
      setRightHidden("false");
    }
  }

  // function checkCentering() {
  //   console.log("checking ref", scrollerLayoutRef.current.scrollWidth);
  //   console.log("checking window", window.innerWidth); //the actual window and not the iframe
  //   if (
  //     scrollerLayoutRef.current.scrollWidth < window.innerWidth &&
  //     !scrollerRef.current.classList.contains("c-cardScroller--center")
  //   ) {
  //     scrollerRef.current.classList.add("c-cardScroller--center");
  //   }

  //   if (
  //     scrollerLayoutRef.current.scrollWidth > window.innerWidth &&
  //     scrollerRef.current.classList.contains("c-cardScroller--center")
  //   ) {
  //     scrollerRef.current.classList.remove("c-cardScroller--center");
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("change", checkCentering());
  //   window.addEventListener("resize", checkCentering());
  // });

  return (
    <div
      className={`c-cardScroller ${cclasses}`}
      largegrid={largescrollState}
      ref={(c) => (scrollerRef.current = c)}
    >
      <button
        className="e-navigationArrow  c-cardScroller__prevBtn"
        aria-label="Previous Slide"
        aria-hidden={leftHidden}
        onClick={clickPrev}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M20.235 7.298a1 1 0 00-1.41 0L12 13.875 5.255 7.344a1 1 0 10-1.4 1.42L12 16.574l8.215-7.866a1 1 0 00.02-1.41z"
            fillRule="nonzero"
          />
        </svg>
      </button>

      {/* <E_NavigationArrow
        naclass="c-cardScroller__prevBtn"
        hiddenState={leftHidden}
        // onClick={clickPrev}
      /> */}

      <div className="c-cardScroller__container">
        <div
          className="c-cardScroller__container__layout"
          ref={(c) => (scrollerLayoutRef.current = c)}
        >
          <ul className="c-cardScroller__container__layout__items">
            {children.map((child, index) => (
              <li className="c-cardScroller__container__layout__items__itemView" key={index}>
                <div className="c-cardScroller__container__layout__items__itemView__item">
                  {child}
                </div>
              </li>
            ))}
          </ul>

          {loadMoreState ? (
            <button className="e-btn c-cardScroller__container__layout__loadMore">
              <span>Load More</span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <button
        className="e-navigationArrow e-navigationArrow--right c-cardScroller__nextBtn"
        aria-label="Next Slide"
        aria-hidden={rightHidden}
        onClick={clickNext}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M20.235 7.298a1 1 0 00-1.41 0L12 13.875 5.255 7.344a1 1 0 10-1.4 1.42L12 16.574l8.215-7.866a1 1 0 00.02-1.41z"
            fillRule="nonzero"
          />
        </svg>
      </button>

      {/* <E_NavigationArrow
        naclass="c-cardScroller__nextBtn"
        dir="right"
        label="Next Slide"
        hiddenState={rightHidden}
        // onClick={clickNext}
      /> */}
    </div>
  );
};

export default C_CardScroller;
