export default {
  sidetab: { label: "Disable Side Tabs", checkbox: false, field: null },

  icons: { label: "Disable Icons", checkbox: false, field: null },
  icon1: { label: "Icon - Side Tab 1", checkbox: null, field: "#star" },
  sidetabfeature: {
    label: "Topic",
    checkbox: null,
    field: {
      Eng: "Featured",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
  },
  icon2: { label: "Icon - Side Tab 2", checkbox: null, field: "#all" },
  tab2: {
    label: "Topic 2",
    checkbox: null,
    field: {
      Eng: "All FAQs",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
  },
  icon3: { label: "Icon - Side Tab 3", checkbox: null, field: "#install" },
  tab3: {
    label: "Topic 3",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Tabla de almacenamiento",
      ChS: "存储图表",
      Gr: "Speicherdiagramm",
      Ru: "График хранения",
      Fr: "Tableau de stockage",
      It: "Grafico di archiviazione",
      Pl: "Wykres przechowywania",
      Port: "Gráfico de armazenamento",
      Viet: "Biểu đồ lưu trữ",
      Turk: "Depolama tablosu",
      Ukrn: "Діаграма зберігання",
      Thai: "แผนภูมิการจัดเก็บ",
      Kor: "스토리지 차트",
      ChT: "存儲圖表",
      Jp: "収納チャート",
    },
  },
  icon4: { label: "Icon - Side Tab 4", checkbox: null, field: "#hdd-2" },
  tab4: {
    label: "Topic 4",
    checkbox: true,
    field: {
      Eng: "Dolor vs Amet",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  icon5: { label: "Icon - Side Tab 5", checkbox: null, field: "#formatting" },
  tab5: {
    label: "Topic 5",
    checkbox: true,
    field: {
      Eng: "Consectetur Adipiscing",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  icon6: { label: "Icon - Side Tab 6", checkbox: null, field: "#key" },
  tab6: {
    label: "Topic 6",
    checkbox: true,
    field: {
      Eng: "Tempor Incididunt",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  icon7: { label: "Icon - Side Tab 7", checkbox: null, field: "#shipping" },
  tab7: {
    label: "Topic 7",
    checkbox: true,
    field: {
      Eng: "Ut Labore",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  icon8: { label: "Icon - Side Tab 8", checkbox: null, field: "#warranty-shield" },
  tab8: {
    label: "Topic 8",
    checkbox: true,
    field: {
      Eng: "Dolore Magna",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  icon9: { label: "Icon - Side Tab 9", checkbox: null, field: "#overclocking-2" },
  tab9: {
    label: "Topic 9",
    checkbox: true,
    field: {
      Eng: "Sed Do Eiusmod",
      Span: "Aprende más",
      ChS: "了解更多",
      Gr: "Erfahren Sie mehr",
      Ru: "Узнать больше",
      Fr: "Apprendre encore plus",
      It: "Saperne di più",
      Pl: "Ucz się więcej",
      Port: "Saber mais",
      Viet: "Tìm hiểu thêm",
      Turk: "Daha fazla bilgi edin",
      Ukrn: "Вивчайте більше",
      Thai: "เรียนรู้เพิ่มเติม",
      Kor: "더 알아보기",
      ChT: "了解更多",
      Jp: "もっと詳しく知る",
    },
  },
  subtitle: {
    label: "Subtitle",
    checkbox: false,
    field: {
      Eng: "FAQs",
      Span: "Subtitular",
      ChS: "副标题",
      Gr: "Untertitel",
      Ru: "Подзаголовок",
      Fr: "Sous-titre",
      It: "Sottotitolo",
      Pl: "Podtytuł",
      Port: "Legenda",
      Viet: "Phụ đề",
      Turk: "Alt yazı",
      Ukrn: "Підзаголовок",
      Thai: "คำบรรยาย",
      Kor: "부제",
      ChT: "副標題",
      Jp: "サブタイトル",
    },
  },
  question1: {
    label: "Question 1",
    checkbox: null,
    field: {
      Eng: "Question 1",
      Span: "Pregunta 1",
      ChS: "问题1",
      Gr: "Frage 1",
      Ru: "Вопрос 1",
      Fr: "Question 1",
      It: "Domanda 1",
      Pl: "Pytanie 1",
      Port: "Questão 1",
      Viet: "Câu hỏi 1",
      Turk: "Soru 1",
      Ukrn: "питання 1",
      Thai: "คำถามที่ 1",
      Kor: "질문 1",
      ChT: "問題1",
      Jp: "質問1",
    },
  },
  answer1: {
    label: "Answer 1",
    checkbox: null,
    field: {
      Eng: "Although the SSD market is growing and SSDs are becoming much more popular, SSD is still a relatively new innovation. As with any new technology, it is only a matter of time before sales increase to a level that will allow manufacturing costs to reduce. In the last few years, the price gap between SSD and HDDs has gotten much smaller.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
    },
  },
  question2: {
    label: "Question 2",
    checkbox: null,
    field: {
      Eng: "Question 2 is longer",
      Span: "Pregunta 2",
      ChS: "问题2",
      Gr: "Frage 2",
      Ru: "Вопрос 2",
      Fr: "Question 2",
      It: "Domanda 2",
      Pl: "Pytanie 2",
      Port: "Questão 2",
      Viet: "Câu hỏi 2",
      Turk: "Soru 2",
      Ukrn: "питання 2",
      Thai: "คำถามที่ 2",
      Kor: "질문 2",
      ChT: "問題2",
      Jp: "質問2",
    },
  },
  answer2: {
    label: "Answer 2",
    checkbox: null,
    field: {
      Eng: "Although the SSD market is growing and SSDs are becoming much more popular.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
    },
  },
  externallink: {
    label: "CTA",
    checkbox: null,
    field: {
      Eng: "Browse all articles",
      Span: "Explorar todos los artículos",
      ChS: "浏览所有文章",
      Gr: "Durchsuchen Sie alle Artikel",
      Ru: "Просмотреть все статьи",
      Fr: "Parcourir tous les articles",
      It: "Sfoglia tutti gli articoli",
      Pl: "Przeglądaj wszystkie artykuły",
      Port: "Procurar todos os artigos",
      Viet: "Duyệt tất cả các bài viết",
      Turk: "Tüm makalelere göz atın",
      Ukrn: "Переглянути всі статті",
      Thai: "รียกดูบทความทั้งหมด",
      Kor: "모든 기사 찾아보기",
      ChT: "瀏覽所有文章",
      Jp: "すべての記事を閲覧する",
    },
  },
  externallinktoggle: { label: "Disable CTA ", checkbox: false, field: null },
  currentLang: "Eng",
};
