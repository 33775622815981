/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dotenv from "dotenv";
import debounce from "lodash.debounce";
import { useContext, useEffect, useRef, useState } from "react";
import Frame from "react-frame-component";
import { useLocation } from "react-router-dom";
// import Tour from "reactour";
import { AppContext } from "../../../AppContext";
import DimensionsContext from "../../../DimensionsContext";
import OptionsContext from "../../../OptionsContext";
import Portal from "../../0_hooks/Portal";
import S_ContentNav from "../../3_sections/contentNav/S_ContentNav";
import S_Headline from "../../3_sections/headline/S_Headline";
import C_Breakpoints from "../Tools/C_Breakpoints";
import C_Resize from "../Tools/C_Resize";
import CIS from "../viewerTabs/CIS/CIS";
import DevTab from "../viewerTabs/DevTab";
import EditContentTab from "../viewerTabs/EditContent/EditContentTab";
import HtmlTab from "../viewerTabs/HtmlTab/C_htmlTab";
import Specs from "../viewerTabs/Specs/Specs";
// import TourButton from "./TourButton";
import { getHTMLData } from "./getHTMLData";
import { getHeight } from "./getHeight";
import { handleWindowResize } from "./handleWindowResize";

const IframeWrapper = ({ children }) => {
  const { appState, setAppState } = useContext(AppContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const { contentOptions } = useContext(OptionsContext);
  //Tabs are the active button tabs below the iframe viewer
  const [currentTab, setCurrentTab] = useState("tabSpecs");
  const [currHtmlName, setCurrHtmlName] = useState(null);
  //display default "showHtml" mode vs edit mode here.
  const [showHtml, setShowHtml] = useState(false);
  const [htmlListenerClicked, setHtmlListenerClicked] = useState(false);
  //this tells the resize drag tool to stop if user is using breakpoints
  const [breakpClicked, setBreakpClicked] = useState(false);
  //cookie and tour
  // const [showTour, setShowTour] = useState(false);
  // const [cookies, setCookie] = useCookies(["tourshown"]);

  const containerRef = useRef();
  const frameRef = useRef();

  dotenv.config();
  var env = process.env.NODE_ENV;

  let path = appState.currentPath;
  //flatHTML is briefly set by save html button
  let saveHtml = appState.flatHTML;

  //get current url from router
  let location = useLocation();
  let pathString = location.pathname;
  let htmlString = "";

  const getHtmlFile = getHTMLData(appState, pathString, setCurrHtmlName);

  //find height needed for viewer
  const getViewHeight = getHeight(dimensions, containerRef, setDimensions, path);

  //Set dimensions when window width resizes
  const handleResize = handleWindowResize(dimensions, setDimensions);

  // const resetCookie = () => {
  //   //sets to expire in 1 year
  //   setCookie("tourshown", false, { path: "/", maxAge: 1704085200 });
  //   setShowTour(true);
  // };

  const xmltoStringHtml = () => {
    if (document.querySelector("#portal-root") !== null) {
      let domHTML = document.querySelector("#portal-root");

      htmlString = new XMLSerializer().serializeToString(domHTML);

      //remove portal div and closing div
      if (htmlString.includes('<div xmlns="http://www.w3.org/1999/xhtml" id="portal-root">')) {
        //replace beginning w empty string
        let phrase = '<div xmlns="http://www.w3.org/1999/xhtml" id="portal-root">';
        htmlString = htmlString.replace(phrase, "");
        //remove 2 divs <div>
        htmlString = htmlString.slice(5);
        //last 12 chars are </div></div>
        htmlString = htmlString.slice(0, -12);
      }
    }
  };

  //set tour cookie
  // useEffect(() => {
  //   if (!cookies.tourshown) {
  //     //sets to expire in 1 year
  //     setCookie("tourshown", false, { path: "/", maxAge: 1704085200 });
  //     setShowTour(true);
  //   }
  // }, []);

  //react-tour settings; uses state so can't be external file.
  const steps = [
    {
      selector: "body",
      content:
        "Welcome to the Kingston Styleguide. You can view and interact with the various content sections used on Kingston.com. Follow this tour to see some of the features.",
      style: {
        color: "#353535",
      },
    },
    {
      selector: ".s-headline__breadcrumbs",
      content: "Here are some breadcrumbs in case you get lost.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: ".c-search",
      content:
        "You can search for any Web Section Demo or Brand Guideline instead of using the menu.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: ".s-headline__googleDoc",
      content:
        "The CMS specs are generally used by the Web Team, and will have very detailed information.",
      style: {
        color: "#353535",
      },
      // action: (node) => {
      //   // by using this, focus trap is temporary disabled
      //   node.focus();
      // },
    },
    {
      selector: ".v-breakpoints",
      content:
        "This is the tool bar where you can see the content at different sizes, or switch from editable to default mode.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: "#inputPixels",
      content: "You can enter the width to view in pixels here...",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: "#inputEms",
      content: "Or the value in EM here. On kingston.com our value is 16px = 1em.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: ".v-breakpoints__modes",
      content:
        "You can also click one of these buttons to choose preset sizes - Mobile, Tablet and Desktop.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
        setHightlight(true);
      },
    },
    {
      selector: "#btnFull",
      content:
        "This one makes the section display full width (which may be larger than the minimum desktop media size).",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: "#btnFiesta",
      content: "This will animate to random sizes...",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: "#btnExt",
      content:
        "And this will open the section in an external window. *Please note that it's NOT an full page example.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: "#web-content",
      content:
        "This is the viewer window. Many items displayed are editable - if there is a default image you can DRAG and DROP a new image to preview as it would appear in the content.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
        setHightlight(false);
        setCurrentTab("tabEdit");
      },
    },
    {
      selector: ".c-editContentPanel",
      content:
        "In this tab you can edit text and other options, like language or theme if available.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      selector: ".s-contentNav__list",
      content:
        "You can change tabs here and see Specs or the View Source Code to see the html if you need it. *Any edits done in the edit tab will be reflected in the html.",
      style: {
        color: "#353535",
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
        setCurrentTab("tabSpecs");
      },
    },
    {
      selector: "body",
      content: "That's it for now. Keep an eye out for new features in the future.",
      style: {
        color: "#353535",
      },
    },
  ];

  //listen for tour button to reset cookie
  // useEffect(() => {
  //   cookies.tourshown === "false" && setShowTour(true);
  // }, [cookies]);

  //redraws the viewer window when handles are dragged
  useEffect(() => {
    //lodash debounce and useMemo most performant way to handle so many rerenders on size change
    const debouncedHandleResize = () => debounce(handleResize, 100);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  // DISABLED because Edit Content changes are overwritten. Usability issue - users probably want to reference the specs while testing content.
  //show html version of section while viewing Specs
  // useEffect(() => {
  //   currentTab == "tabSpecs" && appState.htmlSaved ? setShowHtml(true) : setShowHtml(false);
  // }, [currentTab]);

  //if there's existing html file get the name
  useEffect(() => {
    getHtmlFile();
  }, [appState.htmlSaved, appState.currentPath]);

  //see if developer wants to hide Edit or Default view modes
  useEffect(() => {
    getHtmlFile();
  }, [appState.hideView, appState.currentPath]);

  //resize viewport height for react version
  useEffect(() => {
    //sadly settimout is the only way that resize doesn't happen too soon.

    let reactHeight = setTimeout(function () {
      // console.log("does this timeout run?");
      getViewHeight();
      if (path.includes("/ui")) {
        //set listener to check if the flat html content has had an interaction that might have changed its height, also listen for mouseup in case drag has stopped
        let htmlFrame = document.querySelector("#html-iframe");
        if (showHtml && htmlFrame !== null) {
          htmlFrame.contentDocument.addEventListener("click", () => {
            setHtmlListenerClicked(true);
          });
          htmlFrame.contentDocument.addEventListener("mouseup", () => {
            setHtmlListenerClicked(true);
          });
        }
      }
    }, 500);
    return () => clearTimeout(reactHeight);
  }, [containerRef.current, appState.currentPath, showHtml, dimensions.width, contentOptions]);

  //set viewport height for flat html.
  useEffect(() => {
    if (showHtml && htmlListenerClicked && !dimensions.hardcodeHeight) {
      let htmlHeight = setTimeout(function () {
        let htmlFrame = document.querySelector("#html-iframe");
        let newHeight = htmlFrame.contentWindow.document.body.scrollHeight;
        if (newHeight !== dimensions.viewHeight)
          setDimensions({ ...dimensions, viewHeight: newHeight });
        setHtmlListenerClicked(false);
      }, 800);
      return () => clearTimeout(htmlHeight);
    }
  }, [htmlListenerClicked, handleResize, appState.currentPath, showHtml, dimensions.width]);

  //Part 1 of saving html to server for viewer render. Part 2 is in export btn
  //gets html for currently displayed item in hidden portal and converts to html string, saves to state for output. Portal is used bc it's constantly updated and not stale html, like if ref was used.
  //html output filename is set as appState.outputName in each web section's vars
  useEffect(() => {
    if (document.querySelector("#portal-root") !== null) {
      xmltoStringHtml();
    }

    //html output filename is set as appState.outputName in each web section's vars
    if (htmlString !== "") {
      setAppState({
        ...appState,
        htmlString: htmlString,
      });
    }
  }, [saveHtml]);

  //formats html from portal for use in html tab.
  useEffect(() => {
    if (document.querySelector("#portal-root") !== null) {
      xmltoStringHtml();

      const htmlStr = (str, tab) => {
        // tab = optional indent value, default is tab (\t)
        var formatted = "",
          indent = "";
        tab = tab || "\t";
        str.split(/>\s*</).forEach(function (node) {
          if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
          formatted += indent + "<" + node + ">\r\n";
          if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab; // increase indent
        });
        return formatted.substring(1, formatted.length - 3);
      };

      setAppState({
        ...appState,
        htmltab: htmlStr(htmlString, "  "),
      });
    }
  }, [Portal, currentTab, currHtmlName]);

  // Tab Management
  const [active_tab, setActiveTab] = useState(false);

  useEffect(() => {
    active_tab !== currentTab ? setActiveTab(active_tab) : null;
  }, [currentTab]);

  return (
    <>
      {/* this checks if the current page is brand guidelines or home page (checkpath)and won't render site viewer wrapper */}
      {/* {path.includes("brand") | ( */}
      {!path.includes("/ui") ? (
        <>{children}</>
      ) : (
        // wrapper to demo Kingston sections
        <>
          <div className="v-main__content u-flex u-flex1 u-flexColumn" role="region">
            {/* {showTour ? (
              <Tour
                className="Tour"
                accentColor="#C8102E"
                rounded={4}
                scrollSmooth
                steps={steps}
                isOpen={showTour}
                closeWithMask={false}
                onRequestClose={() => {
                  setShowTour(false);
                  setCookie("tourshown", true, { path: "/" });
                }}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                lastStepNextButton={
                  <TourButton setShowTour={setShowTour} setCookie={() => !setCookie}>
                    Done!
                  </TourButton>
                }
              />
            ) : (
              ""
            )} */}
            <S_Headline
              classMods={"s-headline--alignLeft"}
              headline={appState.headerName}
              breadcrumbs={[
                {
                  url: "/",
                  text: "Home",
                },
                {
                  url: "/web-ui",
                  text: "Web User Interface",
                },
              ]}
              // cookieClick={resetCookie}
            />
            <C_Breakpoints
              bpclick={setBreakpClicked}
              setbpClicked={(val) => setBreakpClicked(val)}
              showHtml={showHtml}
              setShowHtml={setShowHtml}
            />
            {/* Check to see if view flat hmtl (useHTML var) is checked, if so no iframe wrapper is needed here. It's loaded in the section component. Website sections also return different content based on useHTML var. */}
            {showHtml ? (
              // HTML version
              <C_Resize
                contentClicked={htmlListenerClicked}
                bpClicked={breakpClicked}
                setbpClicked={(val) => setBreakpClicked(val)}
              >
                <div style={{ height: `${dimensions.viewHeight}px` }}>
                  <iframe
                    src={`../Views/${currHtmlName}/s${currHtmlName}.html`}
                    style={{ height: `${dimensions.viewHeight}px` }}
                    title="Iframe"
                    id="html-iframe"
                  />
                </div>
              </C_Resize>
            ) : (
              // React version
              <>
                <C_Resize
                  frameRef={frameRef}
                  contentClicked={htmlListenerClicked}
                  bpClicked={breakpClicked}
                  setbpClicked={(val) => setBreakpClicked(val)}
                >
                  <Frame
                    ref={frameRef}
                    id="web-content"
                    initialContent='<!DOCTYPE html><html><head><meta content="width=device-width, initial-scale=1" name="viewport" /><link rel="stylesheet" href="https://use.typekit.net/nvt2bti.css"><link rel="stylesheet" href="https://use.typekit.net/cxe4ruj.css" /></head><body><div className="frame-root" ></div></body></html>'
                    style={{ height: `${dimensions.viewHeight}px` }}
                    head={<link type="text/css" rel="stylesheet" href="/kingston.css" />}
                  >
                    <div ref={containerRef} id="content-container">
                      {children}
                    </div>
                  </Frame>
                </C_Resize>
              </>
            )}
            {/* Tabs buttons here */}
            {/* {env == "development" ? ( */}
            <S_ContentNav
              tabs={[
                env === "development" && { name: "Developer", active: "tabDeveloper" },
                { name: "Dev Specs", active: "tabSpecs" },
                { name: "Edit Content", active: "tabEdit" },
                { name: "View Source Code", active: "tabHtml" },
                { name: "CIS", active: "tabCIS" },
              ]}
              updateContent={setCurrentTab}
              currentContent={currentTab}
              classMods={"s-contentNav--tabs s-contentNav--dark"}
            />
            {/* tabs content here */}
            <div>
              <div
                className="l-inner"
                style={{ paddingTop: "2em", paddingBottom: "2em", minHeight: "25em" }}
              >
                <div className="u-show">
                  {env == "development" && currentTab === "tabDeveloper" ? (
                    <DevTab showHtml={showHtml} setShowHtml={setShowHtml} />
                  ) : currentTab === "tabSpecs" ? (
                    <Specs />
                  ) : currentTab === "tabEdit" ? (
                    <EditContentTab showHtml={showHtml} setShowHtml={setShowHtml} />
                  ) : currentTab === "tabCIS" ? (
                    <CIS />
                  ) : (
                    <HtmlTab />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* This is hidden element stores the html code for html file output and display in source code tab */}
          <Portal id="portal-root">{children}</Portal>
        </>
      )}
    </>
  );
};

export default IframeWrapper;
