import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import modules from "../../../workers/index.cjs";
import "./pg_webui.scss";

const Pg_Webui = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [sections, setSections] = useState([]);
  const [landings, setLandings] = useState([]);
  const [internals, setInternals] = useState([]);
  const [miscs, setMiscs] = useState([]);

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/web-ui",
      outputName: "",
      headerName: "Kingston Web Sections",
      viewHeight: null,
      description: null,
    });

    // setSections([]);
    modules.forEach((module) => {
      let cat = module.categoryType;

      if (typeof cat !== "undefined") {
        switch (true) {
          case cat.includes("landing page"):
            setLandings((landings) => [...landings, module]);
            setSections((sections) => [...sections, module]);
            break;
          case cat.includes("web"):
            setSections((sections) => [...sections, module]);
            break;
          case cat.includes("internal"):
            setInternals((internals) => [...internals, module]);
            break;
          case cat.includes("misc"):
            setMiscs((miscs) => [...miscs, module]);
            break;
          default:
            break;
        }
      }
    });
  }, []);

  if (sections.length < 1 || !appState) return "...Loading";
  return (
    <div className="pg-webMenu">
      <div className="pg-webMenu__main">
        <div className="l-row">
          <h3 style={{ margin: "auto", padding: "1.5em 0 1em" }}>Landing Page Sections</h3>
        </div>
        <div
          className="l-row"
          style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
        >
          {landings.map((landing) => (
            <li key={landing.path} className="l-row__col l-1/1 l-1/5@md">
              <Link to={landing.path.replace(/:.+/, "")}>
                <span>{landing.navtxt}</span>
              </Link>
            </li>
          ))}
        </div>
        <div className="l-row">
          <h3 style={{ margin: "auto", padding: "2em 0 1em" }}>Web Sections</h3>
        </div>
        <div
          className="l-row"
          style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
        >
          {sections.map((section) => (
            <li key={section.component} className="l-row__col l-1/1 l-1/5@md">
              <Link to={section.path.replace(/:.+/, "")}>
                <span>{section.navtxt}</span>
              </Link>
            </li>
          ))}
        </div>
        <div className="l-row">
          <h3 style={{ margin: "auto", padding: "2em 0 1em" }}>Internal Demos</h3>
        </div>
        <div
          className="l-row"
          style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
        >
          {internals.map((internal) => (
            <li key={internal.component} className="l-row__col l-1/1 l-1/5@md">
              <Link to={internal.path.replace(/:.+/, "")}>
                <span>{internal.navtxt}</span>
              </Link>
            </li>
          ))}
        </div>
        <div className="l-row">
          <h3 style={{ margin: "auto", padding: "2em 0 1em" }}>Miscellaneous</h3>
        </div>
        <div
          className="l-row"
          style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
        >
          {miscs.map((misc) => (
            <li key={misc.component} className="l-row__col l-1/1 l-1/5@md">
              <Link to={misc.path.replace(/:.+/, "")}>
                <span>{misc.navtxt}</span>
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  path: "web-ui",
  component: Pg_Webui,
  navtxt: "Kingston Web Sections",
};
