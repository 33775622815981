import React, { useState } from "react";
import DropZone from "../../0_hooks/dropzone";
import { Link } from "react-router-dom";

const C_LandingLarge = ({ Img, title, disabled, link }) => {
  const [featureImage, setFeatureImage] = useState({ preview: "" });

  return (
    <Link
      to={link}
      className={`s-landingGates__col ${
        disabled ? "s-landingGates__col--disabled" : ""
      } l-1/2 u-flex u-flex1`}
    >
      <h2 className="s-landingGates__col__title">{title}</h2>
      <DropZone setImage={setFeatureImage}>
        <img
          className="s-landingGates__col__bg u-animated a-fadeIn"
          src={disabled ? "" : featureImage.preview ? featureImage.preview : Img}
          alt=""
        ></img>
      </DropZone>
    </Link>
  );
};

export default C_LandingLarge;
