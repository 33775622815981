/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import videoGalleryOpts from "./videoGalleryOpts";
// import DropZone from "../../../0_hooks/dropzone";
import C_VideoCard from "../../2_components/videoCard";

const S_VideoGallery = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  // const [phoneImage1, setPhoneImage1] = useState({ preview: "" });
  // const [featureImage1, setFeatureImage1] = useState({ preview: "" });
  // const [phoneImage2, setPhoneImage2] = useState({ preview: "" });
  // const [featureImage2, setFeatureImage2] = useState({ preview: "" });
  // const [phoneImage3, setPhoneImage3] = useState({ preview: "" });
  // const [featureImage3, setFeatureImage3] = useState({ preview: "" });
  // const [phoneImage4, setPhoneImage4] = useState({ preview: "" });
  // const [featureImage4, setFeatureImage4] = useState({ preview: "" });
  // const [phoneImage5, setPhoneImage5] = useState({ preview: "" });
  // const [featureImage5, setFeatureImage5] = useState({ preview: "" });
  // const [phoneImage6, setPhoneImage6] = useState({ preview: "" });
  // const [featureImage6, setFeatureImage6] = useState({ preview: "" });
  // const [phoneImage7, setPhoneImage7] = useState({ preview: "" });
  // const [featureImage7, setFeatureImage7] = useState({ preview: "" });
  // const [phoneImage8, setPhoneImage8] = useState({ preview: "" });
  // const [featureImage8, setFeatureImage8] = useState({ preview: "" });

  useEffect(() => {
    setContentOptions(videoGalleryOpts);
    let tVars = {
      ...appState,
      currentPath: "/ui/video-gallery",
      outputName: "VideoGallery",
      headerName: "Video Gallery",
      tags: ["Landing Page Approved"],
      description:
        "The Video Gallery Section showcases a collection of video thumbnails with titles and descriptions that link to a full view video. Each video card will occupy 4 spaces per row and will center when there are 4 or less video listings.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js"],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*2 images required",
            "Small: Width: 216px, Height: 120px",
            "Medium and Large: Width: 528px, Height: 296px",
          ],
        ],
        ["Image Type", ["JPG"]],
        [
          "Title",
          [
            "Basic text input for title copy for each card. Defaults to an <h3> with class of u-h5 and should text only, will take ~25 characters (spaces included) before wrapping.",
          ],
        ],
        [
          "Copy",
          [
            "Basic text input for paragraph copy for each card. The description appears as a paragraph beneath the heading. No Character limit, will break into 4 lines at ~110 characters.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EVQKUtX83QVAk-YJOD40HmQB-RHU-jwlN1mZ5RuTowvu3w?e=dYYKq3",
        comments: [
          ["Video Gallery Image Sizes", ["Small: 216px x 120px", "Medium: 528px x 296px"]],
        ],
      },
    };
    setAppState(tVars);

    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "511",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions && !contentOptions.videoNum) return "...Loading Video Gallery Section";
  return (
    <section
      className={
        `s-videoGallery ${
          contentOptions.videoNum && contentOptions.videoNum.selected == "1"
            ? "s-videoGallery--1card "
            : ""
        }${
          contentOptions.videoNum && contentOptions.videoNum.selected == "2"
            ? "s-videoGallery--2cards "
            : ""
        }
      ${
        contentOptions.videoNum && contentOptions.videoNum.selected == "3"
          ? "s-videoGallery--3cards "
          : ""
      }` + appState.currentTheme
      }
    >
      {contentOptions.videoNum && contentOptions.videoNum.selected == "1" ? (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              // file deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />
        </div>
      ) : contentOptions.videoNum && contentOptions.videoNum.selected == "2" ? (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />

          <C_VideoCard
            title={
              contentOptions.card2 &&
              contentOptions.card2[0] &&
              contentOptions.card2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card2 && contentOptions.card2[2] && contentOptions.card2[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink2"
          />
        </div>
      ) : contentOptions.videoNum && contentOptions.videoNum.selected == "3" ? (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />

          <C_VideoCard
            title={
              contentOptions.card2 &&
              contentOptions.card2[0] &&
              contentOptions.card2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card2 && contentOptions.card2[2] && contentOptions.card2[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink2"
          />
          <C_VideoCard
            title={
              contentOptions.card3 &&
              contentOptions.card3[0] &&
              contentOptions.card3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card3 &&
                  contentOptions.card3[1] &&
                  contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card3 && contentOptions.card3[2] && contentOptions.card3[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink3"
          />
        </div>
      ) : contentOptions.videoNum && contentOptions.videoNum.selected == "4" ? (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />

          <C_VideoCard
            title={
              contentOptions.card2 &&
              contentOptions.card2[0] &&
              contentOptions.card2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card2 && contentOptions.card2[2] && contentOptions.card2[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink2"
          />
          <C_VideoCard
            title={
              contentOptions.card3 &&
              contentOptions.card3[0] &&
              contentOptions.card3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card3 &&
                  contentOptions.card3[1] &&
                  contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card3 && contentOptions.card3[2] && contentOptions.card3[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink3"
          />
          <C_VideoCard
            title={
              contentOptions.card4 &&
              contentOptions.card4[0] &&
              contentOptions.card4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card4 &&
                  contentOptions.card4[1] &&
                  contentOptions.card4[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card4 && contentOptions.card4[2] && contentOptions.card4[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink4"
          />
        </div>
      ) : contentOptions.videoNum && contentOptions.videoNum.selected == "6" ? (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />

          <C_VideoCard
            title={
              contentOptions.card2 &&
              contentOptions.card2[0] &&
              contentOptions.card2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card2 && contentOptions.card2[2] && contentOptions.card2[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink2"
          />
          <C_VideoCard
            title={
              contentOptions.card3 &&
              contentOptions.card3[0] &&
              contentOptions.card3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card3 &&
                  contentOptions.card3[1] &&
                  contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card3 && contentOptions.card3[2] && contentOptions.card3[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink3"
          />
          <C_VideoCard
            title={
              contentOptions.card4 &&
              contentOptions.card4[0] &&
              contentOptions.card4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card4 &&
                  contentOptions.card4[1] &&
                  contentOptions.card4[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card4 && contentOptions.card4[2] && contentOptions.card4[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink4"
          />
          <C_VideoCard
            title={
              contentOptions.card5 &&
              contentOptions.card5[0] &&
              contentOptions.card5[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card5 &&
                  contentOptions.card5[1] &&
                  contentOptions.card5[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card5 && contentOptions.card5[2] && contentOptions.card5[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink5"
          />
          <C_VideoCard
            title={
              contentOptions.card6 &&
              contentOptions.card6[0] &&
              contentOptions.card6[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card6 &&
                  contentOptions.card6[1] &&
                  contentOptions.card6[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card6 && contentOptions.card6[2] && contentOptions.card6[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink6"
          />
        </div>
      ) : (
        <div className="s-videoGallery__content">
          <C_VideoCard
            title={
              contentOptions.card1 &&
              contentOptions.card1[0] &&
              contentOptions.card1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card1 &&
                  contentOptions.card1[1] &&
                  contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card1 && contentOptions.card1[2] && contentOptions.card1[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink1"
          />

          <C_VideoCard
            title={
              contentOptions.card2 &&
              contentOptions.card2[0] &&
              contentOptions.card2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card2 &&
                  contentOptions.card2[1] &&
                  contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card2 && contentOptions.card2[2] && contentOptions.card2[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink2"
          />
          <C_VideoCard
            title={
              contentOptions.card3 &&
              contentOptions.card3[0] &&
              contentOptions.card3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card3 &&
                  contentOptions.card3[1] &&
                  contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card3 && contentOptions.card3[2] && contentOptions.card3[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink3"
          />
          <C_VideoCard
            title={
              contentOptions.card4 &&
              contentOptions.card4[0] &&
              contentOptions.card4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card4 &&
                  contentOptions.card4[1] &&
                  contentOptions.card4[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card4 && contentOptions.card4[2] && contentOptions.card4[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink4"
          />
          <C_VideoCard
            title={
              contentOptions.card5 &&
              contentOptions.card5[0] &&
              contentOptions.card5[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card5 &&
                  contentOptions.card5[1] &&
                  contentOptions.card5[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card5 && contentOptions.card5[2] && contentOptions.card5[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink5"
          />
          <C_VideoCard
            title={
              contentOptions.card6 &&
              contentOptions.card6[0] &&
              contentOptions.card6[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card6 &&
                  contentOptions.card6[1] &&
                  contentOptions.card6[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card6 && contentOptions.card6[2] && contentOptions.card6[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink6"
          />
          <C_VideoCard
            title={
              contentOptions.card7 &&
              contentOptions.card7[0] &&
              contentOptions.card7[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card7 &&
                  contentOptions.card7[1] &&
                  contentOptions.card7[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card7 && contentOptions.card7[2] && contentOptions.card7[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink7"
          />
          <C_VideoCard
            title={
              contentOptions.card8 &&
              contentOptions.card8[0] &&
              contentOptions.card8[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.descriptionoption && contentOptions.descriptionoption.checkbox == true
                ? contentOptions.card8 &&
                  contentOptions.card8[1] &&
                  contentOptions.card8[1].field[contentOptions.currentLang]
                : ""
            }
            time={contentOptions.card8 && contentOptions.card8[2] && contentOptions.card8[2].field}
            imagesmall="/images/fpo/general/216x120.png"
            imagelarge="/images/fpo/general/528x296.png"
            url="#videoLink8"
          />
        </div>
      )}
      {contentOptions.cta && contentOptions.cta.checkbox ? (
        <div className="s-videoGallery__cta">
          <button className="e-btn">More Videos</button>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "video-gallery",
  component: S_VideoGallery,
  navtxt: "Video Gallery",
  htmlName: "VideoGallery",
  categoryType: ["web"],
};
