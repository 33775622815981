import { BrowserRouter } from "react-router-dom";
import ScrollToAnchor from "./ScrollToAnchor";

const AppRouter = ({ children }) => {
  return (
    <BrowserRouter>
      <ScrollToAnchor />
      {children}
    </BrowserRouter>
  );
};

export default AppRouter;
