import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
let phoneDefImg = "/images/fpo/general/512x608.png";
let tabDefImg = "/images/fpo/general/1024x784.png";
let deskDefImg = "/images/fpo/general/2048x960.png";

const S_Header = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [phone, setPhone] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [desktop, setDesktop] = useState(null);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let keyVars = {
      ...appState,
      currentPath: "/ui/header",
      outputName: "Header",
      headerName: "Header",
      tags: ["Landing Page Approved"],
      description:
        "The Header Section serves as a grand headline for a product or topic. This section can support the largest body of text when compared to other headline related sections (promo and hero). This section is intended to be the first section of any template with related content in the sections that follow.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*All 3 sizes required. ",
            "Small: 512 × 600+",
            "Medium: 1024 × 250+",
            "Large: 2048 × 340+",
          ],
        ],
        [
          "",
          [
            "*Large FPO image used to demonstrate cropping.",
            "*Image max display height is 550px, and will crop in the center. Was made to accomodate various older image sizes without breaking.",
          ],
        ],
        ["Image Type", ["JPG"]],
        ["Title", ["Character Limit: 100"]],
        ["Copy", ["Character Limit: 400"]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1bxWMPwjiWYYu3ZzOJJfQd7P6z_S5jYlz-lmeCzLDIuA/edit?usp=sharing",
        comments: [
          [
            "Header Image Sizes",
            ["Small: 512px × 600px+", "Medium: 1024px × 250px+", "Large: 2048px × 340px+"],
          ],
        ],
      },
    };
    setAppState(keyVars);

    setContentOptions({
      lightMode: { label: "Light Mode", checkbox: false, field: null },
      heading: {
        label: "Title",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
        charLimit: 100,
      },
      paragraph: {
        label: "Copy",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          Span: "Hacía calor en ese tiempo, para mí,",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn Sch",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
          Fr: "Il faisait chaud dans ce temps-là, pour moi",
          It: "Faceva caldo con quel tempo, per me, non lo ",
          Pl: "Było gorąco w taką pogodę, jak dla mnie",
          Port: "Fazia calor naquele tempo, pra mim, não",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
          Ukrn: "У таку погоду було спекотно, для мене",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... --",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察た",
        },
        charLimit: 400,
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Header Section";
  return (
    <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
      <section
        className={`s-header ${
          contentOptions.lightMode && contentOptions.lightMode.checkbox ? "s-header--lightMode" : ""
        }`}
      >
        <div className="s-header__copy">
          <div className="l-inner">
            <h1 className="u-h3">
              {contentOptions.heading && contentOptions.heading.field
                ? contentOptions.heading.field[contentOptions.currentLang]
                : "Header Headline"}
            </h1>
            {contentOptions.paragraph && contentOptions.paragraph.checkbox ? (
              <p>{contentOptions.paragraph.field[contentOptions.currentLang]}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="s-header__background">
          <picture className="e-picture">
            <source
              srcSet={phone && phone.phone ? phone.phone : phoneDefImg}
              media="(max-width:32em)"
            />
            <source
              srcSet={desktop && desktop.desktop ? desktop.desktop : deskDefImg}
              media="(min-width:76em)"
            />
            <img src={tablet && tablet.tablet ? tablet.tablet : tabDefImg} alt="Alternate Text" />
          </picture>
        </div>
      </section>
    </DropZone>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "header",
  component: S_Header,
  navtxt: "Header",
  htmlName: "Header",
  categoryType: ["landing page", "web"],
};
