import React from "react";

const C_SchemaCard = ({ value = "256", unit = "MB", empty }) => {
  let isEmptyClass = "";
  if (empty) {
    isEmptyClass = "c-schemaCard--empty";
  }
  return (
    <>
      <div className={`c-schemaCard ` + isEmptyClass}>
        {empty ? (
          ""
        ) : (
          <>
            <span className={`c-schemaCard__value`}>{value}</span>
            <span className={`c-schemaCard__unit`}>{unit}</span>
          </>
        )}
      </div>
    </>
  );
};

export default C_SchemaCard;
