/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import L_GridFlex from "../../0_layout/L_gridFlex";
import C_Card from "../../2_components/card/C_Card";
import bannerOptions from "./bannerOpts";

const S_Banners = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let bannerVars = {
      ...appState,
      currentPath: "/ui/banners",
      outputName: "Banners",
      headerName: "Banners",
      currentTheme: "t-pearl",
      description:
        "The Banners Section organizes photos with titles and description into a grid based layout using cards that contain the photo, title and description and is linked to content on the site. The number of cards per row changes based on page width.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/gridFlex.layout.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 Image Required", "Small, Medium, Large: Width: 576px, Height: 200px"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        [
          "Title",
          [
            "Defaults to an <h3> and should only be text.",
            "Note: Please keep titles CONCISE. Recommend 160 characters or LESS",
          ],
        ],
        [
          "Copy",
          [
            "The copy appears as a paragraph beneath the title.",
            "Note: Recommend 250 characters or LESS",
          ],
        ],
        ["Link", ["Link to any kingston page or product."]],
        ["Themes", ["Recommend: Use light themes."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EaxrRZAycCJOq_vdhFTO9coBj6G2Pz-nNuZLtEXFarK9FA?e=XprBVe",
        comments: [["Banner Image Size", ["576px x 200px"]]],
      },
    };
    setAppState(bannerVars);

    setContentOptions(bannerOptions);
    setDimensions({
      ...dimensions,
      viewHeight: "346",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Banners";
  return (
    <section
      className={
        `s-banners ${
          contentOptions.numCards &&
          (contentOptions.numCards.selected == "1" ||
            contentOptions.numCards.selected == "2" ||
            contentOptions.numCards.selected == "3")
            ? "s-banners--center "
            : ""
        }` + appState.currentTheme
      }
    >
      {contentOptions.numCards && contentOptions.numCards.selected == 2 ? (
        <L_GridFlex
          min="300"
          max="576"
          numberOfCards={contentOptions.numCards && contentOptions.numCards.selected}
        >
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      ) : contentOptions.numCards && contentOptions.numCards.selected == 3 ? (
        <L_GridFlex min="300" max="576" numberOfCards={contentOptions.numCards.selected}>
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link3"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner3 &&
              contentOptions.banner3[0] &&
              contentOptions.banner3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner3 &&
              contentOptions.banner3[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner3[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner3 &&
              contentOptions.banner3[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner3[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      ) : contentOptions.numCards && contentOptions.numCards.selected == 4 ? (
        <L_GridFlex min="300" max="576" numberOfCards={contentOptions.numCards.selected}>
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link3"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner3 &&
              contentOptions.banner3[0] &&
              contentOptions.banner3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner3 &&
              contentOptions.banner3[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner3[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner3 &&
              contentOptions.banner3[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner3[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link4"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner4 &&
              contentOptions.banner4[0] &&
              contentOptions.banner4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner4 &&
              contentOptions.banner4[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner4[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner4 &&
              contentOptions.banner4[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner4[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      ) : contentOptions.numCards && contentOptions.numCards.selected == 5 ? (
        <L_GridFlex min="300" max="576" numberOfCards={contentOptions.numCards.selected}>
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link3"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner3 &&
              contentOptions.banner3[0] &&
              contentOptions.banner3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner3 &&
              contentOptions.banner3[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner3[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner3 &&
              contentOptions.banner3[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner3[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link4"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner4 &&
              contentOptions.banner4[0] &&
              contentOptions.banner4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner4 &&
              contentOptions.banner4[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner4[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner4 &&
              contentOptions.banner4[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner4[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link5"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner5 &&
              contentOptions.banner5[0] &&
              contentOptions.banner5[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner5 &&
              contentOptions.banner5[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner5[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner5 &&
              contentOptions.banner5[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner5[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      ) : contentOptions.numCards && contentOptions.numCards.selected == 6 ? (
        <L_GridFlex min="300" max="576" numberOfCards={contentOptions.numCards.selected}>
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.banner1[1].checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.banner1[2].checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link3"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner3 &&
              contentOptions.banner3[0] &&
              contentOptions.banner3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner3 &&
              contentOptions.banner3[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner3[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner3 &&
              contentOptions.banner3[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner3[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link4"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner4 &&
              contentOptions.banner4[0] &&
              contentOptions.banner4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner4 &&
              contentOptions.banner4[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner4[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner4 &&
              contentOptions.banner4[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner4[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link5"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner5 &&
              contentOptions.banner5[0] &&
              contentOptions.banner5[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner5 &&
              contentOptions.banner5[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner5[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner5 &&
              contentOptions.banner5[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner5[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link6"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner6 &&
              contentOptions.banner6[0] &&
              contentOptions.banner6[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner6 &&
              contentOptions.banner6[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner6[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner6 &&
              contentOptions.banner6[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner6[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      ) : (
        <L_GridFlex
          min="300"
          max="576"
          numberOfCards={contentOptions.numCards && contentOptions.numCards.selected}
        >
          <C_Card
            type="banner"
            url="#link"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner1 &&
              contentOptions.banner1[0] &&
              contentOptions.banner1[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner1 &&
              contentOptions.banner1[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner1[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner1 &&
              contentOptions.banner1[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner1[2].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_Card
            type="banner"
            url="#link2"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner2 &&
              contentOptions.banner2[0] &&
              contentOptions.banner2[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner2 &&
              contentOptions.banner2[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner2[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner2 &&
              contentOptions.banner2[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner2[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link3"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner3 &&
              contentOptions.banner3[0] &&
              contentOptions.banner3[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner3 &&
              contentOptions.banner3[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner3[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner3 &&
              contentOptions.banner3[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner3[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link4"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner4 &&
              contentOptions.banner4[0] &&
              contentOptions.banner4[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner4 &&
              contentOptions.banner4[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner4[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner4 &&
              contentOptions.banner4[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner4[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link5"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner5 &&
              contentOptions.banner5[0] &&
              contentOptions.banner5[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner5 &&
              contentOptions.banner5[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner5[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner5 &&
              contentOptions.banner5[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner5[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link6"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner6 &&
              contentOptions.banner6[0] &&
              contentOptions.banner6[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner6 &&
              contentOptions.banner6[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner6[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner6 &&
              contentOptions.banner6[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner6[2].field[contentOptions.currentLang]
                : ""
            }
          />

          <C_Card
            type="banner"
            url="#link7"
            image="/images/fpo/general/576x200.png"
            title={
              contentOptions.banner7 &&
              contentOptions.banner7[0] &&
              contentOptions.banner7[0].field[contentOptions.currentLang]
            }
            copy={
              contentOptions.banner7 &&
              contentOptions.banner7[1] &&
              contentOptions.copyoptions &&
              contentOptions.copyoptions.checkbox == true
                ? contentOptions.banner7[1].field[contentOptions.currentLang]
                : ""
            }
            cta={
              contentOptions.banner7 &&
              contentOptions.banner7[2] &&
              contentOptions.ctaoptions &&
              contentOptions.ctaoptions.checkbox == true
                ? contentOptions.banner7[2].field[contentOptions.currentLang]
                : ""
            }
          />
        </L_GridFlex>
      )}
    </section>
  );
};

{
  /* <L_GridFlex min="300" max="576"></L_GridFlex> */
}

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "banners",
  component: S_Banners,
  navtxt: "Banners",
  htmlName: "Banners",
  categoryType: ["web"],
};
