/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_TechSpecs = () => {
  // add instance of appstate
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let tVars = {
      ...appState,
      currentPath: "/ui/tech-specs",
      outputName: "TechSpecs",
      headerName: "Tech Specs",
      tags: null,
      description:
        "The Tech Specs section displays detailed information relating to any specific product.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-stone",
      scripts: [],
      specs: [
        ["Table Heading", ["String text within <th>. Recommend < 100 characters."]],
        [
          "Table Rows",
          [
            "Table Data Left: String text within <td>. Recommend < 30 characters.",
            "Table Data Right: String text within <td>. No Character limit. Text wraps.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERtDFiEX8O1DmYyNayoz_zMBT1Neaw9VbvtOEhh2OsuzrA?e=3Ra3q7",
        comments: null,
      },
    };
    setAppState(tVars);
    setContentOptions({
      tableheading: {
        label: "Table Heading",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
        charLimit: 100,
      },
      tabledata1: {
        label: "Table Data 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来说，我真的不知道",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор сит амет",
          Fr: "Il faisait chaud dans ce temps-là",
          It: "Faceva caldo con quel tempo",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı, benim için",
          Ukrn: "У таку погоду було спекотн",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
          Kor: "국민경제의 발전을 위한 중요정책의",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨ",
        },
        charLimit: 30,
      },
      tabledata2: {
        label: "Table Data 2",
        checkbox: null,
        field: {
          Eng: "Quis varius nunc sed id semper",
          Span: "Hacía calor en ese tiempo, para mí",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn",
          Ru: "Лорем ипсум долор сит амет, пер цлита",
          Fr: "Il faisait chaud dans ce temps-là, pour",
          It: "Faceva caldo con quel tempo, per mm",
          Pl: "Było gorąco w taką pogodę, jak dla",
          Port: "Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi",
          Turk: "O hava sıcaktı, benim için, pek",
          Ukrn: "У таку погоду було спекотно, для",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "665",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!appState) return "...Loading System Scan Results - No Results Section";
  return (
    <section className={`s-techSpecs ` + appState.currentTheme}>
      <div className="l-inner">
        <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
          <div className="c-table__main">
            <table>
              <thead>
                <tr>
                  <th colSpan="2">
                    {contentOptions.tableheading &&
                      contentOptions.tableheading.field[contentOptions.currentLang]}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {contentOptions.tabledata1 &&
                      contentOptions.tabledata1.field[contentOptions.currentLang]}
                  </td>
                  <td>
                    {contentOptions.tabledata2 &&
                      contentOptions.tabledata2.field[contentOptions.currentLang]}
                  </td>
                </tr>
                <tr>
                  <td>Speeds</td>
                  <td>4800MT/s, 5200MT/s, 5600MT/s, 6000MT/s</td>
                </tr>
                <tr>
                  <td>Latencies</td>
                  <td>CL36, CL38, CL40</td>
                </tr>
                <tr>
                  <td>Voltage</td>
                  <td>1.1V, 1.25V, 1.35V</td>
                </tr>
                <tr>
                  <td>Operating Temperature</td>
                  <td>0 °C to 85 °C</td>
                </tr>
                <tr>
                  <td>T.H.D.</td>
                  <td>&lt; 2%</td>
                </tr>
                <tr>
                  <td>Dimensions</td>
                  <td>133.35 mm x 42.23 mm x 7.11 mm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "tech-specs",
  component: S_TechSpecs,
  navtxt: "Tech Specs",
  htmlName: "TechSpecs",
  categoryType: ["web"],
};
