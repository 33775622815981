/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import breadcrumbsOpts from "./breadcrumbsOpts";

const S_Breadcrumbs = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  useEffect(() => {
    let breadcrumbVars = {
      ...appState,
      currentPath: "/ui/breadcrumbs",
      outputName: "Breadcrumbs",
      headerName: "Breadcrumbs",
      tags: null,
      currentTheme: "t-pearl",
      description:
        "The Breadcrumbs section shows where the current page is in the website’s hierarchy. It is a type of secondary navigation that allows users to find their way back to the original page",
      related: null,
      htmlSaved: true,
      hideView: null,
      scripts: ["../../js/rexusManager.component.js", "breadcrumbs.section.js"],
      specs: [
        ["Link", ["String text"]],
        ["", ["First breadcrumb link always links to the homepage"]],
        ["", ["Minimum: 2 breadcrumb links", "Maximum: Recommend up to 4 breadcrumb links"]],
        ["Themes", ["Only available for White and Pearl. Please ignore all other themes."]],
        ["Note", ["Should only be at the top of general web content."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EVxnmRgeHQJOiFlbUzHg1xEB3NKXGwt4638M-2p6J_MqRg?e=xbTqfh",
        comments: null,
      },
    };
    setAppState(breadcrumbVars);
    setContentOptions(breadcrumbsOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);
  if (!contentOptions) return "...Loading Breadcrumbs";
  return (
    <nav className={"s-breadcrumbs " + appState.currentTheme} aria-label="Breadcrumbs">
      <div className="l-inner">
        <ol>
          {contentOptions.numBreadcrumbLinks && contentOptions.numBreadcrumbLinks.selected >= 2 ? (
            <>
              <li>
                <a href="#">
                  {contentOptions.link1 && contentOptions.link1.field[contentOptions.currentLang]}
                </a>
              </li>
              {contentOptions.numBreadcrumbLinks.selected == 2 ? (
                <>
                  <li>
                    <a href="#">
                      {contentOptions.link2 &&
                        contentOptions.link2.field[contentOptions.currentLang]}
                    </a>
                  </li>
                  <li>
                    <span className="u-srOnly" aria-current="page">
                      Lorem ipsum current page
                    </span>
                  </li>
                </>
              ) : (
                <li>
                  <a href="#">
                    {contentOptions.link2 && contentOptions.link2.field[contentOptions.currentLang]}
                  </a>
                </li>
              )}
            </>
          ) : (
            ""
          )}
          {contentOptions.numBreadcrumbLinks && contentOptions.numBreadcrumbLinks.selected >= 3 ? (
            <>
              {contentOptions.numBreadcrumbLinks.selected == 3 ? (
                <>
                  <li>
                    <a href="#">
                      {contentOptions.link3 &&
                        contentOptions.link3.field[contentOptions.currentLang]}
                    </a>
                  </li>
                  <li>
                    <span className="u-srOnly" aria-current="page">
                      Lorem ipsum current page
                    </span>
                  </li>
                </>
              ) : (
                <li>
                  <a href="#">
                    {contentOptions.link3 && contentOptions.link3.field[contentOptions.currentLang]}
                  </a>
                </li>
              )}
            </>
          ) : (
            ""
          )}
          {contentOptions.numBreadcrumbLinks && contentOptions.numBreadcrumbLinks.selected >= 4 ? (
            <>
              <li>
                <a href="#">
                  {contentOptions.link4 && contentOptions.link4.field[contentOptions.currentLang]}
                </a>
              </li>
              <li>
                <span className="u-srOnly" aria-current="page">
                  Lorem ipsum current page
                </span>
              </li>
            </>
          ) : (
            ""
          )}
          {contentOptions.numBreadcrumbLinks &&
          contentOptions.numBreadcrumbLinks.selected == "5 or more" ? (
            <>
              <li>
                <a href="#">
                  {contentOptions.link1 && contentOptions.link1.field[contentOptions.currentLang]}
                </a>
              </li>
              {!showBreadcrumbs ? (
                <li className={` ${showBreadcrumbs ? "u-hide" : ""}`}>
                  <button
                    aria-label="Show more breadcrumbs"
                    onClick={() => setShowBreadcrumbs(true)}
                  >
                    ...
                  </button>
                </li>
              ) : (
                ""
              )}

              {showBreadcrumbs ? (
                <>
                  <li>
                    <a href="#">
                      {contentOptions.link2 &&
                        contentOptions.link2.field[contentOptions.currentLang]}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {contentOptions.link3 &&
                        contentOptions.link3.field[contentOptions.currentLang]}
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
              <li>
                <a href="#">
                  {contentOptions.link4 && contentOptions.link4.field[contentOptions.currentLang]}
                </a>
              </li>
              <li>
                <a href="#">
                  {contentOptions.link5 && contentOptions.link5.field[contentOptions.currentLang]}
                </a>
              </li>
              <li>
                <span className="u-srOnly" aria-current="page">
                  Lorem ipsum current page
                </span>
              </li>
            </>
          ) : (
            ""
          )}
        </ol>
      </div>
    </nav>
  );
};

export default {
  path: "breadcrumbs",
  component: S_Breadcrumbs,
  navtxt: "Breadcrumbs",
  htmlName: "Breadcrumbs",
  categoryType: ["internal"],
};
