/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Forms = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/forms",
      outputName: "forms",
      headerName: "Forms",
      tags: null,
      description: "The Forms section.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/dropDown.component.js",
        "../../js/formsLegacy.component.js",
        "../../js/tooltip.component.js",
      ],
      specs: [
        ["SVG", ["Any square ratio (1:1) SVGs will work best"]],
        ["Copy", ["Character Limit: 100"]],
      ],
      cis: null,
    };
    setAppState(fbVars);

    setContentOptions({ zcurrentLang: "Eng" });
    setDimensions({ ...dimensions });
  }, []);

  if (!contentOptions) return "...Loading Forms";
  return (
    <section className={`s-forms ` + appState.currentTheme}>
      <form style={{ maxWidth: "32em", margin: "auto" }}>
        <fieldset>
          <legend>TEXT INPUTS</legend>
        </fieldset>
        <div className="f-input">
          <div className="f-input__string">
            <input type="text" name="inputName" id="inputName" required="required" />
            <label htmlFor="inputName">Text Input LEGACY (Required)</label>
          </div>
        </div>
        <div className="f-input">
          <div className="f-input__string">
            <textarea name="textArea" id="textArea" required="required"></textarea>
            <label htmlFor="textArea">Textarea Label</label>
          </div>
        </div>
        <fieldset>
          <legend>Title</legend>
          <div className="f-input">
            <div className="f-input__radio">
              <input type="radio" name="radio" id="radio1" value="radio one" />
              <label htmlFor="radio1">Radio 1</label>
            </div>
            <div className="f-input__radio">
              <input type="radio" name="radio" id="radio2" value="radio two" />
              <label htmlFor="radio2">Radio 2</label>
            </div>
            <div className="f-input__radio">
              <input type="radio" name="radio" id="radio3" value="radio three" />
              <label htmlFor="radio3">Radio 3</label>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="u-srOnly">Hidden Title</legend>
          <div className="f-input">
            <div className="f-input__radio">
              <input type="radio" name="radio2" id="radio4" value="radio one" />
              <label htmlFor="radio4">Radio 1</label>
            </div>
            <div className="f-input__radio">
              <input type="radio" name="radio2" id="radio5" value="radio two" />
              <label htmlFor="radio5">Radio 2</label>
            </div>
            <div className="f-input__radio">
              <input type="radio" name="radio2" id="radio6" value="radio three" />
              <label htmlFor="radio6">Radio 3</label>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Title</legend>
          <div className="f-input">
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox1"
                required="required"
                value="checkbox 1"
              />
              <label htmlFor="checkbox1">Checkbox Label</label>
            </div>
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox2"
                required="required"
                value="checkbox 2"
              />
              <label htmlFor="checkbox2">Checkbox Label</label>
            </div>
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox3"
                required="required"
                value="checkbox 3"
              />
              <label htmlFor="checkbox3">Checkbox Label</label>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="u-srOnly">Hidden Title</legend>
          <div className="f-input">
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox4"
                required="required"
                value="checkbox 4"
              />
              <label htmlFor="checkbox4">Checkbox Label</label>
            </div>
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox5"
                required="required"
                value="checkbox 5"
              />
              <label htmlFor="checkbox5">Checkbox Label</label>
            </div>
            <div className="f-input__checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox6"
                required="required"
                value="checkbox 6"
              />
              <label htmlFor="checkbox6">Checkbox Label</label>
            </div>
          </div>
        </fieldset>
        <div className="f-input">
          <div className="f-input__select">
            <select name="selectName" id="selectName" required="required" aria-label="Select Label">
              {/* file deepcode ignore ReactDeprecatedElementProp: this code is old, can only be updated in new project later */}
              <option hidden="hidden" disabled="disabled" selected="selected"></option>
              <option value="textVal1">Value 1</option>
              <option value="textVal2">Value 2</option>
              <option value="textVal3">Value 3</option>
            </select>
            <label htmlFor="selectName">Select Label</label>
          </div>
        </div>
        <div className="f-input f-input--outlined">
          <div className="f-input__select">
            <select
              name="selectName2"
              id="selectName2"
              required="required"
              aria-label="Select Label"
            >
              <option hidden="hidden" disabled="disabled" selected="selected"></option>
              <option value="textVal1">Value 1</option>
              <option value="textVal2">Value 2</option>
              <option value="textVal3">Value 3</option>
            </select>
            <label htmlFor="selectName2">Select Label</label>
          </div>
        </div>
        <div className="f-input">
          <div className="f-input__string">
            <input type="text" name="inputTool" id="inputTool" required="required" />
            <label htmlFor="inputTool">Text Input Tooltip</label>
          </div>
        </div>
        <div className="c-tooltip__group">
          <div className="c-tooltip__message">
            <p>Default Tooltip</p>
          </div>
          <div className="c-tooltip__arrow"></div>
        </div>
        <div className="f-input">
          <div className="f-input__string">
            <input type="text" name="" id="inputTool2" required="required" />
            <label htmlFor="inputTool2">Text Input Tooltip</label>
          </div>
        </div>
        <div className="c-tooltip__group">
          <div className="c-tooltip__message c-tooltip__message--error">
            <p>Error Tooltip</p>
          </div>
          <div className="c-tooltip__arrow"></div>
        </div>
        <div className="f-input">
          <div className="f-input__string">
            <input type="text" name="" id="inputTool3" required="required" />
            <label htmlFor="inputTool3">Text Input Tooltip</label>
          </div>
        </div>
        <div className="c-tooltip__group">
          <div className="c-tooltip__message c-tooltip__message--helper">
            <p>Helper Tooltip</p>
          </div>
          <div className="c-tooltip__arrow"></div>
        </div>
        <div className="l-row">
          <div className="l-row__col l-1/2@md">
            <div className="f-input">
              <div className="f-input__string">
                <input type="text" name="" id="inputTool4" required="required" />
                <label htmlFor="inputTool4">Error Tooltip</label>
              </div>
            </div>
            <div className="c-tooltip__group">
              <div className="c-tooltip__message c-tooltip__message--error">
                <p>Error Tooltip</p>
              </div>
              <div className="c-tooltip__arrow"></div>
            </div>
          </div>
          <div className="l-row__col l-1/2@md">
            <div className="f-input">
              <div className="f-input__string">
                <input type="text" name="" id="inputTool5" required="required" />
                <label htmlFor="inputTool5">Helper Tooltip with Link</label>
              </div>
            </div>
            <div className="c-tooltip__group">
              <div className="c-tooltip__message c-tooltip__message--helper">
                <p>Helper Tooltip</p>
                <a href="#">Helper tooltip with link</a>
              </div>
              <div className="c-tooltip__arrow"></div>
            </div>
          </div>
        </div>
        <div className="l-row">
          <div className="l-row__col l-1/2@md">
            <div className="f-input c-tooltip" data-multiformgroup="grouptest">
              <div className="f-input__string">
                <input type="text" name="" id="inputTool6" required="required" />
                <label htmlFor="inputTool6">Error Tooltip</label>
              </div>
            </div>
          </div>
          <div className="l-row__col l-1/2@md">
            <div className="f-input c-tooltip" data-multiformgroup="grouptest2">
              <div className="f-input__string">
                <input type="text" name="" id="inputTool7" required="required" />
                <label htmlFor="inputTool7">Helper Tooltip</label>
              </div>
            </div>
          </div>
          <div className="l-row__col c-tooltip__group" id="grouptest">
            <div className="c-tooltip__message">
              <p>Group</p>
            </div>
            <div className="c-tooltip__arrow"></div>
          </div>
          <div className="l-row__col c-tooltip__group" id="grouptest2">
            <div className="c-tooltip__message">
              <p>Group 2</p>
            </div>
            <div className="c-tooltip__arrow"></div>
          </div>
        </div>
        <div
          className="f-input f-input--dropDown f-input--dropActive"
          aria-describedby="selectTooltip"
        >
          <div className="f-input__select">
            <select
              name="selectName3"
              id="selectName3"
              required="required"
              aria-label="Select Label"
            >
              <option hidden="hidden" disabled="disabled" selected="selected"></option>
              <option value="textVal1">Value 1</option>
              <option value="textVal2">Value 2</option>
              <option value="textVal3">Value 3</option>
            </select>
            <label htmlFor="selectName2">Select Label</label>
          </div>
        </div>
        <div className="c-tooltip__group">
          <div className="c-tooltip__message c-tooltip__message--helper">
            <p>Helper Tooltip </p>
          </div>
          <div className="c-tooltip__arrow"></div>
        </div>
      </form>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "forms",
  component: S_Forms,
  navtxt: "Forms",
  htmlName: "forms",
  categoryType: ["internal"],
};
