/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import E_ArrowCTA from "../../1_elements/arrowCTA/E_ArrowCTA";
import C_BlogCard from "../../2_components/blogCard/C_BlogCard";
import relatedArticleOpts from "./relatedArticleOpts";

const S_RelatedArticles = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const centered =
    contentOptions.numFeatures && contentOptions.numFeatures.selected === "3"
      ? " s-relatedArticles--centered"
      : "";

  useEffect(() => {
    let blogVars = {
      ...appState,
      currentPath: "/ui/related-articles",
      outputName: "RelatedArticles",
      headerName: "Related Articles",
      tags: null,
      currentTheme: "t-white",
      description:
        "The Related Articles Section. This will be on the bottom of an article page. Only the image and headline are linked.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "relatedArticles.section.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, and Large: Width: 720px and Height: 405px.",
            "It can use other sizes too to accomodate legacy images like 300px x 200px",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation"]],
        ["Tag", ["String text in anchor tags."]],
        [
          "Title",
          [
            "Defaults to an <h5> and should only be text with an anchor tag within. Max is 3 lines.",
          ],
        ],
        ["Copy", ["The copy appears as a paragraph beneath the title. Max is 3 lines."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EUxxB6ekj7BMh9_5cfevn8IBZF575j3F2Ph40EEEqCNveQ?e=z65yuS",
        comments: null,
      },
    };
    setAppState(blogVars);
    setContentOptions(relatedArticleOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "680" });
  }, []);

  if (!contentOptions) return "...Loading Related Article Section";
  return (
    <section className={"s-relatedArticles " + appState.currentTheme + centered}>
      <div className="s-relatedArticles__link l-inner u-max+">
        <E_ArrowCTA copy="Go to Blog" />
      </div>
      <ul className="s-relatedArticles__layout l-inner u-list-unstyled u-max+">
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-memory-vs-storage-thumbnail-md.jpg"
          }
          title={contentOptions.card1 && contentOptions.card1[0].field[contentOptions.currentLang]}
          tags={
            contentOptions.card1 && [
              {
                url: "#",
                tag:
                  contentOptions.card1 && contentOptions.card1[1].field[contentOptions.currentLang],
              },
              {
                url: "#",
                tag:
                  contentOptions.card1 && contentOptions.card1[2].field[contentOptions.currentLang],
              },
              {
                url: "#",
                tag:
                  contentOptions.card1 && contentOptions.card1[3].field[contentOptions.currentLang],
              },
            ]
          }
          copy={contentOptions.card1 && contentOptions.card1[4].field}
        />
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-solutions-data-security-upgrade-your-computer-security-with-self-encrypted-ssds-sm.jpg"
          }
          title={contentOptions.title2 && contentOptions.title2.field[contentOptions.currentLang]}
        />
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "3") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "4") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "7") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "8") ? (
          <C_BlogCard
            image={
              "https://media.kingston.com/kingston/articles/ktc-article-nvme-enterprise-md.jpg"
            }
            title={contentOptions.title3 && contentOptions.title3.field[contentOptions.currentLang]}
          />
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "4") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "7") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "8") ? (
          <C_BlogCard
            title={contentOptions.title4 && contentOptions.title4.field[contentOptions.currentLang]}
            image={
              "https://media.kingston.com/kingston/articles/ktc-articles-memory-vs-storage-thumbnail-md.jpg"
            }
          />
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "7") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "8") ? (
          <C_BlogCard />
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "7") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "8") ? (
          <C_BlogCard />
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "7") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "8") ? (
          <C_BlogCard />
        ) : (
          ""
        )}
        {contentOptions.numFeatures && contentOptions.numFeatures.selected == "8" ? (
          <C_BlogCard />
        ) : (
          ""
        )}
      </ul>
    </section>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "related-articles",
  component: S_RelatedArticles,
  navtxt: "Related Articles",
  htmlName: "RelatedArticles",
  categoryType: ["web"],
};
