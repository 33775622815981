import React from "react";

const C_SystemScanResultsCard = ({ type, ...props }) => {
  let sType = type ? type : "";
  let sClasses = props.classes ? props.classes : "";

  let numItems = props.numitems ? props.numitems : "2";

  //system parameters
  let sModel = props.model ? props.model : "";
  let sCPU = props.cpu ? props.cpu : "";
  let sOS = props.os ? props.os : "";

  //memory and storage parameters
  let sTotal = props.total ? props.total : "";

  let sI1Copy1 = props.item1copy1 ? props.item1copy1 : "";
  let sI1Copy2 = props.item1copy2 ? props.item1copy2 : "";
  let sI2Copy1 = props.item2copy1 ? props.item2copy1 : "";
  let sI2Copy2 = props.item2copy2 ? props.item2copy2 : "";
  let sI3Copy1 = props.item3copy1 ? props.item3copy1 : "";
  let sI3Copy2 = props.item3copy2 ? props.item3copy2 : "";
  let sI4Copy1 = props.item4copy1 ? props.item4copy1 : "";
  let sI4Copy2 = props.item4copy2 ? props.item4copy2 : "";

  let sICtaUpgrade = props.upgrade ? props.upgrade : false;

  let sCopy = props.copy ? props.copy : "";
  let sCtaCopy = props.ctacopy ? props.ctacopy : "";

  return (
    <div className={`c-systemScanResultsCard ` + sClasses}>
      {sType == "Models" ? (
        <>
          <div className="c-systemScanResultsCard__header">
            <svg
              className="c-systemScanResultsCard__header__icon"
              aria-hidden="true"
              viewBox="0 0 24 24"
            >
              <use
                xlink="http://www.w3.org/1999/xlink"
                xlinkHref="../images/icons-map.svg#results-desktop"
              ></use>
            </svg>
            <h3 className="u-h5">{sType}</h3>
          </div>
          <div className="c-systemScanResultsCard__content">
            {sCopy ? <p>{sCopy}</p> : ""}

            <div className="l-row c-systemScanResultsCard__content__model">
              {numItems == "1" ? (
                <>
                  <div className="l-row__col">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI1Copy1}</span>
                        <p>{sI1Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </>
              ) : numItems == "2" ? (
                <>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI1Copy1}</span>
                        <p>{sI1Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI2Copy1}</span>
                        <p>{sI2Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </>
              ) : numItems == "3" ? (
                <>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI1Copy1}</span>
                        <p>{sI1Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI3Copy1}</span>
                        <p>{sI3Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI2Copy1}</span>
                        <p>{sI2Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI1Copy1}</span>
                        <p>{sI1Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI3Copy1}</span>
                        <p>{sI3Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                  <div className="l-row__col l-1/2@md">
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI2Copy1}</span>
                        <p>{sI2Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                    <a className="c-systemScanResultsCard__content__model__item" href="#model1">
                      <div className="c-systemScanResultsCard__content__model__item__info">
                        <span>{sI4Copy1}</span>
                        <p>{sI4Copy2}</p>
                      </div>
                      <svg viewBox="0 0 100 100">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#arrow-down"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </>
              )}
            </div>
            {numItems == "More than 4" ? (
              <div className="c-systemScanResultsCard__content__cta">
                <a className="c-systemScanResultsCard__content__cta__link" href="#link">
                  See All Models
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : sType == "System" ? (
        <>
          <div className="c-systemScanResultsCard__header">
            <svg
              className="c-systemScanResultsCard__header__icon"
              aria-hidden="true"
              viewBox="0 0 24 24"
            >
              <use
                xlink="http://www.w3.org/1999/xlink"
                xlinkHref="../images/icons-map.svg#results-laptop"
              ></use>
            </svg>
            <h3 className="u-h5">{sType}</h3>
          </div>
          <div className="c-systemScanResultsCard__content">
            <div className="l-row">
              <div className="l-row__col l-1/2@md">
                <h4 className="u-h6">Model</h4>
                <p>{sModel}</p>
              </div>
              <div className="l-row__col l-1/2@md">
                <h4 className="u-h6">CPU</h4>
                <p>{sCPU}</p>
                <h4 className="u-h6">OS</h4>
                <p>{sOS}</p>
              </div>
            </div>
          </div>
        </>
      ) : sType == "Memory" || sType == "Storage" ? (
        <>
          <div className="c-systemScanResultsCard__header">
            {sType == "Memory" ? (
              <svg
                className="c-systemScanResultsCard__header__icon"
                aria-hidden="true"
                viewBox="0 0 24 24"
              >
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref="../images/icons-map.svg#results-memory"
                ></use>
              </svg>
            ) : (
              <svg
                className="c-systemScanResultsCard__header__icon"
                aria-hidden="true"
                viewBox="0 0 24 24"
              >
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref="../images/icons-map.svg#results-storage"
                ></use>
              </svg>
            )}
            <h3 className="u-h5">{sType}</h3>
            {sTotal ? (
              <span className="u-h5 c-systemScanResultsCard__header__total">TOTAL {sTotal}</span>
            ) : (
              ""
            )}
          </div>
          <div className="c-systemScanResultsCard__content">
            <div className="c-systemScanResultsCard__content__section">
              {numItems == "1" ? (
                <div className="c-systemScanResultsCard__content__section__item">
                  <div className="c-systemScanResultsCard__content__section__item__info">
                    <span>{sI1Copy1}</span>
                    <p>{sI1Copy2}</p>
                  </div>
                  {sType == "Memory" ? (
                    <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                  ) : (
                    <div
                      className="c-progressBar c-progressBar--high"
                      data-value="81%"
                      id="progressBar0"
                    >
                      <span className="c-progressBar__info">370GB / 432GB</span>
                      <div className="c-progressBar__content">
                        <span className="c-progressBar__content__percentage">81%</span>
                        <div className="c-progressBar__content__bar">
                          <div
                            className="c-progressBar__content__bar__determinate"
                            style={{ width: "81%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : numItems == "2" ? (
                <>
                  <div className="c-systemScanResultsCard__content__section__item">
                    <div className="c-systemScanResultsCard__content__section__item__info">
                      <span>{sI1Copy1}</span>
                      <p>{sI1Copy2}</p>
                    </div>
                    {sType == "Memory" ? (
                      <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                    ) : (
                      <div
                        className="c-progressBar c-progressBar--high"
                        data-value="81%"
                        id="progressBar0"
                      >
                        <span className="c-progressBar__info">370GB / 432GB</span>
                        <div className="c-progressBar__content">
                          <span className="c-progressBar__content__percentage">81%</span>
                          <div className="c-progressBar__content__bar">
                            <div
                              className="c-progressBar__content__bar__determinate"
                              style={{ width: "81%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="c-systemScanResultsCard__content__section__item">
                    <div className="c-systemScanResultsCard__content__section__item__info">
                      <span>{sI2Copy1}</span>
                      <p>{sI2Copy2}</p>
                    </div>
                    {sType == "Memory" ? (
                      <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                    ) : (
                      <div
                        className="c-progressBar c-progressBar--medium"
                        data-value="51%"
                        id="progressBar1"
                      >
                        <span className="c-progressBar__info">344GB / 684GB</span>
                        <div className="c-progressBar__content">
                          <span className="c-progressBar__content__percentage">51%</span>
                          <div className="c-progressBar__content__bar">
                            <div
                              className="c-progressBar__content__bar__determinate"
                              style={{ width: "51%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="c-systemScanResultsCard__content__section__item">
                    <div className="c-systemScanResultsCard__content__section__item__info">
                      <span>{sI1Copy1}</span>
                      <p>{sI1Copy2}</p>
                    </div>
                    {sType == "Memory" ? (
                      <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                    ) : (
                      <div
                        className="c-progressBar c-progressBar--high"
                        data-value="81%"
                        id="progressBar0"
                      >
                        <span className="c-progressBar__info">370GB / 432GB</span>
                        <div className="c-progressBar__content">
                          <span className="c-progressBar__content__percentage">81%</span>
                          <div className="c-progressBar__content__bar">
                            <div
                              className="c-progressBar__content__bar__determinate"
                              style={{ width: "81%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="c-systemScanResultsCard__content__section__item">
                    <div className="c-systemScanResultsCard__content__section__item__info">
                      <span>{sI2Copy1}</span>
                      <p>{sI2Copy2}</p>
                    </div>
                    {sType == "Memory" ? (
                      <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                    ) : (
                      <div
                        className="c-progressBar c-progressBar--medium"
                        data-value="51%"
                        id="progressBar1"
                      >
                        <span className="c-progressBar__info">344GB / 684GB</span>
                        <div className="c-progressBar__content">
                          <span className="c-progressBar__content__percentage">51%</span>
                          <div className="c-progressBar__content__bar">
                            <div
                              className="c-progressBar__content__bar__determinate"
                              style={{ width: "51%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="c-systemScanResultsCard__content__section__item">
                    <div className="c-systemScanResultsCard__content__section__item__info">
                      <span>{sI3Copy1}</span>
                      <p>{sI3Copy2}</p>
                    </div>
                    {sType == "Memory" ? (
                      <img src="/images/fpo/section-configurator_results/stick8.png" alt="8GB" />
                    ) : (
                      <div
                        className="c-progressBar c-progressBar--low"
                        data-value="20%"
                        id="progressBar2"
                      >
                        <span className="c-progressBar__info">30GB / 123GB</span>
                        <div className="c-progressBar__content">
                          <span className="c-progressBar__content__percentage">20%</span>
                          <div className="c-progressBar__content__bar">
                            <div
                              className="c-progressBar__content__bar__determinate"
                              style={{ width: "20%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {sICtaUpgrade == true ? (
              <div className="c-systemScanResultsCard__content__cta">
                <a
                  className="c-systemScanResultsCard__content__cta__link"
                  href="#link"
                  data-pgtabtarget="pgTab1"
                >
                  Compatible {sType} Upgrades
                </a>
              </div>
            ) : (
              <div className="c-systemScanResultsCard__content__upgrade">
                <div className="c-systemScanResultsCard__content__upgrade__header">
                  <svg
                    className="c-systemScanResultsCard__content__upgrade__header__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref="../images/icons-map.svg#results-upgrade"
                    ></use>
                  </svg>
                  <span>Upgrade Recommendations</span>
                </div>
                <p>
                  Sorry, this scan didn’t give us anything to base a storage recommendation on.
                  Please feel free to reach out to a Kingston expert to help with your configuration
                  questions.
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="c-systemScanResultsCard__header">
            <svg
              className="c-systemScanResultsCard__header__icon"
              aria-hidden="true"
              viewBox="0 0 24 24"
            >
              <use
                xlink="http://www.w3.org/1999/xlink"
                xlinkHref="../images/icons-map.svg#results-no_info"
              ></use>
            </svg>
            <h3 className="u-h5">{sType}</h3>
          </div>
          <div className="c-systemScanResultsCard__content">
            {sCopy ? <p>{sCopy}</p> : ""}
            {sCtaCopy ? (
              <div className="c-systemScanResultsCard__content__cta">
                <a className="e-btn e-btn--alt1" href="#link">
                  <svg>
                    <use
                      xlink="http://www.w3.org/1999/xlink"
                      xlinkHref="../images/icons-map.svg#icon-chat"
                    ></use>
                  </svg>
                  <span>{sCtaCopy}</span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default C_SystemScanResultsCard;
