import React, { useState, useContext } from "react";
import ToastContext from "../../../../ToastContext";

const C_InfoCard = ({ link, title, image, copy, cta, classes }) => {
  let iLink = link ? link : "#infoLink";
  let iTitle = title ? title : null;
  let iImage = image ? image : "/images/fpo/general/335x150.png";
  let iCopy = copy ? copy : "Info Card Copy.";
  let iCTA = cta ? cta : null;
  let iClasses = classes ? classes : "";

  // Can't use dropzone bc it adds a container div that breaks the gridflex styles
  const [featureImage, setFeatureImage] = useState({ preview: "" });
  const { setToastOptions } = useContext(ToastContext);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFeatureImage({ preview: URL.createObjectURL(file) });
      setToastOptions({ text: "Image added." });
    }
  };

  return (
    <div className={`c-infoCard ${iClasses}`}>
      <div className="c-infoCard__img">
        <img
          src={featureImage.preview ? featureImage.preview : iImage}
          alt="Info Card"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        />
      </div>
      {iTitle ? <h3 className="c-infoCard__title">{iTitle}</h3> : ""}
      <p className="c-infoCard__copy">{iCopy}</p>
      {iCTA ? (
        <div className="c-infoCard__cta">
          <a className="e-btn" href={iLink}>
            {iCTA}
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default C_InfoCard;
