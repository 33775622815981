/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
import contentOpts from "./contentOpts";
import C_ExternalVideo from "../../2_components/externalVideo/C_ExternalVideo";

let DefaultImg = "/images/fpo/general/1200x600.png";
let MedImg = "/images/fpo/general/500x500.png";

const S_Content = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [featureImage, setFeatureImage] = useState({ preview: "" });
  const [featureImage3, setFeatureImage3] = useState({ preview: "" });

  //ToDo: Tooltips aren't finished in React/interactive mode
  const [toolTipLab, setToolTipLab] = useState(null);
  const [toolTipTop, setToolTipTop] = useState(null);
  const [toolTipRight, setToolTipRight] = useState(null);
  const [toolTipLeft, setToolTipLeft] = useState(null);

  const [modal, setModal] = useState(0);

  // const tip1 = useRef();
  // const tip1Parent = useRef();
  // const tip2 = useRef();
  // const tip2Parent = useRef();

  useEffect(() => {
    let Vars = {
      ...appState,
      currentPath: "/ui/content",
      outputName: "Content",
      headerName: "Content",
      tags: ["Landing Page Approved"],
      description:
        "The Content Section serves to layout any combination of content such as headings, paragraphs, images, lists, quotes, videos, etc. *To get best Tooltip examples view in 'Default' mode.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-stone",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/formsLegacy.component.js",
        "../../js/tooltip.component.js",
        "../../js/externalVideo.component.js",
        "../../js/svp.element.js",
        "../../js/modal.component.js",
        "../../js/dialog.component.js",
        "../../js/socials.component.js",
      ],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width and Height: any size"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Figure Caption", ["A <figcaption> consists of string text"]],
        [
          "Content",
          ["String of text. Can be contained within <p>, <a>, <li> depending on context"],
        ],
        [
          "Buttons",
          ["Buttons should be at the bottom of the column.", "1 to 2 buttons per column"],
        ],
        [
          "",
          ["Can use 1 Primary Button or 1 Primary (Alternate) or 2 Primary (Alternate) per column"],
        ],
        ["Block Quotes", ["A <blockquote> consists of string text within <div>"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EeD73cZkHQhPqglILt12jrkB250FkA1uVL9Rf9H9ExPZ3A?e=6fgmRk",
        additionalDocs: [["Video Guidelines", "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ESI-eaTZFupGupgf2pnLGK4BZL4zYkStMK6KD9PO7NRfRQ?e=doCgfC"]],
        comments: [
          [
            "Social Share",
            [
              "To enable Social Share links, go to Social Share Template on admin.",
              "Social Share can be enabled in Content Section and will be automatically be enabled for any community article managed by Article List Admin",
            ],
          ],
        ],
      },
    };
    setAppState(Vars);

    setContentOptions(contentOpts);

    setDimensions({
      ...dimensions,
      viewHeight: "900",
      hardcodeHeight: true,
      width: window.innerWidth - 24,
    });
  }, []);

  // useEffect(() => {
  //ToDo:calc tooltip default position here
  //add scroll and window resize to dep array
  // }, []);

  if (!contentOptions) return "...Loading Content";
  return (
    <section
      className={
        `s-content ${
          contentOptions.paddingtype && contentOptions.paddingtype.selected == "Remove Top Padding"
            ? " u-pt0 "
            : contentOptions.paddingtype &&
                contentOptions.paddingtype.selected == "Remove Bottom Padding"
              ? " u-pb0 "
              : ""
        }` + appState.currentTheme
      }
    >
      <div className="l-inner">
        {contentOptions.enablerow1 && contentOptions.enablerow1.checkbox ? (
          (contentOptions.row1 && contentOptions.row1.selected == "Single Column") ||
          (contentOptions.row1 && contentOptions.row1.selected == "Multi-Column") ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row1.selected == "Multi-Column" &&
                  contentOptions.row1numcol &&
                  contentOptions.row1numcol.selected == 2
                    ? " l-1/2@md"
                    : contentOptions.row1.selected == "Multi-Column" &&
                        contentOptions.row1numcol &&
                        contentOptions.row1numcol.selected == 3
                      ? " l-1/2@md l-1/3@lg"
                      : " l-1/1"
                }`}
              >
                {contentOptions.heading1 && contentOptions.heading1.checkbox ? (
                  <h2>{contentOptions.heading1.field}</h2>
                ) : (
                  ""
                )}
                {contentOptions.subheading1 && contentOptions.subheading1.checkbox ? (
                  <h3>{contentOptions.subheading1.field}</h3>
                ) : (
                  ""
                )}
                {contentOptions.p1 && contentOptions.p1.checkbox ? (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                ) : (
                  ""
                )}

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. <a href="#link">{contentOptions.link1 && contentOptions.link1.field}</a>{" "}
                  sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                  anim id est laborum.
                </p>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed ea
                  consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam.
                  Enim maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam,
                  necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur veritatis
                  aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                  <span
                    className="c-tooltip"
                    data-toolid="labore"
                    role="tooltip"
                    aria-describedby="labore"
                    tabIndex="0"
                    onMouseEnter={() => setToolTipLab(true)}
                    onFocus={() => setToolTipLab(true)}
                    onMouseOut={() => setToolTipLab(null)}
                    onBlur={() => setToolTipLab(null)}
                  >
                    {contentOptions.tooltip && contentOptions.tooltip.field}
                  </span>
                  Enim maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam,
                  necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur veritatis
                  aspernatur nobis sed a minus autem ut, totam corrupti debitis. Lorem ipsum dolor
                  sit amet, consectetur adipisicing elit. Deserunt sed, ea consequuntur. Laudantium
                  sint
                  <a
                    className="c-tooltip c-tooltip--top"
                    data-toolid="arcu"
                    role="tooltip"
                    aria-describedby="arcu"
                    tabIndex="0"
                    onMouseEnter={() => setToolTipTop(true)}
                    onFocus={() => setToolTipTop(true)}
                    onMouseOut={() => setToolTipTop(null)}
                    onBlur={() => setToolTipTop(null)}
                  >
                    {" "}
                    Arcu{" "}
                  </a>
                  veritatis soluta consectetur fugit saepe numquam. Enim maxime veritatis ea quia
                  eos quam et sunt amet voluptatem aperiam, necessitatibus, est. Delectus repellat,
                  quibusdam quidem, pariatur veritatis aspernatur nobis sed a minus autem ut, totam
                  corrupti debitis. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Deserunt sed, ea consequuntur. Laudantium
                  <a
                    className="c-tooltip c-tooltip--right"
                    data-toolid="tellus"
                    role="tooltip"
                    aria-describedby="tellus"
                    tabIndex="0"
                    onMouseEnter={() => setToolTipRight(true)}
                    onFocus={() => setToolTipRight(true)}
                    onMouseOut={() => setToolTipRight(null)}
                    onBlur={() => setToolTipRight(null)}
                  >
                    {" "}
                    tellus{" "}
                  </a>
                  sint veritatis soluta consectetur fugit saepe numquam. Enim maxime veritatis ea
                  quia eos quam et sunt amet voluptatem aperiam, necessitatibus, est.
                  <a
                    className="c-tooltip c-tooltip--left"
                    data-toolid="massus"
                    role="tooltip"
                    aria-describedby="massus"
                    tabIndex="0"
                    onMouseEnter={() => setToolTipLeft(true)}
                    onFocus={() => setToolTipLeft(true)}
                    onMouseOut={() => setToolTipLeft(null)}
                    onBlur={() => setToolTipLeft(null)}
                  >
                    {" "}
                    massus{" "}
                  </a>
                  repellat, quibusdam quidem, pariatur veritatis aspernatur nobis sed a minus autem
                  ut, totam corrupti debitis.
                </p>
                <div
                  className={`c-tooltip__message ${toolTipLab ? "c-tooltip--active" : ""}`}
                  id="labore"
                  style={{
                    display: toolTipLab && "block",
                    top: toolTipLab && "140px",
                    left: toolTipLab && "43%",
                  }}
                >
                  <h4>Labore</h4>
                  <p>{contentOptions.tooltipmessage && contentOptions.tooltipmessage.field}</p>
                </div>
                <div
                  className={`c-tooltip__message ${toolTipTop ? "c-tooltip--active" : ""}`}
                  id="arcu"
                  style={{
                    display: toolTipTop && "block",
                    top: toolTipTop && "8.5px",
                    left: toolTipTop && "62%",
                  }}
                >
                  <h4>Arcu - Top Tooltip</h4>
                  <p>
                    Orci porta non pulvinar neque laoreet. Orci ac auctor augue mauris augue neq
                  </p>
                </div>
                <div
                  className={`c-tooltip__message ${toolTipRight ? "c-tooltip--active" : ""}`}
                  id="tellus"
                  style={{
                    display: toolTipRight && "block",
                    top: toolTipRight && "115px",
                    left: toolTipRight && "54%",
                  }}
                >
                  <h4>Tellus</h4>
                  <p>
                    Orci porta non pulvinar neque laoreet. Orci ac auctor augue mauris augue neq
                    Commodo sed egestas egestas fringilla phasellu faucibus scelerisque eleifend.
                    Eget est lorem ipsum dolor sitllentesque massa. Nunc non blandit massa enim nec
                    dui nunc. Ante metus dictum at tempor commodo ullamcorper a. Tellus molestie
                    nunc non blandit massa enim nec. Commodo sed egestas egestas fringilla phasellus
                    faucibus scelerisque eleifend.
                  </p>
                </div>
                <div
                  className={`c-tooltip__message ${toolTipLeft ? "c-tooltip--active" : ""}`}
                  id="massus"
                  style={{
                    display: toolTipLeft && "block",
                    top: toolTipLeft && "130px",
                    left: toolTipLeft && "30%",
                  }}
                >
                  <h4>Massus</h4>
                  <p>
                    Orci porta non pulvinar neque laoreet. Orci ac auctor augue mauris augue neq
                    Commodo sed egestas egestas fringilla phasellu faucibus scelerisque eleifend.
                    Eget est lorem ipsum dolor sitllentesque massa. Nunc non blandit massa enim nec
                    dui nunc. Ante metus dictum at tempor commodo ullamcorper a
                  </p>
                </div>

                {contentOptions.row1options &&
                contentOptions.row1options[0] &&
                contentOptions.row1options[0].checkbox ? (
                  <ol>
                    <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                    <li>List Item 2</li>
                    <li>List Item 3</li>
                    <li>
                      List Item 4
                      <ol>
                        <li>Nested Item 1</li>
                        <li>Nested Item 2</li>
                      </ol>
                    </li>
                  </ol>
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[1] &&
                contentOptions.row1options[1].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <ul>
                      <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                      <li>List Item 2</li>
                      <li>List Item 3</li>
                      <li>
                        List Item 4
                        <ul>
                          <li>Nested Item 1</li>
                          <li>Nested Item 2</li>
                        </ul>
                      </li>
                      <li>List Item 5</li>
                      <li>List Item 6</li>
                      <li>List Item 7</li>
                      <li>List Item 8</li>
                    </ul>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[2] &&
                contentOptions.row1options[2].checkbox ? (
                  <>
                    <p>
                      Here is an example of a superscript High
                      <sup>
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[0] &&
                          contentOptions.supsubfoot[0].field}
                      </sup>
                    </p>

                    <p>
                      Here is an example of a footnote High
                      <sup className="u-txt-footnote">
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[1] &&
                          contentOptions.supsubfoot[1].field}
                      </sup>
                    </p>
                    <p>
                      Here is an example of a superscript Copyright
                      <sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </p>

                    <h1>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h1>
                    <h2>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h2>
                    <h3>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h3>
                    <h4>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h4>
                    <h5>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h5>
                    <h6>
                      Heading<sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </h6>

                    <p>
                      Here is an example of a subscript Down
                      <sub>
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[2] &&
                          contentOptions.supsubfoot[2].field}
                      </sub>
                    </p>
                  </>
                ) : (
                  ""
                )}

                {/*
                <h3>Icons</h3>
                {contentOptions.iconsoptions && contentOptions.iconsoptions.checkbox == true ? (
                  <div
                    className={`s-content__icon ${
                      contentOptions.iconalignment &&
                      contentOptions.iconalignment.selected == "Center"
                        ? "u-txt-center"
                        : ""
                    }`}
                  >
                    <svg viewBox="0 0 48 48" aria-hidden="true">
                      <path d="M43.78 31.34l-.01.01c-.24-.09-.49-.17-.75-.25-1.86-.51-3.92-.33-5.77.49L29 34.95c.01-.14.02-.29.02-.44-.03-2-1.49-3.72-3.48-4.11l-5.2-1c-.62-1.47-2.61-5.19-6.97-5.19-1.95 0-3.35.51-4.32 1.09v-1.1H2.09v20.02h6.96v-1.35h.24c2.48 0 4.96.28 7.33.83l7.35 2.03c1.38.38 2.85.24 4.13-.38l16.02-7.88c1.19-.63 1.87-1.86 1.79-3.21-.08-1.33-.9-2.45-2.13-2.92zM8.05 43.21H3.09V25.19h4.96v18.02zm35.16-7.54v.02l-15.98 7.86c-.85.41-1.82.49-2.73.24l-7.39-2.04c-2.35-.55-4.77-.83-7.19-.88V27.1c.69-.45 1.78-.89 3.45-.89 3.84 0 5.23 4.19 5.28 4.37.11.35.4.61.77.68l5.74 1.1c1.06.2 1.84 1.12 1.86 2.22 0 .36-.08.67-.23.94-.12.22-.31.39-.52.52l-.67.27c-.22.04-.46.06-.69.02l-7-1.28a.998.998 0 00-1.16.8c-.1.54.26 1.06.8 1.16l7 1.28c.24.04.48.07.72.07.73 0 1.42-.21 2.01-.57l10.75-4.37c1.46-.65 3.04-.79 4.46-.4.2.05.39.12.57.18.5.19.81.63.85 1.17.04.56-.24 1.06-.7 1.3zM24 26.06c6.63 0 12-5.37 12-12s-5.37-12-12-12-12 5.37-12 12 5.37 12 12 12zm2.8-2.41c1.41-1.34 2.49-3.55 2.98-6.24h3.63c-1.07 3-3.53 5.34-6.61 6.24zm7.2-9.6v.01c0 .81-.11 1.6-.29 2.35h-3.78c.09-.75.15-1.54.15-2.35s-.05-1.59-.15-2.35h3.78c.18.75.29 1.53.29 2.34zm-.58-3.33h-3.64c-.49-2.7-1.57-4.91-2.99-6.26 3.09.9 5.55 3.25 6.63 6.26zm-8.92-6.5c1.98.61 3.59 3.14 4.25 6.5H24.5v-6.5zm0 7.51v-.01h4.42c.1.75.16 1.54.16 2.35s-.06 1.59-.16 2.35H24.5v-4.69zm0 5.68h4.25c-.66 3.36-2.28 5.89-4.25 6.5v-6.5zm-1 6.5c-1.98-.61-3.59-3.14-4.25-6.5h4.25v6.5zm0-7.51l-4.42.01c-.1-.75-.16-1.54-.16-2.35s.06-1.59.16-2.35h4.42v4.69zm0-12.19v6.5h-4.25c.66-3.36 2.28-5.89 4.25-6.5zm-2.3.26c-1.42 1.34-2.5 3.56-2.98 6.25h-3.63c1.07-3 3.53-5.35 6.61-6.25zm-7.2 9.6v-.01c0-.81.11-1.59.29-2.34h3.78c-.09.75-.15 1.54-.15 2.35s.05 1.59.15 2.35h-3.78c-.18-.76-.29-1.54-.29-2.35zm4.22 3.34c.49 2.69 1.56 4.9 2.98 6.24-3.07-.9-5.53-3.22-6.61-6.24h3.63z" />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.subheading && contentOptions.subheading.checkbox == true ? (
                  <h4
                    className={`${
                      contentOptions.iconalignment &&
                      contentOptions.iconalignment.selected == "Center"
                        ? "u-txt-center"
                        : ""
                    }`}
                  >
                    Here is an example of an icon in Single Column.
                  </h4>
                ) : (
                  ""
                )}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p> */}

                {contentOptions.row1options &&
                contentOptions.row1options[3] &&
                contentOptions.row1options[3].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <>
                      <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[4] &&
                contentOptions.row1options[4].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ||
                  (contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected == 2) ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[5] &&
                contentOptions.row1options[5].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <blockquote className="e-blockquote">
                      <div className="e-blockquote__copy">
                        <svg viewBox="0 0 31.63 21.09" width="100%" height="100%">
                          <path
                            d="M13.18 21.09V7.91H5.93L10.54 0H4.61L0 7.91v13.18zM31.63 7.91h-7.25L29 0h-5.94l-4.61 7.91v13.18h13.18z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                        {contentOptions.blockquote &&
                          contentOptions.blockquote[0] &&
                          contentOptions.blockquote[0].field}
                        <svg viewBox="0 0 20.28 13.52" width="100%" height="100%">
                          <path
                            d="M11.83 0v8.45h4.65l-3 5.07h3.8l3-5.07V0zM0 8.45h4.65l-3 5.07h3.8l3-5.07V0H0z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                      </div>
                      {/* {contentOptions.blockquote &&
                      contentOptions.blockquote[1] &&
                      contentOptions.blockquote[1].checkbox ? (
                        <span> {contentOptions.blockquote[1].field} </span>
                      ) : (
                        ""
                      )}
                      {contentOptions.blockquote &&
                      contentOptions.blockquote[2] &&
                      contentOptions.blockquote[2].checkbox ? (
                        <span className="e-blockquote__author">
                          {contentOptions.blockquote[2].field}
                          {contentOptions.blockquote &&
                          contentOptions.blockquote[3] &&
                          contentOptions.blockquote[3].checkbox ? (
                            <span className="e-blockquote__author__title">
                              &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </blockquote>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[6] &&
                contentOptions.row1options[6].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <>
                      <div className={`c-videoCard`}>
                        <a
                          href="#"
                          className="c-videoCard__img"
                          data-modal="ytVideo1"
                          onClick={() => setModal(1)}
                        >
                          <picture className="e-picture" id="js-picture">
                            <source
                              srcSet="https://media.kingston.com/europe/rwd-mock-ups/2024/05/ktc-content-blog-system-builder-ai-pc-hype-organizations-upgrade-refresh-webinar-clip-1.jpg"
                              media="(max-width:32em)"
                            />
                            <img
                              src="https://media.kingston.com/europe/rwd-mock-ups/2024/05/ktc-content-blog-system-builder-ai-pc-hype-organizations-upgrade-refresh-webinar-clip-1.jpg"
                              alt="Alternate Text"
                            />
                          </picture>
                          <svg viewBox="0 0 73 73">
                            <path
                              d="M0 36.5a36.5 36.5 0 1073 0 36.5 36.5 0 10-73 0"
                              fill="rgba(0,0,0,0.5)"
                            />
                            <path d="M36.5 9A27.5 27.5 0 119 36.5 27.5 27.5 0 0136.5 9m0-5.5a33 33 0 1033 33 33 33 0 00-33-33z" />
                            <path d="M52.56 37.36l-23 13.74a1 1 0 01-1.56-.86V22.76a1 1 0 011.51-.86l23 13.74a1 1 0 010 1.72z" />
                          </svg>
                        </a>
                      </div>

                      <div
                        className={`c-modal c-modal--darkmode${modal == 1 ? " c-modal--open" : ""}`}
                        role="dialog"
                        id="ytVideo1"
                        aria-modal="true"
                        aria-hidden={modal == 1 ? "false" : "true"}
                      >
                        <div className="c-modal__overlay"></div>
                        <button
                          className="c-modal__close"
                          aria-label="close"
                          data-close="true"
                          onClick={() => setModal(0)}
                        >
                          <svg viewBox="0 0 14 14">
                            <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                            <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                          </svg>
                        </button>
                        <div className="c-modal__content">
                          <div
                            className="c-externalVideo"
                            data-type="Youtube"
                            data-id="1MieluM0c6c"
                            data-starttime="1"
                            data-modal-link="ytVideo1"
                          >
                            {/* <iframe
                              src="https://www.youtube.com/embed/1MieluM0c6c?start=1&amp;enablejsapi=1"
                              id="lnk-b6ozmb"
                              data-modal-link="ytVideo1"
                            ></iframe> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[7] &&
                contentOptions.row1options[7].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <>
                      {/* <C_ExternalVideo type="Youtube" />
                      <C_ExternalVideo type="Vimeo" videoid="848071418" />
                      <video
                        src="/images/fpo/section-hero/hero-video-lg1.mp4"
                        title="Video Title"
                        controls
                        webkit-playsinline="true" playsInline
                      ></video>
                      <div
                      className="e-svp"
                      data-small-video="euis6sdzh008"
                      data-large-video="dk6w3nipntsg"
                      // data-initialized="true"
                      // style={{
                      //   width: "100%",
                      //   height: "auto",
                      //   paddingTop: "56.25%",
                      //   boxShadow: "rgba(48, 48, 48, 0.4) 0px 20px 22px -8px",
                      //   borderRadius: "4px",
                      //   overflow: "hidden",
                      // }}
                    ></div> */}

                    <iframe className="c-embedVideo" src="https://www.youtube.com/embed/JeHRCuy_Lo0?si=EAbfeBCi4I3sNNiJ" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="allowfullscreen"></iframe>
                    <iframe className="c-embedVideo"src="https://player.vimeo.com/video/937895779" title="Vimeo video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    <iframe className="c-embedVideo"
                      src="https://kcmsqasadm.us.kingston.corp/en/video/svp?videoid=clihnxy3ql4c"
                      title="SVP video player"
                      frameborder="0"
                      allowfullscreen>
                    </iframe>
                    <video className="c-embedVideo" poster="https://media.kingston.com/kingston/videos/ktc-video-landing-mongol-rally-sm.jpg" controls="controls" webkit-playsinline="true" playsInline>
                      <source src="https://media.kingston.com/video/STB_Kingston_ServerRAM_60s.mp4" type="video/mp4" />
                      <track src="subtitles-en.vtt" kind="subtitles" srclang="en" label="English"/>
                      <track src="subtitles-es.vtt" kind="subtitles" srclang="es" label="Español"/>
                      Your browser does not support the video tag.
                    </video>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row1options &&
                contentOptions.row1options[8] &&
                contentOptions.row1options[8].checkbox ? (
                  contentOptions.row1.selected == "Single Column" ? (
                    <>
                      <a
                        href="#"
                        className={`e-btn${
                          (contentOptions.buttonvariation &&
                            contentOptions.buttonvariation.selected == "1 Primary (Alt)") ||
                          (contentOptions.buttonvariation &&
                            contentOptions.buttonvariation.selected == "2 Primary (Alt)")
                            ? " e-btn--alt1"
                            : ""
                        }`}
                      >
                        {contentOptions.button1 && contentOptions.button1.field}
                      </a>

                      {contentOptions.buttonvariation &&
                      contentOptions.buttonvariation.selected == "2 Primary (Alt)" ? (
                        <a href="#" className="e-btn e-btn--alt1">
                          {contentOptions.button2 && contentOptions.button2.field}
                        </a>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {contentOptions.row1.selected == "Multi-Column" &&
              contentOptions.row1numcol.selected >= 2 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row1numcol && contentOptions.row1numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row1numcol && contentOptions.row1numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  <h2>Heading Column 2</h2>
                  <h3>Subheading Column 2</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row1options &&
                  contentOptions.row1options[1] &&
                  contentOptions.row1options[1].checkbox ? (
                    contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected >= 2 ? (
                      <ul>
                        <li>List Item 1</li>
                        <li>List Item 2</li>
                        <li>List Item 3</li>
                        <li>
                          List Item 4
                          <ul>
                            <li>Nested Item 1</li>
                            <li>Nested Item 2</li>
                          </ul>
                        </li>
                        <li>List Item 5</li>
                        <li>List Item 6</li>
                        <li>List Item 7</li>
                        <li>List Item 8</li>
                      </ul>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row1options &&
                  contentOptions.row1options[3] &&
                  contentOptions.row1options[3].checkbox ? (
                    contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected >= 2 ? (
                      <>
                        <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                          est laborum.
                        </p>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row1options &&
                  contentOptions.row1options[5] &&
                  contentOptions.row1options[5].checkbox ? (
                    contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected >= 2 ? (
                      <blockquote className="e-blockquote">
                        <div className="e-blockquote__copy">
                          {contentOptions.blockquote &&
                            contentOptions.blockquote[0] &&
                            contentOptions.blockquote[0].field}
                        </div>
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[1] &&
                        contentOptions.blockquote[1].checkbox ? (
                          <span> {contentOptions.blockquote[1].field} </span>
                        ) : (
                          ""
                        )}
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[2] &&
                        contentOptions.blockquote[2].checkbox ? (
                          <span className="e-blockquote__author">
                            {contentOptions.blockquote[2].field}
                            {contentOptions.blockquote &&
                            contentOptions.blockquote[3] &&
                            contentOptions.blockquote[3].checkbox ? (
                              <span className="e-blockquote__author__title">
                                &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </blockquote>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row1options &&
                  contentOptions.row1options[6] &&
                  contentOptions.row1options[6].checkbox ? (
                    contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected >= 2 ? (
                      <>
                        <div className={`c-videoCard`}>
                          <a
                            href="#"
                            className="c-videoCard__img"
                            data-modal="ytVideo2"
                            onClick={() => setModal(2)}
                          >
                            <picture className="e-picture" id="js-picture">
                              <source
                                srcSet="https://media.kingston.com/europe/rwd-mock-ups/2024/05/ktc-content-blog-system-builder-ai-pc-hype-organizations-upgrade-refresh-webinar-clip-1.jpg"
                                media="(max-width:32em)"
                              />
                              <img
                                src="https://media.kingston.com/europe/rwd-mock-ups/2024/05/ktc-content-blog-system-builder-ai-pc-hype-organizations-upgrade-refresh-webinar-clip-1.jpg"
                                alt="Alternate Text"
                              />
                            </picture>
                            <svg viewBox="0 0 73 73">
                              <path
                                d="M0 36.5a36.5 36.5 0 1073 0 36.5 36.5 0 10-73 0"
                                fill="rgba(0,0,0,0.5)"
                              />
                              <path d="M36.5 9A27.5 27.5 0 119 36.5 27.5 27.5 0 0136.5 9m0-5.5a33 33 0 1033 33 33 33 0 00-33-33z" />
                              <path d="M52.56 37.36l-23 13.74a1 1 0 01-1.56-.86V22.76a1 1 0 011.51-.86l23 13.74a1 1 0 010 1.72z" />
                            </svg>
                          </a>
                        </div>

                        <div
                          className={`c-modal c-modal--darkmode${
                            modal == 2 ? " c-modal--open" : ""
                          }`}
                          role="dialog"
                          id="ytVideo2"
                          aria-modal="true"
                          aria-hidden={modal == 2 ? "false" : "true"}
                        >
                          <div className="c-modal__overlay"></div>
                          <button
                            className="c-modal__close"
                            aria-label="close"
                            data-close="true"
                            onClick={() => setModal(0)}
                          >
                            <svg viewBox="0 0 14 14">
                              <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                              <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                            </svg>
                          </button>
                          <div className="c-modal__content">
                            <div
                              className="c-externalVideo"
                              data-type="Youtube"
                              data-id="1MieluM0c6c"
                              data-starttime="1"
                              data-modal-link="ytVideo2"
                            >
                              {/* <iframe
                                src="https://www.youtube.com/embed/1MieluM0c6c?start=1&amp;enablejsapi=1"
                                id="lnk-b6ozmb"
                                data-modal-link="ytVideo2"
                              ></iframe> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row1options &&
                  contentOptions.row1options[7] &&
                  contentOptions.row1options[7].checkbox ? (
                    contentOptions.row1.selected == "Multi-Column" &&
                    contentOptions.row1numcol.selected >= 2 ? (
                      <>
                        <a
                          href="#"
                          className={`e-btn${
                            (contentOptions.buttonvariation &&
                              contentOptions.buttonvariation.selected == "1 Primary (Alt)") ||
                            (contentOptions.buttonvariation &&
                              contentOptions.buttonvariation.selected == "2 Primary (Alt)")
                              ? " e-btn--alt1"
                              : ""
                          }`}
                        >
                          {contentOptions.button1 && contentOptions.button1.field}
                        </a>

                        {contentOptions.buttonvariation &&
                        contentOptions.buttonvariation.selected == "2 Primary (Alt)" ? (
                          <a href="#" className="e-btn e-btn--alt1">
                            {contentOptions.button2 && contentOptions.button2.field}
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {contentOptions.row1.selected == "Multi-Column" &&
              contentOptions.row1numcol.selected >= 3 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row1numcol && contentOptions.row1numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row1numcol && contentOptions.row1numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  <h2>Heading Column 3</h2>
                  <h3>Subheading Column 3</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row1options &&
                  contentOptions.row1options[4] &&
                  contentOptions.row1options[4].checkbox ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : contentOptions.row1.selected == "Column Flow" ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row1numcol && contentOptions.row1numcol.selected == 2
                    ? " l-row__col--flow2@md l-row__col--flow2@lg l-row__col--flow2@xlg"
                    : contentOptions.row1.selected == "Column Flow" &&
                        contentOptions.row1numcol &&
                        contentOptions.row1numcol.selected == 3
                      ? " l-row__col--flow2@md l-row__col--flow3@lg l-row__col--flow3@xlg"
                      : ""
                }`}
              >
                <h2>Heading Column Flow</h2>
                <h3>Subheading Column Flow</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          ) : contentOptions.row1.selected == "Chart Bar" ? (
            <div className="c-graph c-graph--bar">
              <div
                className="c-graph__item"
                style={{
                  width: `${
                    contentOptions.chartbarlength && contentOptions.chartbarlength.selected
                  }`,
                  backgroundColor: `${
                    contentOptions.chartbarcolor && contentOptions.chartbarcolor.field
                  }`,
                }}
              >
                <div
                  className="c-graph__item__info"
                  style={{
                    color: `${
                      contentOptions.chartbarcopycolor &&
                      contentOptions.chartbarcopycolor.checkbox == true
                        ? "#000"
                        : "#fff"
                    }`,
                  }}
                >
                  {contentOptions.chartbartitle && contentOptions.chartbartitle.checkbox == true ? (
                    <span>{contentOptions.chartbartitle.field}</span>
                  ) : (
                    ""
                  )}
                  {contentOptions.chartbarcopy && contentOptions.chartbarcopy.checkbox == true ? (
                    <span>{contentOptions.chartbarcopy.field}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="s-graph">
              <table className="u-tableUnstyled u-tableCollapse">
                <thead>
                  <tr>
                    <th scope="col">
                      {contentOptions.stackedtitle && contentOptions.stackedtitle.field}
                    </th>
                    <th scope="col">Latency</th>
                    <th scope="col">Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{contentOptions.stackedcopy && contentOptions.stackedcopy.field}</td>
                    <td>13-13-15</td>
                    <td>1.35v</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        ) : (
          ""
        )}

        {contentOptions.enablerow2 && contentOptions.enablerow2.checkbox ? (
          (contentOptions.row2 && contentOptions.row2.selected == "Single Column") ||
          (contentOptions.row2 && contentOptions.row2.selected == "Multi-Column") ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row2.selected == "Multi-Column" &&
                  contentOptions.row2numcol &&
                  contentOptions.row2numcol.selected == 2
                    ? " l-1/2@md"
                    : contentOptions.row2.selected == "Multi-Column" &&
                        contentOptions.row2numcol &&
                        contentOptions.row2numcol.selected == 3
                      ? " l-1/2@md l-1/3@lg"
                      : " l-1/1"
                }`}
              >
               {contentOptions.row2options &&
                contentOptions.row2options[6] &&
                contentOptions.row2options[6].checkbox ? (
                  <iframe className="c-embedVideo" src="https://www.youtube.com/embed/JeHRCuy_Lo0?si=EAbfeBCi4I3sNNiJ" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="allowfullscreen"></iframe>
                ) : (
                  ""
                )}
                {contentOptions.heading1 && contentOptions.heading1.checkbox ? (
                  <h2>{contentOptions.heading1.field}</h2>
                ) : (
                  ""
                )}
                {contentOptions.subheading1 && contentOptions.subheading1.checkbox ? (
                  <h3>{contentOptions.subheading1.field}</h3>
                ) : (
                  ""
                )}

                {contentOptions.p1 && contentOptions.p1.checkbox ? (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                ) : (
                  ""
                )}

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. <a href="#link">{contentOptions.link1 && contentOptions.link1.field}</a>{" "}
                  sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                  anim id est laborum.
                </p>

                {contentOptions.row2options &&
                contentOptions.row2options[0] &&
                contentOptions.row2options[0].checkbox ? (
                  <ol>
                    <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                    <li>List Item 2</li>
                    <li>List Item 3</li>
                    <li>
                      List Item 4
                      <ol>
                        <li>Nested Item 1</li>
                        <li>Nested Item 2</li>
                      </ol>
                    </li>
                  </ol>
                ) : (
                  ""
                )}

                {contentOptions.row2options &&
                contentOptions.row2options[1] &&
                contentOptions.row2options[1].checkbox ? (
                  contentOptions.row2.selected == "Single Column" ? (
                    <ul>
                      <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                      <li>List Item 2</li>
                      <li>List Item 3</li>
                      <li>
                        List Item 4
                        <ul>
                          <li>Nested Item 1</li>
                          <li>Nested Item 2</li>
                        </ul>
                      </li>
                      <li>List Item 5</li>
                      <li>List Item 6</li>
                      <li>List Item 7</li>
                      <li>List Item 8</li>
                    </ul>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row2options &&
                contentOptions.row2options[2] &&
                contentOptions.row2options[2].checkbox ? (
                  <>
                    <p>
                      Here is an example of a superscript High
                      <sup>
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[0] &&
                          contentOptions.supsubfoot[0].field}
                      </sup>
                    </p>

                    <p>
                      Here is an example of a footnote High
                      <sup className="u-txt-footnote">
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[1] &&
                          contentOptions.supsubfoot[1].field}
                      </sup>
                    </p>
                    <p>
                      Here is an example of a superscript Copyright
                      <sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </p>

                    <p>
                      Here is an example of a subscript Down<sub>low</sub>
                    </p>
                  </>
                ) : (
                  ""
                )}

                {contentOptions.row2options &&
                contentOptions.row2options[3] &&
                contentOptions.row2options[3].checkbox ? (
                  contentOptions.row2.selected == "Single Column" ? (
                    <>
                      <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row2options &&
                contentOptions.row2options[4] &&
                contentOptions.row2options[4].checkbox ? (
                  contentOptions.row2.selected == "Single Column" ||
                  (contentOptions.row2.selected == "Multi-Column" &&
                    contentOptions.row2numcol.selected == 2) ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row2options &&
                contentOptions.row2options[5] &&
                contentOptions.row2options[5].checkbox ? (
                  contentOptions.row2.selected == "Single Column" ? (
                    <blockquote className="e-blockquote">
                      <div className="e-blockquote__copy">
                        <svg viewBox="0 0 31.63 21.09" width="100%" height="100%">
                          <path
                            d="M13.18 21.09V7.91H5.93L10.54 0H4.61L0 7.91v13.18zM31.63 7.91h-7.25L29 0h-5.94l-4.61 7.91v13.18h13.18z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                        {contentOptions.blockquote &&
                          contentOptions.blockquote[0] &&
                          contentOptions.blockquote[0].field}
                        <svg viewBox="0 0 20.28 13.52" width="100%" height="100%">
                          <path
                            d="M11.83 0v8.45h4.65l-3 5.07h3.8l3-5.07V0zM0 8.45h4.65l-3 5.07h3.8l3-5.07V0H0z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                      </div>
                      {/* {contentOptions.blockquote &&
                      contentOptions.blockquote[1] &&
                      contentOptions.blockquote[1].checkbox ? (
                        <span> {contentOptions.blockquote[1].field} </span>
                      ) : (
                        ""
                      )}
                      {contentOptions.blockquote &&
                      contentOptions.blockquote[2] &&
                      contentOptions.blockquote[2].checkbox ? (
                        <span className="e-blockquote__author">
                          {contentOptions.blockquote[2].field}
                          {contentOptions.blockquote &&
                          contentOptions.blockquote[3] &&
                          contentOptions.blockquote[3].checkbox ? (
                            <span className="e-blockquote__author__title">
                              &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </blockquote>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {contentOptions.row2.selected == "Multi-Column" &&
              contentOptions.row2numcol.selected >= 2 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row2numcol && contentOptions.row2numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row2numcol && contentOptions.row2numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  {contentOptions.row2options &&
                contentOptions.row2options[7] &&
                contentOptions.row2options[7].checkbox ? (
                  <iframe className="c-embedVideo"src="https://player.vimeo.com/video/937895779" title="Vimeo video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                ) : (
                  ""
                )}
                  <h2>Heading Column 2</h2>
                  <h3>Subheading Column 2</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row2options &&
                  contentOptions.row2options[1] &&
                  contentOptions.row2options[1].checkbox ? (
                    contentOptions.row2.selected == "Multi-Column" &&
                    contentOptions.row2numcol.selected >= 2 ? (
                      <ul>
                        <li>List Item 1</li>
                        <li>List Item 2</li>
                        <li>List Item 3</li>
                        <li>
                          List Item 4
                          <ul>
                            <li>Nested Item 1</li>
                            <li>Nested Item 2</li>
                          </ul>
                        </li>
                        <li>List Item 5</li>
                        <li>List Item 6</li>
                        <li>List Item 7</li>
                        <li>List Item 8</li>
                      </ul>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row2options &&
                  contentOptions.row2options[3] &&
                  contentOptions.row2options[3].checkbox ? (
                    contentOptions.row2.selected == "Multi-Column" &&
                    contentOptions.row2numcol.selected >= 2 ? (
                      <>
                        <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                          est laborum.
                        </p>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row2options &&
                  contentOptions.row2options[5] &&
                  contentOptions.row2options[5].checkbox ? (
                    contentOptions.row2.selected == "Multi-Column" &&
                    contentOptions.row2numcol.selected >= 2 ? (
                      <blockquote className="e-blockquote">
                        <div className="e-blockquote__copy">
                          {contentOptions.blockquote &&
                            contentOptions.blockquote[0] &&
                            contentOptions.blockquote[0].field}
                        </div>
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[1] &&
                        contentOptions.blockquote[1].checkbox ? (
                          <span> {contentOptions.blockquote[1].field} </span>
                        ) : (
                          ""
                        )}
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[2] &&
                        contentOptions.blockquote[2].checkbox ? (
                          <span className="e-blockquote__author">
                            {contentOptions.blockquote[2].field}
                            {contentOptions.blockquote &&
                            contentOptions.blockquote[3] &&
                            contentOptions.blockquote[3].checkbox ? (
                              <span className="e-blockquote__author__title">
                                &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </blockquote>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {contentOptions.row2.selected == "Multi-Column" &&
              contentOptions.row2numcol.selected >= 3 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row2numcol && contentOptions.row2numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row2numcol && contentOptions.row2numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  <h2>Heading Column 3</h2>
                  <h3>Subheading Column 3</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row2options &&
                  contentOptions.row2options[4] &&
                  contentOptions.row2options[4].checkbox ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : contentOptions.row2.selected == "Column Flow" ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row2numcol && contentOptions.row2numcol.selected == 2
                    ? " l-row__col--flow2@md l-row__col--flow2@lg l-row__col--flow2@xlg"
                    : contentOptions.row2.selected == "Column Flow" &&
                        contentOptions.row2numcol &&
                        contentOptions.row2numcol.selected == 3
                      ? " l-row__col--flow2@md l-row__col--flow3@lg l-row__col--flow3@xlg"
                      : ""
                }`}
              >
                  {contentOptions.row2options &&
                contentOptions.row2options[7] &&
                contentOptions.row2options[7].checkbox ? (
                  <iframe className="c-embedVideo"src="https://player.vimeo.com/video/937895779" title="Vimeo video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                ) : (
                  ""
                )}
                <h2>Heading Column Flow</h2>
                <h3>Subheading Column Flow</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          ) : contentOptions.row2.selected == "Chart Bar" ? (
            <div className="c-graph c-graph--bar">
              <div
                className="c-graph__item"
                style={{
                  width: `${
                    contentOptions.chartbarlength && contentOptions.chartbarlength.selected
                  }`,
                  backgroundColor: `${
                    contentOptions.chartbarcolor && contentOptions.chartbarcolor.field
                  }`,
                }}
              >
                <div
                  className="c-graph__item__info"
                  style={{
                    color: `${
                      contentOptions.chartbarcopycolor &&
                      contentOptions.chartbarcopycolor.checkbox == true
                        ? "#000"
                        : "#fff"
                    }`,
                  }}
                >
                  {contentOptions.chartbartitle && contentOptions.chartbartitle.checkbox == true ? (
                    <span>{contentOptions.chartbartitle.field}</span>
                  ) : (
                    ""
                  )}
                  {contentOptions.chartbarcopy && contentOptions.chartbarcopy.checkbox == true ? (
                    <span>{contentOptions.chartbarcopy.field}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="s-graph">
              <table className="u-tableUnstyled u-tableCollapse">
                <thead>
                  <tr>
                    <th scope="col">
                      {contentOptions.stackedtitle && contentOptions.stackedtitle.field}
                    </th>
                    <th scope="col">Latency</th>
                    <th scope="col">Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{contentOptions.stackedcopy && contentOptions.stackedcopy.field}</td>
                    <td>13-13-15</td>
                    <td>1.35v</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        ) : (
          ""
        )}

        {contentOptions.enablerow3 && contentOptions.enablerow3.checkbox ? (
          (contentOptions.row3 && contentOptions.row3.selected == "Single Column") ||
          (contentOptions.row3 && contentOptions.row3.selected == "Multi-Column") ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row3.selected == "Multi-Column" &&
                  contentOptions.row3numcol &&
                  contentOptions.row3numcol.selected == 2
                    ? " l-1/2@md"
                    : contentOptions.row3.selected == "Multi-Column" &&
                        contentOptions.row3numcol &&
                        contentOptions.row3numcol.selected == 3
                      ? " l-1/2@md l-1/3@lg"
                      : " l-1/1"
                }`}
              >
                {contentOptions.heading1 && contentOptions.heading1.checkbox ? (
                  <h2>{contentOptions.heading1.field}</h2>
                ) : (
                  ""
                )}
                {contentOptions.subheading1 && contentOptions.subheading1.checkbox ? (
                  <h3>{contentOptions.subheading1.field}</h3>
                ) : (
                  ""
                )}
                {contentOptions.p1 && contentOptions.p1.checkbox ? (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                ) : (
                  ""
                )}

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. <a href="#link">{contentOptions.link1 && contentOptions.link1.field}</a>{" "}
                  sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                  anim id est laborum.
                </p>

                {contentOptions.row3options &&
                contentOptions.row3options[0] &&
                contentOptions.row3options[0].checkbox ? (
                  <ol>
                    <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                    <li>List Item 2</li>
                    <li>List Item 3</li>
                    <li>
                      List Item 4
                      <ol>
                        <li>Nested Item 1</li>
                        <li>Nested Item 2</li>
                      </ol>
                    </li>
                  </ol>
                ) : (
                  ""
                )}

                {contentOptions.row3options &&
                contentOptions.row3options[1] &&
                contentOptions.row3options[1].checkbox ? (
                  contentOptions.row3.selected == "Single Column" ? (
                    <ul>
                      <li>{contentOptions.listitem && contentOptions.listitem.field}</li>
                      <li>List Item 2</li>
                      <li>List Item 3</li>
                      <li>
                        List Item 4
                        <ul>
                          <li>Nested Item 1</li>
                          <li>Nested Item 2</li>
                        </ul>
                      </li>
                      <li>List Item 5</li>
                      <li>List Item 6</li>
                      <li>List Item 7</li>
                      <li>List Item 8</li>
                    </ul>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row3options &&
                contentOptions.row3options[2] &&
                contentOptions.row3options[2].checkbox ? (
                  <>
                    <p>
                      Here is an example of a superscript High
                      <sup>
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[0] &&
                          contentOptions.supsubfoot[0].field}
                      </sup>
                    </p>

                    <p>
                      Here is an example of a footnote High
                      <sup className="u-txt-footnote">
                        {contentOptions.supsubfoot &&
                          contentOptions.supsubfoot[1] &&
                          contentOptions.supsubfoot[1].field}
                      </sup>
                    </p>
                    <p>
                      Here is an example of a superscript Copyright
                      <sup className="u-txt-copyright">&copy;</sup>
                      <sup className="u-txt-registered">&reg;</sup>
                      <sup className="u-txt-trademark">&trade;</sup>
                    </p>

                    <p>
                      Here is an example of a subscript Down<sub>low</sub>
                    </p>
                  </>
                ) : (
                  ""
                )}

                {contentOptions.row3options &&
                contentOptions.row3options[3] &&
                contentOptions.row3options[3].checkbox ? (
                  contentOptions.row3.selected == "Single Column" ? (
                    <>
                      <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row3options &&
                contentOptions.row3options[4] &&
                contentOptions.row3options[4].checkbox ? (
                  contentOptions.row3.selected == "Single Column" ||
                  (contentOptions.row3.selected == "Multi-Column" &&
                    contentOptions.row3numcol.selected == 2) ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {contentOptions.row3options &&
                contentOptions.row3options[5] &&
                contentOptions.row3options[5].checkbox ? (
                  contentOptions.row3.selected == "Single Column" ? (
                    <blockquote className="e-blockquote">
                      <div className="e-blockquote__copy">
                        <svg viewBox="0 0 31.63 21.09" width="100%" height="100%">
                          <path
                            d="M13.18 21.09V7.91H5.93L10.54 0H4.61L0 7.91v13.18zM31.63 7.91h-7.25L29 0h-5.94l-4.61 7.91v13.18h13.18z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                        {contentOptions.blockquote &&
                          contentOptions.blockquote[0] &&
                          contentOptions.blockquote[0].field}
                        <svg viewBox="0 0 20.28 13.52" width="100%" height="100%">
                          <path
                            d="M11.83 0v8.45h4.65l-3 5.07h3.8l3-5.07V0zM0 8.45h4.65l-3 5.07h3.8l3-5.07V0H0z"
                            data-name="Layer 1"
                          ></path>
                        </svg>
                      </div>
                      {/* {contentOptions.blockquote &&
                      contentOptions.blockquote[1] &&
                      contentOptions.blockquote[1].checkbox ? (
                        <span> {contentOptions.blockquote[1].field} </span>
                      ) : (
                        ""
                      )}
                      {contentOptions.blockquote &&
                      contentOptions.blockquote[2] &&
                      contentOptions.blockquote[2].checkbox ? (
                        <span className="e-blockquote__author">
                          {contentOptions.blockquote[2].field}
                          {contentOptions.blockquote &&
                          contentOptions.blockquote[3] &&
                          contentOptions.blockquote[3].checkbox ? (
                            <span className="e-blockquote__author__title">
                              &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </blockquote>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {contentOptions.row3.selected == "Multi-Column" &&
              contentOptions.row3numcol.selected >= 2 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row3numcol && contentOptions.row3numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row3numcol && contentOptions.row3numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  <h2>Heading Column 2</h2>
                  <h3>Subheading Column 2</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row3options &&
                  contentOptions.row3options[1] &&
                  contentOptions.row3options[1].checkbox ? (
                    contentOptions.row3.selected == "Multi-Column" &&
                    contentOptions.row3numcol.selected >= 2 ? (
                      <ul>
                        <li>List Item 1</li>
                        <li>List Item 2</li>
                        <li>List Item 3</li>
                        <li>
                          List Item 4
                          <ul>
                            <li>Nested Item 1</li>
                            <li>Nested Item 2</li>
                          </ul>
                        </li>
                        <li>List Item 5</li>
                        <li>List Item 6</li>
                        <li>List Item 7</li>
                        <li>List Item 8</li>
                      </ul>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row3options &&
                  contentOptions.row3options[3] &&
                  contentOptions.row3options[3].checkbox ? (
                    contentOptions.row3.selected == "Multi-Column" &&
                    contentOptions.row3numcol.selected >= 2 ? (
                      <>
                        <img src="/images/fpo/general/1200x600.png" alt="Kingston" />

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                          est laborum.
                        </p>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {contentOptions.row3options &&
                  contentOptions.row3options[5] &&
                  contentOptions.row3options[5].checkbox ? (
                    contentOptions.row3.selected == "Multi-Column" &&
                    contentOptions.row3numcol.selected >= 2 ? (
                      <blockquote className="e-blockquote">
                        <div className="e-blockquote__copy">
                          {contentOptions.blockquote &&
                            contentOptions.blockquote[0] &&
                            contentOptions.blockquote[0].field}
                        </div>
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[1] &&
                        contentOptions.blockquote[1].checkbox ? (
                          <span> {contentOptions.blockquote[1].field} </span>
                        ) : (
                          ""
                        )}
                        {contentOptions.blockquote &&
                        contentOptions.blockquote[2] &&
                        contentOptions.blockquote[2].checkbox ? (
                          <span className="e-blockquote__author">
                            {contentOptions.blockquote[2].field}
                            {contentOptions.blockquote &&
                            contentOptions.blockquote[3] &&
                            contentOptions.blockquote[3].checkbox ? (
                              <span className="e-blockquote__author__title">
                                &nbsp;-&nbsp;{contentOptions.blockquote[3].field}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </blockquote>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {contentOptions.row3.selected == "Multi-Column" &&
              contentOptions.row3numcol.selected >= 3 ? (
                <div
                  className={`l-row__col${
                    contentOptions.row3numcol && contentOptions.row3numcol.selected == 2
                      ? " l-1/2@md"
                      : contentOptions.row3numcol && contentOptions.row3numcol.selected == 3
                        ? " l-1/2@md l-1/3@lg"
                        : " l-1/1"
                  }`}
                >
                  <h2>Heading Column 3</h2>
                  <h3>Subheading Column 3</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  {contentOptions.row3options &&
                  contentOptions.row3options[4] &&
                  contentOptions.row3options[4].checkbox ? (
                    <>
                      <figure className="c-figure">
                        <DropZone setImage={setFeatureImage}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage.preview ? featureImage.preview : DefaultImg}
                            />
                            {/* {contentOptions.figureoverlay &&
                       contentOptions.figureoverlay.checkbox == true ? (
                         <div className="c-figure__gfx__overlay">
                           <a className="c-figure__gfx__overlay__svg">
                             <svg>
                               <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                             </svg>
                           </a>
                         </div>
                       ) : (
                         ""
                       )} */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          {contentOptions.figurecaption && contentOptions.figurecaption.field}
                        </figcaption>
                      </figure>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                      </p>
                      <figure
                        className={`c-figure ${
                          contentOptions.figurealignment &&
                          contentOptions.figurealignment.selected == "Float Left"
                            ? "c-figure--left"
                            : contentOptions.figurealignment &&
                                contentOptions.figurealignment.selected == "Float Right"
                              ? "c-figure--right"
                              : ""
                        }`}
                      >
                        <DropZone setImage={setFeatureImage3}>
                          <div className="c-figure__gfx">
                            <img
                              className="c-figure__gfx"
                              src={featureImage3.preview ? featureImage3.preview : MedImg}
                            />

                            {/* <div className="c-figure__gfx__overlay">
                         <a className="c-figure__gfx__overlay__svg">
                           <svg>
                             <use xlinkHref="../images/icons-map.svg#zoom-in"></use>
                           </svg>
                         </a>
                       </div> */}
                          </div>
                        </DropZone>
                        <figcaption className="c-figure__caption">
                          Figure Component with Description
                          {/* Figure Component with overlay Description */}
                        </figcaption>
                      </figure>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe
                        numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem
                        aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
                        veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
                        consequuntur.
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : contentOptions.row3.selected == "Column Flow" ? (
            <div className="l-row">
              <div
                className={`l-row__col${
                  contentOptions.row3numcol && contentOptions.row3numcol.selected == 2
                    ? " l-row__col--flow2@md l-row__col--flow2@lg l-row__col--flow2@xlg"
                    : contentOptions.row3.selected == "Column Flow" &&
                        contentOptions.row3numcol &&
                        contentOptions.row3numcol.selected == 3
                      ? " l-row__col--flow2@md l-row__col--flow3@lg l-row__col--flow3@xlg"
                      : ""
                }`}
              >
                <h2>Heading Column Flow</h2>
                <h3>Subheading Column Flow</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          ) : contentOptions.row3.selected == "Chart Bar" ? (
            <div className="c-graph c-graph--bar">
              <div
                className="c-graph__item"
                style={{
                  width: `${
                    contentOptions.chartbarlength && contentOptions.chartbarlength.selected
                  }`,
                  backgroundColor: `${
                    contentOptions.chartbarcolor && contentOptions.chartbarcolor.field
                  }`,
                }}
              >
                <div
                  className="c-graph__item__info"
                  style={{
                    color: `${
                      contentOptions.chartbarcopycolor &&
                      contentOptions.chartbarcopycolor.checkbox == true
                        ? "#000"
                        : "#fff"
                    }`,
                  }}
                >
                  {contentOptions.chartbartitle && contentOptions.chartbartitle.checkbox == true ? (
                    <span>{contentOptions.chartbartitle.field}</span>
                  ) : (
                    ""
                  )}
                  {contentOptions.chartbarcopy && contentOptions.chartbarcopy.checkbox == true ? (
                    <span>{contentOptions.chartbarcopy.field}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="s-graph">
              <table className="u-tableUnstyled u-tableCollapse">
                <thead>
                  <tr>
                    <th scope="col">
                      {contentOptions.stackedtitle && contentOptions.stackedtitle.field}
                    </th>
                    <th scope="col">Latency</th>
                    <th scope="col">Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{contentOptions.stackedcopy && contentOptions.stackedcopy.field}</td>
                    <td>13-13-15</td>
                    <td>1.35v</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        ) : (
          ""
        )}

        {contentOptions.enablerow4 && contentOptions.enablerow4.checkbox ? (
          <div className="l-row">
            <div
              className={`l-row__col l-row__col--flow2@md l-row__col--flow2@lg l-row__col--flow2@xlg`}
            >
              {contentOptions.row4options &&
                contentOptions.row4options[0] &&
                contentOptions.row4options[0].checkbox ? (
                  <iframe className="c-embedVideo" src="https://www.youtube.com/embed/JeHRCuy_Lo0?si=EAbfeBCi4I3sNNiJ" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="allowfullscreen"></iframe>
                ) : (
                  ""
              )}
              <h2>Heading Column Flow</h2>
              <h3>Subheading Column Flow</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {contentOptions.enablerow5 && contentOptions.enablerow5.checkbox ? (
          <div className="l-row">
            <div
              className={`l-row__col l-row__col--flow2@md l-row__col--flow3@lg l-row__col--flow3@xlg`}
            >
              <h2>Heading Column Flow</h2>
              <h3>Subheading Column Flow</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {contentOptions.enablerow6 && contentOptions.enablerow6.checkbox ? (
          <div className="c-graph c-graph--bar">
            <div
              className="c-graph__item"
              style={{
                width: `${contentOptions.chartbarlength && contentOptions.chartbarlength.selected}`,
                backgroundColor: `${
                  contentOptions.chartbarcolor && contentOptions.chartbarcolor.field
                }`,
              }}
            >
              <div
                className="c-graph__item__info"
                style={{
                  color: `${
                    contentOptions.chartbarcopycolor &&
                    contentOptions.chartbarcopycolor.checkbox == true
                      ? "#000"
                      : "#fff"
                  }`,
                }}
              >
                {contentOptions.chartbartitle && contentOptions.chartbartitle.checkbox == true ? (
                  <span>{contentOptions.chartbartitle.field}</span>
                ) : (
                  ""
                )}
                {contentOptions.chartbarcopy && contentOptions.chartbarcopy.checkbox == true ? (
                  <span>{contentOptions.chartbarcopy.field}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {contentOptions.enablerow7 && contentOptions.enablerow7.checkbox ? (
          <div className="s-graph">
            <table className="u-tableUnstyled u-tableCollapse">
              <thead>
                <tr>
                  <th scope="col">
                    {contentOptions.stackedtitle && contentOptions.stackedtitle.field}
                  </th>
                  <th scope="col">Latency</th>
                  <th scope="col">Voltage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{contentOptions.stackedcopy && contentOptions.stackedcopy.field}</td>
                  <td>13-13-15</td>
                  <td>1.35v</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_. ex: htmlName: "KeyFeature",
export default {
  path: "content",
  component: S_Content,
  navtxt: "Content",
  htmlName: "Content",
  categoryType: ["landing page", "web"],
};
