/* eslint-disable jsx-a11y/anchor-has-content */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_ProductResults = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/product-results",
      outputName: "ProductResults",
      headerName: "Product Results",
      tags: null,
      description: "The Product Results displays related products",
      related: null,
      currentTheme: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/gridFlex.layout.js",
        "../../js/compactProductCard.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: varies based on product image"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["Defaults to a <span> and should only be text. Recommend max lines of 2"]],
        ["Product Number", ["String text within a span. Recommended one line."]],
        ["Tag", ["String text within a span"]],
        ["Copy", ["The copy appears as a paragraph beneath the title. Max 3 lines."]],
        ["Downloads", ["String text within an anchor."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ER0331UivtZPlIV6PVJVHpgBJ1K0zuDYp8I7DN8tWc19JA?e=Q6gTGH",
        comments: null,
      },
    };
    setAppState(fbVars);

    setContentOptions({
      kit: {
        name: "kit",
        label: "Tag",
        checkbox: true,
        field: "4",
      },
      product1: [
        {
          name: "title1",
          label: "Title 1",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum",
            Span: "Hacía calo",
            ChS: "那个天气很热",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con",
            Pl: "Było gorąco w",
            Port: "Fazia calor",
            Viet: "Thời tiết đó",
            Turk: "O hava sıcaktı",
            Ukrn: "У таку погоду",
            Thai: "อากาศช่วงนั้นร้อน",
            Kor: "국민경제의 발전을",
            ChT: "那個天氣很熱",
            Jp: "旅ロ京青利セムレ",
          },
          block: 1,
        },
        {
          name: "copy1",
          label: "Copy 1",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum dolor",
            Span: "Hacía calor en",
            ChS: "那个天气很热，对我来说",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con quel",
            Pl: "Było gorąco w taką pogodę",
            Port: "Fazia calor naquele tempo",
            Viet: "Thời tiết đó nóng nực",
            Turk: "O hava sıcaktı, benim için",
            Ukrn: "У таку погоду було спекотно",
            Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
            Kor: "국민경제의 발전을 위한 중요정책의 수립에",
            ChT: "那個天氣很熱，對我來說，我真的不知道",
            Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
          },
          block: 1,
        },
        {
          name: "link1",
          label: "Spec link for card 1",
          checkbox: null,
          field: {
            Eng: "Spec Sheet PDF",
            Span: "Velocidad",
            ChS: "速度",
            Gr: "Geschwindigkeit",
            Ru: "Скорость",
            Fr: "Vitesse",
            It: "Velocità",
            Pl: "Prędkość",
            Port: "Velocidade",
            Viet: "Tốc độ",
            Turk: "Hız",
            Ukrn: "швидкість",
            Thai: "ความเร็ว",
            Kor: "속도",
            ChT: "速度",
            Jp: "スピード",
          },
          block: 1,
        },
      ],
      product2: [
        {
          name: "title2",
          label: "Title 2",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum",
            Span: "Hacía calo",
            ChS: "那个天气很热",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con",
            Pl: "Było gorąco w",
            Port: "Fazia calor",
            Viet: "Thời tiết đó",
            Turk: "O hava sıcaktı",
            Ukrn: "У таку погоду",
            Thai: "อากาศช่วงนั้นร้อน",
            Kor: "국민경제의 발전을",
            ChT: "那個天氣很熱",
            Jp: "旅ロ京青利セムレ",
          },
          block: 2,
        },
        {
          name: "copy2",
          label: "Copy 2",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum dolor",
            Span: "Hacía calor en",
            ChS: "那个天气很热，对我来说",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con quel",
            Pl: "Było gorąco w taką pogodę",
            Port: "Fazia calor naquele tempo",
            Viet: "Thời tiết đó nóng nực",
            Turk: "O hava sıcaktı, benim için",
            Ukrn: "У таку погоду було спекотно",
            Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
            Kor: "국민경제의 발전을 위한 중요정책의 수립에",
            ChT: "那個天氣很熱，對我來說，我真的不知道",
            Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
          },
          block: 2,
        },
      ],
      product3: [
        {
          name: "title3",
          label: "Title 3",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum",
            Span: "Hacía calo",
            ChS: "那个天气很热",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con",
            Pl: "Było gorąco w",
            Port: "Fazia calor",
            Viet: "Thời tiết đó",
            Turk: "O hava sıcaktı",
            Ukrn: "У таку погоду",
            Thai: "อากาศช่วงนั้นร้อน",
            Kor: "국민경제의 발전을",
            ChT: "那個天氣很熱",
            Jp: "旅ロ京青利セムレ",
          },
          block: 3,
        },
        {
          name: "copy3",
          label: "Copy 3",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum dolor",
            Span: "Hacía calor en",
            ChS: "那个天气很热，对我来说",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con quel",
            Pl: "Było gorąco w taką pogodę",
            Port: "Fazia calor naquele tempo",
            Viet: "Thời tiết đó nóng nực",
            Turk: "O hava sıcaktı, benim için",
            Ukrn: "У таку погоду було спекотно",
            Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
            Kor: "국민경제의 발전을 위한 중요정책의 수립에",
            ChT: "那個天氣很熱，對我來說，我真的不知道",
            Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
          },
          block: 3,
        },
      ],
      product4: [
        {
          name: "title4",
          label: "Title 4",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum",
            Span: "Hacía calo",
            ChS: "那个天气很热",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con",
            Pl: "Było gorąco w",
            Port: "Fazia calor",
            Viet: "Thời tiết đó",
            Turk: "O hava sıcaktı",
            Ukrn: "У таку погоду",
            Thai: "อากาศช่วงนั้นร้อน",
            Kor: "국민경제의 발전을",
            ChT: "那個天氣很熱",
            Jp: "旅ロ京青利セムレ",
          },
          block: 4,
        },
        {
          name: "copy4",
          label: "Copy 4",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum dolor",
            Span: "Hacía calor en",
            ChS: "那个天气很热，对我来说",
            Gr: "Tochter; es hieß",
            Ru: "Лорем ипсум долор",
            Fr: "Il faisait chaud",
            It: "Faceva caldo con quel",
            Pl: "Było gorąco w taką pogodę",
            Port: "Fazia calor naquele tempo",
            Viet: "Thời tiết đó nóng nực",
            Turk: "O hava sıcaktı, benim için",
            Ukrn: "У таку погоду було спекотно",
            Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
            Kor: "국민경제의 발전을 위한 중요정책의 수립에",
            ChT: "那個天氣很熱，對我來說，我真的不知道",
            Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
          },
          block: 4,
        },
      ],
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "" });
  }, []);

  if (!contentOptions || !dimensions) return "...Loading Product Results";
  return (
    <section className="s-productResults">
      <ul className="l-gridFlex" data-min="288" data-max="330" data-type="fixed" id="pg-kz7ou9">
        <li data-index="0" data-id="pg-kz7ou9">
          <div
            className="c-compactProductCard c-compactProductCard--dram"
            id="compactProductCard-0"
          >
            {contentOptions.kit && contentOptions.kit.checkbox == true ? (
              <div className="e-tag c-compactProductCard__kit">
                <span className="c-compactProductCard__kit__lg">
                  Kit of {contentOptions.kit.field}
                </span>
                <span className="c-compactProductCard__kit__sm">x {contentOptions.kit.field}</span>
              </div>
            ) : (
              ""
            )}
            <a
              className="c-compactProductCard__link"
              href="#productCardLink"
              title="Product Card Title"
            ></a>
            <div className="c-compactProductCard__header">
              <span className="c-compactProductCard__header__name">
                {contentOptions.product1 &&
                  contentOptions.product1[0].field[contentOptions.currentLang]}
                <span className="c-compactProductCard__header__name__partNumber">
                  (KTH-PL426/16G)
                </span>
              </span>
            </div>
            <div className="c-compactProductCard__image">
              <img
                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                alt={
                  contentOptions.product1 &&
                  contentOptions.product1[0].field[contentOptions.currentLang]
                }
              />
            </div>
            <div className="c-compactProductCard__details">
              <div className="c-compactProductCard__details__content">
                <div className="c-compactProductCard__details__content__longDesc">
                  <ul className="u-list-commas">
                    <li>This is Bullet Number One and it goes on for a little while here</li>
                    <li>
                      Second Bullet Point Goes Here, thank you very much for not keeping it short
                    </li>
                    <li className="c-compactProductCard__details__content__longDesc__partNumber">
                      Part Number: KTH-PL426/16G
                    </li>
                  </ul>
                </div>
              </div>
              <p className="c-compactProductCard__details__shortDesc">
                {contentOptions.product1 &&
                  contentOptions.product1[1].field[contentOptions.currentLang]}
              </p>
              <ul className="c-compactProductCard__details__downloads u-list-unstyled u-list-commas">
                <li>
                  <a
                    href="http://www.africau.edu/images/default/sample.pdf"
                    download=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contentOptions.product1 &&
                      contentOptions.product1[2].field[contentOptions.currentLang]}
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.africau.edu/images/default/sample.pdf"
                    download=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    PCN
                  </a>
                </li>
              </ul>
            </div>
            <span className="c-compactProductCard__partNumber">Part Number: KTH-PL426/16G</span>
          </div>
        </li>
        <li data-index="1" data-id="pg-kz7ou9">
          <div
            className="c-compactProductCard c-compactProductCard--product c-compactProductCard--dram"
            id="compactProductCard-1"
          >
            <a
              className="c-compactProductCard__link"
              href="#productCardLink"
              title="Product Card Title"
            ></a>
            <div className="c-compactProductCard__header">
              <span className="c-compactProductCard__header__name">
                {contentOptions.product2 &&
                  contentOptions.product2[0].field[contentOptions.currentLang]}
              </span>
            </div>
            <div className="c-compactProductCard__image">
              <img
                src="https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                alt={
                  contentOptions.product2 &&
                  contentOptions.product2[0].field[contentOptions.currentLang]
                }
              />
            </div>
            <div className="c-compactProductCard__details">
              <div className="c-compactProductCard__details__content">
                <div className="c-compactProductCard__details__content__longDesc">
                  <ul className="u-list-commas">
                    <li>
                      {contentOptions.product2 &&
                        contentOptions.product2[1].field[contentOptions.currentLang]}
                    </li>
                    <li>Second Bullet Point Goes Here</li>
                    <li>Maybe a Third One As Well</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li data-index="2" data-id="pg-kz7ou9">
          <div className="c-compactProductCard" id="compactProductCard-2">
            <a
              className="c-compactProductCard__link"
              href="#productCardLink"
              title="Product Card Title"
            ></a>
            <div className="c-compactProductCard__header">
              <span className="c-compactProductCard__header__name">
                {contentOptions.product3 &&
                  contentOptions.product3[0].field[contentOptions.currentLang]}
                <span className="c-compactProductCard__header__name__partNumber">
                  (KTH-PL426/16G)
                </span>
              </span>
            </div>
            <div className="c-compactProductCard__image">
              <img
                src="https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                alt={
                  contentOptions.product3 &&
                  contentOptions.product3[0].field[contentOptions.currentLang]
                }
              />
            </div>
            <div className="c-compactProductCard__details">
              <div className="c-compactProductCard__details__content">
                <div className="c-compactProductCard__details__content__longDesc">
                  <ul className="u-list-commas">
                    <li>
                      {contentOptions.product3 &&
                        contentOptions.product3[1].field[contentOptions.currentLang]}
                    </li>
                    <li>
                      Second Bullet Point Goes Here, thank you very much for not keeping it short.
                    </li>
                    <li className="c-compactProductCard__details__content__longDesc__partNumber">
                      Part Number: KTH-PL426/16G
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="c-compactProductCard__partNumber">Part Number: KTH-PL426/16G</span>
          </div>
        </li>
        <li data-index="3" data-id="pg-kz7ou9">
          <div
            className="c-compactProductCard c-compactProductCard--product"
            id="compactProductCard-3"
          >
            <a
              className="c-compactProductCard__link"
              href="#productCardLink"
              title="Product Card Title"
            ></a>
            <div className="c-compactProductCard__header">
              <span className="c-compactProductCard__header__name">
                {contentOptions.product4 &&
                  contentOptions.product4[0].field[contentOptions.currentLang]}
              </span>
            </div>
            <div className="c-compactProductCard__image">
              <img
                src="https://media.kingston.com/kingston/product/ktc-product-usb-dt100g3-dt100g3256gb-1-tn.png"
                alt={
                  contentOptions.product4 &&
                  contentOptions.product4[0].field[contentOptions.currentLang]
                }
              />
            </div>
            <div className="c-compactProductCard__details">
              <div className="c-compactProductCard__details__content">
                <div className="c-compactProductCard__details__content__longDesc">
                  <ul className="u-list-commas">
                    <li>
                      {contentOptions.product4 &&
                        contentOptions.product4[1].field[contentOptions.currentLang]}
                    </li>
                    <li>USB 3.1 Gen 1 (USB 3.0)</li>
                    <li>32GB, 64GB, 128GB, 256GB</li>
                    <li>Up to 130MB/s read</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-results",
  component: S_ProductResults,
  navtxt: "Product Results",
  htmlName: "ProductResults",
  categoryType: ["internal"],
};
