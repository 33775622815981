export default {
  featureType: {
    label: "Feature Box Type",
    checkbox: null,
    field: ["Default", "Long Description"],
    selected: "Default",
  },
  numFeatures: {
    label: "Featured Items",
    checkbox: null,
    field: ["1", "2", "3", "4", "5", "6"],
    selected: "3",
  },
  optNoTitles: { label: "Titles [optional]", checkbox: true, field: null },
  optNoCopy: { label: "Copy [optional]", checkbox: true, field: null },
  optNoCTA: { label: "CTA [optional]", checkbox: true, field: null },
  modSmallIcons: { label: "Small Icon [modifier]", checkbox: false, field: null },
  titletype: {
    label: "Title Type",
    checkbox: null,
    field: ["H2", "H3", "H4", "H5", "H6"],
    selected: "H3",
  },
  feature1: [
    {
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Tabla de almacenamiento",
        ChS: "存储图表",
        Gr: "Speicherdiagramm",
        Ru: "График хранения",
        Fr: "Tableau de stockage",
        It: "Grafico di archiviazione",
        Pl: "Wykres przechowywania",
        Port: "Gráfico de armazenamento",
        Viet: "Biểu đồ lưu trữ",
        Turk: "Depolama tablosu",
        Ukrn: "Діаграма зберігання",
        Thai: "แผนภูมิการจัดเก็บ",
        Kor: "스토리지 차트",
        ChT: "存儲圖表",
        Jp: "収納チャート",
      },
      block: 1,
    },
    {
      label: "Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "Desde fotos hasta videos y música, obtenga una idea más amplia de lo que su capacidad contendrá.",
        ChS: "从照片到视频和音乐，更全面地了解您的容量。",
        Gr: "Von Foto über Video bis hin zu Musik – verschaffen Sie sich einen besseren Überblick über Ihre Kapazität.",
        Ru: "От фото до видео и музыки, получите более полное представление о том, что может вместить ваша емкость.",
        Fr: "De la photo à la vidéo et à la musique, obtenez une plus grande idée de ce que votre capacité contiendra.",
        It: "Dalla foto al video e alla musica, fatti un'idea più ampia di ciò che la tua capacità può contenere.",
        Pl: "Od zdjęć po filmy i muzykę — uzyskaj większy obraz tego, ile pomieści Twoja pojemność.",
        Port: "De fotos a vídeos e músicas, tenha uma ideia mais ampla de sua capacidade.",
        Viet: "Từ ảnh đến video và âm nhạc, hãy hiểu rõ hơn về dung lượng của bạn sẽ chứa.",
        Turk: "Fotoğraftan videoya ve müziğe, kapasitenizin neler taşıyacağına dair daha geniş bir fikir edinin.",
        Ukrn: "Від фотографій до відео та музики – отримайте ширше уявлення про те, що вміщає ваша ємність.",
        Thai: "ตั้งแต่ภาพถ่ายไปจนถึงวิดีโอและเพลง ทำความเข้าใจให้กว้างขึ้นเกี่ยวกับความจุของคุณ",
        Kor: "사진에서 비디오 및 음악에 이르기까지 귀하의 용량에 대해 더 큰 아이디어를 얻으십시오.",
        ChT: "從照片到視頻和音樂，更全面地了解您的容量。",
        Jp: "写真からビデオ、音楽まで、あなたの容量がどれだけ保持できるかについてより大きなアイデアを得ることができます。",
      },
      block: 1,
      charLimit: 150,
    },
    {
      label: "Cta 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 1,
    },
  ],
  feature2: [
    {
      label: "Title 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Cuidado de flash",
        ChS: "闪光护理",
        Gr: "Blitzpflege",
        Ru: "Уход за вспышками",
        Fr: "Soin flash",
        It: "Cura del flash",
        Pl: "Pielęgnacja błysku",
        Port: "Cuidados com flash",
        Viet: "Chăm sóc đèn flash",
        Turk: "Flaş bakımı",
        Ukrn: "Догляд за спалахом",
        Thai: "การดูแลแฟลช",
        Kor: "플래시 케어",
        ChT: "閃光護理",
        Jp: "フラッシュケア",
      },
      block: 2,
    },
    {
      label: "Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "El cuidado y el mantenimiento pueden ser abrumadores. Menos mal que lo tenemos cubierto.",
        ChS: "保养和维护可能会让人不知所措。幸好我们为您服务。",
        Gr: "Pflege und Wartung können überwältigend sein. Gut, dass wir Sie abgedeckt haben.",
        Ru: "Содержание и техническое обслуживание могут быть огромными. Хорошо, что мы тебя прикрыли.",
        Fr: "L'entretien et la maintenance peuvent être accablants. Heureusement que nous vous avons couvert.",
        It: "La manutenzione e la manutenzione possono essere travolgenti. Meno male che ti abbiamo coperto.",
        Pl: "Utrzymanie i konserwacja mogą być przytłaczające. Dobrze, że cię chronimy.",
        Port: "Manutenção e manutenção podem ser esmagadoras. Ainda bem que temos você coberto.",
        Viet: "Bảo trì và bảo trì có thể được áp đảo. Điều tốt là chúng tôi có bạn bảo hiểm.",
        Turk: "Bakım ve onarım ezici olabilir. İyi ki seni koruduk.",
        Ukrn: "Догляд і технічне обслуговування можуть бути непосильними. Добре, що ми забезпечили вас.",
        Thai: "การบำรุงรักษาและการบำรุงรักษาสามารถท่วมท้น สิ่งที่ดีที่เรามีคุณครอบคลุม",
        Kor: "유지 및 유지 관리가 압도적일 수 있습니다. 우리가 당신을 다루어 다행입니다.",
        ChT: "保養和維護可能會讓人不知所措。幸好我們為您服務。",
        Jp: "メンテナンスとメンテナンスは大変な場合があります。良いことです",
      },
      block: 2,
      charLimit: 150,
    },
    {
      label: "Cta 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 2,
    },
  ],
  feature3: [
    {
      label: "Title 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Garantía",
        ChS: "保修单",
        Gr: "Garantie",
        Ru: "Гарантия",
        Fr: "Garantie",
        It: "Garanzia",
        Pl: "Gwarancja",
        Port: "Garantia",
        Viet: "Sự bảo đảm",
        Turk: "Garanti",
        Ukrn: "Гарантія",
        Thai: "การรับประกัน",
        Kor: "보증",
        ChT: "保修單",
        Jp: "保証",
      },
      block: 3,
    },
    {
      label: "Copy 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "¿Necesita más información sobre la garantía limitada de Kingston?",
        ChS: "需要有关金士顿有限保修的更多信息？",
        Gr: "Benötigen Sie weitere Informationen zur beschränkten Garantie von Kingston?",
        Ru: "Нужна дополнительная информация об ограниченной гарантии Kingston?",
        Fr: "Besoin de plus d'informations sur la garantie limitée de Kingston ?",
        It: "Hai bisogno di maggiori informazioni sulla garanzia limitata di Kingston?",
        Pl: "Potrzebujesz więcej informacji na temat ograniczonej gwarancji firmy Kingston?",
        Port: "Precisa de mais informações sobre a Garantia Limitada da Kingston?",
        Viet: "Cần thêm thông tin về Bảo hành có giới hạn của Kingston?",
        Turk: "Kingston'ın Sınırlı Garantisi hakkında daha fazla bilgiye mi ihtiyacınız var?",
        Ukrn: "Потрібна додаткова інформація щодо обмеженої гарантії Kingston?",
        Thai: "ต้องการข้อมูลเพิ่มเติมเกี่ยวกับการรับประกันแบบจำกัดของ Kingston หรือไม่",
        Kor: "Kingston의 제한 보증에 대한 자세한 정보가 필요하십니까?",
        ChT: "需要有關金士頓有限保修的更多信息？",
        Jp: "Kingston の限定保証に関する詳細情報が必要ですか?",
      },
      block: 3,
      charLimit: 150,
    },
    {
      label: "Cta 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 3,
    },
  ],
  feature4: [
    {
      label: "Title 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Garantía",
        ChS: "保修单",
        Gr: "Garantie",
        Ru: "Гарантия",
        Fr: "Garantie",
        It: "Garanzia",
        Pl: "Gwarancja",
        Port: "Garantia",
        Viet: "Sự bảo đảm",
        Turk: "Garanti",
        Ukrn: "Гарантія",
        Thai: "การรับประกัน",
        Kor: "보증",
        ChT: "保修單",
        Jp: "保証",
      },
      block: 4,
    },
    {
      label: "Copy 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "¿Necesita más información sobre la garantía limitada de Kingston?",
        ChS: "需要有关金士顿有限保修的更多信息？",
        Gr: "Benötigen Sie weitere Informationen zur beschränkten Garantie von Kingston?",
        Ru: "Нужна дополнительная информация об ограниченной гарантии Kingston?",
        Fr: "Besoin de plus d'informations sur la garantie limitée de Kingston ?",
        It: "Hai bisogno di maggiori informazioni sulla garanzia limitata di Kingston?",
        Pl: "Potrzebujesz więcej informacji na temat ograniczonej gwarancji firmy Kingston?",
        Port: "Precisa de mais informações sobre a Garantia Limitada da Kingston?",
        Viet: "Cần thêm thông tin về Bảo hành có giới hạn của Kingston?",
        Turk: "Kingston'ın Sınırlı Garantisi hakkında daha fazla bilgiye mi ihtiyacınız var?",
        Ukrn: "Потрібна додаткова інформація щодо обмеженої гарантії Kingston?",
        Thai: "ต้องการข้อมูลเพิ่มเติมเกี่ยวกับการรับประกันแบบจำกัดของ Kingston หรือไม่",
        Kor: "Kingston의 제한 보증에 대한 자세한 정보가 필요하십니까?",
        ChT: "需要有關金士頓有限保修的更多信息？",
        Jp: "Kingston の限定保証に関する詳細情報が必要ですか?",
      },
      block: 4,
      charLimit: 150,
    },
    {
      label: "Cta 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 4,
    },
  ],
  feature5: [
    {
      label: "Title 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Tabla de almacenamiento",
        ChS: "存储图表",
        Gr: "Speicherdiagramm",
        Ru: "График хранения",
        Fr: "Tableau de stockage",
        It: "Grafico di archiviazione",
        Pl: "Wykres przechowywania",
        Port: "Gráfico de armazenamento",
        Viet: "Biểu đồ lưu trữ",
        Turk: "Depolama tablosu",
        Ukrn: "Діаграма зберігання",
        Thai: "แผนภูมิการจัดเก็บ",
        Kor: "스토리지 차트",
        ChT: "存儲圖表",
        Jp: "収納チャート",
      },
      block: 5,
    },
    {
      label: "Copy 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "Desde fotos hasta videos y música, obtenga una idea más amplia de lo que su capacidad contendrá.",
        ChS: "从照片到视频和音乐，更全面地了解您的容量。",
        Gr: "Von Foto über Video bis hin zu Musik – verschaffen Sie sich einen besseren Überblick über Ihre Kapazität.",
        Ru: "От фото до видео и музыки, получите более полное представление о том, что может вместить ваша емкость.",
        Fr: "De la photo à la vidéo et à la musique, obtenez une plus grande idée de ce que votre capacité contiendra.",
        It: "Dalla foto al video e alla musica, fatti un'idea più ampia di ciò che la tua capacità può contenere.",
        Pl: "Od zdjęć po filmy i muzykę — uzyskaj większy obraz tego, ile pomieści Twoja pojemność.",
        Port: "De fotos a vídeos e músicas, tenha uma ideia mais ampla de sua capacidade.",
        Viet: "Từ ảnh đến video và âm nhạc, hãy hiểu rõ hơn về dung lượng của bạn sẽ chứa.",
        Turk: "Fotoğraftan videoya ve müziğe, kapasitenizin neler taşıyacağına dair daha geniş bir fikir edinin.",
        Ukrn: "Від фотографій до відео та музики – отримайте ширше уявлення про те, що вміщає ваша ємність.",
        Thai: "ตั้งแต่ภาพถ่ายไปจนถึงวิดีโอและเพลง ทำความเข้าใจให้กว้างขึ้นเกี่ยวกับความจุของคุณ",
        Kor: "사진에서 비디오 및 음악에 이르기까지 귀하의 용량에 대해 더 큰 아이디어를 얻으십시오.",
        ChT: "從照片到視頻和音樂，更全面地了解您的容量。",
        Jp: "写真からビデオ、音楽まで、あなたの容量がどれだけ保持できるかについてより大きなアイデアを得ることができます。",
      },
      block: 5,
      charLimit: 150,
    },
    {
      label: "Cta 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 5,
    },
  ],
  feature6: [
    {
      label: "Title 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Cuidado de flash",
        ChS: "闪光护理",
        Gr: "Blitzpflege",
        Ru: "Уход за вспышками",
        Fr: "Soin flash",
        It: "Cura del flash",
        Pl: "Pielęgnacja błysku",
        Port: "Cuidados com flash",
        Viet: "Chăm sóc đèn flash",
        Turk: "Flaş bakımı",
        Ukrn: "Догляд за спалахом",
        Thai: "การดูแลแฟลช",
        Kor: "플래시 케어",
        ChT: "閃光護理",
        Jp: "フラッシュケア",
      },
      block: 6,
    },
    {
      label: "Copy 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        Span: "El cuidado y el mantenimiento pueden ser abrumadores. Menos mal que lo tenemos cubierto.",
        ChS: "保养和维护可能会让人不知所措。幸好我们为您服务。",
        Gr: "Pflege und Wartung können überwältigend sein. Gut, dass wir Sie abgedeckt haben.",
        Ru: "Содержание и техническое обслуживание могут быть огромными. Хорошо, что мы тебя прикрыли.",
        Fr: "L'entretien et la maintenance peuvent être accablants. Heureusement que nous vous avons couvert.",
        It: "La manutenzione e la manutenzione possono essere travolgenti. Meno male che ti abbiamo coperto.",
        Pl: "Utrzymanie i konserwacja mogą być przytłaczające. Dobrze, że cię chronimy.",
        Port: "Manutenção e manutenção podem ser esmagadoras. Ainda bem que temos você coberto.",
        Viet: "Bảo trì và bảo trì có thể được áp đảo. Điều tốt là chúng tôi có bạn bảo hiểm.",
        Turk: "Bakım ve onarım ezici olabilir. İyi ki seni koruduk.",
        Ukrn: "Догляд і технічне обслуговування можуть бути непосильними. Добре, що ми забезпечили вас.",
        Thai: "การบำรุงรักษาและการบำรุงรักษาสามารถท่วมท้น สิ่งที่ดีที่เรามีคุณครอบคลุม",
        Kor: "유지 및 유지 관리가 압도적일 수 있습니다. 우리가 당신을 다루어 다행입니다.",
        ChT: "保養和維護可能會讓人不知所措。幸好我們為您服務。",
        Jp: "メンテナンスとメンテナンスは大変な場合があります。良いことです",
      },
      block: 6,
      charLimit: 150,
    },
    {
      label: "Cta 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Aprende más",
        ChS: "了解更多",
        Gr: "Erfahren Sie mehr",
        Ru: "Узнать больше",
        Fr: "Apprendre encore plus",
        It: "Saperne di più",
        Pl: "Ucz się więcej",
        Port: "Saber mais",
        Viet: "Tìm hiểu thêm",
        Turk: "Daha fazla bilgi edin",
        Ukrn: "Вивчайте більше",
        Thai: "เรียนรู้เพิ่มเติม",
        Kor: "더 알아보기",
        ChT: "了解更多",
        Jp: "もっと詳しく知る",
      },
      block: 6,
    },
  ],
  currentLang: "Eng",
};
