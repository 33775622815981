import React from "react";
import C_ColorSwatch from "../../../2_components/colorSwatch/C_ColorSwatch";

let ironKeyLogo = "https://www.kingstonloda.com/AssetLink/32c85e0j3dkc17v4bsh2765n173ubv0a.svg";
let ironKeyLogo2 = "https://www.kingstonloda.com/AssetLink/4i13285mr6xj0cx5o03rj064468p1m3l.svg";

let ironKeyLogoSolo = "https://www.kingstonloda.com/AssetLink/vfgydut2dli6a301kfqky76l4ihf482i.png";
let ironKeyLogoSolo2 =
  "https://www.kingstonloda.com/AssetLink/62x403qs82k3288tjf62lre118s1jt01.png";

const imgClear1 = "https://www.kingstonloda.com/AssetLink/6v4bfd3wxaw5xgbg24q7i7rq3o52w8k3.png";
const imgClear2 = "https://www.kingstonloda.com/AssetLink/hj413w0bld4v0f2c8s1xqp055bp813a1.png";

const incorrectUsageA =
  "https://www.kingstonloda.com/AssetLink/8w0010i5ucrv4ici1u544p4ae2vkh770.svg";
const incorrectUsageB =
  "https://www.kingstonloda.com/AssetLink/81trv08y5om6re5qk08h522v001u1v1s.svg";
const incorrectUsageC =
  "https://www.kingstonloda.com/AssetLink/m217003tcr4nilwa5blw31204xyri4l5.svg";
const incorrectUsageD =
  "https://www.kingstonloda.com/AssetLink/7nyo0141k43g3i8jh2se1nb4c5278qp3.svg";
const incorrectUsageE =
  "https://www.kingstonloda.com/AssetLink/h6ses4n4x2ah030ng5yd05r7a07q351s.svg";
const incorrectUsageF =
  "https://www.kingstonloda.com/AssetLink/og74128qnohtt07k5k3n3hb15e2y6s1a.svg";

const IronKey = () => {
  return (
    <>
      <div className="s-content">
        <div className="l-inner">
          <div className="l-row">
            <div
              className="s-content__headline l-row__col l-1/1 u-flex"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <h2 className="u-mb0">Kingston IronKey Logo</h2>
              <p style={{ margin: "0" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14.05 15.64"
                  style={{ width: "1em", height: "1em" }}
                >
                  <defs></defs>
                  <g>
                    <g>
                      <path d="M6.9 11.14a.73.73 0 01-.43-.14L1.75 6.27a.7.7 0 01-.17-.71.71.71 0 01.66-.48h2.27V.7a.7.7 0 01.7-.7h3.38a.7.7 0 01.7.7v4.38h2.27a.69.69 0 01.65.43.73.73 0 01-.17.77L7.33 11a.74.74 0 01-.43.14zm-4-5.08l4 4 4-4H8.32V1H5.48v5.05zm8.47-.46zm-9 0z"></path>
                      <path d="M14.05 15.64H0v-5.12h3.37v2h7.31v-2h3.37zm-7.12-1h6.15v-3.15h-1.43v2H2.41v-2H1v3.18z"></path>
                    </g>
                  </g>
                </svg>
                &nbsp;
                <a
                  href="https://www.kingstonloda.com/AssetLink/fn4223844s62a505l4wdigx572t4p3eu.zip"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Logo Assets
                </a>
              </p>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The Kingston <strong>IronKey™</strong> logo, comprised of the key icon, logotype and
                trademark (™) symbol, should be used on Kingston IronKey communications and
                marketing collateral. The only instances the IronKey logo will be used without the
                KINGSTON text will be on physical product and / or packaging.
              </p>
              <p>
                The logo should always be used in proportion (as shown) and should not be changed or
                altered in any way - guidance provided below.
              </p>
              <ul>
                <li>
                  <strong>Monochromatic Kingston IronKey Logo</strong>
                </li>
              </ul>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Full color - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={ironKeyLogo} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Full color - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={ironKeyLogo2} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row u-mt">
            <div className="l-row__col l-1/1">
              <h3>Primary Palette</h3>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "1em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch hex={"#000000"} cmyk={"0/0/0/100"} rgb={"0/0/0"} />
                  <C_ColorSwatch hex={"#FFFFFF"} cmyk={"0/0/0/0"} rgb={"255/255/255"} />
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1" style={{ paddingTop: "1em" }}>
              <h3>Secondary Accent Palette</h3>
              <p>
                The following may be used as accent colors for creating IronKey™ marketing
                materials. Please do not use any color but black or white for the IronKey logo.
              </p>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "1em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch
                    hex={"#00A3E0"}
                    cmyk={"74/19/00/00"}
                    rgb={"0/163/224"}
                    pms={"299C"}
                  />
                  <C_ColorSwatch
                    hex={"#005EB8"}
                    cmyk={"91/67/00/00"}
                    rgb={"0/94/184"}
                    pms={"300C"}
                  />
                  <C_ColorSwatch
                    hex={"#6AD1E3"}
                    cmyk={"52/00/11/00"}
                    rgb={"106/209/227"}
                    pms={"310C"}
                  />
                  <C_ColorSwatch
                    hex={"#211E16"}
                    cmyk={"40/30/30/100"}
                    rgb={"33/30/22"}
                    pms={"Process Black C"}
                  />
                  <C_ColorSwatch
                    hex={"#454142"}
                    cmyk={"66/62/58/45"}
                    rgb={"69/65/66"}
                    pms={"4287C"}
                  />
                  <C_ColorSwatch
                    hex={"#53565A"}
                    cmyk={"66/57/52/29"}
                    rgb={"83/86/90"}
                    pms={"Cool Gray 11 C"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="s-content" style={{ background: "#F7F9FA" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>Clear Space</h2>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The Kingston IronKey logo must have a clearance of the “key” length on all four
                sides that allow all applications free of type, graphics, and other elements that
                may visually clutter the logo.
              </p>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1 l-1/2@md">
              <img src={imgClear1} alt="Visual demonstrationg of 'Key' height." />
            </div>
            <div className="l-row__col l-1/1 l-1/2@md">
              <img src={imgClear2} alt="Visual demonstrationg of 'Key' height." />
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                <small>
                  Example showing proper `&quot;`key`&quot;` spacing around the Kingston IronKey
                  logo, free of text, image clutter and high-contrasting backgrounds.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="s-content">
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>Specific Cases</h2>
              <p>IronKey Logo Without Kingston</p>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The only instances the IronKey logo will be used without the KINGSTON text will be
                on physical product and / or packaging.
              </p>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <div>
                    <img src={ironKeyLogoSolo} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <div>
                    <img src={ironKeyLogoSolo2} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                <small>
                  When using the IronKey logo without KINGSTON text (in the above instances only), a
                  Kingston logo must accompany it.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="s-content" style={{ background: "#F7F9FA" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>Guidance</h2>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The Kingston IronKey logo is the fundamental branding element of Kingston IronKey
                and it’s the foundation to our representation and recognition.
              </p>
              <div className="l-row">
                <div className="l-row__col l-1/1">
                  <p>Correct Usage:</p>
                  <div className="u-flex">
                    <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                      <div
                        style={{
                          padding: "3em 5.5em",
                          border: "1px solid black",
                          background: "white",
                        }}
                      >
                        <img src={ironKeyLogo} alt="Kingston Care Logo" />
                      </div>
                    </div>
                    <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                      <div
                        style={{
                          padding: "3em 5.5em",
                          border: "1px solid black",
                          background: "black",
                        }}
                      >
                        <img src={ironKeyLogo2} alt="Kingston Care" />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: "1em" }}>
                    <strong style={{ color: "green", fontWeight: "800" }}>DO</strong> choose the
                    Kingston IronKey logo from the “Correct Usage” section best suited for your
                    needs.
                  </div>
                </div>
              </div>

              <div className="l-row">
                <div className="l-row__col l-1/1">
                  <p>Incorrect Usage:</p>
                  <div className="u-flex" style={{ marginBottom: "1em" }}>
                    <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageA}
                          alt="Incorrect Usage A"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>A:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> change
                      or alter “Kingston” type or “key icon” proportions
                    </div>
                    <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageB}
                          alt="Incorrect Usage B"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>B:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use
                      Kingston IronKey and Kingston Technology logo together on the same visual
                      plane*
                    </div>
                  </div>
                  <div className="u-flex" style={{ marginBottom: "1em" }}>
                    <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageC}
                          alt="Incorrect Usage C"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>C:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> rotate
                      or skew the logo
                    </div>
                    <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageD}
                          alt="Incorrect Usage D"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>D:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong>{" "}
                      stretch, condense or change proportion of logo
                    </div>
                  </div>
                  <div className="u-flex" style={{ marginBottom: "1em" }}>
                    <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageE}
                          alt="Incorrect Usage E"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>E:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use
                      colors other than those specified or colorize any portion of the logo
                    </div>
                    <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                      <div style={{ border: "1px solid black", marginBottom: ".5em" }}>
                        <img
                          src={incorrectUsageF}
                          alt="Incorrect Usage F"
                          style={{ display: "block" }}
                        />
                      </div>
                      <strong>F:</strong>{" "}
                      <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use
                      backgrounds where the Kingston IronKey logo is not legible
                    </div>
                  </div>
                  <div>
                    <p>
                      <small>
                        * There may be some exceptions such as packaging and product compliance
                        labels. Please check with Corporate Team for guidance.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IronKey;
