import React, { useState } from "react";
// let cardImg = "/images/fpo/general/576x200.png";

const C_SupportGalleryCard = ({ image, name, partnumber, url }) => {
  let sName = name ? name : "Support Gallery Card Name";
  let sPartNumber = partnumber ? partnumber : "";
  let sImg = image
    ? image
    : "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png";
  let sUrl = url ? url : null;

  return (
    <>
      {sUrl ? (
        <a href={sUrl} className={`c-supportGalleryCard`}>
          <div className="c-supportGalleryCard__header">
            <span className="c-supportGalleryCard__header__name">
              {sName}
              {sPartNumber ? (
                <>
                  {" "}
                  <span className="c-supportGalleryCard__header__name__partNumber">
                    {"("}
                    {sPartNumber}
                    {")"}
                  </span>
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="c-supportGalleryCard__image">
            <img src={sImg} alt={sName} />
          </div>
        </a>
      ) : (
        <div href={sUrl} className={`c-supportGalleryCard`}>
          <div className="c-supportGalleryCard__header">
            <span className="c-supportGalleryCard__header__name">
              {sName}
              {sPartNumber ? (
                <>
                  {" "}
                  <span className="c-supportGalleryCard__header__name__partNumber">
                    {"("}
                    {sPartNumber}
                    {")"}
                  </span>
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="c-supportGalleryCard__image">
            <img src={sImg} alt={sName} />
          </div>
        </div>
      )}
    </>
  );
};

export default C_SupportGalleryCard;
