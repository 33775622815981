import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext.jsx";
import C_ColorSwatch from "../../2_components/colorSwatch/C_ColorSwatch.jsx";
import C_Swatch from "../../2_components/swatch/C_Swatch.jsx";
import S_Headline from "../../3_sections/headline/S_Headline.jsx";

const Pg_UiUxStyleguide = () => {
  const { appState, setAppState } = useContext(AppContext);

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/uxui",
      outputName: "",
      headerName: "UI/UX Styleguide",
      thumb: "",
      viewHeight: null,
      description: null,
      hasjs: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pg-brandUiUxStyleguide u-flex u-flex1 u-flexColumn">
      <S_Headline
        classMods={"s-headline--alignLeft"}
        headline={appState.headerName}
        history={true}
      />
      <div className="pg-brandUiUxStyleguide__centerWrap u-flex u-flexColumn u-flex1 u-justifyCenter u-animated u-animated--delayFast u-animated--slower a-fadeIn">
        <div className="s-content">
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Colors</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_ColorSwatch
                      name={"Rex"}
                      hex={"#C41320"}
                      cmyk={"0/0/0/79"}
                      rgb={"53/53/53"}
                    />
                    <C_ColorSwatch
                      name={"Coal"}
                      hex={"#353535"}
                      cmyk={"0/0/0/75"}
                      rgb={"99/100/102"}
                    />
                    <C_ColorSwatch
                      name={"Denim"}
                      hex={"#0D4891"}
                      cmyk={"91/50/0/43"}
                      rgb={"13/72/145"}
                    />
                    <C_ColorSwatch
                      name={"Sky"}
                      hex={"#93D5FF"}
                      cmyk={"42/16/0/0"}
                      rgb={"147/213/255"}
                    />
                    <C_ColorSwatch
                      name={"Grey"}
                      hex={"#D8D8D8"}
                      cmyk={"0/0/0/15"}
                      rgb={"216/216/216"}
                    />
                    <C_ColorSwatch
                      name={"White"}
                      hex={"#FFFFFF"}
                      cmyk={"0/0/0/0"}
                      rgb={"255/225/225"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Themes</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_ColorSwatch
                      name={"White"}
                      hex={"#FFFFFF"}
                      cmyk={"0/0/0/0"}
                      rgb={"255/225/225"}
                    />
                    <C_ColorSwatch
                      name={"Pearl"}
                      hex={"#F7F9FA"}
                      cmyk={"1/0/0/2"}
                      rgb={"247/249/250"}
                    />
                    <C_ColorSwatch
                      name={"Stone"}
                      hex={"#EAEEF1"}
                      cmyk={"3/1/0/5"}
                      rgb={"234/238/241"}
                    />
                    <C_ColorSwatch
                      name={"Silver"}
                      hex={"#E0E3E7"}
                      cmyk={"3/2/0/9"}
                      rgb={"224/227/231"}
                    />
                    <C_ColorSwatch
                      name={"Cloudy"}
                      hex={"#B5B9BD"}
                      cmyk={"4/2/0/26"}
                      rgb={"181/185/189"}
                    />
                    <C_ColorSwatch
                      name={"Slate"}
                      hex={"#3B444C"}
                      cmyk={"22/11/0/70"}
                      rgb={"59/68/76"}
                    />
                    <C_ColorSwatch
                      name={"Dark Slate"}
                      hex={"#20262D"}
                      cmyk={"29/16/0/82"}
                      rgb={"32/38/45"}
                    />
                    <C_ColorSwatch
                      name={"Midnight"}
                      hex={"#001428"}
                      cmyk={"100/50/0/84"}
                      rgb={"0/20/40"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content" style={{ background: "#F7F9FA" }}>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Fonts</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <h3 className="u-font-myriadpro">Myriad Pro</h3>
                    <ul className="u-list-unstyled u-p0">
                      <li>
                        <span className="u-font-myriadpro" style={{ fontWeight: "400" }}>
                          Regular
                        </span>
                      </li>
                      <li>
                        <span className="u-font-myriadpro" style={{ fontWeight: "600" }}>
                          Bold
                        </span>
                      </li>
                    </ul>

                    <h3 className="u-font-breuer">Breuer Text</h3>
                    <ul className="u-list-unstyled u-p0">
                      <li>
                        <span className="u-font-breuer" style={{ fontWeight: "500" }}>
                          Regular
                        </span>
                      </li>
                      <li>
                        <span className="u-font-breuer" style={{ fontWeight: "700" }}>
                          Bold
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Font sizes</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Font"}
                      name={"<p> Font"}
                      px={"16px"}
                      em={"1em"}
                      weight={"400"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"Mega Font"}
                      px={"48px"}
                      em={"3em"}
                      weight={"700"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h1> Font"}
                      px={"34px"}
                      em={"2.125em"}
                      weight={"500"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h2> Font"}
                      px={"28px"}
                      em={"1.75em"}
                      weight={"500"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h3> Font"}
                      px={"24px"}
                      em={"1.5em"}
                      weight={"500"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h4> Font"}
                      px={"22px"}
                      em={"1.375em"}
                      weight={"500"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h5> Font"}
                      px={"18px"}
                      em={"1.125em"}
                      weight={"500"}
                    />
                    <C_Swatch
                      type={"Font"}
                      name={"<h6> Font"}
                      px={"16px"}
                      em={"1em"}
                      weight={"500"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Margin</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<p> Margin"}
                      px={"24px"}
                      em={"1.5rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"Mega Margin"}
                      px={"9px"}
                      em={"0.5rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h1> Margin"}
                      px={"40px"}
                      em={"2.5rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h2> Margin"}
                      px={"24px"}
                      em={"1.5rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h3> Margin"}
                      px={"32px"}
                      em={"2rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h4> Margin"}
                      px={"29.26px [natural size]"}
                      em={"1.82875rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h5> Margin"}
                      px={"30.06px [natural size]"}
                      em={"1.87875rem"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Font Margin"}
                      name={"<h6> Margin"}
                      px={"37.28px [natural size]"}
                      em={"2.33rem"}
                      bgColor={"#D8D8D8"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Line height</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Line height"}
                      name={"<p> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.5 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"Mega Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.0 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h1> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.136 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h2> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.5 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h3> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.5 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h4> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.12 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h5> Spacing"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.1 height"}
                    />
                    <C_Swatch
                      type={"Line height"}
                      name={"<h6> Spacing>"}
                      bgColor={"#D8D8D8"}
                      extrattr={"1.1 height"}
                    />
                  </div>
                </div>
              </div>
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <p>
                      Body Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                      tempus lorem, quis scelerisque lorem congue eu. Mauris posuere, nulla eu
                      vestibulum dap velit massa finibus enim, in rutrum libero tortor at turpis.
                      Sed a mi at massa cursus pretium. In porta pellentesque orci. Sed mollis
                      lectus et pellentesque imperdiet. Etiam malesuada lacinia metus quis
                      sollicitudin. Maecenas sed mollis nunc. Aenean vulputate lacus augue, eget
                      tempus nisi placerat.
                    </p>

                    <ul>
                      <li>Body Bullet List</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Mauris posuere, nulla eu vestibulum dap velit massa finnibus enim</li>
                      <li>Sed a mi at massa cursus</li>
                    </ul>

                    <ol>
                      <li>Body Bullet List</li>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Mauris posuere, nulla eu vestibulum dap velit massa finnibus enim</li>
                      <li>Sed a mi at massa cursus</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content">
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Shapes</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Shape"}
                      name={"Square"}
                      px={"0px"}
                      em={"0em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Shape"}
                      name={"Corners 4"}
                      px={"4px"}
                      em={"0.25em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Shape"}
                      name={"Corners 8"}
                      px={"8px"}
                      em={"0.5em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Shape"}
                      name={"Corners 16"}
                      px={"16px"}
                      em={"1em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Shape"}
                      name={"Droplet"}
                      subtitle={"Corner"}
                      px={"8px"}
                      em={"0.5em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Shape"}
                      name={"Round/Pill"}
                      px={"+px"}
                      em={"+em"}
                      bgColor={"#D8D8D8"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content" style={{ background: "#F7F9FA" }}>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>States</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"State"}
                      name={"Border"}
                      px={"1px"}
                      em={"0.063em"}
                      color={"10% #000000"}
                    />
                    <C_Swatch
                      type={"State"}
                      name={"Shadow"}
                      color={"10% #000000"}
                      bgColor={"#FFFFFF"}
                      extrattr={"0x, 0y, 8px Blur"}
                    />
                    <C_Swatch
                      type={"State"}
                      name={"Dark Overlay"}
                      color={"8% #000000"}
                      bgColor={"rgba(0,0,0,8%"}
                    />
                    <C_Swatch
                      type={"State"}
                      name={"Primary CTA Hover"}
                      color={"#A60B16"}
                      bgColor={"#A60B16"}
                    />
                    <C_Swatch
                      type={"State"}
                      name={"Second CTA Hover"}
                      color={"#353535"}
                      bgColor={"#353535"}
                    />
                    <C_Swatch
                      type={"State"}
                      name={"Primary Alt. Hover"}
                      color={"#1B1B1B"}
                      bgColor={"#1B1B1B"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content">
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Margins</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Margin"}
                      name={"Desktop margins"}
                      px={"48px"}
                      em={"3em"}
                      bgColor={"#5EFFBA"}
                      direction="horizontal"
                    />
                    <C_Swatch
                      type={"Margin"}
                      name={"Desktop margins"}
                      px={"na?"}
                      em={"na?"}
                      bgColor={"#5EFFBA"}
                      direction="vertical"
                    />
                    <C_Swatch
                      type={"Margin"}
                      name={"Tablet margins"}
                      px={"48px"}
                      em={"3em"}
                      bgColor={"#5EFFBA"}
                      direction="horizontal"
                    />
                    <C_Swatch
                      type={"Margin"}
                      name={"Tablet margins"}
                      px={"24px"}
                      em={"1.5em"}
                      bgColor={"#5EFFBA"}
                      direction="vertical"
                    />
                    <C_Swatch
                      type={"Margin"}
                      name={"Mobile margins"}
                      subtitle={"Corner"}
                      px={"48px"}
                      em={"3em"}
                      bgColor={"#5EFFBA"}
                      direction="horizontal"
                    />
                    <C_Swatch
                      type={"Margin"}
                      name={"Mobile margins"}
                      px={"16px"}
                      em={"1em"}
                      bgColor={"#5EFFBA"}
                      direction="vertical"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content" style={{ background: "#F7F9FA" }}>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Gutters</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Gutter"}
                      name={"Desktop gutters 16"}
                      px={"16px"}
                      em={"1em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Gutter"}
                      name={"Desktop gutters 24"}
                      px={"24px"}
                      em={"1.5em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Gutter"}
                      name={"Tablet gutters 16"}
                      px={"16px"}
                      em={"1em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Gutter"}
                      name={"Tablet gutters 24"}
                      px={"24px"}
                      em={"1.5em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Gutter"}
                      name={"Mobile gutters 16"}
                      px={"16px"}
                      em={"1em"}
                      bgColor={"#D8D8D8"}
                    />
                    <C_Swatch
                      type={"Gutter"}
                      name={"Mobile gutters 24"}
                      px={"24px"}
                      em={"1.5em"}
                      bgColor={"#D8D8D8"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content" style={{ background: "#F7F9FA" }}>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Breakpoints</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Breakpoint"}
                      name={"Mobile"}
                      bgColor={"#AC5EFF"}
                      px={"320px - 512px"}
                      em={"20em - 32em"}
                    />
                    <C_Swatch
                      type={"Breakpoint"}
                      name={"Tablet"}
                      bgColor={"#AC5EFF"}
                      px={"512px - 800px"}
                      em={"32em - 50em"}
                    />
                    <C_Swatch
                      type={"Breakpoint"}
                      name={"Desktop"}
                      bgColor={"#AC5EFF"}
                      px={"800px - 1808px"}
                      em={"50em - 113em"}
                    />
                    <C_Swatch
                      type={"Breakpoint"}
                      name={"Ultra Wide"}
                      bgColor={"#AC5EFF"}
                      px={"1808px +"}
                      em={"113em +"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content">
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Buttons</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <h3>Primary</h3>
                    <button className="e-btn" style={{ margin: "0 0.5em 0 0" }}>
                      <span>Call to action</span>
                    </button>
                    <button className="e-btn" style={{ margin: "0 0 0 0.5em" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>

                    <br />
                    <br />

                    <h3>Primary (Alternate)</h3>
                    <button className="e-btn e-btn--alt1" style={{ margin: "0 0.5em 0 0" }}>
                      <span>Call to action</span>
                    </button>
                    <button className="e-btn e-btn--alt1" style={{ margin: "0 0 0 0.5em" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>

                    <br />
                    <br />

                    <h3>Secondary</h3>
                    <button className="e-btn e-btn--alt2" style={{ margin: "0 0.5em 0 0" }}>
                      <span>Call to action</span>
                    </button>
                    <button className="e-btn e-btn--alt2" style={{ margin: "0 0 0 0.5em" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>

                    <br />
                    <br />

                    <h3>Modifiers</h3>
                    <h4>Small</h4>
                    <button className="e-btn e-btn---" style={{ margin: "0 0 1em 0" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>
                    <h4>Large</h4>
                    <button className="e-btn e-btn--+" style={{ margin: "0 0 1em 0" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>
                    <h4>Full Width</h4>
                    <button className="e-btn e-btn--fullWidth" style={{ margin: "0 0 0 0" }}>
                      <svg style={{ height: "1em", width: "1em" }}>
                        <use xlinkHref="/images/icons-map.svg#placeholder" />
                      </svg>
                      <span>Call to action</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content" style={{ background: "#F7F9FA" }}>
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Icons</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<p> Size"}
                      width={"20px width"}
                      height={"20px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"Mega Size"}
                      width={"32px width"}
                      height={"32px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h1> Size"}
                      width={"32px width"}
                      height={"32px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h2> Size"}
                      width={"32px width"}
                      height={"32px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h3> Size"}
                      width={"32px width"}
                      height={"32px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h4> Size"}
                      width={"32px width"}
                      height={"32px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h5> Size"}
                      width={"24px width"}
                      height={"24px height"}
                    />
                    <C_Swatch
                      type={"Placeholder"}
                      name={"<h6> Size"}
                      width={"24px width"}
                      height={"24px height"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-content">
          <div className="l-inner">
            <div className="l-row">
              <div className="s-content__headline l-row__col l-1/1">
                <h2>Animations</h2>
              </div>
            </div>
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <div className="u-flex">
                  <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                    <C_Swatch
                      type={"Animation"}
                      name={"Transition speed (Slow)"}
                      classname={"c-swatch--slowAnimation"}
                      bgColor={"#A9FF5E"}
                      extrattr={"480ms"}
                    />
                    <C_Swatch
                      type={"Animation"}
                      name={"Transition speed (Normal)"}
                      classname={"c-swatch--normalAnimation"}
                      bgColor={"#A9FF5E"}
                      extrattr={"350ms"}
                    />
                    <C_Swatch
                      type={"Animation"}
                      name={"Transition speed (Fast)"}
                      classname={"c-swatch--fastAnimation"}
                      bgColor={"#A9FF5E"}
                      extrattr={"240ms"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default {
  path: "uiux",
  component: Pg_UiUxStyleguide,
  navtxt: "UI/UX Styleguide",
};
