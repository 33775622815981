export default {
  videoNum: {
    label: "Number of Videos",
    checkbox: null,
    field: ["1", "2", "3", "4", "6", "8"],
    selected: "6",
  },
  cta: {
    label: "Enable CTA",
    checkbox: true,
    field: null,
  },
  descriptionoption: {
    label: "Enable Description [Optional]",
    checkbox: true,
    field: null,
  },

  card1: [
    {
      name: "title",
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 1,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 1",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 1,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 1",
      checkbox: null,
      field: "1:15",
      block: 1,
    },
  ],
  card2: [
    {
      name: "title",
      label: "Title 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 2,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 2,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 2",
      checkbox: null,
      field: "2:15",
      block: 2,
    },
  ],
  card3: [
    {
      name: "title",
      label: "Title 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 3,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 3",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 3,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 3",
      checkbox: null,
      field: "3:15",
      block: 3,
    },
  ],
  card4: [
    {
      name: "title",
      label: "Title 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 4,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 4",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 4,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 4",
      checkbox: null,
      field: "4:15",
      block: 4,
    },
  ],
  card5: [
    {
      name: "title",
      label: "Title 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 5,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 5",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 5,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 5",
      checkbox: null,
      field: "5:15",
      block: 5,
    },
  ],
  card6: [
    {
      name: "title",
      label: "Title 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 6,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 6",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 6,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 6",
      checkbox: null,
      field: "6:15",
      block: 6,
    },
  ],
  card7: [
    {
      name: "title",
      label: "Title 7",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 7,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 7",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 7,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 7",
      checkbox: null,
      field: "7:15",
      block: 7,
    },
  ],
  card8: [
    {
      name: "title",
      label: "Title 8",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 8,
      charLimit: 25,
    },
    {
      name: "desc",
      label: "Description 8",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 8,
      charLimit: 110,
    },
    {
      name: "time",
      label: "Timestamp 8",
      checkbox: null,
      field: "8:15",
      block: 8,
    },
  ],
  currentLang: "Eng",
};
