/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SocialIntl = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let vars = {
      ...appState,
      currentPath: "/ui/social-international",
      currentTheme: null,
      outputName: "SocialInternational",
      headerName: "Social International Links",
      tags: null,
      related: null,
      description:
        "The Social International Links Section displays all of the social media platforms that are currently used in certain regions.",
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, and Large: Width: 26px and Height: 26px. 1:1 ratio.",
          ],
        ],
        ["Image Type", ["JPG"]],
        ["Title", ["String text within a div. No character limit."]],
        ["Link", ["String text within an anchor tag. No character limit."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EX6E_6S1TExFlJfy8Q6GziIBLqlMu8i1v28vPuz6SLoOkg?e=xCM9WN",
        comments: null,
      },
    };
    setAppState(vars);
    setContentOptions({
      title: {
        label: "Title",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Presentada",
          ChS: "精选",
          Gr: "Hervorgehoben",
          Ru: "Избранное",
          Fr: "Mis en exergue",
          It: "In primo piano",
          Pl: "Zobacz więcej",
          Port: "Apresentou",
          Viet: "Đặc sắc",
          Turk: "Öne Çıkanlar",
          Ukrn: "Рекомендовані",
          Thai: "แนะนำ",
          Kor: "추천",
          ChT: "精選",
          Jp: "特徴",
        },
      },
      link: {
        label: "Link",
        checkbox: null,
        field: {
          Eng: "Etiam sit",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем",
          Fr: "Il faisait",
          It: "Faceva caldo",
          Pl: "Było",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セム",
        },
      },
      link2: {
        label: "Link 2",
        checkbox: null,
        field: {
          Eng: "Quis varius",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter;",
          Ru: "Лорем ипсум",
          Fr: "Il faisait",
          It: "Faceva caldo",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết đó.",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погод",
          Thai: "อากาศช่วงนั้นร้",
          Kor: "국민경제의",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "1722",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Social International Links";
  return (
    <section className={"s-socialIntl " + appState.currentTheme}>
      <dl className="l-tabMenu l-tabMenu--mobileOnly">
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-facebook.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">
              {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
            </div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  {contentOptions.link && contentOptions.link.field[contentOptions.currentLang]}
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  {contentOptions.link2 && contentOptions.link2.field[contentOptions.currentLang]}
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-instagram.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">Instagram</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-linkedin.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">LinkedIn</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-twitter.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">Twitter</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-reddit.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">Reddit</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-vk.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">VK</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-youtube.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">YouTube</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-tiktok.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">TikTok</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
        <dt className="l-tabMenu__tab">
          <div className="l-inner">
            <img
              className="s-socialIntl__icon"
              src="/images/fpo/section-social/icon-twitch.svg"
              alt="Alternate Text"
            />
            <div className="s-socialIntl__name">Twitch</div>
          </div>
        </dt>
        <dd className="l-tabMenu__panel">
          <div className="l-inner">
            <ul className="s-socialIntl__list u-list-unstyled u-m0 u-p0 l-row__col--flow3">
              <li>
                <a className="u-txt-main u-txt-bold" href="#anchorLink" title="Anchor Title">
                  Corporate
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
              <li>
                <a className="u-txt-main" href="#anchorLink" title="Anchor Title">
                  Country
                </a>
              </li>
            </ul>
          </div>
        </dd>
      </dl>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "social-international",
  component: S_SocialIntl,
  navtxt: "Social International Links",
  htmlName: "SocialInternational",
  categoryType: ["misc"],
};
