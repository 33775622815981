/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SearchConfigurator = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let revVars = {
      ...appState,
      currentPath: "/ui/search-configurator",
      outputName: "SearchConfigurator",
      headerName: "Search Configurator",
      tags: null,
      currentTheme: "t-pearl",
      description:
        "The Search Configurator Section displays search cards that allow the user to search devices, systems, part numbers, and etc.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/formInput.component.js",
      ],
      specs: [
        [
          "Title",
          [
            "Default: Defaults to an <h4> and should only be text. No Character limit.",
            "Single: Defaults to an <h2> and should only be text. No Character limit.",
          ],
        ],
        [
          "Copy",
          [
            "Default: The copy appears as a paragraph beneath the title. No Character limit.",
            "Single: No copy",
          ],
        ],
        ["Label", ["String text within a label. No Character limit but recommend one line."]],
        ["CTA", ["String text within a CTA button."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ET2IBZY2QTlDmVhn8eXWgxcBO1gxRu8lf9dEienZ6zkifA?e=LEKc3i",
        comments: null,
      },
    };
    setAppState(revVars);
    setContentOptions({
      type: {
        label: "Type",
        checkbox: null,
        field: ["Default", "Single"],
        selected: "Default",
      },

      title1: {
        name: "title1",
        label: "Title 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud dans",
          It: "Faceva caldo con quel",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      copy1: {
        name: "copy1",
        label: "Copy 1",
        checkbox: null,
        field: {
          Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      label1: {
        label: "Label 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud dans",
          It: "Faceva caldo con quel",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      cta1: {
        name: "cta1",
        label: "CTA 1",
        checkbox: null,
        field: {
          Eng: "Lorem",
          Span: "Buscaro",
          ChS: "搜索",
          Gr: "Suchen",
          Ru: "Поиск",
          Fr: "Recherche",
          It: "Ricerca",
          Pl: "Szukaj",
          Port: "Procurar",
          Viet: "Tìm kiếm",
          Turk: "Aramak",
          Ukrn: "Пошук",
          Thai: "ค้นหา",
          Kor: "찾다",
          ChT: "搜索",
          Jp: "サーチ",
        },
      },
      title2: {
        name: "title2",
        label: "Title 2",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud dans",
          It: "Faceva caldo con quel",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      copy2: {
        name: "copy2",
        label: "Copy 2",
        checkbox: null,
        field: {
          Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      label2: {
        label: "Label 2",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud dans",
          It: "Faceva caldo con quel",
          Pl: "Było gorąco w taką pogodę",
          Port: "Fazia calor naquele tempo",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      cta2: {
        name: "cta2",
        label: "CTA 2",
        checkbox: null,
        field: {
          Eng: "Lorem",
          Span: "Buscaro",
          ChS: "搜索",
          Gr: "Suchen",
          Ru: "Поиск",
          Fr: "Recherche",
          It: "Ricerca",
          Pl: "Szukaj",
          Port: "Procurar",
          Viet: "Tìm kiếm",
          Turk: "Aramak",
          Ukrn: "Пошук",
          Thai: "ค้นหา",
          Kor: "찾다",
          ChT: "搜索",
          Jp: "サーチ",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "328",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Search Configurator Section";
  return (
    <section className={"s-searchConfigurator " + appState.currentTheme}>
      {contentOptions.type && contentOptions.type.selected == "Default" ? (
        <div className="s-searchConfigurator__body l-inner">
          <div className="l-grid l-grid--2col@lg">
            <div className="c-searchCard">
              <h4 className="c-searchCard__heading">
                {contentOptions.title1 && contentOptions.title1.field[contentOptions.currentLang]}
              </h4>
              <div className="c-searchCard__body">
                <p>
                  {contentOptions.copy1 && contentOptions.copy1.field[contentOptions.currentLang]}
                </p>
                <form className="c-searchCard__body__cta">
                  <div className="f-input">
                    <div className="f-input__icon">
                      <svg viewBox="0 0 17.2 16.7" aria-hidden="true">
                        <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z"></path>
                        <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1"></path>
                      </svg>
                    </div>
                    <div className="f-input__string">
                      <input
                        type="text"
                        name="card1"
                        id="card1"
                        required="required"
                        pattern="[a-zA-Z0-9]+"
                      />
                      <label htmlFor="card1">
                        {contentOptions.label1 &&
                          contentOptions.label1.field[contentOptions.currentLang]}
                      </label>
                    </div>
                  </div>
                  <a className="e-btn e-btn--+" href="#">
                    {contentOptions.cta1 && contentOptions.cta1.field[contentOptions.currentLang]}
                  </a>
                </form>
              </div>
            </div>
            <div className="c-searchCard">
              <h4 className="c-searchCard__heading">
                {contentOptions.title2 && contentOptions.title2.field[contentOptions.currentLang]}
              </h4>
              <div className="c-searchCard__body">
                <p>
                  {contentOptions.copy2 && contentOptions.copy2.field[contentOptions.currentLang]}
                </p>
                <form className="c-searchCard__body__cta">
                  <div className="f-input" data-id="fi-ipqdfn">
                    <div className="f-input__icon">
                      <svg viewBox="0 0 17.2 16.7" aria-hidden="true">
                        <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z"></path>
                        <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1"></path>
                      </svg>
                    </div>
                    <div className="f-input__string">
                      <input
                        type="text"
                        name="card2"
                        id="card2"
                        required="required"
                        pattern="[a-zA-Z0-9]+"
                      />
                      <label htmlFor="card2">
                        {contentOptions.label2 &&
                          contentOptions.label2.field[contentOptions.currentLang]}
                      </label>
                    </div>
                  </div>
                  <a className="e-btn e-btn--+" href="#">
                    {contentOptions.cta2 && contentOptions.cta2.field[contentOptions.currentLang]}
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="c-heading">
            <div className="c-heading__block">
              <div className="c-heading__block__header">
                <h2>
                  {contentOptions.title1 && contentOptions.title1.field[contentOptions.currentLang]}
                </h2>
              </div>
            </div>
          </div>
          <div className="l-inner">
            <div className="c-searchCard c-searchCard--singleBar">
              <form>
                <div className="f-input f-input--outlined" data-id="fi-8pcxo9">
                  <div className="f-input__icon">
                    <svg viewBox="0 0 17.2 16.7" aria-hidden="true">
                      <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z"></path>
                      <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1"></path>
                    </svg>
                  </div>
                  <div className="f-input__string">
                    <input
                      type="text"
                      name="card1"
                      id="card1"
                      required="required"
                      pattern="[a-zA-Z0-9]+"
                    />
                    <label htmlFor="card1">
                      {contentOptions.label1 &&
                        contentOptions.label1.field[contentOptions.currentLang]}
                    </label>
                  </div>
                  <div className="f-input__cta">
                    <button className="e-btn e-btn--+ u-hide-sm" href="#">
                      {contentOptions.cta1 && contentOptions.cta1.field[contentOptions.currentLang]}
                    </button>
                  </div>
                </div>
                <button className="e-btn e-btn--+ c-searchCard__mBtn u-show-sm" href="#">
                  {contentOptions.cta1 && contentOptions.cta1.field[contentOptions.currentLang]}
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "search-configurator",
  component: S_SearchConfigurator,
  navtxt: "Search Configurator",
  htmlName: "SearchConfigurator",
  categoryType: ["web"],
};
