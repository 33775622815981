import React, { useContext } from "react";
import { AppContext } from "../../../../AppContext";

const CIS = () => {
  const { appState } = useContext(AppContext);
  let cis = appState.cis;

  if (cis)
    return (
      <>
        {appState.cis.cmsDoc || appState.cis.additionalDocs ? (
          <div className="v-cis__googleDoc">
            <ul>
              {appState.cis.cmsDoc ? (
              <li>
                <a href={cis.cmsDoc} target="_blank" rel="noreferrer">
                  <svg viewBox="0 0 20 20">
                    <use xlinkHref="../images/icons-map.svg#blog-white" alt="blog white"></use>
                  </svg>
                  CMS and Document Specs
                </a>
              </li>
              ) : ""}
              {appState.cis.additionalDocs ? (
               <>
               {
                appState.cis.additionalDocs.map((docs, i) => (
                  <li key={i}>
                    <a href={docs[1]} target="_blank" rel="noreferrer">
                      <svg viewBox="0 0 20 20">
                        <use xlinkHref="../images/icons-map.svg#blog-white" alt="blog white"></use>
                      </svg>
                      {docs[0]}
                    </a>
                  </li>
                ))
               }
               </>
              ) : ""}
            </ul>
          </div>
        ) : (
          ""
        )}
        
        {appState.cis.comments ? (
          <article>
            <div className={`v-cis u-animated u-animated--delay500ms u-animated a-fadeIn`}>
              {appState.cis.comments.map((cis, i) => (
                <div className="v-cis__data" key={i}>
                  <div className="v-cis__data__key">{cis[0]}</div>
                  <div className="v-cis__data__content">
                    <div className={`l-row l-row--flush`}>
                      {cis[1].map((detail, index) => (
                        <div className={`l-row__col l-1/` + `${cis[1].length}`} key={index}>
                          <span>{detail}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </article>
        ) : (
          "No Comments"
        )}
      </>
    );
  else return "No CIS Information...";
};

export default CIS;
