export default {
  numFeatures: {
    label: "Num of items:",
    checkbox: null,
    field: ["1", "2", "3", "4", "5", "6", "7", "8"],
    selected: "4",
  },
  linkText1: {
    label: "Item 1 Link Text",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
  },

  linkText2a: {
    label: "Item 2 1st Link Text",
    checkbox: null,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  linkText2b: {
    label: "Item 2 Link 2 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
  },

  linkText3: {
    label: "Item 3 Link Text",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
  },

  linkText4: {
    label: "Item 4 Link Text",
    checkbox: null,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  currentLang: "Eng",
  // itemCount: 4,
};
