/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_CardScroller from "../../2_components/cardScroller/C_CardScroller";
import C_HorizontalCard from "../../2_components/horizontalCard/C_HorizontalCard";
import testimonialsOpts from "./testimonialsOpts";

const S_Testimonials = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let testVars = {
      ...appState,
      currentPath: "/ui/testimonials",
      outputName: "Testimonials",
      headerName: "Testimonials",
      tags: null,
      currentTheme: "t-white",
      description:
        "The Testimonials Section displays a collection of testimonial cards, which contains a brief copy, title, subtitle, and tagline. *Currently arrows are only working on Live Site version - not Editable version.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/cardScroller.component.js",
      ],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*1 sizes required",
            "Small, Medium, and Large: Width: 240px and Height: 240px",
            "or 1:1 ratio that is less than 240px",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation"]],
        ["Copy", ["String text within <p> tag. Character Limit: 150"]],
        ["Title", ["String text generated as a div tag. No character limit but max 2 lines."]],
        ["Subtitle", ["String text generated as a div tag. No character limit but max 2 lines."]],
        ["Tagline", ["String text generated as a div tag. No character limit but max 2 lines."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EXMO9k-Lef9PkFwkYKu4RLgBXHUxKFYUSm1gdmOf5QEITA?e=heOSO6",
        comments: [["Testimonial Image Sizes", ["240px x240px"]]],
      },
    };
    setAppState(testVars);

    setContentOptions(testimonialsOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Testimonials";
  return (
    <section className={`s-cardScroller ` + appState.currentTheme}>
      {contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "1" ? (
        <div className="c-cardScroller c-cardScroller--center">
          <button
            className="e-navigationArrow  c-cardScroller__prevBtn"
            aria-label="Previous Slide"
            aria-hidden="true"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M20.235 7.298a1 1 0 00-1.41 0L12 13.875 5.255 7.344a1 1 0 10-1.4 1.42L12 16.574l8.215-7.866a1 1 0 00.02-1.41z"
                fillRule="nonzero"
              />
            </svg>
          </button>

          <div className="c-cardScroller__container">
            <div className="c-cardScroller__container__layout">
              <ul className="c-cardScroller__container__layout__items">
                <li className="c-cardScroller__container__layout__items__itemView">
                  <div className="c-cardScroller__container__layout__items__itemView__item">
                    <div className="c-horizontalCard c-horizontalCard--testimonial ">
                      <div className="c-horizontalCard__image">
                        <img
                          src="/images/fpo/general/240x240.png"
                          alt="Alternate Text"
                          data-dropdown="true"
                          className={`${
                            contentOptions.styleTestimonials &&
                            contentOptions.styleTestimonials.selected == "Rounded"
                              ? "u-img-rounded"
                              : contentOptions.styleTestimonials &&
                                  contentOptions.styleTestimonials.selected == "Circle"
                                ? "u-img-circle"
                                : contentOptions.styleTestimonials &&
                                    contentOptions.styleTestimonials.selected == "Droplet BL"
                                  ? "u-img-dropletBL"
                                  : ""
                          } ${
                            contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                              ? ""
                              : "u-border-none"
                          }`}
                        />
                      </div>
                      <div className="c-horizontalCard__details">
                        <p className="c-horizontalCard__details__copy">
                          {contentOptions.card1 &&
                            contentOptions.card1[1].field[contentOptions.currentLang]}
                        </p>
                        {contentOptions.testimonialNames &&
                        contentOptions.testimonialNames.checkbox ? (
                          <div className="c-horizontalCard__details__name">
                            {contentOptions.card1 &&
                              contentOptions.card1[0].field[contentOptions.currentLang]}
                          </div>
                        ) : (
                          ""
                        )}
                        {contentOptions.testimonialSubtitles &&
                        contentOptions.testimonialSubtitles.checkbox ? (
                          <div className="c-horizontalCard__details__subtitle">
                            {contentOptions.card1 &&
                              contentOptions.card1[2].field[contentOptions.currentLang]}
                          </div>
                        ) : (
                          ""
                        )}
                        {contentOptions.testimonialTaglines &&
                        contentOptions.testimonialTaglines.checkbox ? (
                          <div className="c-horizontalCard__details__tagline">
                            {contentOptions.card1 &&
                              contentOptions.card1[3].field[contentOptions.currentLang]}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : contentOptions.numTestimonials && contentOptions.numTestimonials.selected >= "2" ? (
        <C_CardScroller>
          <C_HorizontalCard
            type="testimonial"
            image="/images/fpo/general/240x240.png"
            imageClass={`${
              contentOptions.styleTestimonials &&
              contentOptions.styleTestimonials.selected == "Rounded"
                ? "u-img-rounded"
                : contentOptions.styleTestimonials &&
                    contentOptions.styleTestimonials.selected == "Circle"
                  ? "u-img-circle"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Droplet BL"
                    ? "u-img-dropletBL"
                    : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            name={
              contentOptions.testimonialNames &&
              contentOptions.testimonialNames.checkbox &&
              contentOptions.card1
                ? contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            copy={contentOptions.card1 && contentOptions.card1[1].field[contentOptions.currentLang]}
            subtitle={
              contentOptions.testimonialSubtitles &&
              contentOptions.testimonialSubtitles.checkbox &&
              contentOptions.card1
                ? contentOptions.card1[2].field[contentOptions.currentLang]
                : ""
            }
            tagline={
              contentOptions.testimonialTaglines &&
              contentOptions.testimonialTaglines.checkbox &&
              contentOptions.card1
                ? contentOptions.card1[3].field[contentOptions.currentLang]
                : ""
            }
          />
          {(contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "2") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "3") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "4") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "5") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "6") ? (
            <C_HorizontalCard
              type="testimonial"
              image="/images/fpo/general/240x240.png"
              imageClass={`${
                contentOptions.styleTestimonials &&
                contentOptions.styleTestimonials.selected == "Rounded"
                  ? "u-img-rounded"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Circle"
                    ? "u-img-circle"
                    : contentOptions.styleTestimonials &&
                        contentOptions.styleTestimonials.selected == "Droplet BL"
                      ? "u-img-dropletBL"
                      : ""
              } ${
                contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                  ? ""
                  : "u-border-none"
              }`}
              name={
                contentOptions.testimonialNames &&
                contentOptions.testimonialNames.checkbox &&
                contentOptions.card2
                  ? contentOptions.card2[0].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.card2 && contentOptions.card2[1].field[contentOptions.currentLang]
              }
              subtitle={
                contentOptions.testimonialSubtitles &&
                contentOptions.testimonialSubtitles.checkbox &&
                contentOptions.card2
                  ? contentOptions.card2[2].field[contentOptions.currentLang]
                  : ""
              }
              tagline={
                contentOptions.testimonialTaglines &&
                contentOptions.testimonialTaglines.checkbox &&
                contentOptions.card2
                  ? contentOptions.card2[3].field[contentOptions.currentLang]
                  : ""
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "3") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "4") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "5") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "6") ? (
            <C_HorizontalCard
              type="testimonial"
              image="/images/fpo/general/240x240.png"
              imageClass={`${
                contentOptions.styleTestimonials &&
                contentOptions.styleTestimonials.selected == "Rounded"
                  ? "u-img-rounded"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Circle"
                    ? "u-img-circle"
                    : contentOptions.styleTestimonials &&
                        contentOptions.styleTestimonials.selected == "Droplet BL"
                      ? "u-img-dropletBL"
                      : ""
              } ${
                contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                  ? ""
                  : "u-border-none"
              }`}
              name={
                contentOptions.testimonialNames &&
                contentOptions.testimonialNames.checkbox &&
                contentOptions.card3
                  ? contentOptions.card3[0].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.card3 && contentOptions.card3[1].field[contentOptions.currentLang]
              }
              subtitle={
                contentOptions.testimonialSubtitles &&
                contentOptions.testimonialSubtitles.checkbox &&
                contentOptions.card3
                  ? contentOptions.card3[2].field[contentOptions.currentLang]
                  : ""
              }
              tagline={
                contentOptions.testimonialTaglines &&
                contentOptions.testimonialTaglines.checkbox &&
                contentOptions.card3
                  ? contentOptions.card3[3].field[contentOptions.currentLang]
                  : ""
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "4") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "5") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "6") ? (
            <C_HorizontalCard
              type="testimonial"
              image="/images/fpo/general/240x240.png"
              imageClass={`${
                contentOptions.styleTestimonials &&
                contentOptions.styleTestimonials.selected == "Rounded"
                  ? "u-img-rounded"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Circle"
                    ? "u-img-circle"
                    : contentOptions.styleTestimonials &&
                        contentOptions.styleTestimonials.selected == "Droplet BL"
                      ? "u-img-dropletBL"
                      : ""
              } ${
                contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                  ? ""
                  : "u-border-none"
              }`}
              name={
                contentOptions.testimonialNames &&
                contentOptions.testimonialNames.checkbox &&
                contentOptions.card4
                  ? contentOptions.card4[0].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.card4 && contentOptions.card4[1].field[contentOptions.currentLang]
              }
              subtitle={
                contentOptions.testimonialSubtitles &&
                contentOptions.testimonialSubtitles.checkbox &&
                contentOptions.card4
                  ? contentOptions.card4[2].field[contentOptions.currentLang]
                  : ""
              }
              tagline={
                contentOptions.testimonialTaglines &&
                contentOptions.testimonialTaglines.checkbox &&
                contentOptions.card4
                  ? contentOptions.card4[3].field[contentOptions.currentLang]
                  : ""
              }
            />
          ) : (
            ""
          )}
          {(contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "5") ||
          (contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "6") ? (
            <C_HorizontalCard
              type="testimonial"
              image="/images/fpo/general/240x240.png"
              imageClass={`${
                contentOptions.styleTestimonials &&
                contentOptions.styleTestimonials.selected == "Rounded"
                  ? "u-img-rounded"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Circle"
                    ? "u-img-circle"
                    : contentOptions.styleTestimonials &&
                        contentOptions.styleTestimonials.selected == "Droplet BL"
                      ? "u-img-dropletBL"
                      : ""
              } ${
                contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                  ? ""
                  : "u-border-none"
              }`}
              name={
                contentOptions.testimonialNames &&
                contentOptions.testimonialNames.checkbox &&
                contentOptions.card5
                  ? contentOptions.card5[0].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.card5 && contentOptions.card5[1].field[contentOptions.currentLang]
              }
              subtitle={
                contentOptions.testimonialSubtitles &&
                contentOptions.testimonialSubtitles.checkbox &&
                contentOptions.card5
                  ? contentOptions.card5[2].field[contentOptions.currentLang]
                  : ""
              }
              tagline={
                contentOptions.testimonialTaglines &&
                contentOptions.testimonialTaglines.checkbox &&
                contentOptions.card5
                  ? contentOptions.card5[3].field[contentOptions.currentLang]
                  : ""
              }
            />
          ) : (
            ""
          )}
          {contentOptions.numTestimonials && contentOptions.numTestimonials.selected == "6" ? (
            <C_HorizontalCard
              type="testimonial"
              image="/images/fpo/general/240x240.png"
              imageClass={`${
                contentOptions.styleTestimonials &&
                contentOptions.styleTestimonials.selected == "Rounded"
                  ? "u-img-rounded"
                  : contentOptions.styleTestimonials &&
                      contentOptions.styleTestimonials.selected == "Circle"
                    ? "u-img-circle"
                    : contentOptions.styleTestimonials &&
                        contentOptions.styleTestimonials.selected == "Droplet BL"
                      ? "u-img-dropletBL"
                      : ""
              } ${
                contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                  ? ""
                  : "u-border-none"
              }`}
              name={
                contentOptions.testimonialNames &&
                contentOptions.testimonialNames.checkbox &&
                contentOptions.card6
                  ? contentOptions.card6[0].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.card6 && contentOptions.card6[1].field[contentOptions.currentLang]
              }
              subtitle={
                contentOptions.testimonialSubtitles &&
                contentOptions.testimonialSubtitles.checkbox &&
                contentOptions.card6
                  ? contentOptions.card6[2].field[contentOptions.currentLang]
                  : ""
              }
              tagline={
                contentOptions.testimonialTaglines &&
                contentOptions.testimonialTaglines.checkbox &&
                contentOptions.card6
                  ? contentOptions.card6[3].field[contentOptions.currentLang]
                  : ""
              }
            />
          ) : (
            ""
          )}
        </C_CardScroller>
      ) : (
        ""
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "testimonials",
  component: S_Testimonials,
  navtxt: "Testimonials",
  htmlName: "Testimonials",
  icon: "testimonials",
  categoryType: ["web"],
};
