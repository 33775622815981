export default {
  opts: {
    headline1: { label: "Headline 1", checkbox: null, field: "Lorem Ipsum", charLimit: 35 },
    desc1: {
      label: "Description 1",
      checkbox: true,
      field: "Duis mattis mattis aliquet.",
      charLimit: 100,
    },
    CTAP1: { label: "CTA Panel 1", checkbox: true, field: "Learn More", charLimit: 28 },
    SVG1: { label: "CTA Icon SVG", checkbox: true, field: null },
    headline2: { label: "Headline 2", checkbox: null, field: "Lorem Ipsum" },
    headline3: { label: "Headline 3", checkbox: null, field: "Donec sit amet turpis" },
    headline4: { label: "Headline 4", checkbox: null, field: "Duis mattis mattis" },
    headline5: { label: "Headline 5", checkbox: null, field: "Lorem Ipsum" },

    desc2: {
      label: "Description 2",
      checkbox: null,
      field:
        "Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempus id egettellus. Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempusid eget tellus.",
      charLimit: 100,
    },
    desc3: {
      label: "Description 3",
      checkbox: null,
      field:
        "Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempus id egettellus.",
      charLimit: 100,
    },
    desc4: {
      label: "Description 4",
      checkbox: null,
      field:
        "Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempus id egettellus.",
      charLimit: 100,
    },
    desc5: {
      label: "Description 5",
      checkbox: null,
      field:
        "Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempus id egettellus. Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempusid eget tellus.",
      charLimit: 100,
    },

    aligned: {
      label: "Clip Alignment (default - left)",
      checkbox: null,
      field: ["left", "right"],
      selected: "left",
    },
  },
};
