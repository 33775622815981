/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import L_FlexTiles from "../../0_layout/L_Flextiles";
import C_ProductCardLite from "../../2_components/productCardLite/C_ProductCardLite";
import shortcutOpts from "./shortcutOpts";

//can't use number of items dd bc of flextiles using children
const S_ShortcutNav = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let slVars = {
      ...appState,
      currentPath: "/ui/shortcut-nav",
      currentTheme: "t-midnight",
      outputName: "ShortcutNav",
      headerName: "Shortcut Navigation",
      tags: ["Landing Page Approved"],
      description:
        "The Shortcut Navigation section provides quick links to product pages, with a SVG graphic to represent each category.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/flexTiles.layout.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, and Large: Any size with a max width of 200px and a max height of 80px.",
          ],
        ],
        ["Image Type", ["SVG"]],
        [
          "Link",
          [
            "String text within an anchor tag. Each link label should max at 25 characters before breaking into 2 lines.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Eewq3rV-3M9Po1J7LQRXjEoBA88kg3_W8ZAJpZafI_a05A?e=qnYtdj",
        comments: null,
      },
    };
    setAppState(slVars);

    setContentOptions(shortcutOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "220",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Shortcut Nav";
  return (
    <section className={"s-shortcutNavigation " + appState.currentTheme}>
      <div className="u-pr u-pl">
        <L_FlexTiles minWidth="150" maxWidth="225">
          <C_ProductCardLite
            img="https://media.kingston.com/kingston/navigation/ktc-navigation-product-usb.svg"
            products={[
              {
                name: `${
                  contentOptions.linkText1 &&
                  contentOptions.linkText1.field[contentOptions.currentLang]
                }`,
              },
            ]}
          />
          <C_ProductCardLite
            img="https://media.kingston.com/kingston/navigation/ktc-navigation-product-ssd.svg"
            products={
              contentOptions.linkText2b && contentOptions.linkText2b.checkbox
                ? [
                    {
                      name: `${
                        contentOptions.linkText2a &&
                        contentOptions.linkText2a.field[contentOptions.currentLang]
                      }`,
                    },
                    {
                      name: `${
                        contentOptions.linkText2b &&
                        contentOptions.linkText2b.field[contentOptions.currentLang]
                      }`,
                    },
                  ]
                : [
                    {
                      name: `${
                        contentOptions.linkText2a &&
                        contentOptions.linkText2a.field[contentOptions.currentLang]
                      }`,
                    },
                  ]
            }
          />
          <C_ProductCardLite
            img="https://media.kingston.com/kingston/navigation/ktc-navigation-product-encrypted-external-ssd.svg"
            products={[
              {
                name: `${
                  contentOptions.linkText3 &&
                  contentOptions.linkText3.field[contentOptions.currentLang]
                }`,
              },
            ]}
          />
          <C_ProductCardLite
            // key={i}
            img="https://media.kingston.com/kingston/navigation/ktc-navigation-product-desktop-notebook-memory.svg"
            products={[
              {
                name: `${
                  contentOptions.linkText4 &&
                  contentOptions.linkText4.field[contentOptions.currentLang]
                }`,
              },
            ]}
          />
        </L_FlexTiles>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "shortcut-nav",
  component: S_ShortcutNav,
  navtxt: "Shortcut Navigation",
  htmlName: "ShortcutNav",
  categoryType: ["landing page", "web"],
};
