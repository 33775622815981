export const footerOpts = {
  socials: [
    {
      label: "Social #1",
      checkbox: true,
      field: "#logo-facebook_grey",
    },
    {
      label: "Social #2",
      checkbox: true,
      field: "#logo-twitter_grey",
    },
    {
      label: "Social #3",
      checkbox: true,
      field: "#linkedin",
    },
    {
      label: "Social #4",
      checkbox: true,
      field: "#logo-youtube_grey",
    },
    {
      label: "Social #5",
      checkbox: true,
      field: "#logo-instagram_grey",
    },
    {
      label: "Social #6",
      checkbox: true,
      field: "#social-tiktok",
    },
  ],
  heading1: {
    label: "Heading 1",
    checkbox: true,
    field: {
      Eng: "Heading 1",
      Span: "Título 1",
      ChS: "标题 1",
      Gr: "Überschrift 1",
      Ru: "Заголовок 1",
      Fr: "Titre 1",
      It: "Titolo 1",
      Pl: "Tytuł 1",
      Port: "Título 1",
      Viet: "Cú đội đầu 1",
      Turk: "Başlık 1",
      Ukrn: "Заголовок 1",
      Thai: "หัวเรื่อง 1",
      Kor: "표제 1",
      ChT: "標題 1",
      Jp: "見出し 1",
    },
  },
  link1: {
    label: "Link 1",
    checkbox: true,
    field: {
      Eng: "Item Link 1",
      Span: "Artículo Enlace 1",
      ChS: "项目链接1",
      Gr: "Artikellink 1",
      Ru: "Ссылка на товар 1",
      Fr: "Lien d'article 1",
      It: "Collegamento articolo 1",
      Pl: "Link do elementu 1",
      Port: "Link do item 1",
      Viet: "Liên kết mục 1",
      Turk: "Öğe Bağlantısı 1",
      Ukrn: "Посилання на предмет 1",
      Thai: "รายการลิงค์ 1",
      Kor: "항목 링크 1",
      ChT: "項目鏈接1",
      Jp: "アイテムリンク1",
    },
  },
  link2: {
    label: "Link 2",
    checkbox: true,
    field: {
      Eng: "Item Link 2",
      Span: "Artículo Enlace 2",
      ChS: "项目链接2",
      Gr: "Artikellink 2",
      Ru: "Ссылка на товар 2",
      Fr: "Lien d'article 2",
      It: "Collegamento articolo 2",
      Pl: "Link do elementu 2",
      Port: "Link do item 2",
      Viet: "Liên kết mục 2",
      Turk: "Öğe Bağlantısı 2",
      Ukrn: "Посилання на предмет 2",
      Thai: "รายการลิงค์ 2",
      Kor: "항목 링크 2",
      ChT: "項目鏈接2",
      Jp: "アイテムリンク2",
    },
  },
  link3: {
    label: "Link 3",
    checkbox: true,
    field: {
      Eng: "Item Link 3",
      Span: "Artículo Enlace 3",
      ChS: "项目链接3",
      Gr: "Artikellink 3",
      Ru: "Ссылка на товар 3",
      Fr: "Lien d'article 3",
      It: "Collegamento articolo 3",
      Pl: "Link do elementu 3",
      Port: "Link do item 3",
      Viet: "Liên kết mục 3",
      Turk: "Öğe Bağlantısı 3",
      Ukrn: "Посилання на предмет 3",
      Thai: "รายการลิงค์ 3",
      Kor: "항목 링크 3",
      ChT: "項目鏈接3",
      Jp: "アイテムリンク3",
    },
  },
  link4: {
    label: "Link 4",
    checkbox: true,
    field: {
      Eng: "Item Link 4",
      Span: "Artículo Enlace 4",
      ChS: "项目链接4",
      Gr: "Artikellink 4",
      Ru: "Ссылка на товар 4",
      Fr: "Lien d'article 4",
      It: "Collegamento articolo 4",
      Pl: "Link do elementu 4",
      Port: "Link do item 4",
      Viet: "Liên kết mục 4",
      Turk: "Öğe Bağlantısı 4",
      Ukrn: "Посилання на предмет 4",
      Thai: "รายการลิงค์ 4",
      Kor: "항목 링크 4",
      ChT: "項目鏈接4",
      Jp: "アイテムリンク4",
    },
  },
  link5: {
    label: "Link 5",
    checkbox: true,
    field: {
      Eng: "Item Link 5",
      Span: "Artículo Enlace 5",
      ChS: "项目链接5",
      Gr: "Artikellink 5",
      Ru: "Ссылка на товар 5",
      Fr: "Lien d'article 5",
      It: "Collegamento articolo 5",
      Pl: "Link do elementu 5",
      Port: "Link do item 5",
      Viet: "Liên kết mục 5",
      Turk: "Öğe Bağlantısı 5",
      Ukrn: "Посилання на предмет 5",
      Thai: "รายการลิงค์ 5",
      Kor: "항목 링크 5",
      ChT: "項目鏈接5",
      Jp: "アイテムリンク5",
    },
  },
  link6: {
    label: "Link 6",
    checkbox: true,
    field: {
      Eng: "Item Link 6",
      Span: "Artículo Enlace 6",
      ChS: "项目链接6",
      Gr: "Artikellink 6",
      Ru: "Ссылка на товар 6",
      Fr: "Lien d'article 6",
      It: "Collegamento articolo 6",
      Pl: "Link do elementu 6",
      Port: "Link do item 6",
      Viet: "Liên kết mục 6",
      Turk: "Öğe Bağlantısı 6",
      Ukrn: "Посилання на предмет 6",
      Thai: "รายการลิงค์ 6",
      Kor: "항목 링크 6",
      ChT: "項目鏈接6",
      Jp: "アイテムリンク6",
    },
  },
  heading2: {
    label: "Heading 2",
    checkbox: true,
    field: {
      Eng: "Heading 2",
      Span: "Título 2",
      ChS: "标题 2",
      Gr: "Überschrift 2",
      Ru: "Заголовок 2",
      Fr: "Titre 2",
      It: "Titolo 2",
      Pl: "Tytuł 2",
      Port: "Título 2",
      Viet: "Cú đội đầu 2",
      Turk: "Başlık 2",
      Ukrn: "Заголовок 2",
      Thai: "หัวเรื่อง 2",
      Kor: "표제 2",
      ChT: "標題 2",
      Jp: "見出し 2",
    },
  },
  link7: {
    label: "Link 7",
    checkbox: true,
    field: {
      Eng: "Item Link 7",
      Span: "Artículo Enlace 7",
      ChS: "项目链接7",
      Gr: "Artikellink 7",
      Ru: "Ссылка на товар 7",
      Fr: "Lien d'article 7",
      It: "Collegamento articolo 7",
      Pl: "Link do elementu 7",
      Port: "Link do item 7",
      Viet: "Liên kết mục 7",
      Turk: "Öğe Bağlantısı 7",
      Ukrn: "Посилання на предмет 7",
      Thai: "รายการลิงค์ 7",
      Kor: "항목 링크 7",
      ChT: "項目鏈接7",
      Jp: "アイテムリンク7",
    },
  },
  link8: {
    label: "Link 8",
    checkbox: true,
    field: {
      Eng: "Item Link 8",
      Span: "Artículo Enlace 8",
      ChS: "项目链接8",
      Gr: "Artikellink 8",
      Ru: "Ссылка на товар 8",
      Fr: "Lien d'article 8",
      It: "Collegamento articolo 8",
      Pl: "Link do elementu 8",
      Port: "Link do item 8",
      Viet: "Liên kết mục 8",
      Turk: "Öğe Bağlantısı 8",
      Ukrn: "Посилання на предмет 8",
      Thai: "รายการลิงค์ 8",
      Kor: "항목 링크 8",
      ChT: "項目鏈接8",
      Jp: "アイテムリンク8",
    },
  },
  link9: {
    label: "Link 9",
    checkbox: true,
    field: {
      Eng: "Item Link 9",
      Span: "Artículo Enlace 9",
      ChS: "项目链接9",
      Gr: "Artikellink 9",
      Ru: "Ссылка на товар 9",
      Fr: "Lien d'article 9",
      It: "Collegamento articolo 9",
      Pl: "Link do elementu 9",
      Port: "Link do item 9",
      Viet: "Liên kết mục 9",
      Turk: "Öğe Bağlantısı 9",
      Ukrn: "Посилання на предмет 9",
      Thai: "รายการลิงค์ 9",
      Kor: "항목 링크 9",
      ChT: "項目鏈接9",
      Jp: "アイテムリンク9",
    },
  },
  link10: {
    label: "Link 10",
    checkbox: true,
    field: {
      Eng: "Item Link 10",
      Span: "Artículo Enlace 10",
      ChS: "项目链接10",
      Gr: "Artikellink 10",
      Ru: "Ссылка на товар 10",
      Fr: "Lien d'article 10",
      It: "Collegamento articolo 10",
      Pl: "Link do elementu 10",
      Port: "Link do item 10",
      Viet: "Liên kết mục 10",
      Turk: "Öğe Bağlantısı 10",
      Ukrn: "Посилання на предмет 10",
      Thai: "รายการลิงค์ 10",
      Kor: "항목 링크 10",
      ChT: "項目鏈接10",
      Jp: "アイテムリンク10",
    },
  },
  link11: {
    label: "Link 11",
    checkbox: true,
    field: {
      Eng: "Item Link 11",
      Span: "Artículo Enlace 11",
      ChS: "项目链接11",
      Gr: "Artikellink 11",
      Ru: "Ссылка на товар 11",
      Fr: "Lien d'article 11",
      It: "Collegamento articolo 11",
      Pl: "Link do elementu 11",
      Port: "Link do item 11",
      Viet: "Liên kết mục 11",
      Turk: "Öğe Bağlantısı 11",
      Ukrn: "Посилання на предмет 11",
      Thai: "รายการลิงค์ 11",
      Kor: "항목 링크 11",
      ChT: "項目鏈接11",
      Jp: "アイテムリンク11",
    },
  },
  link12: {
    label: "Link 12",
    checkbox: true,
    field: {
      Eng: "Item Link 12",
      Span: "Artículo Enlace 12",
      ChS: "项目链接12",
      Gr: "Artikellink 12",
      Ru: "Ссылка на товар 12",
      Fr: "Lien d'article 12",
      It: "Collegamento articolo 12",
      Pl: "Link do elementu 12",
      Port: "Link do item 12",
      Viet: "Liên kết mục 12",
      Turk: "Öğe Bağlantısı 12",
      Ukrn: "Посилання на предмет 12",
      Thai: "รายการลิงค์ 12",
      Kor: "항목 링크 12",
      ChT: "項目鏈接12",
      Jp: "アイテムリンク12",
    },
  },
  heading3: {
    label: "Heading 3",
    checkbox: true,
    field: {
      Eng: "Heading 3",
      Span: "Título 3",
      ChS: "标题 3",
      Gr: "Überschrift 3",
      Ru: "Заголовок 3",
      Fr: "Titre 3",
      It: "Titolo 3",
      Pl: "Tytuł 3",
      Port: "Título 3",
      Viet: "Cú đội đầu 3",
      Turk: "Başlık 3",
      Ukrn: "Заголовок 3",
      Thai: "หัวเรื่อง 3",
      Kor: "표제 3",
      ChT: "標題 3",
      Jp: "見出し 3",
    },
  },
  link13: {
    label: "Link 13",
    checkbox: true,
    field: {
      Eng: "Item Link 13",
      Span: "Artículo Enlace 13",
      ChS: "项目链接13",
      Gr: "Artikellink 13",
      Ru: "Ссылка на товар 13",
      Fr: "Lien d'article 13",
      It: "Collegamento articolo 13",
      Pl: "Link do elementu 13",
      Port: "Link do item 13",
      Viet: "Liên kết mục 13",
      Turk: "Öğe Bağlantısı 13",
      Ukrn: "Посилання на предмет 13",
      Thai: "รายการลิงค์ 13",
      Kor: "항목 링크 13",
      ChT: "項目鏈接13",
      Jp: "アイテムリンク13",
    },
  },
  link14: {
    label: "Link 14",
    checkbox: true,
    field: {
      Eng: "Item Link 14",
      Span: "Artículo Enlace 14",
      ChS: "项目链接14",
      Gr: "Artikellink 14",
      Ru: "Ссылка на товар 14",
      Fr: "Lien d'article 14",
      It: "Collegamento articolo 14",
      Pl: "Link do elementu 14",
      Port: "Link do item 14",
      Viet: "Liên kết mục 14",
      Turk: "Öğe Bağlantısı 14",
      Ukrn: "Посилання на предмет 14",
      Thai: "รายการลิงค์ 14",
      Kor: "항목 링크 14",
      ChT: "項目鏈接14",
      Jp: "アイテムリンク14",
    },
  },
  link15: {
    label: "Link 15",
    checkbox: true,
    field: {
      Eng: "Item Link 15",
      Span: "Artículo Enlace 15",
      ChS: "项目链接15",
      Gr: "Artikellink 15",
      Ru: "Ссылка на товар 15",
      Fr: "Lien d'article 15",
      It: "Collegamento articolo 15",
      Pl: "Link do elementu 15",
      Port: "Link do item 15",
      Viet: "Liên kết mục 15",
      Turk: "Öğe Bağlantısı 15",
      Ukrn: "Посилання на предмет 15",
      Thai: "รายการลิงค์ 15",
      Kor: "항목 링크 15",
      ChT: "項目鏈接15",
      Jp: "アイテムリンク15",
    },
  },
  link16: {
    label: "Link 16",
    checkbox: true,
    field: {
      Eng: "Item Link 16",
      Span: "Artículo Enlace 16",
      ChS: "项目链接16",
      Gr: "Artikellink 16",
      Ru: "Ссылка на товар 16",
      Fr: "Lien d'article 16",
      It: "Collegamento articolo 16",
      Pl: "Link do elementu 16",
      Port: "Link do item 16",
      Viet: "Liên kết mục 16",
      Turk: "Öğe Bağlantısı 16",
      Ukrn: "Посилання на предмет 16",
      Thai: "รายการลิงค์ 16",
      Kor: "항목 링크 16",
      ChT: "項目鏈接16",
      Jp: "アイテムリンク16",
    },
  },
  link17: {
    label: "Link 17",
    checkbox: true,
    field: {
      Eng: "Item Link 17",
      Span: "Artículo Enlace 17",
      ChS: "项目链接17",
      Gr: "Artikellink 17",
      Ru: "Ссылка на товар 17",
      Fr: "Lien d'article 17",
      It: "Collegamento articolo 17",
      Pl: "Link do elementu 17",
      Port: "Link do item 17",
      Viet: "Liên kết mục 17",
      Turk: "Öğe Bağlantısı 17",
      Ukrn: "Посилання на предмет 17",
      Thai: "รายการลิงค์ 17",
      Kor: "항목 링크 17",
      ChT: "項目鏈接17",
      Jp: "アイテムリンク17",
    },
  },
  link18: {
    label: "Link 18",
    checkbox: true,
    field: {
      Eng: "Item Link 18",
      Span: "Artículo Enlace 18",
      ChS: "项目链接18",
      Gr: "Artikellink 18",
      Ru: "Ссылка на товар 18",
      Fr: "Lien d'article 18",
      It: "Collegamento articolo 18",
      Pl: "Link do elementu 18",
      Port: "Link do item 18",
      Viet: "Liên kết mục 18",
      Turk: "Öğe Bağlantısı 18",
      Ukrn: "Посилання на предмет 18",
      Thai: "รายการลิงค์ 18",
      Kor: "항목 링크 18",
      ChT: "項目鏈接18",
      Jp: "アイテムリンク18",
    },
  },
  heading4: {
    label: "Heading 4",
    checkbox: true,
    field: {
      Eng: "Heading 4",
      Span: "Título 4",
      ChS: "标题 4",
      Gr: "Überschrift 4",
      Ru: "Заголовок 4",
      Fr: "Titre 4",
      It: "Titolo 4",
      Pl: "Tytuł 4",
      Port: "Título 4",
      Viet: "Cú đội đầu 4",
      Turk: "Başlık 4",
      Ukrn: "Заголовок 4",
      Thai: "หัวเรื่อง 4",
      Kor: "표제 4",
      ChT: "標題 4",
      Jp: "見出し 4",
    },
  },
  link19: {
    label: "Link 19",
    checkbox: true,
    field: {
      Eng: "Item Link 19",
      Span: "Artículo Enlace 19",
      ChS: "项目链接19",
      Gr: "Artikellink 19",
      Ru: "Ссылка на товар 19",
      Fr: "Lien d'article 19",
      It: "Collegamento articolo 19",
      Pl: "Link do elementu 19",
      Port: "Link do item 19",
      Viet: "Liên kết mục 19",
      Turk: "Öğe Bağlantısı 19",
      Ukrn: "Посилання на предмет 19",
      Thai: "รายการลิงค์ 19",
      Kor: "항목 링크 19",
      ChT: "項目鏈接19",
      Jp: "アイテムリンク19",
    },
  },
  link20: {
    label: "Link 20",
    checkbox: true,
    field: {
      Eng: "Item Link 20",
      Span: "Artículo Enlace 20",
      ChS: "项目链接20",
      Gr: "Artikellink 20",
      Ru: "Ссылка на товар 20",
      Fr: "Lien d'article 20",
      It: "Collegamento articolo 20",
      Pl: "Link do elementu 20",
      Port: "Link do item 20",
      Viet: "Liên kết mục 20",
      Turk: "Öğe Bağlantısı 20",
      Ukrn: "Посилання на предмет 20",
      Thai: "รายการลิงค์ 20",
      Kor: "항목 링크 20",
      ChT: "項目鏈接20",
      Jp: "アイテムリンク20",
    },
  },
  link21: {
    label: "Link 21",
    checkbox: true,
    field: {
      Eng: "Item Link 21",
      Span: "Artículo Enlace 21",
      ChS: "项目链接21",
      Gr: "Artikellink 21",
      Ru: "Ссылка на товар 21",
      Fr: "Lien d'article 21",
      It: "Collegamento articolo 21",
      Pl: "Link do elementu 21",
      Port: "Link do item 21",
      Viet: "Liên kết mục 21",
      Turk: "Öğe Bağlantısı 21",
      Ukrn: "Посилання на предмет 21",
      Thai: "รายการลิงค์ 21",
      Kor: "항목 링크 21",
      ChT: "項目鏈接21",
      Jp: "アイテムリンク21",
    },
  },
  link22: {
    label: "Link 22",
    checkbox: true,
    field: {
      Eng: "Item Link 22",
      Span: "Artículo Enlace 22",
      ChS: "项目链接22",
      Gr: "Artikellink 22",
      Ru: "Ссылка на товар 22",
      Fr: "Lien d'article 22",
      It: "Collegamento articolo 22",
      Pl: "Link do elementu 22",
      Port: "Link do item 22",
      Viet: "Liên kết mục 22",
      Turk: "Öğe Bağlantısı 22",
      Ukrn: "Посилання на предмет 22",
      Thai: "รายการลิงค์ 22",
      Kor: "항목 링크 22",
      ChT: "項目鏈接22",
      Jp: "アイテムリンク22",
    },
  },
  link23: {
    label: "Link 23",
    checkbox: true,
    field: {
      Eng: "Item Link 23",
      Span: "Artículo Enlace 23",
      ChS: "项目链接23",
      Gr: "Artikellink 23",
      Ru: "Ссылка на товар 23",
      Fr: "Lien d'article 23",
      It: "Collegamento articolo 23",
      Pl: "Link do elementu 23",
      Port: "Link do item 23",
      Viet: "Liên kết mục 23",
      Turk: "Öğe Bağlantısı 23",
      Ukrn: "Посилання на предмет 23",
      Thai: "รายการลิงค์ 23",
      Kor: "항목 링크 23",
      ChT: "項目鏈接23",
      Jp: "アイテムリンク23",
    },
  },
  link24: {
    label: "Link 24",
    checkbox: true,
    field: {
      Eng: "Item Link 24",
      Span: "Artículo Enlace 24",
      ChS: "项目链接24",
      Gr: "Artikellink 24",
      Ru: "Ссылка на товар 24",
      Fr: "Lien d'article 24",
      It: "Collegamento articolo 24",
      Pl: "Link do elementu 24",
      Port: "Link do item 24",
      Viet: "Liên kết mục 24",
      Turk: "Öğe Bağlantısı 24",
      Ukrn: "Посилання на предмет 24",
      Thai: "รายการลิงค์ 24",
      Kor: "항목 링크 24",
      ChT: "項目鏈接24",
      Jp: "アイテムリンク24",
    },
  },
  heading5: {
    label: "Heading 5",
    checkbox: true,
    field: {
      Eng: "Heading 5",
      Span: "Título 5",
      ChS: "标题 5",
      Gr: "Überschrift 5",
      Ru: "Заголовок 5",
      Fr: "Titre 5",
      It: "Titolo 5",
      Pl: "Tytuł 5",
      Port: "Título 5",
      Viet: "Cú đội đầu 5",
      Turk: "Başlık 5",
      Ukrn: "Заголовок 5",
      Thai: "หัวเรื่อง 5",
      Kor: "표제 5",
      ChT: "標題 5",
      Jp: "見出し 5",
    },
  },
  link25: {
    label: "Link 25",
    checkbox: true,
    field: {
      Eng: "Item Link 25",
      Span: "Artículo Enlace 25",
      ChS: "项目链接25",
      Gr: "Artikellink 25",
      Ru: "Ссылка на товар 25",
      Fr: "Lien d'article 25",
      It: "Collegamento articolo 25",
      Pl: "Link do elementu 25",
      Port: "Link do item 25",
      Viet: "Liên kết mục 25",
      Turk: "Öğe Bağlantısı 25",
      Ukrn: "Посилання на предмет 25",
      Thai: "รายการลิงค์ 25",
      Kor: "항목 링크 25",
      ChT: "項目鏈接25",
      Jp: "アイテムリンク25",
    },
  },
  link26: {
    label: "Link 26",
    checkbox: true,
    field: {
      Eng: "Item Link 26",
      Span: "Artículo Enlace 26",
      ChS: "项目链接26",
      Gr: "Artikellink 26",
      Ru: "Ссылка на товар 26",
      Fr: "Lien d'article 26",
      It: "Collegamento articolo 26",
      Pl: "Link do elementu 26",
      Port: "Link do item 26",
      Viet: "Liên kết mục 26",
      Turk: "Öğe Bağlantısı 26",
      Ukrn: "Посилання на предмет 26",
      Thai: "รายการลิงค์ 26",
      Kor: "항목 링크 26",
      ChT: "項目鏈接26",
      Jp: "アイテムリンク26",
    },
  },
  link27: {
    label: "Link 27",
    checkbox: true,
    field: {
      Eng: "Item Link 27",
      Span: "Artículo Enlace 27",
      ChS: "项目链接27",
      Gr: "Artikellink 27",
      Ru: "Ссылка на товар 27",
      Fr: "Lien d'article 27",
      It: "Collegamento articolo 27",
      Pl: "Link do elementu 27",
      Port: "Link do item 27",
      Viet: "Liên kết mục 27",
      Turk: "Öğe Bağlantısı 27",
      Ukrn: "Посилання на предмет 27",
      Thai: "รายการลิงค์ 27",
      Kor: "항목 링크 27",
      ChT: "項目鏈接27",
      Jp: "アイテムリンク27",
    },
  },
  link28: {
    label: "Link 28",
    checkbox: true,
    field: {
      Eng: "Item Link 28",
      Span: "Artículo Enlace 28",
      ChS: "项目链接28",
      Gr: "Artikellink 28",
      Ru: "Ссылка на товар 28",
      Fr: "Lien d'article 28",
      It: "Collegamento articolo 28",
      Pl: "Link do elementu 28",
      Port: "Link do item 28",
      Viet: "Liên kết mục 28",
      Turk: "Öğe Bağlantısı 28",
      Ukrn: "Посилання на предмет 28",
      Thai: "รายการลิงค์ 28",
      Kor: "항목 링크 28",
      ChT: "項目鏈接28",
      Jp: "アイテムリンク28",
    },
  },
  link29: {
    label: "Link 29",
    checkbox: true,
    field: {
      Eng: "Item Link 29",
      Span: "Artículo Enlace 29",
      ChS: "项目链接29",
      Gr: "Artikellink 29",
      Ru: "Ссылка на товар 29",
      Fr: "Lien d'article 29",
      It: "Collegamento articolo 29",
      Pl: "Link do elementu 29",
      Port: "Link do item 29",
      Viet: "Liên kết mục 29",
      Turk: "Öğe Bağlantısı 29",
      Ukrn: "Посилання на предмет 29",
      Thai: "รายการลิงค์ 29",
      Kor: "항목 링크 29",
      ChT: "項目鏈接29",
      Jp: "アイテムリンク29",
    },
  },
  link30: {
    label: "Link 30",
    checkbox: true,
    field: {
      Eng: "Item Link 30",
      Span: "Artículo Enlace 30",
      ChS: "项目链接30",
      Gr: "Artikellink 30",
      Ru: "Ссылка на товар 30",
      Fr: "Lien d'article 30",
      It: "Collegamento articolo 30",
      Pl: "Link do elementu 30",
      Port: "Link do item 30",
      Viet: "Liên kết mục 30",
      Turk: "Öğe Bağlantısı 30",
      Ukrn: "Посилання на предмет 30",
      Thai: "รายการลิงค์ 30",
      Kor: "항목 링크 30",
      ChT: "項目鏈接30",
      Jp: "アイテムリンク30",
    },
  },
  heading6: {
    label: "Heading 6",
    checkbox: true,
    field: {
      Eng: "Heading 6",
      Span: "Título 6",
      ChS: "标题 6",
      Gr: "Überschrift 6",
      Ru: "Заголовок 6",
      Fr: "Titre 6",
      It: "Titolo 6",
      Pl: "Tytuł 6",
      Port: "Título 6",
      Viet: "Cú đội đầu 6",
      Turk: "Başlık 6",
      Ukrn: "Заголовок 6",
      Thai: "หัวเรื่อง 6",
      Kor: "표제 6",
      ChT: "標題 6",
      Jp: "見出し 6",
    },
  },
  link31: {
    label: "Link 31",
    checkbox: true,
    field: {
      Eng: "Item Link 31",
      Span: "Artículo Enlace 31",
      ChS: "项目链接31",
      Gr: "Artikellink 31",
      Ru: "Ссылка на товар 31",
      Fr: "Lien d'article 31",
      It: "Collegamento articolo 31",
      Pl: "Link do elementu 31",
      Port: "Link do item 31",
      Viet: "Liên kết mục 31",
      Turk: "Öğe Bağlantısı 31",
      Ukrn: "Посилання на предмет 31",
      Thai: "รายการลิงค์ 31",
      Kor: "항목 링크 31",
      ChT: "項目鏈接31",
      Jp: "アイテムリンク31",
    },
  },
  link32: {
    label: "Link 32",
    checkbox: true,
    field: {
      Eng: "Item Link 32",
      Span: "Artículo Enlace 32",
      ChS: "项目链接32",
      Gr: "Artikellink 32",
      Ru: "Ссылка на товар 32",
      Fr: "Lien d'article 32",
      It: "Collegamento articolo 32",
      Pl: "Link do elementu 32",
      Port: "Link do item 32",
      Viet: "Liên kết mục 32",
      Turk: "Öğe Bağlantısı 32",
      Ukrn: "Посилання на предмет 32",
      Thai: "รายการลิงค์ 32",
      Kor: "항목 링크 32",
      ChT: "項目鏈接32",
      Jp: "アイテムリンク32",
    },
  },
  link33: {
    label: "Link 33",
    checkbox: true,
    field: {
      Eng: "Item Link 33",
      Span: "Artículo Enlace 33",
      ChS: "项目链接33",
      Gr: "Artikellink 33",
      Ru: "Ссылка на товар 33",
      Fr: "Lien d'article 33",
      It: "Collegamento articolo 33",
      Pl: "Link do elementu 33",
      Port: "Link do item 33",
      Viet: "Liên kết mục 33",
      Turk: "Öğe Bağlantısı 33",
      Ukrn: "Посилання на предмет 33",
      Thai: "รายการลิงค์ 33",
      Kor: "항목 링크 33",
      ChT: "項目鏈接33",
      Jp: "アイテムリンク33",
    },
  },
  link34: {
    label: "Link 34",
    checkbox: true,
    field: {
      Eng: "Item Link 34",
      Span: "Artículo Enlace 34",
      ChS: "项目链接34",
      Gr: "Artikellink 34",
      Ru: "Ссылка на товар 34",
      Fr: "Lien d'article 34",
      It: "Collegamento articolo 34",
      Pl: "Link do elementu 34",
      Port: "Link do item 34",
      Viet: "Liên kết mục 34",
      Turk: "Öğe Bağlantısı 34",
      Ukrn: "Посилання на предмет 34",
      Thai: "รายการลิงค์ 34",
      Kor: "항목 링크 34",
      ChT: "項目鏈接34",
      Jp: "アイテムリンク34",
    },
  },
  link35: {
    label: "Link 35",
    checkbox: true,
    field: {
      Eng: "Item Link 35",
      Span: "Artículo Enlace 35",
      ChS: "项目链接35",
      Gr: "Artikellink 35",
      Ru: "Ссылка на товар 35",
      Fr: "Lien d'article 35",
      It: "Collegamento articolo 35",
      Pl: "Link do elementu 35",
      Port: "Link do item 35",
      Viet: "Liên kết mục 35",
      Turk: "Öğe Bağlantısı 35",
      Ukrn: "Посилання на предмет 35",
      Thai: "รายการลิงค์ 35",
      Kor: "항목 링크 35",
      ChT: "項目鏈接35",
      Jp: "アイテムリンク35",
    },
  },
  link36: {
    label: "Link 36",
    checkbox: true,
    field: {
      Eng: "Item Link 36",
      Span: "Artículo Enlace 36",
      ChS: "项目链接36",
      Gr: "Artikellink 36",
      Ru: "Ссылка на товар 36",
      Fr: "Lien d'article 36",
      It: "Collegamento articolo 36",
      Pl: "Link do elementu 36",
      Port: "Link do item 36",
      Viet: "Liên kết mục 36",
      Turk: "Öğe Bağlantısı 36",
      Ukrn: "Посилання на предмет 36",
      Thai: "รายการลิงค์ 36",
      Kor: "항목 링크 36",
      ChT: "項目鏈接36",
      Jp: "アイテムリンク36",
    },
  },
  ctaHeading: {
    label: "Email Heading",
    checkbox: true,
    field: {
      Eng: "Subscribe",
      Span: "Suscribir",
      ChS: "订阅",
      Gr: "Abonnieren",
      Ru: "Подписаться",
      Fr: "S'abonner",
      It: "Sottoscrivi",
      Pl: "Subskrybuj",
      Port: "Se inscrever",
      Viet: "Đặt mua",
      Turk: "Abone",
      Ukrn: "Підпишіться",
      Thai: "ติดตาม",
      Kor: "구독하다",
      ChT: "訂閱",
      Jp: "購読する",
    },
  },
  ctaSubheading: {
    label: "Email Subheading",
    checkbox: true,
    field: {
      Eng: "Keep in touch!",
      Span: "¡Mantenerse en contacto!",
      ChS: "保持联系！",
      Gr: "Den Kontakt halten!",
      Ru: "Поддерживать связь!",
      Fr: "Rester en contact!",
      It: "Rimaniamo in contatto!",
      Pl: "Pozostajemy w kontakcie!",
      Port: "Mantenha contato!",
      Viet: "Giữ liên lạc!",
      Turk: "İletişimi koparmamak!",
      Ukrn: "Підтримувати зв'язок!",
      Thai: "ติดต่อกัน!",
      Kor: "계속 연락하세요!",
      ChT: "保持聯繫！",
      Jp: "連絡を取り合う！",
    },
  },
  ctaText: {
    label: "Email blurb",
    checkbox: true,
    field: {
      Eng: "Sign up to our emails for Kingston news and more.",
      Span: "Suscríbase a nuestros correos electrónicos para recibir noticias de Kingston y más.",
      ChS: "注册我们的电子邮件以获取金士顿新闻及更多信息。",
      Gr: "Abonnieren Sie unsere E-Mails für Neuigkeiten zu Kingston und mehr.",
      Ru: "Подпишитесь на нашу электронную почту, чтобы получать новости Kingston и многое другое.",
      Fr: "Inscrivez-vous à nos e-mails pour les nouvelles de Kingston et plus encore.",
      It: "Iscriviti alle nostre e-mail per notizie su Kingston e altro ancora.",
      Pl: "Zarejestruj się, aby otrzymywać nasze e-maile, aby otrzymywać aktualności firmy Kingston i nie tylko.",
      Port: "Inscreva-se para receber nossos e-mails para receber notícias da Kingston e muito mais.",
      Viet: "Đăng ký email của chúng tôi để biết tin tức về Kingston và hơn thế nữa.",
      Turk: "Kingston haberleri ve daha fazlası için e-postalarımıza kaydolun.",
      Ukrn: "Підпишіться на наші електронні листи, щоб отримувати новини Kingston тощо.",
      Thai: "ลงทะเบียนอีเมลของเราเพื่อรับข่าวสารจาก Kingston และอีกมากมาย",
      Kor: "Kingston 뉴스 등의 이메일에 등록하십시오.",
      ChT: "註冊我們的電子郵件以獲取金士頓新聞及更多信息。",
      Jp: "キングストンのニュースやその他の情報を入手するには、メールに登録してください。",
    },
  },
  ctaBtn: {
    label: "CTA btn Text",
    checkbox: null,
    field: {
      Eng: "Sign up now",
      Span: "Regístrate ahora",
      ChS: "立即注册",
      Gr: "Jetzt registrieren",
      Ru: "Войти Сейчас",
      Fr: "S'inscrire maintenant",
      It: "Iscriviti ora",
      Pl: "Zapisz się teraz",
      Port: "Inscreva-se agora",
      Viet: "Đăng ký ngay",
      Turk: "Şimdi kayıt ol",
      Ukrn: "Зареєструватися зараз",
      Thai: "สมัครตอนนี้เลย",
      Kor: "지금 등록하세요",
      ChT: "立即註冊",
      Jp: "今すぐサインアップ",
    },
  },
  Copy: {
    label: "Copyright Text",
    checkbox: null,
    field: {
      Eng: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. All rights reserved. All trademarks and registered trademarks are the property of their respective owners.",
      Span: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Reservados todos los derechos. Todas las marcas comerciales y marcas registradas son propiedad de sus respectivos dueños.",
      ChS: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. 版权所有。所有商标和注册商标均为其各自所有者的财产。",
      Gr: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Alle Rechte vorbehalten. Alle Marken und eingetragenen Marken sind Eigentum ihrer jeweiligen Inhaber.",
      Ru: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Все права защищены. Все товарные знаки и зарегистрированные товарные знаки являются собственностью их соответствующих владельцев.",
      Fr: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Tous les droits sont réservés. Toutes les marques et marques déposées sont la propriété de leurs propriétaires respectifs.",
      It: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Tutti i diritti riservati. Tutti i marchi e marchi registrati sono di proprietà dei rispettivi proprietari.",
      Pl: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Wszelkie prawa zastrzeżone. Wszystkie znaki towarowe i zastrzeżone znaki towarowe są własnością ich właścicieli.",
      Port: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Todos os direitos reservados. Todas as marcas comerciais e marcas registradas são de propriedade de seus respectivos proprietários.",
      Viet: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Đã đăng ký Bản quyền. Tất cả các nhãn hiệu và nhãn hiệu đã đăng ký là tài sản của chủ sở hữu tương ứng của họ.",
      Turk: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Her hakkı saklıdır. Tüm ticari markalar ve tescilli ticari markalar ilgili sahiplerinin mülkiyetindedir.",
      Ukrn: "©2022 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Всі права захищені. Усі торгові марки та зареєстровані торгові марки є власністю відповідних власників.",
      Thai: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. สงวนลิขสิทธิ์. เครื่องหมายการค้าและเครื่องหมายการค้าจดทะเบียนทั้งหมดเป็นทรัพย์สินของเจ้าของที่เกี่ยวข้อง",
      Kor: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. 판권 소유. 모든 상표 및 등록 상표는 해당 소유자의 재산입니다.",
      ChT: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. 版權所有。所有商標和註冊商標均為其各自所有者的財產。",
      Jp: "©2023 Kingston Technology Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. 全著作権所有。すべての商標および登録商標は、それぞれの所有者の財産です。",
    },
  },
  location: { label: "Shop Location Name", checkbox: null, field: { Eng: "United States" } },
  currentLang: "Eng",
};
