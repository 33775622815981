import React from "react";

const C_Rating = ({ copy }) => {
  let rCopy = copy ? copy : "Thanks for reading, did this help?";

  return (
    <div className={`c-rating`}>
      <p className="c-rating__copy">{rCopy}</p>
      <div className="c-rating__icons">
        <button className="c-rating__icons__btn" aria-label="Upvote">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 10c-.75 0-1 .5-1 1v11c0 .5.314 1 1 1h1c.686 0 1-.5 1-1V11c0-.5-.25-1-1-1h-1Zm14.602-2c-.236 0-.509-.211-.509-.434L15.573 4c0-.809-.24-1.477-.792-2-1.057-1-2.401-1-3.05-1-.126 0-.34.003-.37.003-.25 0-.489.089-.675.246-.215.179-.396.501-.396 1.001V5c0 1-.264 2.75-1.32 3.75-1.057 1-1.92.986-2.526 1.22-.55.214-.908.717-.908 1.28v9.62C5.536 22.105 6.73 23 8 23h11c1.27 0 1.736-.75 2-2l2.498-10c.263-1.7-.875-3-2.642-3h-4.754Z"
              fillRule="evenodd"
            />
          </svg>
        </button>
        <button className="c-rating__icons__btn" aria-label="Downvote">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 10c-.75 0-1 .5-1 1v11c0 .5.314 1 1 1h1c.686 0 1-.5 1-1V11c0-.5-.25-1-1-1h-1Zm14.602-2c-.236 0-.509-.211-.509-.434L15.573 4c0-.809-.24-1.477-.792-2-1.057-1-2.401-1-3.05-1-.126 0-.34.003-.37.003-.25 0-.489.089-.675.246-.215.179-.396.501-.396 1.001V5c0 1-.264 2.75-1.32 3.75-1.057 1-1.92.986-2.526 1.22-.55.214-.908.717-.908 1.28v9.62C5.536 22.105 6.73 23 8 23h11c1.27 0 1.736-.75 2-2l2.498-10c.263-1.7-.875-3-2.642-3h-4.754Z"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default C_Rating;
