/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../AppContext";
// import ToastContext from "../../../ToastContext";
import Icon from "./Icon";
// import Ids from "./icons.json";
//Ids are created by running writeiconlist npm script when running buildiconsearch. Used for search.
import buttonIds from "./buttons.json";
import careersIds from "./careers.json";
import controlsIds from "./controls.json";
import configIds from "./configurator.json";
import deprecatedIds from "./deprecated.json";
import featureIds from "./feature.json";
import miscIds from "./misc.json";
import navIds from "./nav.json";
import reservedIds from "./reserved.json";
import socialIds from "./social.json";
import Fuse from "fuse.js";
// import "./c_search.scss";
import "./iconMap.section.scss";
import "./pg_icons.scss";
import searchIcon from "./search.svg";

const Pg_Icons = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [currIcon, setCurrIcon] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const [download, setDownload] = useState("");
  //search states
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [matches, setMatches] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [matchActive, setMatchActive] = useState(false);
  const [toastOptions, setToastOptions] = useState(null);

  const searchRef = useRef();
  const inputRef = useRef();

  //combined list for search
  let Ids = [];

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "56%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#f7f9fa",
      padding: ".25em",
      borderRadius: "0.5em",
      paddingBottom: "1.5em",
    },
  };

  const handleIconClick = (name) => {
    setModalOpen(true);
    setCurrIcon(name);
  };

  const handleTagClick = (e, txt) => {
    e.preventDefault();
    setModalOpen(false);
    let tag = "#" + txt;
    navigator.clipboard.writeText(tag).then(
      function () {
        setToastOptions({ text: "Icon '" + currIcon + "' copied to clipboard!" });
      },
      function (err) {
        setToastOptions({ text: "There was a problem copying." });
        console.error("Async: Could not copy text: ", err);
      },
    );
  };

  const notify = () => {
    toast(toastOptions.text);
  };

  //search functions
  const searchOn = () => {
    setSearchActive(true);
    setMatchActive(true);
    // setMatches(Ids);
  };

  const resetSearch = () => {
    setMatches([]);
    setSearchActive(false);
    inputRef && inputRef.current && inputRef.current.value ? (inputRef.current.value = "") : "";
    setMatchActive(false);
    setSearchText("");
  };

  const handleKeydown = (e) => {
    e.key === "Escape" && resetSearch();
    // e.key === "Backspace" && doSearch();
  };

  const handleChange = (e, eText) => {
    e.preventDefault();
    if (eText.length < 1) {
      resetSearch();
      return;
    } else {
      if (eText.length >= 1) {
        searchOn();
        doSearch(eText);
      }
    }
  };

  const doSearch = (txt) => {
    setSearchText(txt);
    let options = {
      threshold: 0.3,
    };

    const fuse = new Fuse(searchData, options);
    const result = fuse.search(txt);
    let fuseMatches = [];

    if (!result.length) {
      setMatches([]);
      setMatchActive(false);
    } else {
      result.forEach(({ item }) => {
        fuseMatches.push(item);
      });
      setMatches(fuseMatches);
      setMatchActive(true);
    }
  };

  //load needed app data
  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/icon-guide",
      outputName: "",
      headerName: "Icon Guide",
      thumb: "",
      viewHeight: null,
      description: null,
    });
    Ids.push(
      ...buttonIds,
      ...careersIds,
      ...controlsIds,
      ...configIds,
      ...deprecatedIds,
      ...featureIds,
      ...miscIds,
      ...navIds,
      ...reservedIds,
      ...socialIds,
    );
    // console.log("Ids: ", Ids);
    setSearchData(Ids);
  }, []);
  //toast
  useEffect(() => {
    if (toastOptions && toastOptions.text) {
      notify();
    }
  }, [toastOptions]);

  //search
  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, true);

    //must clear event listeners when done
    return () => {
      document.removeEventListener("keydown", handleKeydown, true);
    };
  }, []);

  return (
    <>
      {/* <S_Headline
        classMods={"s-headline--alignLeft"}
        headline={appState.headerName}
        copy={"Click on an icon to view and/or copy the hashtag."}
        breadcrumbs={[
          {
            url: "/",
            text: "Home",
          },
        ]}
        // cookieClick={resetCookie}
      /> */}
      <div className={"s-headline"}>
        <div className="l-inner">
          <div className="u-animated u-animated--slow a-fadeIn u-animated--easeOut u-flex u-flexColumn">
            <div className="u-flex u-flexRow u-spaceBetween">
              <p className="s-headline__breadcrumbs">
                <span>
                  <Link to={""}>Home</Link>
                  <span className="s-headline__breadcrumbs__slash">/</span>
                </span>
              </p>
            </div>

            <div className="s-headline__title">
              <h1 className="s-headline__title__copy">Icons Guide</h1>
            </div>
            <p
              className="s-headline__description"
              style={{ alignItems: "flex-start", display: "flex" }}
            >
              Click on an icon to view and/or copy the hashtag.
            </p>
          </div>
        </div>
      </div>
      <div className="s-iconMap__navbar">
        <div className="s-iconMap__navbar__search">
          {/* Search */}
          <div className={`c-search ${searchActive && "c-search--active"}`} ref={searchRef}>
            <input
              className="c-search__input"
              type="text"
              onChange={(e) => handleChange(e, e.target.value)}
              placeholder="Search Icons"
              ref={inputRef}
              name="iconsearchfield"
            />
            <button
              className="c-search__icon"
              aria-label="Search"
              onClick={(e) => doSearch(searchText)}
              tabIndex={0}
              style={{ border: "1px solid white" }}
              id="c-search__input__button"
            >
              <img src={searchIcon} alt="icon search" />
            </button>
          </div>
        </div>
        <div className="s-iconMap__navbar__links">
          <Link to={"/icon-guide"} onClick={() => window.scrollTo(0, 0)}>
            Buttons
          </Link>
          <Link to={"#controls"}>Controls</Link>
          <Link to={"#social"}>Social Media</Link>
          <Link to={"#feature"}>Feature</Link>
          <Link to={"#configurator"}>Configurator</Link>
          <Link to={"#careers"}>Careers</Link>
          <Link to={"#misc"}>Miscellaneous</Link>
          <Link to={"#reserved"}>Reserved Use</Link>
          <Link to={"#deprecated"}>Phasing Out</Link>
        </div>
      </div>

      <div className={`s-iconMap`} id="Icon-Guide">
        {/* Search Results */}
        {matchActive ? (
          // This fade-in causes the tiles to lay over modal
          // <div
          //   className={`c-search__panel u-animated u-animated--delayFast a-fadeIn`}
          //   style={{ display: "block", top: "25em", color: "white" }}
          // >
          <div className="s-iconMap__layout l-inner">
            <button onClick={resetSearch} style={{ marginBottom: "2em" }}>
              Back to Categories
            </button>
            <ul className="s-iconMap__layout__grid s-iconMap__layout__grid--results">
              {matches.length > 0 &&
                matches.map((id, index) => (
                  <Icon
                    key={id}
                    name={id}
                    num={index + 1}
                    currIcon={currIcon}
                    icons={"/images/icons-map.svg"}
                    click={handleIconClick}
                  />
                ))}
            </ul>
          </div>
        ) : (
          // </div>
          //  Default layout
          <div className="s-iconMap__layout l-inner">
            <h4 id="buttons" style={{ paddingTop: "3rem" }}>
              Buttons
            </h4>
            <p>For use in buttons on various forms and navigation.</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {buttonIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="controls">Controls</h4>
            <p>
              For use in video or audio playback or other UI control like closing modals, opening
              accordions, etc.
            </p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {controlsIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="social">Social Media</h4>
            <p>Used as links to social media, sometimes for sharing content.</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {socialIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: ".8rem",
              }}
            >
              <h4 id="deprecated" style={{ marginBottom: "0" }}>
                Phasing Out
              </h4>
              <span
                style={{
                  backgroundColor: "#c8102e",
                  color: "white",
                  borderRadius: "1em",
                  margin: "0 0 0 1em",
                  padding: ".125em .75em",
                  fontSize: ".75rem",
                  textTransform: "uppercase",
                  lineHeight: "1.12",
                }}
              >
                Do Not Use
              </span>
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: ".8rem",
              }}
            >
              <h4 id="feature" style={{ marginBottom: "0" }}>
                Features
              </h4>
              <span
                style={{
                  backgroundColor: "rgb(0, 119, 11)",
                  color: "white",
                  borderRadius: "1em",
                  margin: ".25em 0 0 1em",
                  padding: ".125em .75em",
                  fontSize: ".75rem",
                  textTransform: "uppercase",
                  lineHeight: "1.12",
                }}
              >
                Available to use in Articles
              </span>
            </div>
            <p>Icons representing various product features.</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {featureIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="configurator">Configurator</h4>
            <p>Icons used only in configurator UI</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {configIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="careers">Careers and Company</h4>
            <p>For use only in the Careers and company section of the website.</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {careersIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>

            <h4 id="misc">Miscellaneous</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {miscIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="reserved">Reserved Use</h4>
            <p>Used for specific purposes like navigation, chat, etc.</p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {reservedIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: ".8rem",
              }}
            >
              <h4 id="deprecated" style={{ marginBottom: "0" }}>
                Phasing Out
              </h4>
              <span
                style={{
                  backgroundColor: "#c8102e",
                  color: "white",
                  borderRadius: "1em",
                  margin: ".25em 0 0 1em",
                  padding: ".125em .75em",
                  fontSize: ".75rem",
                  textTransform: "uppercase",
                  lineHeight: "1.12",
                }}
              >
                Do Not Use
              </span>
            </div>
            <p>
              These icons currently appear in some content, but do not use going forward, as
              eventually the goal is to replace or remove them.
            </p>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {deprecatedIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
          </div>
        )}

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={modalStyles}
          contentLabel={currIcon}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div className="u-flex u-flexRow u-justifyEnd">
            <button onClick={() => setModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <use href={`/images/icons-map.svg#close-round`} />
              </svg>
            </button>
          </div>
          <div className="u-flex u-flexRow u-justifyCenter u-mb1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <use href={`/images/icons-map.svg#${currIcon}`} />
            </svg>
          </div>
          <div className="u-flex u-flexRow u-justifyCenter u-mt1 u-mb1">
            <button className="tagBtn" onClick={(e) => handleTagClick(e, currIcon)}>
              <span>{`#${currIcon}`}</span>
            </button>
          </div>
          {/* <div className="u-flex u-flexRow u-justifyCenter u-mb1">
          <a href={download} download={`${currIcon}`} target="_blank" rel="noreferrer">
            Download SVG
          </a>
        </div> */}
        </Modal>
      </div>
    </>
  );
};

export default {
  path: "icon-guide",
  component: Pg_Icons,
  navtxt: "Icon Guide",
};
