import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import inpagenavOpts from "./inpagenavOpts";

const S_InPageNav = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let navVars = {
      ...appState,
      currentPath: "/ui/in-page-nav",
      outputName: "InPageNav",
      headerName: "In Page Nav",
      tags: null,
      description:
        "The In-Page-Navigation is used mainly in product pages to jump to specific areas of the page. Please note that the arrow will hide itself if scrolling isn't needed on the site, but that isn't currently working in the editable demo.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "../../js/inPageNav.component.js"],
      specs: [
        [
          "Title",
          [
            "Defaults to a <span> with a slightly larger (18px) and bolder text. No character limit, will not wrap.",
          ],
        ],
        [
          "Links",
          [
            "Standard text, will use the <a> tag with inherit global styling. Wrapped within <li> tags",
          ],
        ],
        [
          "Pricing (Optional)",
          ["Pricing details for products, presented in any format. No Character Limit."],
        ],
        [
          "CTA (Optional)",
          [
            "Call to Action should have a customizable text field and will be formatted as a button on the right side.",
            "Character limit: 10",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EdlT-rST7RBEvmlqXFYSCowBwFtV1auS-1OpwFWH8YVqkA?e=WpRcch",
        comments: null,
      },
    };
    setAppState(navVars);
    setContentOptions(inpagenavOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "40" });
  }, []);

  // this is a line that returns a line of text as a loader while the page data is loading, otherwise it skips to the next return which is the page output.
  if (!contentOptions) return "...Loading In-Page-Nav";
  return (
    <section className={`s-inPageNav ` + appState.currentTheme} id="inPageNav1">
      <div className="s-inPageNav__product">
        <span>
          {contentOptions.Title && contentOptions.Title.field[contentOptions.currentLang]}
        </span>
      </div>
      <button
        className="e-arrowToggle__down s-inPageNav__toggle"
        aria-label="Show Inpage Navigation Links"
      >
        <svg viewBox="0 0 14 8" aria-hidden="true">
          <path
            path="path"
            d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"
          />
        </svg>
      </button>
      <div className="s-inPageNav__links">
        <button className="u-invisible" aria-label="Previous Links">
          <svg viewBox="0 0 14 8" aria-hidden="true">
            <path
              path="path"
              d="M13.02 7.98a.062.062 0 0 1-.09 0L7.058 2.13c-.038-.035-.068-.039-.105-.01L1.071 7.98a.062.062 0 0 1-.09 0L.02 7.023a.062.062 0 0 1 0-.09L6.957.017a.066.066 0 0 1 .09 0l.962.96 5.973 5.954a.065.065 0 0 1 0 .09l-.961.958z"
            />
          </svg>
        </button>
        <span className="s-inPageNav__links__product">
          {contentOptions.Title && contentOptions.Title.field[contentOptions.currentLang]}
        </span>
        <ul
          className="s-inPageNav__links__nav"
          id="s-inPageNav__links__nav"
          style={{ left: "0px" }}
          aria-label="List of Inpage Navigation Links"
        >
          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 1 ? (
            <li>
              <a href="#link1" data-root="inPageNav1">
                {contentOptions.link1 && contentOptions.link1.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 2 ? (
            <li>
              <a href="#link2" data-root="inPageNav1">
                {contentOptions.link2 && contentOptions.link2.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 3 ? (
            <li>
              <a href="#link3" data-root="inPageNav1">
                {contentOptions.link3 && contentOptions.link3.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 4 ? (
            <li>
              <a href="#link4" data-root="inPageNav1">
                {contentOptions.link4 && contentOptions.link4.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 5 ? (
            <li>
              <a href="#link5" data-root="inPageNav1">
                {contentOptions.link5 && contentOptions.link5.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 6 ? (
            <li>
              <a href="#reviews" data-root="inPageNav1">
                {contentOptions.link6 && contentOptions.link6.field[contentOptions.currentLang]}
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 7 ? (
            <li>
              <a href="#videos" data-root="inPageNav1">
                Voluptatem
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 8 ? (
            <li>
              <a href="#videos" data-root="inPageNav1">
                Accusantium
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 9 ? (
            <li>
              <a href="#videos" data-root="inPageNav1">
                Doloremque
              </a>
            </li>
          ) : (
            ""
          )}

          {contentOptions.numFeatures && contentOptions.numFeatures.selected >= 10 ? (
            <li>
              <a href="#compare" data-root="inPageNav1">
                Laudantium
              </a>
            </li>
          ) : (
            ""
          )}
          {contentOptions.numFeatures && contentOptions.numFeatures.selected == "11" ? (
            <li>
              <a href="#reviews" data-root="inPageNav1">
                Lorem
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
        <button className="u-invisible" aria-label="Next Links">
          <svg viewBox="0 0 14 8" aria-hidden="true">
            <path
              path="path"
              d="M13.02 7.98a.062.062 0 0 1-.09 0L7.058 2.13c-.038-.035-.068-.039-.105-.01L1.071 7.98a.062.062 0 0 1-.09 0L.02 7.023a.062.062 0 0 1 0-.09L6.957.017a.066.066 0 0 1 .09 0l.962.96 5.973 5.954a.065.065 0 0 1 0 .09l-.961.958z"
            />
          </svg>
        </button>
      </div>
      {contentOptions.cta && contentOptions.cta.checkbox ? (
        <>
          {contentOptions.price && contentOptions.price.checkbox == true ? (
            <span className="s-inPageNav__price">
              {contentOptions.discount && contentOptions.discount.checkbox ? (
                <>
                  <span className="u-txt-strike">{contentOptions.discount.field}</span>
                </>
              ) : (
                ""
              )}
              {contentOptions.price.field}
            </span>
          ) : (
            ""
          )}
          <a className="e-btn" href="#buyLink" target="_self" title="Button Title">
            <span>
              {contentOptions.cta && contentOptions.cta.field[contentOptions.currentLang]}
            </span>
          </a>
        </>
      ) : (
        ""
      )}
    </section>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "in-page-nav",
  component: S_InPageNav,
  navtxt: "In Page Nav",
  htmlName: "InPageNav",
  categoryType: ["web"],
};
