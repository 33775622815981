import modules from "../../../workers/index.cjs";

//this function builds the path to the html file to load based off the value in app context
export function getHTMLData(appState, pathString, setCurrHtmlName) {
  return () => {
    if (appState.htmlSaved) {
      //remove everything but the file name in path
      let currContentPath = pathString.replace(/\/ui\//, "");
      //remove dashes in name
      currContentPath = currContentPath.replace(/-/g, "");

      //check all modules for right one to load
      modules.find(function (module) {
        let name;
        if (module.htmlName) {
          name = module.htmlName.toLowerCase();
        }
        //match module name to path
        if (name == currContentPath) {
          setCurrHtmlName(module.htmlName);
        }
      });
    }
  };
}
