[
  "10x",
  "20x",
  "25HourBattery",
  "3-year-warranty",
  "3d-nand",
  "a1-app-performance",
  "a2-app-performance",
  "access",
  "accessory",
  "adjust-settings",
  "agility",
  "aluminum",
  "anti-virus",
  "automated",
  "available-server-slot",
  "backup-mobile-device",
  "backup-photos",
  "backup",
  "battery-indicator",
  "binary-real-time-data",
  "bindings",
  "blog-white",
  "bluetooth",
  "capacity-storage",
  "capacity",
  "capless-design",
  "card-reader",
  "carrying-case",
  "cas-latency",
  "case",
  "certified",
  "charge-device",
  "chip",
  "chipset-supported",
  "class-10",
  "class-u1",
  "class-u3",
  "class10",
  "class2",
  "class4",
  "class6",
  "cloud-backup",
  "cloud-computing",
  "comfort",
  "compact-portable-slim",
  "compatibility",
  "compatible",
  "compliance",
  "content",
  "cost-efficient",
  "customizable",
  "data-corruption",
  "densely-woven-surface",
  "desktop-computing",
  "desktop",
  "detachable-cable",
  "detachable",
  "diamond-cut",
  "divisions",
  "dock-or-go",
  "drive",
  "dt-micro",
  "dual-chamber-driver",
  "dual-channel",
  "dual-interface",
  "dustproof-switches",
  "easy-installation",
  "eco-friendly",
  "embedded-industry-alliances",
  "encrypted",
  "ergonomic",
  "extra-space",
  "extreme-accuracy",
  "factory-tested",
  "fast-migration",
  "fast",
  "features",
  "file-data-transfer",
  "flexible",
  "form-factors",
  "formatting",
  "frequency-speed",
  "guaranteed-warranty",
  "hand-held",
  "hdd-2",
  "hdd",
  "hdmi",
  "heat-spreader",
  "hours-video-recording",
  "infrared-sync",
  "ironkey",
  "it-applications",
  "lightweight",
  "limited-edition",
  "low-power-consumption",
  "maintenance",
  "microsd-hc-class10",
  "microsd-xc-u3-i",
  "microsd-xc-v90-ii",
  "mobile-sizes",
  "msata",
  "multi-devices",
  "multi-platform",
  "multiple-colors",
  "multiple-ports",
  "multiple-sizes",
  "natural-rubber",
  "no-info",
  "onboard-memory",
  "optimized",
  "over-provisioning",
  "overclocking-2",
  "overclocking",
  "padlock",
  "password-security",
  "pcie-interface",
  "pcn-change",
  "pcn",
  "performance-speed",
  "plug-play",
  "port",
  "power-loss",
  "power",
  "push-button",
  "quad-channel",
  "raid",
  "read-intensive",
  "read-only-access",
  "real-time-data",
  "reliable",
  "rgb-small",
  "rgb",
  "rubber-tag",
  "rugged-durable",
  "safeconsole",
  "sandforce-controller",
  "savings",
  "sd-card",
  "secure-docking",
  "security-lock",
  "sensor",
  "server-virtualization",
  "server",
  "sff-connector",
  "shock-vibration-proof",
  "shock-vibration-proof_2",
  "single-sided",
  "sliding-cap",
  "slim",
  "slip-resistant-grip",
  "smooth-movement",
  "solutions-oem",
  "spill-resistant",
  "status-indicator",
  "steel",
  "storage",
  "sturdy-loop",
  "sync",
  "tangle-free-cable",
  "temperature-proof",
  "texture",
  "thermal-label",
  "thin-close",
  "trim",
  "trophy-white",
  "u1",
  "u3",
  "unique-design",
  "usb-c",
  "usb-cap",
  "usb-functionality",
  "usb-pass-throug",
  "usb",
  "usb30",
  "usb31",
  "usb32",
  "user-gear",
  "user-pin",
  "v10",
  "v30",
  "v6",
  "v60",
  "v90",
  "volt",
  "voltage",
  "warranty-circle",
  "warranty-shield",
  "water-resistant",
  "waterproof",
  "wireless-2",
  "wireless-charging",
  "wireless",
  "write-intensive",
  "xmp-ready",
  "xmp",
  "xmp2"
]
