/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SocialLinks = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);

  const [linkCopy, setLinkCopy] = useState(false);

  useEffect(() => {
    let lVars = {
      ...appState,
      currentPath: "/ui/social-links",
      currentTheme: "t-white",
      outputName: "SocialLinks", //html name
      headerName: "Social Links",
      tags: null,
      description:
        "The Social Links displays social media icons with links to their respective social media pages.",
      related: [["Social Share", "social-share"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/socials.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image optional",
            "Small, Medium, Large: Width: 20px ~ 24px and Height: 20px ~ 24px. 1:1 ratio",
          ],
        ],
        ["Image Type", ["SVG"]],
        [
          "Title",
          ["String text defaults to <h2> tag. No Character Limit but recommend max 2 lines."],
        ],
        ["Copy", ["The copy appears as a paragraph beneath the title. No Character limit."]],
        ["Copy Link", ["String text within a span. Recommend a single text."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EWb5tZUB2v1Ot3yOnzcJiuABMdX0xGW1eb-nZdHEWgLE9w?e=dSl769",
        comments: null,
      },
    };
    setAppState(lVars);
    setContentOptions({
      title: {
        label: "Title",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
      },
      copy: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          Span: "Hacía calor en ese tiempo, para mí,",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn Sch",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
          Fr: "Il faisait chaud dans ce temps-là, pour moi",
          It: "Faceva caldo con quel tempo, per me, non lo ",
          Pl: "Było gorąco w taką pogodę, jak dla mnie",
          Port: "Fazia calor naquele tempo, pra mim, não",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
          Ukrn: "У таку погоду було спекотно, для мене",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... --",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察た",
        },
      },
      copytext: {
        label: "Copy Link",
        checkbox: null,
        field: {
          Eng: "Lorem",
          Span: "Copiada",
          ChS: "已复制",
          Gr: "Kopiert",
          Ru: "Скопированот",
          Fr: "Copié",
          It: "Copiato",
          Pl: "Skopiowane",
          Port: "Copiada",
          Viet: "Đã sao chép",
          Turk: "kopyalandı",
          Ukrn: "Скопійовано",
          Thai: "คัดลอก",
          Kor: "복사됨",
          ChT: "已復制",
          Jp: "コピーされました",
        },
      },

      icon1: {
        label: "Icon 1",
        checkbox: null,
        field: "#share-facebook",
      },
      icon2: {
        label: "Icon 2",
        checkbox: null,
        field: "#share-twitter",
      },
      icon3: {
        label: "Icon 3",
        checkbox: null,
        field: "#share-linkedin",
      },
      icon4: {
        label: "Icon 4",
        checkbox: null,
        field: "#share-reddit",
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "148",
      width: window.innerWidth - 24,
    });
  }, []);

  useEffect(() => {
    if (linkCopy === true) {
      setTimeout(function () {
        setLinkCopy(false);
      }, 3000);
    }
  }, [linkCopy]);

  if (!AppContext) return "...Loading Social Links";
  return (
    <section className={"s-social " + appState.currentTheme}>
      <div className="l-inner">
        <div className="c-heading">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h2>
                {contentOptions.title && contentOptions.title.field[contentOptions.currentLang]}
              </h2>
              <p>{contentOptions.copy && contentOptions.copy.field[contentOptions.currentLang]}</p>
            </div>
          </div>
        </div>
        <div className="c-socials c-socials--large">
          <ul
            className="u-list-unstyled u-list-horizontal c-socials__list"
            aria-label="List of socials to share on"
          >
            <li className="c-socials__list__item">
              <a href="#facebook" target="_blank" aria-label="Share with Facebook">
                <svg aria-hidden="true">
                  <use
                    xlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon1 && contentOptions.icon1.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#x" target="_blank" aria-label="Share with X">
                <svg viewBox="0 0 20 20" aria-hidden="true">
                  <use
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon2 && contentOptions.icon2.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#linkedin" target="_blank" aria-label="Share with LinkedIn">
                <svg viewBox="0 0 20 20" aria-hidden="true">
                  <use
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon3 && contentOptions.icon3.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#reddit" target="_blank" aria-label="Share with Reddit">
                <svg viewBox="0 0 20 20" aria-hidden="true">
                  <use
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon4 && contentOptions.icon4.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a
                className={`c-socials__list__item__shareLink ${
                  linkCopy ? "c-socials__list__item__shareLink--copied" : ""
                }`}
                href="#link"
                target=""
                aria-label="Share with Link"
                onClick={() => setLinkCopy(true)}
              >
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    d="M9.369 8.711a4.552 4.552 0 0 1 4.022.263c.33.192.641.429.924.711A1.142 1.142 0 0 1 12.7 11.3a2.25 2.25 0 0 0-3.168 0l-4.594 4.594a2.243 2.243 0 0 0 0 3.168 2.243 2.243 0 0 0 3.168 0l2.74-2.74a5.58 5.58 0 0 0 2.906.325l-4.03 4.03A4.51 4.51 0 0 1 6.521 22a4.51 4.51 0 0 1-3.199-1.323 4.53 4.53 0 0 1 0-6.398l4.594-4.594a4.544 4.544 0 0 1 1.452-.974ZM17.478 2a4.51 4.51 0 0 1 3.199 1.323 4.53 4.53 0 0 1 0 6.398l-4.594 4.594a4.544 4.544 0 0 1-1.452.974 4.552 4.552 0 0 1-4.022-.263 4.516 4.516 0 0 1-.924-.711A1.142 1.142 0 0 1 11.3 12.7a2.25 2.25 0 0 0 3.168 0l4.594-4.594a2.243 2.243 0 0 0 0-3.168 2.243 2.243 0 0 0-3.168 0l-2.74 2.74a5.58 5.58 0 0 0-2.906-.325l4.03-4.03A4.51 4.51 0 0 1 17.479 2Z"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <span className="c-socials__list__item__shareLink__copy">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path
                      d="m10.94 16.243-1.415 1.414-4.95-4.95 1.415-1.414 4.95 4.95Zm7.07-9.9 1.415 1.414-8.486 8.486-1.414-1.415 8.485-8.485Z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  {contentOptions.copytext &&
                    contentOptions.copytext.field[contentOptions.currentLang]}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "social-links",
  component: S_SocialLinks,
  navtxt: "Social Links",
  htmlName: "SocialLinks",
  categoryType: ["web"],
};
