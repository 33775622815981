const C_ColorSwatch = ({ name, hex, cmyk, rgb, pms }) => {
  return (
    <div
      style={{
        fontSize: "0.75em",
        maxWidth: "150px",
        display: "inline-block",
        width: "100%",
        marginRight: "1em",
        verticalAlign: "top",
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          height: "70px",
          backgroundColor: hex,
          marginBottom: ".5em",
          border: " 1px solid black",
        }}
      ></div>
      {name ? (
        <>
          <strong style={{ fontSize: "1.25em" }}>{name}</strong> <br />
        </>
      ) : (
        ""
      )}
      {pms ? (
        <>
          <strong style={{ fontSize: "1.25em" }}>PMS {pms}</strong>
          <br />
        </>
      ) : (
        ""
      )}
      <span>HEX /</span> <strong>{hex}</strong>
      <br />
      <span>RGB /</span> <strong>{rgb} </strong>
      {/* <br />
      <span>CMYK /</span> <strong>{cmyk}</strong> */}
    </div>
  );
};

export default C_ColorSwatch;
