import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_ProductLite = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let plVars = {
      ...appState,
      currentPath: "/ui/product-lite",
      outputName: "roductLite",
      headerName: "Product Lite Section",
      tags: null,
      description: "The Product Lite Section provides a brief description with featured text.",
      related: null,
      currentTheme: "t-stone",
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width: 30px, Height: 30px"],
        ],
        ["Image Type", ["SVG"]],
        ["Copy", ["The copy appears as a paragraph. No Character limit."]],
        [
          "Feature",
          ["The feature appears as a span. No Character limit but recommended max 2 lines."],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EQPeHcABD_REjNl2N9rQXr0BYopSZ39rQuTF4YPhaPMdWg?e=aPENQT",
        comments: null,
      },
    };
    setAppState(plVars);
    setContentOptions({
      text: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus, est.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una. Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。 那个天气很热，对我来说",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von. Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит. Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит. Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит. Лорем ипсум долор сит амет, пер цлита поссит ех",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un. Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un. Il faisait",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra. Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra. Faceva caldo",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną. Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną. Było gorąco w taką pogodę, jak dla mnie",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra. Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra. Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một. Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một. Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda. O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda. O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній. У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній. У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다. 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다. 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다. 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      feature1: {
        label: "Feature 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем ипсум",
          Fr: "Il faisait.",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду бул",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      icon1: {
        label: "Icon 1",
        checkbox: null,
        field: "#bolt-duo",
      },
      feature2: {
        label: "Feature 2",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем ипсум",
          Fr: "Il faisait.",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду бул",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      icon2: {
        label: "Icon 2",
        checkbox: null,
        field: "#iphone-ipad",
      },
      feature3: {
        label: "Feature 3",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем ипсум",
          Fr: "Il faisait.",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду бул",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      icon3: {
        label: "Icon 3",
        checkbox: null,
        field: "#calendar",
      },
      feature4: {
        label: "Feature 4",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем ипсум",
          Fr: "Il faisait.",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду бул",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      icon4: {
        label: "Icon 4",
        checkbox: null,
        field: "#extra-space",
      },
      feature5: {
        label: "Feature 5",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      icon5: {
        label: "Icon 5",
        checkbox: null,
        field: "#backup-photos",
      },
      feature6: {
        label: "Feature 6",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      icon6: {
        label: "Icon 6",
        checkbox: null,
        field: "#accessory",
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "343" });
  }, []);

  if (!contentOptions || Object.keys(contentOptions).length < 1) return "...Loading Product Lite";
  return (
    <section className={`s-productLite  ` + appState.currentTheme}>
      <div className="l-inner">
        <p className="s-productLite__intro">
          {contentOptions.text && contentOptions.text.field[contentOptions.currentLang]}
        </p>
        <ul className="u-m0 u-p0 u-list-unstyled s-productLite__features">
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon1 && contentOptions.icon1.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature1 && contentOptions.feature1.field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon2 && contentOptions.icon2.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature2 && contentOptions.feature2.field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon3 && contentOptions.icon3.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature3 && contentOptions.feature3.field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon4 && contentOptions.icon4.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature4 && contentOptions.feature4.field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon5 && contentOptions.icon5.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature5 && contentOptions.feature5.field[contentOptions.currentLang]}
            </span>
          </li>
          <li>
            <div className="svg-bullet">
              <svg>
                <use
                  xlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`/images/icons-map.svg${
                    contentOptions.icon6 && contentOptions.icon6.field
                  }`}
                ></use>
              </svg>
            </div>
            <span>
              {contentOptions.feature6 && contentOptions.feature6.field[contentOptions.currentLang]}
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-lite",
  component: S_ProductLite,
  navtxt: "Product Lite",
  htmlName: "ProductLite",
  categoryType: ["web"],
};
