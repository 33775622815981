import React, { useEffect, useState } from "react";
import DropZone from "../../../0_hooks/dropzone";

const C_ProductCardLite = ({ img, products }) => {
  const [featureImage, setFeatureImage] = useState({ preview: "" });
  const [prodLinkTxt, setProdLinkTxt] = useState([{ name: "" }]);
  // const { contentOptions } = useContext(OptionsContext);
  let prodImg = img
    ? img
    : "https://media.kingston.com/kingston/navigation/ktc-navigation-product-usb.svg";

  useEffect(() => {
    products ? setProdLinkTxt(products) : setProdLinkTxt([{ name: "Product Name" }]);
  }, [products]);

  return (
    <DropZone setImage={setFeatureImage}>
      <div className={`c-productCardLite`}>
        <a className="c-productCardLite__img" href="#link" title={prodLinkTxt[0]["name"]}>
          <img
            alt={`navigation ${prodLinkTxt[0]["name"]}`}
            src={featureImage.preview ? featureImage.preview : prodImg}
          />
        </a>
        <div className="c-productCardLite__links">
          {prodLinkTxt.map((product, i) => (
            <a href="#link" key={product.name + i}>
              {product.name}
            </a>
          ))}
        </div>
      </div>
    </DropZone>
  );
};

export default C_ProductCardLite;
