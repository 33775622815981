export default {
  numTestimonials: {
    label: "Number of Testimonials",
    checkbox: null,
    field: ["1", "2", "3", "4", "5", "6"],
    selected: "6",
  },
  styleTestimonials: {
    label: "Image Style",
    checkbox: null,
    field: ["Square", "Rounded", "Circle", "Droplet BL"],
    selected: "Droplet BL",
  },
  imgBorder: {
    label: "Image Border [Optional]",
    checkbox: true,
    field: null,
  },
  testimonialNames: {
    label: "Names [optional]",
    checkbox: true,
    field: null,
  },
  testimonialSubtitles: {
    label: "Subtitles [optional]",
    checkbox: true,
    field: null,
  },
  testimonialTaglines: {
    label: "Tagline [optional]",
    checkbox: true,
    field: null,
  },
  card1: [
    {
      label: "Testimonial 1 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 1,
    },
    {
      label: "Testimonial 1 Copy",
      checkbox: null,
      field: {
        Eng: "Consectetur adipiscing elit, sed do eiusmod.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 1,
      charLimit: 150,
    },
    {
      label: "Testimonial 1 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 1,
    },
    {
      label: "Testimonial 1 Tagline",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título de la pancarta",
        ChS: "横幅标题",
        Gr: "Bannertitel",
        Ru: "Название баннера",
        Fr: "Titre de la bannière",
        It: "Titolo dello striscione",
        Pl: "Tytuł banera",
        Port: "Título do banner",
        Viet: "Tiêu đề biểu ngữ",
        Turk: "Afiş Başlığı",
        Ukrn: "Заголовок банера",
        Thai: "ชื่อแบนเนอร์",
        Kor: "배너 제목",
        ChT: "橫幅標題",
        Jp: "バナータイトル",
      },
      block: 1,
    },
  ],
  card2: [
    {
      label: "Testimonial 2 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 2,
    },
    {
      label: "Testimonial 2 Copy",
      checkbox: null,
      field: {
        Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia vo.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 2,
      charLimit: 150,
    },
    {
      label: "Testimonial 2 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 2,
    },
    {
      label: "Testimonial 2 Tagline",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 2,
    },
  ],
  card3: [
    {
      label: "Testimonial 3 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
    },
    {
      label: "Testimonial 3 Copy",
      checkbox: null,
      field: {
        Eng: "Consectetur adipiscing elit, sed do eiusmod.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 3,
      charLimit: 150,
    },
    {
      label: "Testimonial 3 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 3,
    },
    {
      label: "Testimonial 3 Tagline",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 3,
    },
  ],
  card4: [
    {
      label: "Testimonial 4 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 4,
    },
    {
      label: "Testimonial 4 Copy",
      checkbox: null,
      field: {
        Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia vo.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 4,
      charLimit: 150,
    },
    {
      label: "Testimonial 4 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 4,
    },
    {
      label: "Testimonial 4 Tagline",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título de la pancarta",
        ChS: "横幅标题",
        Gr: "Bannertitel",
        Ru: "Название баннера",
        Fr: "Titre de la bannière",
        It: "Titolo dello striscione",
        Pl: "Tytuł banera",
        Port: "Título do banner",
        Viet: "Tiêu đề biểu ngữ",
        Turk: "Afiş Başlığı",
        Ukrn: "Заголовок банера",
        Thai: "ชื่อแบนเนอร์",
        Kor: "배너 제목",
        ChT: "橫幅標題",
        Jp: "バナータイトル",
      },
      block: 4,
    },
  ],
  card5: [
    {
      label: "Testimonial 5 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 5,
    },
    {
      label: "Testimonial 5 Copy",
      checkbox: null,
      field: {
        Eng: "Sed ut perspiciatis unde omnis iste natus error",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 5,
      charLimit: 150,
    },
    {
      label: "Testimonial 5 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 5,
    },
    {
      label: "Testimonial 5 Tagline",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título de la pancarta",
        ChS: "横幅标题",
        Gr: "Bannertitel",
        Ru: "Название баннера",
        Fr: "Titre de la bannière",
        It: "Titolo dello striscione",
        Pl: "Tytuł banera",
        Port: "Título do banner",
        Viet: "Tiêu đề biểu ngữ",
        Turk: "Afiş Başlığı",
        Ukrn: "Заголовок банера",
        Thai: "ชื่อแบนเนอร์",
        Kor: "배너 제목",
        ChT: "橫幅標題",
        Jp: "バナータイトル",
      },
      block: 5,
    },
  ],
  card6: [
    {
      label: "Testimonial 6 Name",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Título",
        ChS: "标题",
        Gr: "Überschrift",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Cú đội đầu",
        Turk: "Başlık",
        Ukrn: "Заголовок",
        Thai: "หัวเรื่อง",
        Kor: "표제",
        ChT: "標題 ",
        Jp: "見出し",
      },
      block: 6,
    },
    {
      label: "Testimonial 6 Copy",
      checkbox: null,
      field: {
        Eng: "Sed ut perspiciatis unde omnis iste natus error",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 6,
      charLimit: 150,
    },
    {
      label: "Testimonial 6 Subtitle",
      checkbox: null,
      field: {
        Eng: "Quis variu",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱",
      },
      block: 6,
    },
    {
      label: "Testimonial 6 Tagline",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Título de la pancarta",
        ChS: "横幅标题",
        Gr: "Bannertitel",
        Ru: "Название баннера",
        Fr: "Titre de la bannière",
        It: "Titolo dello striscione",
        Pl: "Tytuł banera",
        Port: "Título do banner",
        Viet: "Tiêu đề biểu ngữ",
        Turk: "Afiş Başlığı",
        Ukrn: "Заголовок банера",
        Thai: "ชื่อแบนเนอร์",
        Kor: "배너 제목",
        ChT: "橫幅標題",
        Jp: "バナータイトル",
      },
      block: 6,
    },
  ],
  currentLang: "Eng",
};
