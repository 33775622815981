/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import OptionsContext from "../../../../OptionsContext";
import DimensionsContext from "../../../../DimensionsContext";
import C_IconFeatureItem from "../../2_components/iconFeature/C_IconFeatureItem";
import iconFeatureOpts from "./iconFeatureOpts";

const S_IconFeature = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let iconFeature = {
      ...appState,
      currentPath: "/ui/icon-feature",
      outputName: "IconFeature",
      headerName: "Icon Feature",
      tags: ["Landing Page Approved"],
      description:
        "The Icon Feature Section can be either single or double column of features, which includes an svg icon, feature heading, and/or description copy for each item",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: 32px x 32px, ideally 1:1 ratio"],
        ],
        ["Image Type", ["SVG"]],
        ["Feature Title", ["String text encased in a <h4> tag. Recommend: max lines 4"]],
        ["Feature Copy", ["String text in a <p> tag. No character limit"]],
        [
          "Note",
          [
            "Minimum: 1 feature item",
            "Maxiumum: Recommend maximum of 8 feature items, but not limited to 8",
          ],
        ],
        [
          "",
          [
            "Feature Title and Feature Copy are optional, but Icon must be paired with either a Feature Title or Feature Copy.",
          ],
        ],
        ["Note", ["Please refer to Heading Section for heading specs."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Eep1UdZEZ7dEislNXsfTp3MB9oTPZhh6FqFDCWy1ZZaE6Q?e=mqZU3z",
        comments: [
          ["Notes", ["There is a dedicated field for entering svg, jpg, or png urls"]],
          [
            "",
            [
              "Icon Feature must have at least one section of copy, either Feature Title or Feature Copy is acceptable",
            ],
          ],
          ["Icon Size:", [" 32px x 32px"]],
          ["Heading Title:", ["200 max char"]],
          ["Subheading Copy:", ["200 max char"]],
          ["Heading Copy", ["500 max char"]],
          ["Feature Title:", ["400 max char"]],
          ["Feature Copy:", ["No limit"]],
        ],
      },
    };
    setAppState(iconFeature);
    setContentOptions(iconFeatureOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "629" });
  }, []);

  if (!contentOptions) return "...Loading Icon Feature Section";
  return (
    <>
      {contentOptions.headingstyle && contentOptions.headingstyle.selected == "Content" ? (
        <section className="s-content u-pb0">
          <div className="l-inner">
            <div className="l-row">
              <div className="l-row__col l-1/1">
                <h2>Heading</h2>
                <h3>Subheading</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed ea
                  consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam.
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : contentOptions.headingstyle && contentOptions.headingstyle.selected == "Heading" ? (
        <section className="s-heading">
          <div className="c-heading">
            <div className="c-heading__block">
              <div className="c-heading__block__header">
                <h2 className="">
                  Lorem ipsum<span className="u-txt-subhead">Consectetur adipiscing elit</span>
                </h2>
                <p>Sed ut perspiciatis unde omnis iste natus error</p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      <section className={"s-iconFeature"}>
        <div className="l-inner">
          <ul
            className={`c-iconFeature u-list-unstyled${
              contentOptions.numColumns && contentOptions.numColumns.selected == 2
                ? " c-iconFeature--2col"
                : ""
            }${
              contentOptions.largeicons && contentOptions.largeicons.checkbox
                ? " c-iconFeature--largeIcons"
                : ""
            }`}
          >
            <C_IconFeatureItem
              svg={
                contentOptions.kflite1 &&
                contentOptions.kflite1[0] &&
                contentOptions.kflite1[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite1 &&
                contentOptions.kflite1[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite1[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite1 &&
                contentOptions.kflite1[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite1[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite2 &&
                contentOptions.kflite2[0] &&
                contentOptions.kflite2[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite2 &&
                contentOptions.kflite2[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite2[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite2 &&
                contentOptions.kflite2[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite2[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite3 &&
                contentOptions.kflite3[0] &&
                contentOptions.kflite3[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite3 &&
                contentOptions.kflite3[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite3[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite3 &&
                contentOptions.kflite3[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite3[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite4 &&
                contentOptions.kflite4[0] &&
                contentOptions.kflite4[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite4 &&
                contentOptions.kflite4[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite4[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite4 &&
                contentOptions.kflite4[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite4[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite5 &&
                contentOptions.kflite5[0] &&
                contentOptions.kflite5[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite5 &&
                contentOptions.kflite5[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite5[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite5 &&
                contentOptions.kflite5[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite5[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite6 &&
                contentOptions.kflite6[0] &&
                contentOptions.kflite6[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite6 &&
                contentOptions.kflite6[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite6[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite6 &&
                contentOptions.kflite6[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite6[2].field[contentOptions.currentLang]
                  : ""
              }
            />
            <C_IconFeatureItem
              svg={
                contentOptions.kflite7 &&
                contentOptions.kflite7[0] &&
                contentOptions.kflite7[0].field
              }
              htag={contentOptions.headingmode && contentOptions.headingmode.selected}
              title={
                contentOptions.kflite7 &&
                contentOptions.kflite7[1] &&
                contentOptions.enableheading.checkbox
                  ? contentOptions.kflite7[1].field[contentOptions.currentLang]
                  : ""
              }
              copy={
                contentOptions.kflite7 &&
                contentOptions.kflite7[2] &&
                contentOptions.enablecopy.checkbox
                  ? contentOptions.kflite7[2].field[contentOptions.currentLang]
                  : ""
              }
            />
          </ul>
        </div>
      </section>
    </>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "icon-feature",
  component: S_IconFeature,
  navtxt: "Icon Feature",
  htmlName: "IconFeature",
  categoryType: ["landing page", "web"],
};
