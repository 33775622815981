import React, { useState } from "react";

const C_ExternalVideo = ({ type, videoid, starttime }) => {
  let evType = type ? type : "Vimeo";
  let evVideoId = videoid ? videoid : "q1DOk2vRsus";
  let evStartTime = starttime ? starttime : null;

  return (
    <>
      <div
        className="c-externalVideo"
        data-type={evType}
        data-id={evVideoId}
        data-starttime={evStartTime}
      ></div>
    </>
  );
};

export default C_ExternalVideo;
