/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_CheckoutEmea = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  //on document load equivalent
  useEffect(() => {
    let ckVars = {
      ...appState,
      currentPath: "/ui/checkout-emea",
      outputName: "CheckoutEmea",
      headerName: "Checkout: Emea",
      tags: null,
      currentTheme: "t-white",
      description:
        "The Checkout EMEA Section is used by regions in EMEA that don't use Shopify, except UK.",
      related: [["Checkout: Default", "checkout"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/quantityCounter.component.js",
        "../../js/forms.component.js",
        "../../js/dropDown.component.js",
        "checkoutEmea.section.js",
        "../../js/googlePlaces.component.js",
        "../../js/checkout.section.js",
        "../../js/accordion.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["Small, Medium, Large: Width and Height: Depends on the product item"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Credit Card Image (mobile, tablet, desktop)", ["Width: 32px, Height: 20px"]],
        ["Credit Card Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["A <h2> consists of string text"]],
        ["Copy Heading", ["A <p> consists of string text"]],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Question", ["A <span> consists of string text"]],
        ["Copy", ["A <p> consists of string text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EY9eExcb5pZIob4yq2PTZ20Bo2ReQwuybrgZOkHPCZiANg?e=1RVJ2f",
        comments: null,
      },
    };

    setAppState(ckVars);
    setContentOptions({
      savings: { label: "Savings", checkbox: true, field: null },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      viewHeight: "1323",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Checkout: Emea";
  return (
    <section className={"s-checkout s-checkout--emea " + appState.currentTheme}>
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <svg viewBox="0 0 16 20">
              <path d="M16 9.785C16 8.247 14.723 7 13.147 7H2.853C1.277 7 0 8.247 0 9.785v7.43C0 18.753 1.277 20 2.853 20h10.294a2.89 2.89 0 0 0 2.017-.816c.535-.522.836-1.23.836-1.969v-7.43zM9.333 15.08A1.04 1.04 0 0 1 8.28 16.11h-.56a1.04 1.04 0 0 1-1.053-1.028v-4.463A1.04 1.04 0 0 1 7.72 9.59h.56c.28 0 .547.108.745.3.197.193.308.455.308.728v4.463zM8.1.002a4.824 4.824 0 0 0-3.583 1.445 5.274 5.274 0 0 0-1.509 3.689c-.024.291.006.585.09.864h2.545c-.127 0-.19-.505-.19-.864 0-1.47 1.139-2.66 2.545-2.66 1.376 0 2.504 1.142 2.545 2.58 0 .359-.063.904-.19.904h2.545S13 5.441 13 5.082C12.959 2.28 10.783.024 8.1.002z"></path>
            </svg>
            <h1>Secure Checkout</h1>
          </div>
        </div>
      </div>
      <div className="s-checkout__signinContainer">
        <p className="s-checkout__signinContainer__checkoutSignIn">
          Have a Kingston account?{" "}
          <a className="signing-toggle" href="#link1">
            Sign In
          </a>
        </p>
        <form className="s-checkout__signinContainer__frmSignIn" id="formSignin">
          <div className="f-input">
            <div className="f-input__string">
              <input id="signinEmail" type="text" name="signinEmail" />
              <label htmlFor="signinEmail">Email Address</label>
            </div>
          </div>
          <div className="f-input" id="textFieldValue">
            <div className="f-input__string">
              <input id="signinPassword" type="password" name="signinPassword" />
              <label htmlFor="signinPassword">Password</label>
            </div>
          </div>
          <div className="s-checkout__signinContainer__frmSignIn__signinFtr">
            <div className="s-checkout__signinContainer__frmSignIn__signinFtr__btns">
              <a className="e-btn" href="#/checkout-thankyou">
                Sign In
              </a>
              <a className="e-btn e-btn--alt1 signin-toggle" href="#link">
                Cancel
              </a>
            </div>
            <a href="#/reset-password">Forgot Password</a>
          </div>
        </form>
      </div>
      <div className="s-checkout__overlay"></div>

      <div className="l-inner">
        <form className="l-grid s-checkout__gridCheckout l-grid--2col@lg" id="formCheckout">
          <div className="s-checkout__gridCheckout__form">
            <div className="s-checkout__gridCheckout__form__message"></div>
            <div className="s-checkout__gridCheckout__form__paypal"></div>
            <button className="s-checkout__gridCheckout__form__cardBtn" aria-controls="formInfo">
              <svg viewBox="0 0 22 15">
                <path d="M0 1.992C0 .892.89 0 1.991 0H20.01C21.109 0 22 .893 22 1.992v11.016c0 1.1-.89 1.992-1.991 1.992H1.99C.891 15 0 14.107 0 13.008V1.992zM0 3v3h22V3H0zm3 6v3h5V9H3z" />
              </svg>
              Debit or Credit Card
            </button>
            <div id="formInfo" className="s-checkout__gridCheckout__form__info" aria-hidden="true">
              <button
                className="s-checkout__gridCheckout__form__info__close"
                aria-label="Close Button"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                  <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z" />
                  <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z" />
                </svg>
              </button>
              <fieldset className="s-checkout__gridCheckout__form__info__section">
                <span className="u-h5">Card Details</span>
                <div className="l-row">
                  <div className="l-row__col l-1/1">
                    <div
                      className="s-checkout__gridCheckout__form__info__section__cardField"
                      id="cardNumber"
                    ></div>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-1/2@md">
                    <div
                      className="s-checkout__gridCheckout__form__info__section__cardField"
                      id="expiration"
                    ></div>
                  </div>
                  <div className="l-row__col l-1/2@md">
                    <div
                      className="s-checkout__gridCheckout__form__info__section__cardField"
                      id="csc"
                    ></div>
                  </div>
                </div>
                <div className="s-checkout__gridCheckout__form__info__section__cards">
                  <img src="/images/fpo/section-checkout/card-logo-visa.png" alt="VISA" />
                  <img
                    src="/images/fpo/section-checkout/card-logo-mastercard.png"
                    alt="MasterCard"
                  />
                </div>
              </fieldset>
              <fieldset className="s-checkout__gridCheckout__form__info__section">
                <span className="u-h5">Contact Info</span>
                <div className="l-row">
                  <div className="l-row__col l-1/1">
                    <div className="f-input" data-id="fi-wddvrt">
                      <div className="f-input__string">
                        <input type="text" name="email" id="email" data-root="fi-wddvrt" />
                        <label htmlFor="email">Email Address</label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="s-checkout__gridCheckout__form__info__section">
                <span className="u-h5">Shipping Address</span>
                <div className="l-row">
                  <div className="l-row__col l-1/2@md">
                    <div className="f-input" data-id="fi-huvq49">
                      <div className="f-input__string">
                        <input type="text" name="firstName" id="firstName" data-root="fi-huvq49" />
                        <label htmlFor="firstName">First Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-1/2@md">
                    <div className="f-input" data-id="fi-nx5qmc">
                      <div className="f-input__string">
                        <input type="text" name="lastName" id="lastName" data-root="fi-nx5qmc" />
                        <label htmlFor="lastName">Last Name</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-1/1">
                    <div className="f-input" data-id="fi-a2y7g6">
                      <div className="f-input__string">
                        <input
                          type="text"
                          name="streetAddress"
                          id="streetAddress"
                          data-root="fi-a2y7g6"
                        />
                        <label htmlFor="streetAddress">Street Address</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-1/1">
                    <div className="f-input" data-id="fi-7l0xnp">
                      <div className="f-input__string">
                        <input
                          type="text"
                          name="otherAddress"
                          id="otherAddress"
                          data-root="fi-7l0xnp"
                        />
                        <label htmlFor="otherAddress">
                          Apt, Unit, Floor, Suite, Building (Optional)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-1/2@md">
                    <div className="f-input" data-id="fi-77uxp0">
                      <div className="f-input__string">
                        <input type="text" name="city" id="city" data-root="fi-77uxp0" />
                        <label htmlFor="city">City</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-1/4@md">
                    <div className="f-input" data-id="fi-hdepob">
                      <div className="f-input__string">
                        <input type="text" name="state" id="state" data-root="fi-hdepob" />
                        <label htmlFor="state">State</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-1/4@md">
                    <div className="f-input" data-id="fi-7ixrqh">
                      <div className="f-input__string">
                        <input type="text" name="postcode" id="postcode" data-root="fi-7ixrqh" />
                        <label htmlFor="postcode">Postcode</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-1/1">
                    <div className="f-input" data-id="fi-tcwun5">
                      <div className="f-input__string">
                        <input
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          data-root="fi-tcwun5"
                        />
                        <label htmlFor="phoneNumber">Phone</label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="s-checkout__gridCheckout__form__info__section s-checkout__gridCheckout__form__info__billing">
                <legend className="u-h5">Billing Address</legend>
                <div className="f-input" data-id="fi-sdrks1">
                  <div className="f-input__checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox1"
                      required="required"
                      value="checkbox 1"
                      data-root="fi-sdrks1"
                    />
                    <label htmlFor="checkbox1">Same as Delivery Address</label>
                  </div>
                </div>
                <div className="f-input" data-id="fi-mic3mw">
                  <div className="f-input__checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox2"
                      required="required"
                      value="checkbox 2"
                      data-root="fi-mic3mw"
                    />
                    <label htmlFor="checkbox2">
                      I agree to both the <a href="#link">Terms and Conditions</a> and the{" "}
                      <a href="#link">Privacy Policy</a> of Shop Europe Ltd
                    </label>
                  </div>
                </div>
              </fieldset>
              <div
                className="s-checkout__gridCheckout__form__orderContainer u-txt-center"
                id="submitBtnContainer"
              >
                <div className="wrapper3d">
                  <div className="btn-container">
                    <a className="e-btn checkout-btn" id="checkoutBtn" href="#link">
                      <div className="default__text">
                        <svg>
                          <use xlinkHref="images/icons/icons-map.svg#factory-tested"></use>
                        </svg>
                        Place Order
                      </div>
                      <div className="emea__text">Proceed to Payment</div>
                      <span>
                        <svg
                          className="loader10"
                          height="38"
                          viewBox="0 0 38 38"
                          width="38"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <defs>
                            <linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%">
                              <stop offset="0%" stopColor="#fff" stopOpacity="0"></stop>
                              <stop offset="63.146%" stopColor="#fff" stopOpacity=".631"></stop>
                              <stop offset="100%" stopColor="#fff"></stop>
                            </linearGradient>
                          </defs>
                          <g fillRule="evenodd" fill="none">
                            <g transform="translate(1 1)">
                              <path
                                d="M36 18c0-9.94-8.06-18-18-18"
                                id="Oval-2"
                                strokeWidth="2"
                                stroke="url(#a)"
                              ></path>
                              <circle cx="36" cy="18" fill="#fff" r="1"></circle>
                            </g>
                          </g>
                        </svg>
                        Processing...
                      </span>
                    </a>
                    <a className="e-btn continue-btn bottom" id="continueBtn" href="#link">
                      Continue Shopping
                    </a>
                  </div>
                </div>
              </div>
              <p className="emea__text">
                You’ll be directed to sagepay.com for secure payment processing.
              </p>
            </div>
          </div>

          <div className="s-checkout__gridCheckout__summary">
            <h2 className="u-h4">Shipping Options</h2>
            <div className="f-input" data-id="fi-2jn5zc">
              <div className="f-input__select">
                <select
                  name="selectName"
                  id="selectName"
                  required="required"
                  aria-label="Select Shipping Option"
                  data-root="fi-2jn5zc"
                >
                  <option hidden="hidden" disabled="" selected="selected"></option>
                  <option value="textVal1">FedEX Ground 5-7 Days (M-F) - FREE</option>
                  <option value="textVal2">USPS</option>
                  <option value="textVal3">Dragon</option>
                </select>
                <div className="c-dropDown" data-root="fi-2jn5zc">
                  <div className="c-dropDown__toggler">
                    <div className="f-input">
                      <div className="f-input__string">
                        <input type="text" id="tmp-6g90lm" disabled="" data-root="fi-2jn5zc" />
                        <label htmlFor="tmp-6g90lm">Shipping</label>
                      </div>
                      <e-icon className="f-input__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                          <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                        </svg>
                      </e-icon>
                    </div>
                  </div>
                  <div className="c-dropDown__panel">
                    <ul className="c-dropDown__panel__items" data-reference="selectName">
                      <li data-index="1" data-input="tmp-6g90lm">
                        FedEX Ground 5-7 Days (M-F) - FREE
                      </li>
                      <li data-index="2" data-input="tmp-6g90lm">
                        USPS
                      </li>
                      <li data-index="3" data-input="tmp-6g90lm">
                        Dragon
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-accordion c-accordion--iconAfter">
              <span className="c-accordion__tab c-accordion__tab--active" id="accTab-l5uokg">
                <button aria-controls="accPanel-zhzu41" aria-expanded="true">
                  Order Summary
                  <svg
                    className="c-accordion__tab__icon"
                    aria-hidden="true"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                  >
                    <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                  </svg>
                  <span>$100.00</span>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                id="accPanel-zhzu41"
                aria-labelledby="accTab-l5uokg"
              >
                <div className="c-accordion__panel__content c-cartCheckout">
                  <ul className="list-unstyled c-cartCheckout__listing">
                    <li>
                      <div className="c-cartItem" data-kit="4">
                        <img
                          className="c-cartItem__image"
                          src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                          alt="Product"
                        />
                        <div className="c-cartItem__title u-txt-bold">
                          480GB A400 SATA3 2.5 SSD (7mm height)
                        </div>
                        <div className="c-cartItem__quantity">
                          <div className="f-quantity js-quantity s-checkout--emea" id="jsQuantity0">
                            <label className="f-quantity__label" htmlFor="quant1">
                              Quantity
                            </label>
                            <div className="f-quantity__counter">
                              <button
                                className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                aria-label="Decrease Quantity"
                                tabIndex="-1"
                              >
                                <span className="f-quantity__counter__btn__inner">
                                  <svg viewBox="0 0 16 2">
                                    <title>Minus Icon</title>
                                    <path d="M0 0h16v2H0z"></path>
                                  </svg>
                                </span>
                              </button>
                              <input
                                className="f-quantity__counter__input"
                                type="text"
                                maxLength="4"
                                value="1"
                                id="quant1"
                                name="fQuantity0"
                              />
                              <button
                                className="f-quantity__counter__btn js-quantity__btn"
                                aria-label="Increase Quantity"
                                data-add="true"
                              >
                                <span className="f-quantity__counter__btn__inner">
                                  <svg viewBox="0 0 16 16">
                                    <title>Plus Icon</title>
                                    <path d="M0 7h16v2H0z"></path>
                                    <path d="M7 0h2v16H7z"></path>
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="c-cartItem__total">
                          <span className="u-txt-bold">
                            1 x{" "}
                            {contentOptions.savings && contentOptions.savings.checkbox == true ? (
                              <span className="u-txt-strike c-cartItem__total__strike">$6.00</span>
                            ) : (
                              ""
                            )}{" "}
                            $54.99
                          </span>
                          <span className="c-cartItem__total__label">Total</span>
                          <button className="c-cartItem__total__trash">
                            <svg viewBox="0 0 14 17.2" width="100%" height="100%">
                              <path d="M11.3 16.1V4.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V16c0 .1 1.1.1 1 .1z"></path>
                              <path d="M2.2 16.9c0 .2.2.3.3.3h8.9c.2 0 .3-.1.3-.3l1-11.8H1.3l.9 11.8zm11.5-15H9.3C9.1.8 8.2 0 7.1 0s-2 .8-2.2 1.9H.3c-.2 0-.3.1-.3.3v1.3c0 .2.1.3.3.3h13.4c.2 0 .3-.1.3-.3V2.2c0-.2-.1-.3-.3-.3z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="c-cartCheckout__summary">
                    <table className="c-cartCheckout__summary__details">
                      <tbody>
                        <tr>
                          <td className="l-summary__link">
                            <a className="link-editCart" id="editCartLink" href="#link">
                              Edit Cart
                            </a>
                            <a className="link-updateCart" id="updateCartLink" href="#link">
                              Update Cart
                            </a>
                            <a className="link-continueCart" id="continueLink" href="#link">
                              Continue Shopping
                            </a>
                          </td>
                          <td className="l-summary__subTitle">Subtotal</td>
                          <td className="l-summary__subTotal">$1.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__deliveryTitle">Shipping</td>
                          <td className="l-summary__shpping">FREE</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__taxTitle">Tax</td>
                          <td className="l-summary__tax">$1.00</td>
                        </tr>
                        <tr>
                          <td className="l-summary__itemCount">1 Item</td>
                          <td className="l-summary__totalTitle">Total</td>
                          <td className="l-summary__total">$2.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__exVatTotal">Total (VAT excluded)</td>
                          <td className="l-summary__exVatTotal">$0.10</td>
                        </tr>
                        {contentOptions.savings && contentOptions.savings.checkbox == true ? (
                          <tr>
                            <td></td>
                            <td className="l-summary__saveTitle">Savings</td>
                            <td className="l-summary__savings">$0.10</td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="c-accordion__overflow" role="region">
                <div className="f-input f-input--reactive" data-id="fi-qenjp8">
                  <div className="f-input__string">
                    <input id="acode" type="text" name="acode" data-root="fi-qenjp8" />
                    <label htmlFor="acode">Discount Code</label>
                  </div>
                  <a className="f-input__icon" href="#link">
                    Apply
                  </a>
                </div>
              </div>
            </div>
            <span className="u-txt-bold">Are you a VAT registrered business outside the UK?</span>
            <p>
              You can register your VAT number so that we can verify your VAT number before
              exempting UK VAT from your order. This option will be available after you checkout..{" "}
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "checkout-emea",
  component: S_CheckoutEmea,
  navtxt: "Checkout: Emea",
  htmlName: "CheckoutEmea",
  categoryType: ["internal"],
};
