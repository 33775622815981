import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../AppContext.jsx";
import S_ContentNav from "../../3_sections/contentNav/S_ContentNav.jsx";
import S_Headline from "../../3_sections/headline/S_Headline.jsx";

// Content Areas
import IronKey from "./content/Iron-Key";
import ServerPremier from "./content/Server-Premier";
import ValueRam from "./content/Value-Ram";

const Pg_ProductCategoryLogos = () => {
  const { appState, setAppState } = useContext(AppContext);

  //now currentContent just sets active state for tab btns
  const [currentContent, setCurrentContent] = useState("IronKey");

  const navigate = useNavigate();
  //active_tab var to be able to track current tab and push url to browser
  const { active_tab } = useParams();
  let defaultTab = "iron-key";

  const toggleTab = (tab) => {
    if (active_tab !== tab) {
      navigate(`/brand/product-category-logos/${tab}`);
    }
  };

  //this is to change the tab if it's changed from outside this page like search. Also allows forward and back on browser nav.
  useEffect(() => {
    let cPath = location.pathname;
    let tab = cPath.replace("/brand/product-category-logos/", "");
    setCurrentContent(tab);
  }, [appState.currentPath, location]);

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/brand/product-category-logos",
      outputName: "",
      headerName: "Product Category Logos",
      tags: null,
      viewHeight: null,
      description: null,
    });
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!active_tab) {
      navigate(`/${defaultTab}`);
    }
    active_tab !== currentContent ? setCurrentContent(active_tab) : null;
    window.scrollTo(0, 0);
  }, [currentContent]);

  return (
    <div className="pg-brandProductCategoryLogos u-flex u-flex1 u-flexColumn">
      <S_Headline
        classMods={"s-headline--alignLeft"}
        headline={appState.headerName}
        navigate={true}
      />
      <S_ContentNav
        tabs={[
          { name: "IronKey", active: "iron-key", path: "iron-key" },
          { name: "ValueRAM", active: "value-ram", path: "value-ram" },
          { name: "Server Premier", active: "server-premier", path: "server-premier" },
        ]}
        updateContent={setCurrentContent}
        toggleTab={toggleTab}
        currentContent={currentContent}
        classMods={"s-contentNav--tabs"}
      />
      <div className="u-flex u-flexColumn u-flex1 u-justifyCenter u-animated u-animated--delayFast u-animated--slower a-fadeIn">
        {currentContent == "iron-key" ? (
          <IronKey />
        ) : currentContent == "server-premier" ? (
          <ServerPremier />
        ) : currentContent == "value-ram" ? (
          <ValueRam />
        ) : (
          <IronKey />
        )}
      </div>
    </div>
  );
};

export default {
  path: "product-category-logos/:active_tab",
  component: Pg_ProductCategoryLogos,
  navtxt: "Product Category Logos",
};
