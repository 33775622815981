/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../AppContext";
import "./Toggle.scss";

const C_UseHTMLToggle = ({ setShowHtml, show, option1, option2, num }) => {
  const { appState } = useContext(AppContext);
  const [htmlOn, setHtmlOn] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);

  // const htmlSaved = appState.htmlSaved;
  // const hideView = appState.hideView;

  // const setDisableToggle = () => {
  //   // if (!htmlSaved && (hideView !== null || typeof hideView !== undefined)) {
  //   if (!htmlSaved && (hideView !== null || typeof hideView !== undefined)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // show is 'showHtml'
  useEffect(() => {
    if (show !== undefined) {
      setHtmlOn(show);
    } else {
      setHtmlOn(false);
      setShowHtml(false);
    }
  }, [show, appState.currentPath, setShowHtml]);

  useEffect(() => {
    appState.htmlSaved ? setDisableToggle(false) : setDisableToggle(true);
    appState.hideView && appState.hideView !== null
      ? setDisableToggle(true)
      : setDisableToggle(false);

    if (appState.hideView == "default" && num === 1) {
      setHtmlOn(false);
      setShowHtml(false);
    } else if (appState.hideView == "edit") {
      setHtmlOn(true);
      setShowHtml(true);
    }
  }, [appState.htmlSaved, appState.hideView, num]);

  //must set local setHtmlOn for toggle display, setShowHtml tells parent wrapper to switch to iframe or not.
  const handleToggle = (e) => {
    e.preventDefault();
    if (appState.htmlSaved == false) {
      return;
    }
    // console.log(num);
    if (htmlOn === true) {
      // console.log("got its on");
      setShowHtml(false);
      setHtmlOn(false);
    } else {
      // console.log("got its off");
      setShowHtml(true);
      setHtmlOn(true);
    }
  };

  return (
    // <div className="l-viewerPanels__controls__option">
    <div>
      <label
        className={`c-toggle${disableToggle ? " c-toggle--disabled tooltip--top-right" : ""}`}
        htmlFor={"toggleSwitch" + num}
        onClick={handleToggle}
        aria-label='Toggle is disabled until you save as HTML or remove the "hide" setting.'
      >
        <input
          type="checkbox"
          className="c-toggle__checkbox"
          name={"toggleSwitch" + num}
          id={"toggleSwitch" + num}
          onChange={() => {
            null;
          }}
          checked={htmlOn}
          // defaultChecked={htmlOn}
        />
        <div className="c-toggle__button" />
        <div className="c-toggle__labels">
          <span>{option1 || "React"}</span>
          <span>{option2 || "HTML"}</span>
        </div>
      </label>
    </div>
  );
};

export default C_UseHTMLToggle;
