/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Headline = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let headingVars = {
      ...appState,
      currentPath: "/ui/headline",
      outputName: "Headline",
      headerName: "Headline",
      tags: null,
      description:
        "The header section serves as a grand headline for a product or topic with a single line and no subtitles. This section is intended to be the first section of any template with related content in the sections that follow.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "../../js/headline.component.js"],
      specs: [
        ["Headline Text", ["Modified style text, uses the P tag. Javascript sets the size."]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1cTvPozugXtndLV6IAKdfBdDXDT5ztFswiW2XS0rSClA/edit?usp=sharing",
        comments: null,
      },
    };
    setAppState(headingVars);

    setContentOptions({
      heading1: {
        label: "Headline",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Titular",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Gros titre",
          It: "Titolo",
          Pl: "Nagłówek",
          Port: "Título",
          Viet: "Tiêu đề",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "พาดหัว",
          Kor: "표제",
          ChT: "標題",
          Jp: "見出し",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "168" });
  }, []);

  if (!contentOptions) return "...Loading Heading";
  return (
    <section className={`s-headline ` + appState.currentTheme}>
      <div className="c-headline">
        <p>
          {contentOptions.heading1 && contentOptions.heading1.field[contentOptions.currentLang]}
        </p>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
export default {
  path: "headline",
  component: S_Headline,
  navtxt: "Headline",
  htmlName: "Headline",
  categoryType: ["misc"],
};
