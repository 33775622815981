const C_Swatch = ({
  type,
  name,
  classname,
  subtitle,
  px,
  em,
  weight,
  color,
  bgColor,
  height,
  width,
  direction,
  extrattr,
}) => {
  return (
    <div className={`c-swatch ${classname}`}>
      <div
        className="c-swatch__display"
        style={{
          flexDirection: `${type == "Line height" || type == "Font Margin" ? "column" : ""}`,
          alignItems: `${
            type == "Margin"
              ? "center"
              : type == "Line height" || type == "Font Margin"
              ? "initial"
              : "flex-end"
          }`,
          justifyContent: `${
            type == "Gutter" || type == "Line height"
              ? "center"
              : type == "Font Margin"
              ? "flex-end"
              : ""
          }`,
          gap: `${type == "Gutter" && em ? em : ""}`,
          backgroundColor: `${bgColor ? bgColor : ""}`,
          border: `${type == "Shape" || name == "Border" ? "1px solid rgba(0, 0, 0, 0.1)" : ""}`,
          borderRadius: `${
            type == "Shape"
              ? `${
                  name == "Droplet"
                    ? `100% 100% 100% ${em}`
                    : `${name == "Round/Pill" ? "100%" : em}`
                }`
              : "1px"
          }`,
          boxShadow: `${name == "Shadow" ? "0px 0px 8px 0px rgba(0, 0, 0, 0.1)" : ""}`,
        }}
      >
        {/*Font*/}
        {type == "Font" ? (
          name == "<p> Font" ? (
            <p>p</p>
          ) : name == "Mega Font" ? (
            <strong className="u-txt-mega">M</strong>
          ) : name == "<h1> Font" ? (
            <h1>h1</h1>
          ) : name == "<h2> Font" ? (
            <h2>h2</h2>
          ) : name == "<h3> Font" ? (
            <h3>h3</h3>
          ) : name == "<h4> Font" ? (
            <h4>h4</h4>
          ) : name == "<h5> Font" ? (
            <h5>h5</h5>
          ) : (
            <h6>h6</h6>
          )
        ) : (
          ""
        )}

        {/*Line Height*/}
        {type == "Line height" ? (
          name == "<p> Spacing" ? (
            <>
              <p>p</p>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <p>p</p>
            </>
          ) : name == "Mega Spacing" ? (
            <>
              <strong className="u-txt-mega">M</strong>
              <div
                style={{
                  height: "6px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <strong className="u-txt-mega">M</strong>
            </>
          ) : name == "<h1> Spacing" ? (
            <>
              <h1>h1</h1>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h1>h1</h1>
            </>
          ) : name == "<h2> Spacing" ? (
            <>
              <h2>h2</h2>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h2>h2</h2>
            </>
          ) : name == "<h3> Spacing" ? (
            <>
              <h3>h3</h3>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h3>h3</h3>
            </>
          ) : name == "<h4> Spacing" ? (
            <>
              <h4>h4</h4>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h4>h4</h4>
            </>
          ) : name == "<h5> Spacing" ? (
            <>
              <h5>h5</h5>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h5>h5</h5>
            </>
          ) : (
            <>
              <h6>h6</h6>
              <div
                style={{
                  height: "13px",
                  width: "100%",
                  backgroundColor: "#FF5EFD",
                }}
              ></div>
              <h6>h6</h6>
            </>
          )
        ) : (
          ""
        )}

        {/*Margin*/}
        {type == "Margin" ? (
          <div
            style={{
              height: `${direction == "vertical" ? "100%" : "80%"}`,
              width: `${direction == "vertical" ? "80%" : "100%"}`,
              backgroundColor: "#D8D8D8",
              margin: "0 auto",
            }}
          ></div>
        ) : (
          ""
        )}

        {/*Gutters*/}
        {type == "Gutter" ? (
          <>
            <div
              style={{
                height: "100%",
                width: `${em == "1em" ? "15%" : "10%"}`,
                backgroundColor: "#5EE9FF",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                width: `${em == "1em" ? "15%" : "10%"}`,
                backgroundColor: "#5EE9FF",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                width: `${em == "1em" ? "15%" : "10%"}`,
                backgroundColor: "#5EE9FF",
              }}
            ></div>
          </>
        ) : (
          ""
        )}

        {/*Font Margin*/}
        {type == "Font Margin" ? (
          name == "<p> Margin" ? (
            <>
              <p>p</p>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "Mega Margin" ? (
            <>
              <strong className="u-txt-mega">M</strong>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "<h1> Margin" ? (
            <>
              <h1>h1</h1>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "<h2> Margin" ? (
            <>
              <h2>h2</h2>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "<h3> Margin" ? (
            <>
              <h3>h3</h3>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "<h4> Margin" ? (
            <>
              <h4>h4</h4>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : name == "<h5> Margin" ? (
            <>
              <h5>h5</h5>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          ) : (
            <>
              <h6>h6</h6>
              <div
                style={{
                  height: "16px",
                  width: "100%",
                  backgroundColor: "#FFEB5E",
                }}
              ></div>
            </>
          )
        ) : (
          ""
        )}

        {/*Breakpoints*/}
        {type == "Breakpoint" ? (
          <div
            style={{
              height: "100%",
              width: "90%",
              backgroundColor: "#D8D8D8",
              margin: "0 auto",
            }}
          ></div>
        ) : (
          ""
        )}

        {/*Animations*/}
        {type == "Animation" ? (
          <div
            style={{
              // height: `${
              //   name == "Transition speed (Slow)"
              //     ? "90%"
              //     : name == "Transition speed (Normal)"
              //     ? "70%"
              //     : "50%"
              // }`,
              // width: `${
              //   name == "Transition speed (Slow)"
              //     ? "90%"
              //     : name == "Transition speed (Normal)"
              //     ? "70%"
              //     : "50%"
              // }`,
              backgroundColor: "#D8D8D8",
              marginLeft: "auto",
            }}
          ></div>
        ) : (
          ""
        )}

        {/*Placeholders*/}
        {type == "Placeholder" ? (
          name == "<p> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <p>p</p>
            </div>
          ) : name == "Mega Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <strong className="u-txt-mega">M</strong>
            </div>
          ) : name == "<h1> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h1>h1</h1>
            </div>
          ) : name == "<h2> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h2>h2</h2>
            </div>
          ) : name == "<h3> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h3>h3</h3>
            </div>
          ) : name == "<h4> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h4>h4</h4>
            </div>
          ) : name == "<h5> Size" ? (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "24px",
                  width: "24px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h5>h5</h5>
            </div>
          ) : (
            <div className="c-iconcopy">
              <svg
                viewBox="0 0 16 16"
                style={{
                  height: "24px",
                  width: "24px",
                }}
              >
                <path
                  d="M4.988 1a2 2 0 012 2v1.011h2.007V3a2 2 0 012-2H13a2 2 0 012 2v8a4 4 0 01-4 4H5a4 4 0 01-4-4V3a2 2 0 012-2h1.988zm0 1H3a1 1 0 00-.993.883L2 3v8a3 3 0 002.824 2.995L5 14h6a3 3 0 002.995-2.824L14 11V3a1 1 0 00-.883-.993L13 2h-2.005a1 1 0 00-.993.883L9.995 3v1.011a1 1 0 01-.883.994l-.117.006H6.988a1 1 0 01-.993-.883l-.007-.117V3a1 1 0 00-.884-.993L4.988 2zm1.598 5.379L8 8.793l1.414-1.414a.5.5 0 11.707.707L8.707 9.5l1.414 1.414a.5.5 0 11-.707.707L8 10.206l-1.413 1.415a.5.5 0 11-.707-.707L7.292 9.5 5.88 8.086a.5.5 0 11.707-.707zM4 3a1 1 0 110 2 1 1 0 010-2zm8 0a1 1 0 110 2 1 1 0 010-2z"
                  fill="#353535"
                />
              </svg>
              <h6>h6</h6>
            </div>
          )
        ) : (
          ""
        )}
      </div>

      {name ? <strong style={{ fontSize: "1.25em" }}>{name}</strong> : ""}

      {subtitle ? (
        <>
          <br /> <strong>{subtitle}</strong>
        </>
      ) : (
        ""
      )}

      {px || em ? (
        <>
          <br />
          <strong>
            {px} / {em}
          </strong>
        </>
      ) : (
        ""
      )}

      {weight ? (
        <>
          <br />
          <strong>{weight} weight</strong>
        </>
      ) : (
        ""
      )}

      {color ? (
        <>
          <br />
          <strong>{color}</strong>
        </>
      ) : (
        ""
      )}

      {height || width ? (
        <>
          {" "}
          <br /> <strong>{width}</strong>
          <br /> <strong>{height}</strong>
        </>
      ) : (
        ""
      )}

      {extrattr ? (
        <>
          <br />
          <strong>{extrattr}</strong>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default C_Swatch;
