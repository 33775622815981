export default {
  numLinks: {
    label: "Number of Links",
    checkbox: null,
    field: [1, 5, 10, 15],
    selected: 5,
  },

  linkText1: {
    label: "Link 1 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText2: {
    label: "Link 2 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText3: {
    label: "Link 3 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText4: {
    label: "Link 4 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText5: {
    label: "Link 5 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText6: {
    label: "Link 6 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText7: {
    label: "Link 7 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText8: {
    label: "Link 8 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText9: {
    label: "Link 9 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText10: {
    label: "Link 10 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText11: {
    label: "Link 11 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText12: {
    label: "Link 12 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText13: {
    label: "Link 13 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  linkText14: {
    label: "Link 14 Text",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
    charLimit: 50,
  },

  linkText15: {
    label: "Link 15 Text",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Presentada",
      ChS: "精选",
      Gr: "Hervorgehoben",
      Ru: "Избранное",
      Fr: "Mis en exergue",
      It: "In primo piano",
      Pl: "Zobacz więcej",
      Port: "Apresentou",
      Viet: "Đặc sắc",
      Turk: "Öne Çıkanlar",
      Ukrn: "Рекомендовані",
      Thai: "แนะนำ",
      Kor: "추천",
      ChT: "精選",
      Jp: "特徴",
    },
    charLimit: 50,
  },

  currentLang: "Eng",
};
