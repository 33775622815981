export default {
  id: "blog",
  title1: {
    name: "title1",
    label: "Title 1",
    checkbox: null,
    field: {
      Eng: "300x200 Image Example",
      Span: "Ejemplo de imagen de 300x200",
      ChS: "300x200 图像示例",
      Gr: "Beispielbild 300x200",
      Ru: "Пример изображения 300x200",
      Fr: "Exemple d'image 300 x 200",
      It: "Esempio di immagine 300x200",
      Pl: "Przykład obrazu 300x200",
      Port: "Exemplo de imagem 300 x 200",
      Viet: "Ví dụ hình ảnh 300x200",
      Turk: "300x200 Resim Örneği",
      Ukrn: "Приклад зображення 300x200",
      Thai: "ตัวอย่างภาพขนาด 300x200",
      Kor: "300x200 이미지 예",
      ChT: "300x200 圖像示例",
      Jp: "300x200 の画像の例",
    },
  },

  copy1: {
    name: "copy1",
    label: "Copy 1",
    checkbox: null,
    field: {
      Eng: "Consectetur adipiscing elit, sed do eiusmod.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
    },
  },
  // title2: {
  //   name: "title2",
  //   label: "Title 2",
  //   checkbox: null,
  //   field: "384x144 Image Example",
  // },
  // title3: {
  //   name: "title3",
  //   label: "Title 3",
  //   checkbox: null,
  //   field: "128x120 Image Example",
  // },
  // title4: {
  //   name: "title4",
  //   label: "Title 4",
  //   checkbox: null,
  //   field:
  //     "720x405 Image Example with very long title that will push down any text in the content below, and it goes on and on and is really longer than a normal headline.",
  // },
  title5: {
    name: "title5",
    label: "Title 5",
    checkbox: null,
    field: {
      Eng: "384x144 Image Example",
      Span: "Ejemplo de imagen de 384x144",
      ChS: "384x1440 图像示例",
      Gr: "Beispielbild 384x144",
      Ru: "Пример изображения 384x144",
      Fr: "Exemple d'image 384x144",
      It: "Esempio di immagine 384x144",
      Pl: "Przykład obrazu 384x144",
      Port: "Exemplo de imagem 384x144",
      Viet: "Ví dụ hình ảnh 384x144",
      Turk: "384x144 Resim Örneği",
      Ukrn: "Приклад зображення 384x144",
      Thai: "ตัวอย่างภาพขนาด 384x144",
      Kor: "384x144 이미지 예",
      ChT: "384x144 圖像示例",
      Jp: "384x144 の画像の例",
    },
  },
  copy5: {
    name: "copy5",
    label: "Copy 5",
    checkbox: null,
    field: {
      Eng: "Consectetur adipiscing elit, sed do eiusmod.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
    },
  },
  // title6: {
  //   name: "title6",
  //   label: "Title 6",
  //   checkbox: null,
  //   field: "128x120 Image Example",
  // },
  // title7: {
  //   name: "title7",
  //   label: "Title 7",
  //   checkbox: null,
  //   field: "720x405 Image Example",
  // },
  // title8: {
  //   name: "title8",
  //   label: "Title 8",
  //   checkbox: null,
  //   field:
  //     "384x144 Image Example with some extra text so that you can see what three lines of headline looks like.",
  // },
  // title9: { name: "title9", label: "Title 9", checkbox: null, field: "Blog Heading 9" },
  // title10: { name: "title10", label: "Title 10", checkbox: null, field: "Blog Heading 10" },
  // title11: { name: "title11", label: "Title 11", checkbox: null, field: "Blog Heading 11" },
  // title12: { name: "title12", label: "Title 12", checkbox: null, field: "Blog Heading 12" },
  // btnTxt: { name: "btnTxt", label: "Button Text", checkbox: null, field: "More" },
  currentLang: "Eng",
};
