/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import shortcutLinksOpts from "./shortcutLinksOpts";

const S_ShortcutLinks = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let lVars = {
      ...appState,
      currentPath: "/ui/shortcut-links",
      currentTheme: "t-white",
      outputName: "ShortcutLinks",
      headerName: "Shortcut Links",
      tags: null,
      description:
        "The shortcut links section provides a quick and simple way to layout links across the section.",
      related: null,
      htmlSaved: true,
      scripts: [],
      specs: [["Link", ["String text within an anchor tag. Character Limit: 50."]]],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ESyuLftlGqNHs5595rt5ZXgBHhnDWLuEUjUMOuP1fdRYjg?e=C7kgop",
        comments: null,
      },
    };
    setAppState(lVars);
    setContentOptions(shortcutLinksOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "72",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Shortcut Links";
  return (
    <section className={"s-shortcutLinks " + appState.currentTheme}>
      <div className="l-inner">
        <ul className="s-shortcutLinks__nav u-list-unstyled">
          {contentOptions.numLinks && contentOptions.numLinks.selected >= 1 ? (
            <>
              {contentOptions.linkText1 && contentOptions.linkText1.checkbox == true ? (
                <li>
                  <a href="#link1" title="Anchor Title">
                    {contentOptions.linkText1.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {contentOptions.numLinks && contentOptions.numLinks.selected >= 5 ? (
            <>
              {contentOptions.linkText2 && contentOptions.linkText2.checkbox == true ? (
                <li>
                  <a href="#link2" title="Anchor Title">
                    {contentOptions.linkText2.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText3 && contentOptions.linkText3.checkbox == true ? (
                <li>
                  <a href="#link3" title="Anchor Title">
                    {contentOptions.linkText3.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText4 && contentOptions.linkText4.checkbox == true ? (
                <li>
                  <a href="#link4" title="Anchor Title">
                    {contentOptions.linkText4.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText5 && contentOptions.linkText5.checkbox == true ? (
                <li>
                  <a href="#link5" title="Anchor Title">
                    {contentOptions.linkText5.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {contentOptions.numLinks && contentOptions.numLinks.selected >= 10 ? (
            <>
              {contentOptions.linkText6 && contentOptions.linkText6.checkbox == true ? (
                <li>
                  <a href="#link6" title="Anchor Title">
                    {contentOptions.linkText6.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.linkText7 && contentOptions.linkText7.checkbox == true ? (
                <li>
                  <a href="#link7" title="Anchor Title">
                    {contentOptions.linkText7.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText8 && contentOptions.linkText8.checkbox == true ? (
                <li>
                  <a href="#link8" title="Anchor Title">
                    {contentOptions.linkText8.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText9 && contentOptions.linkText9.checkbox == true ? (
                <li>
                  <a href="#link9" title="Anchor Title">
                    {contentOptions.linkText9.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText10 && contentOptions.linkText10.checkbox == true ? (
                <li>
                  <a href="#link10" title="Anchor Title">
                    {contentOptions.linkText10.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {contentOptions.numLinks && contentOptions.numLinks.selected == 15 ? (
            <>
              {contentOptions.linkText11 && contentOptions.linkText11.checkbox == true ? (
                <li>
                  <a href="#link11" title="Anchor Title">
                    {contentOptions.linkText11.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.linkText12 && contentOptions.linkText12.checkbox == true ? (
                <li>
                  <a href="#link12" title="Anchor Title">
                    {contentOptions.linkText12.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText13 && contentOptions.linkText13.checkbox == true ? (
                <li>
                  <a href="#link13" title="Anchor Title">
                    {contentOptions.linkText13.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText14 && contentOptions.linkText14.checkbox == true ? (
                <li>
                  <a href="#link14" title="Anchor Title">
                    {contentOptions.linkText14.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.linkText15 && contentOptions.linkText15.checkbox == true ? (
                <li>
                  <a href="#link15" title="Anchor Title">
                    {contentOptions.linkText15.field[contentOptions.currentLang]}
                  </a>
                </li>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "shortcut-links",
  component: S_ShortcutLinks,
  navtxt: "Shortcut Links",
  htmlName: "ShortcutLinks",
  categoryType: ["web"],
};
