export default {
  numLinks: {
    label: "Number of Links",
    checkbox: null,
    field: ["1", "2", "3", "4", "5", "6"],
    selected: "6",
  },
  optNoCopy: { label: "Copies [Optional]", checkbox: true, field: null },
  optIcons: { label: "Icons [Optional and see CIS tab]", checkbox: false, field: null },

  card1: [
    {
      label: "#1 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #1",
        Span: "Título 1",
        ChS: "标题 1",
        Gr: "Titel 1",
        Ru: "Заголовок 1",
        Fr: "Titre 1",
        It: "Titolo 1",
        Pl: "Tytuł 1",
        Port: "Título 1",
        Viet: "Tiêu đề 1",
        Turk: "Başlık 1",
        Ukrn: "Назва 1",
        Thai: "ชื่อ 1",
        Kor: "제목 1",
        ChT: "標題 1",
        Jp: "タイトル 1",
      },
      block: 1,
      charLimit: 50,
    },
    {
      label: "#1 Icon",
      checkbox: null,
      field: "#pdf",
      block: 1,
    },
    {
      label: "#1 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #1",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 1,
      charLimit: 100,
    },
  ],

  card2: [
    {
      label: "#2 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #2",
        Span: "Título 2",
        ChS: "标题 2",
        Gr: "Titel 2",
        Ru: "Заголовок 2",
        Fr: "Titre 2",
        It: "Titolo 2",
        Pl: "Tytuł 2",
        Port: "Título 2",
        Viet: "Tiêu đề 2",
        Turk: "Başlık 2",
        Ukrn: "Назва 2",
        Thai: "ชื่อ 2",
        Kor: "제목 2",
        ChT: "標題 2",
        Jp: "タイトル 2",
      },
      block: 2,
      charLimit: 50,
    },
    {
      label: "#2 Icon",
      checkbox: null,
      field: "#download",
      block: 2,
    },
    {
      label: "#2 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #2",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 2,
      charLimit: 100,
    },
  ],

  card3: [
    {
      label: "#3 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #3",
        Span: "Título 3",
        ChS: "标题 3",
        Gr: "Titel 3",
        Ru: "Заголовок 3",
        Fr: "Titre 3",
        It: "Titolo 3",
        Pl: "Tytuł 3",
        Port: "Título 3",
        Viet: "Tiêu đề 3",
        Turk: "Başlık 3",
        Ukrn: "Назва 3",
        Thai: "ชื่อ 3",
        Kor: "제목 3",
        ChT: "標題 3",
        Jp: "タイトル 3",
      },
      block: 3,
      charLimit: 50,
    },
    {
      label: "#3 Icon",
      checkbox: null,
      field: "#pcn",
      block: 3,
    },
    {
      label: "#3 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #3",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 3,
      charLimit: 100,
    },
  ],
  card4: [
    {
      label: "#4 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #4",
        Span: "Título 4",
        ChS: "标题 4",
        Gr: "Titel 4",
        Ru: "Заголовок 4",
        Fr: "Titre 4",
        It: "Titolo 4",
        Pl: "Tytuł 4",
        Port: "Título 4",
        Viet: "Tiêu đề 4",
        Turk: "Başlık 4",
        Ukrn: "Назва 4",
        Thai: "ชื่อ 4",
        Kor: "제목 4",
        ChT: "標題 4",
        Jp: "タイトル 4",
      },
      block: 4,
      charLimit: 50,
    },
    {
      label: "#4 Icon",
      checkbox: null,
      field: "#warranty-shield",
      block: 4,
    },
    {
      label: "#4 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #4",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 4,
      charLimit: 100,
    },
  ],

  card5: [
    {
      label: "#5 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #5",
        Span: "Título 5",
        ChS: "标题 5",
        Gr: "Titel 5",
        Ru: "Заголовок 5",
        Fr: "Titre 5",
        It: "Titolo 5",
        Pl: "Tytuł 5",
        Port: "Título 5",
        Viet: "Tiêu đề 5",
        Turk: "Başlık 5",
        Ukrn: "Назва 5",
        Thai: "ชื่อ 5",
        Kor: "제목 5",
        ChT: "標題 5",
        Jp: "タイトル 5",
      },
      block: 5,
      charLimit: 50,
    },
    {
      label: "#5 Icon",
      checkbox: null,
      field: "#pdf",
      block: 5,
    },
    {
      label: "#5 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #5",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 5,
      charLimit: 100,
    },
  ],
  card6: [
    {
      label: "#6 Title",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum #6",
        Span: "Título 6",
        ChS: "标题 6",
        Gr: "Titel 6",
        Ru: "Заголовок 6",
        Fr: "Titre 6",
        It: "Titolo 6",
        Pl: "Tytuł 6",
        Port: "Título 6",
        Viet: "Tiêu đề 6",
        Turk: "Başlık 6",
        Ukrn: "Назва 6",
        Thai: "ชื่อ 6",
        Kor: "제목 6",
        ChT: "標題 6",
        Jp: "タイトル 6",
      },
      block: 6,
      charLimit: 50,
    },
    {
      label: "#6 Icon",
      checkbox: null,
      field: "#brilliant-lighting",
      block: 6,
    },
    {
      label: "#6 Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet #6",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 6,
      charLimit: 100,
    },
  ],
  currentLang: "Eng",
};
