import { useContext, useEffect, useState } from "react";
// import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../AppContext";
// import ToastContext from "../../../ToastContext";

import dotenv from "dotenv";

dotenv.config();
var server = process.env.API_URL;

//TODO: move call function that calls fetch out of useEffect
//passed showHtml from IframeWrapper to Tools to here. If showing html then disable button
const C_HTMLExportBtn = ({ show }) => {
  const { appState, setAppState } = useContext(AppContext);
  const [callingApi, setCallingApi] = useState(false);

  let string = appState.htmlString;

  // const { toastOptions, setToastOptions } = useContext(ToastContext);

  function callSaveApi() {
    //tells iframeWrapper to get string
    setAppState({
      ...appState,
      flatHTML: true,
    });

    //this is the same as below but needed if for users hitting save twice
    if (string && string.length > 1) {
      //api is running
      setCallingApi(true);
    }
  }

  // const notify = () => {
  //   toast(toastOptions.text, {
  //     position: "bottom-center",
  //     autoClose: 3000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: false,
  //     progress: undefined,
  //   });
  //   // reset context to empty obj
  //   setToastOptions(null);
  // };
  //need this as separate function bc string can take a while to load
  useEffect(() => {
    if (string && string.length > 1) {
      //api is running
      setCallingApi(true);
    }
  }, [string]);

  //triggered by listening for setCallingApi set to true
  useEffect(() => {
    //not great to put fetch in useEffect but wasn't working if pulled out
    const path = server + "/api/output-html";
    const data = {
      fileName: appState.outputName,
      htmlString: appState.htmlString,
      scripts: appState.scripts,
    };
    if (callingApi) {
      fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res && res.statusText == "OK") {
            // setToastOptions({ text: "HTML File was output." });
            // notify();
            alert("Files saved");
            // console.log("page saved");
            setCallingApi(false);
          } else {
            // throw res;
            // console.log("res.statusText: ", res.statusText);
            alert("There was a problem. Check the static directory for missing files.");
          }
        })
        .catch((error) => {
          // console.log("inside fetch catch error");
          console.log(error);
          setCallingApi(false);
          // setToastOptions({ text: "There was a problem outputting the HTML file." });
          // notify();
        });
    }
  }, [callingApi]);

  return (
    <div className="l-viewerPanels__controls__option u-pl u-pr">
      <h6>Output html file after React edits</h6>
      <button
        className="v-tool__btn v-pill"
        onClick={() => callSaveApi()}
        disabled={show}
        alt={
          show
            ? "You must be in React mode to save html."
            : "Output flat html and JS file after edits."
        }
        title={
          show
            ? "You must be in React mode to save html."
            : "Output flat html and JS file after edits."
        }
      >
        Create Static View
      </button>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default C_HTMLExportBtn;
