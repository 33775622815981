/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";

const S_Image = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  // These are all states that are needed for sections with picture tags.
  const [phone, setPhone] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [desktop, setDesktop] = useState(null);

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/image",
      outputName: "Image",
      headerName: "Image",
      tags: ["Landing Page Approved"],
      description:
        "The Image Section displays an image with nothing overlaying it. 3 sizes (mobile, tablet and desktop) are required, and display is full width. *Note: Copy and paste https://via.placeholder.com/512x700 into any of the fields if you want to demo different sizes. *Another Note: Drag and Drop works when Small: width less than 512px, Medium: width is 512px to 1024px, Large: width more than 1024px",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Multiple Images (mobile, tablet, desktop)",
          [
            "*1 image required (Can use up to 3 images)",
            "Small, Medium, and Large: Width and Height: any size",
          ],
        ],
        [
          "Image Type",
          [
            "1 Image: No limitation to file formation recommendation",
            "2 Images: JPG",
            "3 Images: JPG",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1_Dbz83klTj6x3Q47-7A1W_7NxANV926fxU6ckubcl6Q/edit?usp=share_link",
        comments: [
          [
            "Support Related Pages:",
            ["Small: 512px x 512px", "Medium: 1024px x 450px", "Large: 2048px x 600px"],
          ],
        ],
      },
    };
    setAppState(fbVars);
    setContentOptions({
      fullwidth: {
        label: "Enable Full Width [Modifier]",
        checkbox: true,
        field: null,
      },
      smallimage: {
        label: "Small Image",
        checkbox: null,
        field: "/images/fpo/general/512x512.png",
      },
      mediumimage: {
        label: "Medium Image",
        checkbox: null,
        field: "/images/fpo/general/1024x450.png",
      },
      largeimage: {
        label: "Large Image",
        checkbox: null,
        field: "/images/fpo/general/2048x600.png",
      },
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "700" });
  }, []);

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading Image";
  return (
    <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
      <section
        className={`s-image ${
          contentOptions.fullwidth && contentOptions.fullwidth.checkbox == true
            ? "s-image--fullWidth"
            : ""
        }`}
      >
        <picture className="e-picture" id="js-picture">
          <source
            srcSet={
              phone && phone.phone
                ? phone.phone
                : contentOptions.smallimage && contentOptions.smallimage.field
            }
            media="(max-width: 32em)"
          />
          <source
            srcSet={
              desktop && desktop.desktop
                ? desktop.desktop
                : contentOptions.largeimage && contentOptions.largeimage.field
            }
            media="(min-width: 64em)"
          />
          <img
            src={
              tablet && tablet.tablet
                ? tablet.tablet
                : contentOptions.mediumimage && contentOptions.mediumimage.field
            }
            alt="Alternate Text"
          />
        </picture>
      </section>
    </DropZone>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
export default {
  path: "image",
  component: S_Image,
  navtxt: "Image",
  htmlName: "Image",
  categoryType: ["landing page", "web"],
};
