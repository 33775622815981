import { useContext, useEffect, useState } from "react";
import OptionsContext from "../../../../../OptionsContext";

const Field = ({ optionsState, option, handleCB, handleField }) => {
  const { contentOptions } = useContext(OptionsContext);
  const [count, setCount] = useState(0);
  const [countColor, setCountColor] = useState("green");

  useEffect(() => {
    // do setCount here somehow after contentOptions updates
    // if option label or something matches one in contentOptions use that for character count
    // setCount(e.target.value.length);
    let txt =
      option && option.field && option.field[optionsState.currentLang]
        ? option.field[optionsState.currentLang]
        : option.field;
    option && option.field && setCount(txt.length);
  }, [contentOptions]);

  useEffect(() => {
    option && option.charLimit >= count ? setCountColor("green") : setCountColor("#c8102e");
  }, [option, count]);

  return (
    <div className="c-editContent__field">
      <label
        style={{
          color: "white",
        }}
        className="c-contentOptions__label"
      >
        {/* ie. options[title1].checkbox. Allows to have checkbox prechecked, or even none shown */}
        {option && option.checkbox !== null ? (
          <input
            type="checkbox"
            name={option.label}
            onChange={(e) => handleCB(e)}
            checked={option.checkbox}
            className="c-contentOptions__input"
          />
        ) : (
          ""
        )}
        <span
          style={{
            margin: "0 0.25em 0 0",
          }}
        >
          {/* checks for existing option label - property like 'currentLang' doesn't have this */}
          {option && option.label}
        </span>
        {/* checks for existing option field - property like 'currentLang' doesn't have this */}
        {option && option.field !== null ? (
          option.field && typeof option.field == "object" && !Array.isArray(option.field) ? (
            <>
              <input
                style={{
                  display: option.checkbox === true || option.checkbox == null ? "block" : "none",
                }}
                type="text"
                name={option.label}
                // maxLength={option.charLimit || "524288"}
                maxLength={"524288"}
                onChange={(e) => {
                  handleField(e);
                  // setCount(e.target.value.length);
                }}
                //display the text for the current selected language to start
                placeholder={option.field[optionsState.currentLang] || ""}
                value={option.field[optionsState.currentLang] || ""}
                className="c-editContent__input"
              />
              {option.charLimit && (
                <div
                  style={{
                    color: countColor,
                    marginTop: "4px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "right",
                    display: option.checkbox === true || option.checkbox == null ? "block" : "none",
                  }}
                >
                  {count}/{option.charLimit}
                </div>
              )}
            </> // This should just be a regular text field
          ) : option !== "currLang" ? (
            <>
              <input
                style={{
                  display: option.checkbox === true || option.checkbox == null ? "block" : "none",
                }}
                type="text"
                name={option.label}
                // maxLength={option.charLimit || "524288"}
                maxLength={"524288"}
                onChange={(e) => handleField(e)}
                placeholder={option.field}
                className="c-editContent__input"
              />
              {/* {option.charLimit && (
                <div
                  style={{
                    color: countColor,
                    marginTop: "4px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "right",
                    display: option.checkbox === true || option.checkbox == null ? "block" : "none",
                  }}
                >
                  {count}/{option.charLimit}
                </div>
              )} */}
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </label>
    </div>
  );
};

export default Field;
