import React from "react";

import C_LandingLarge from "./C_LandingLarge";

// let brandImg = "/images/fpo/general/1200x800.png";
// let webImg = "/images/fpo/general/1200x800.png";
// let thirdImg = "/images/fpo/general/780x675.png";

let brandImg = "/images/fpo/homepage/brand-guidelines.png";
let webImg = "/images/fpo/homepage/web-guidelines.png";
let uxuiImg = "/images/fpo/homepage/uxui-guide.png";
let iconImg = "/images/fpo/homepage/icon-guide.png";
let landingImg = "/images/fpo/homepage/landing-pages.png";

const S_LandingGates = () => {
  return (
    <div className="s-landingGates u-animated u-animated--delayFast u-animated--slowest a-fadeIn">
      <div className="s-landingGates__row">
        <C_LandingLarge Img={brandImg} title="Brand Guidelines" link={"/brand-guidelines"} />
        <C_LandingLarge Img={webImg} title="Web Guidelines" link={"/web-ui"} />
      </div>
      <div className="s-landingGates__row">
        <C_LandingLarge Img={uxuiImg} title="UX/UI Guide" link={"/uiux"} />
        <C_LandingLarge Img={iconImg} title="Icon Guide" link={"/icon-guide"} />
        <C_LandingLarge Img={landingImg} title="Landing Pages" disabled={true} link={"#"} />
      </div>
    </div>
  );
};

export default S_LandingGates;
