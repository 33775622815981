export default {
  numFeatures: {
    label: "Num of items:",
    checkbox: null,
    field: ["1", "2", "3", "4", "5", "6", "7", "8"],
    selected: "3",
  },
  card1: [
    {
      name: "title1",
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "300x200 Lorem Ipsum",
        Span: "Ejemplo de imagen de 300x200",
        ChS: "300x200 图像示例",
        Gr: "Beispielbild 300x200",
        Ru: "Пример изображения 300x200",
        Fr: "Exemple d'image 300 x 200",
        It: "Esempio di immagine 300x200",
        Pl: "Przykład obrazu 300x200",
        Port: "Exemplo de imagem 300 x 200",
        Viet: "Ví dụ hình ảnh 300x200",
        Turk: "300x200 Resim Örneği",
        Ukrn: "Приклад зображення 300x200",
        Thai: "ตัวอย่างภาพขนาด 300x200",
        Kor: "300x200 이미지 예",
        ChT: "300x200 圖像示例",
        Jp: "300x200 の画像の例",
      },
      block: 1,
    },
    {
      name: "tag1",
      label: "Article Tag 1",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
      block: 1,
    },
    {
      name: "tag2",
      label: "Article Tag 2",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
      block: 1,
    },
    {
      name: "tag3",
      label: "Article Tag 3",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
      block: 1,
    },
    {
      name: "copy1",
      label: "Description 1",
      checkbox: null,
      field:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      block: 1,
    },
  ],

  title2: {
    name: "title2",
    label: "Title 2",
    checkbox: null,
    field: {
      Eng: "384x144 Lorem Ipsum",
      Span: "Ejemplo de imagen de 384x144",
      ChS: "384x1440 图像示例",
      Gr: "Beispielbild 384x144",
      Ru: "Пример изображения 384x144",
      Fr: "Exemple d'image 384x144",
      It: "Esempio di immagine 384x144",
      Pl: "Przykład obrazu 384x144",
      Port: "Exemplo de imagem 384x144",
      Viet: "Ví dụ hình ảnh 384x144",
      Turk: "384x144 Resim Örneği",
      Ukrn: "Приклад зображення 384x144",
      Thai: "ตัวอย่างภาพขนาด 384x144",
      Kor: "384x144 이미지 예",
      ChT: "384x144 圖像示例",
      Jp: "384x144 の画像の例",
    },
    block: 2,
  },
  title3: {
    name: "title3",
    label: "Title 3",
    checkbox: null,
    field: {
      Eng: "720x405 Lorem Ipsum",
      Span: "Ejemplo de imagen de 720x405",
      ChS: "720x405 图像示例",
      Gr: "Beispielbild 720x405",
      Ru: "Пример изображения 720x405",
      Fr: "Exemple d'image 720x405",
      It: "Esempio di immagine 720x405",
      Pl: "Przykład obrazu 720x405",
      Port: "Exemplo de imagem 720x405",
      Viet: "Ví dụ hình ảnh 720x405",
      Turk: "720x405 Resim Örneği",
      Ukrn: "Приклад зображення 720x405",
      Thai: "ตัวอย่างภาพขนาด 720x405",
      Kor: "720x405 이미지 예",
      ChT: "720x405 圖像示例",
      Jp: "720x405 の画像の例",
    },
  },
  title4: {
    name: "title4",
    label: "Title 4",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Hacía calo",
      ChS: "那个天气很热",
      Gr: "Tochter; es hieß",
      Ru: "Лорем ипсум долор",
      Fr: "Il faisait chaud",
      It: "Faceva caldo con",
      Pl: "Było gorąco w",
      Port: "Fazia calor",
      Viet: "Thời tiết đó",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  currentLang: "Eng",
};
