import { createContext } from "react";

// type AppObjType = {
//   [key: string]: unknown;
// };

// interface AppContextType {
//   appState: { [key: string]: unknown };
//   // appState: AppObjType;
//   setAppState: React.Dispatch<React.SetStateAction<object>>;
// }

// const initialState = {
//   currentPath: "/",
//   navVisible: false,
//   outPutName: null,
//   headerName: null,
//   tags: null,
//   description: "Welcome to Kingston's Interactive Guidelines...",
//   related: null,
//   htmlSaved: false,
//   scripts: [],
//   currentTheme: "t-pearl",
//   specs: null,
//   cis: null,
// };

// export const AppContext = createContext<AppContextType>(undefined);

export const AppContext = createContext(undefined);

//Other properties that the app saves are:
// saveHtml: true/false, htmlString: "", flatHTML: "true", htmlSaved: "false"

// export const AppProvider = ({ children }: { children: ReactElement }) => {
//   const [appState, setAppState] = useState<{ [key: string]: unknown }>(initialState);
//   // console.log("initial appstate");
//   const value = { appState, setAppState };

//   return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
// };
