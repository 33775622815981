/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import C_BlogCard from "../../2_components/blogCard/C_BlogCard";
// import E_Button from "../../1_elements/button/E_Button";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_Filter from "../../2_components/filter/C_Filter";
import C_Pagination from "../../2_components/pagination/C_Pagination";
import blogOpts from "./blogOpts";

const S_Blog = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let blogVars = {
      ...appState,
      currentPath: "/ui/blog",
      outputName: "Blog",
      headerName: "Blog",
      currentTheme: "t-silver",
      description:
        "The Blog Section has a collection of blog articles that include an image, headline, and excerpt from a blog article. Only the image and headline are linked. If there are more than 12 blog articles, then a pagination will appear for the user to continue looking at the other blog articles. *Currently pagination is only working on Live Site version - not Editable version.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/pagination.component.js",
        "../../js/accordion.component.js",
        "../../js/overlay.component.js",
        "../../js/slideMenu.component.js",
        "../../js/filterCard.component.js",
        "../../js/overlay.component.js",
        "blog.section.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small and Medium media sizes created, and use one image. Width: 720px, Height: 405px",
            "Note: Can work with various sizes based on legacy images",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["An <h5> anchor consists of string text. Maximum of 3 lines."]],
        [
          "Copy",
          [
            "The copy appears as a paragraph beneath the title. No Character limit, and will cut off at 4 lines on large blog cards, and 3 lines for small blog cards.",
          ],
        ],
        ["Tags", ["An <a> anchor consists of string text."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Eco04BnBcQFFogfDbCfb4aQBYcRPzx1QlLhcP7xEsPIRYg?e=FwYGJH",
        comments: [["Blog Image Size", ["What asset sizes are being submitted"]]],
      },
    };
    setAppState(blogVars);
    setContentOptions(blogOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "1245" });
  }, []);

  if (!contentOptions) return "...Loading Blog Section";
  return (
    <section className={"s-blog " + appState.currentTheme} id="blog0">
      <C_Filter />
      <ul className="s-blog__layout l-inner u-max+ u-list-unstyled">
        <C_BlogCard
          image={"/images/fpo/section-blog/newSize.jpg"}
          title={contentOptions.title1 && contentOptions.title1.field[contentOptions.currentLang]}
          copy={contentOptions.copy1 && contentOptions.copy1.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-memory-vs-storage-thumbnail-md.jpg"
          }
          // title={contentOptions.title2 && contentOptions.title2.field[contentOptions.currentLang]}
          classes="c-blogCard--visited"
          copy={"This card is faded to indicate that it has been visited"}
        />
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-solutions-data-security-upgrade-your-computer-security-with-self-encrypted-ssds-sm.jpg"
          }
          // title={contentOptions.title3 && contentOptions.title3.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          image={"https://media.kingston.com/kingston/articles/ktc-article-nvme-enterprise-md.jpg"}
          // title={contentOptions.title4 && contentOptions.title4.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-memory-vs-storage-thumbnail-md.jpg"
          }
          title={contentOptions.title5 && contentOptions.title5.field[contentOptions.currentLang]}
          copy={contentOptions.copy5 && contentOptions.copy5.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-solutions-data-security-upgrade-your-computer-security-with-self-encrypted-ssds-sm.jpg"
          }
          // title={contentOptions.title6 && contentOptions.title6.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          image={"https://media.kingston.com/kingston/articles/ktc-article-nvme-enterprise-md.jpg"}
          // title={contentOptions.title7 && contentOptions.title7.field[contentOptions.currentLang]}
        />
        <C_BlogCard
          // title={contentOptions.title8 && contentOptions.title8.field[contentOptions.currentLang]}
          image={
            "https://media.kingston.com/kingston/articles/ktc-articles-memory-vs-storage-thumbnail-md.jpg"
          }
        />
        <C_BlogCard
        // title={contentOptions.title9 && contentOptions.title9.field[contentOptions.currentLang]}
        />
        <C_BlogCard
        // title={contentOptions.title10 && contentOptions.title10.field[contentOptions.currentLang]}
        />
        <C_BlogCard
        // title={contentOptions.title11 && contentOptions.title11.field[contentOptions.currentLang]}
        />
        <C_BlogCard
        // title={contentOptions.title12 && contentOptions.title12.field[contentOptions.currentLang]}
        />
      </ul>
      <div className="l-inner u-txt-center u-pt u-pb">
        {/*  <E_Button
          action={true}
          text={(contentOptions.btnTxt && contentOptions.btnTxt.field) || `More`}
        />*/}
        <C_Pagination pagetotal={10} />
      </div>
    </section>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "blog",
  component: S_Blog,
  navtxt: "Blog",
  htmlName: "Blog",
  categoryType: ["web"],
};
