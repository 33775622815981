import { createContext } from "react";

// const initialState = {
//   width: 1152,
//   hardcodeHeight: false,
//   viewHeight: null,
// };

// type dimType = {
//   width?: number;
//   hardcodeHeight?: boolean;
//   viewHeight?: number | null;
// };

const DimensionsContext = createContext({});

// export const DimensionsProvider = ({ children }: { children: ReactElement }) => {
//   const [dimensions, setDimensions] = useState(initialState as dimType);

export default DimensionsContext;
