/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Checkout = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let ckVars = {
      ...appState,
      currentPath: "/ui/checkout",
      outputName: "Checkout",
      headerName: "Checkout",
      tags: null,
      currentTheme: "t-white",
      description: "The Checkout Section has been used by regions that don't use Shopify",
      related: [["Checkout: EMEA", "checkout-emea"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/quantityCounter.component.js",
        "../../js/formsLegacy.component.js",
        "../../js/dropDown.component.js",
        "../../js/checkoutEmea.section.js",
        "../../js/googlePlaces.component.js",
        "../../js/checkout.section.js",
        "../../js/accordion.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["Small, Medium, Large: Width and Height: Depends on the product item"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Credit Card Image (mobile, tablet, desktop)", ["Width: 32px, Height: 20px"]],
        ["Credit Card Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["A <h2> consists of string text"]],
        ["Copy Heading", ["A <p> consists of string text"]],
        ["Fieldset Title", ["An <h5> consists of string text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Eb2d-vi9aPVEj5R17thyTdYBCx-IMpKLN6MMuASjiNDnNA?e=0ONkth",
        comments: null,
      },
    };

    setAppState(ckVars);
    setContentOptions({
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      viewHeight: "1323",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Checkout";
  return (
    <section className={"s-checkout " + appState.currentTheme}>
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <h2>Secure Checkout</h2>
            <svg viewBox="0 0 16 20">
              <path d="M16 9.785C16 8.247 14.723 7 13.147 7H2.853C1.277 7 0 8.247 0 9.785v7.43C0 18.753 1.277 20 2.853 20h10.294a2.89 2.89 0 0 0 2.017-.816c.535-.522.836-1.23.836-1.969v-7.43zM9.333 15.08A1.04 1.04 0 0 1 8.28 16.11h-.56a1.04 1.04 0 0 1-1.053-1.028v-4.463A1.04 1.04 0 0 1 7.72 9.59h.56c.28 0 .547.108.745.3.197.193.308.455.308.728v4.463zM8.1.002a4.824 4.824 0 0 0-3.583 1.445 5.274 5.274 0 0 0-1.509 3.689c-.024.291.006.585.09.864h2.545c-.127 0-.19-.505-.19-.864 0-1.47 1.139-2.66 2.545-2.66 1.376 0 2.504 1.142 2.545 2.58 0 .359-.063.904-.19.904h2.545S13 5.441 13 5.082C12.959 2.28 10.783.024 8.1.002z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="s-checkout__signinContainer">
        <p className="s-checkout__signinContainer__checkoutSignIn">
          Have a Kingston account?{" "}
          <a className="signing-toggle" href="#link1">
            Sign In
          </a>
        </p>
        <form className="s-checkout__signinContainer__frmSignIn" id="formSignin">
          <div className="f-input">
            <div className="f-input__string">
              <input id="signinEmail" type="text" name="signinEmail" />
              <label htmlFor="signinEmail">Email Address</label>
            </div>
          </div>
          <div className="f-input" id="textFieldValue">
            <div className="f-input__string">
              <input id="signinPassword" type="password" name="signinPassword" />
              <label htmlFor="signinPassword">Password</label>
            </div>
          </div>
          <div className="s-checkout__signinContainer__frmSignIn__signinFtr">
            <div className="s-checkout__signinContainer__frmSignIn__signinFtr__btns">
              <a className="e-btn" href="#/checkout-thankyou">
                Sign In
              </a>
              <a className="e-btn e-btn--alt1 signin-toggle" href="#link">
                Cancel
              </a>
            </div>
            <a href="#/reset-password">Forgot Password</a>
          </div>
        </form>
      </div>
      <div className="s-checkout__overlay"></div>
      <div className="l-inner">
        <form className="l-grid s-checkout__gridCheckout l-grid--2col@lg" id="formCheckout">
          <div className="s-checkout__gridCheckout__form">
            <div className="s-checkout__gridCheckout__form__expressCheckout">
              <div className="s-checkout__gridCheckout__form__expressCheckout__apple">
                <a className="e-btn u-btn-apple" href="#link">
                  <span>Pay With&nbsp;</span>
                  <img
                    className="btn__logo"
                    src="/images/fpo/section-navigation/logo-applePay.svg"
                    alt="apple"
                  />
                </a>
                <div className="s-checkout__gridCheckout__form__expressCheckout__hrBlock">
                  <span className="s-checkout__gridCheckout__form__expressCheckout__hrBlock__text">
                    or pay with credit card
                  </span>
                </div>
              </div>
              <div className="s-checkout__gridCheckout__form__expressCheckout__paypal">
                <a className="e-btn u-btn-paypal" id="continueBtn" href="#link">
                  <img
                    className="btn__logo"
                    src="/images/fpo/section-navigation/logo-payPal.svg"
                    alt="PayPal"
                  />
                  <span>&nbsp;Checkout</span>
                </a>
                <p className="u-mt1 u-mb1">
                  Note: Shipping ionfo shown here will not be passed on to Paypal and must be
                  entered on their site
                </p>
                <div className="s-checkout__gridCheckout__form__expressCheckout__hrBlock">
                  <span className="s-checkout__gridCheckout__form__expressCheckout__hrBlock__text">
                    or pay with
                  </span>
                </div>
                <div className="s-checkout__gridCheckout__form__expressCheckout__table">
                  <div className="s-checkout__gridCheckout__form__expressCheckout__table__row">
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img src="/images/fpo/section-checkout/card-logo-visa.png" alt="VISA" />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img
                        src="/images/fpo/section-checkout/card-logo-mastercard.png"
                        alt="MasterCard"
                      />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img src="/images/fpo/section-checkout/card-logo-maestro.png" alt="Maestro" />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img src="/images/fpo/section-checkout/card-logo-sofort.png" alt="Sofort" />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img
                        src="/images/fpo/section-checkout/card-logo-giropay.png"
                        alt="giro pay"
                      />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img src="/images/fpo/section-checkout/card-logo-eps.png" alt="eps" />
                    </div>
                    <div className="s-checkout__gridCheckout__form__expressCheckout__table__row__cell">
                      <img src="/images/fpo/section-checkout/card-logo-ideal.png" alt="ideal" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset>
              <h5>1. Contact Info</h5>
              <div className="f-input">
                <div className="f-input__string">
                  <input type="text" name="email" id="email" />
                  <label htmlFor="email">Email Address</label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h5>2. Shipping Address</h5>
              <div className="l-row">
                <div className="l-row__col l-1/2">
                  <div className="f-input">
                    <div className="f-input__string">
                      <input id="firstname" type="text" name="firstname" />
                      <label htmlFor="firstname">First Name</label>
                    </div>
                  </div>
                </div>
                <div className="l-row__col l-1/2">
                  <div className="f-input">
                    <div className="f-input__string">
                      <input id="lastname" type="text" name="lastname" />
                      <label htmlFor="lastname">Last Name</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="f-input c-googlePlaces" id="gAddress1">
                <div className="f-input__string">
                  <input id="address" type="text" name="address" />
                  <label htmlFor="address">Street Address</label>
                </div>
                <a className="f-input__icon" href="#link">
                  <svg viewBox="0 0 14 14">
                    <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                    <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                  </svg>
                </a>
              </div>
              <div className="f-input">
                <div className="f-input__string">
                  <input type="text" name="address2" id="address2" />
                  <label htmlFor="address2">Apt, Suite, Bldg, Gate Code (optional)</label>
                </div>
              </div>
              <div className="l-row">
                <div className="l-row__col l-5/12">
                  <div className="f-input" id="gCity1">
                    <div className="f-input__string">
                      <input id="locality" type="text" name="locality" />
                      <label htmlFor="locality">City</label>
                    </div>
                  </div>
                </div>
                <div className="l-row__col l-3/12">
                  <div className="f-input" id="gState1">
                    <div className="f-input__select">
                      <select
                        id="administrative_area_level_1"
                        name="administrative_area_level_1"
                        defaultValue={"DEFAULT"}
                      >
                        <option hidden="hidden" value={"DEFAULT"}></option>
                        <option value="ca">CA</option>
                        <option value="co">CO</option>
                        <option value="wy">WY</option>
                      </select>
                      <label htmlFor="administrative_area_level_1">State</label>
                    </div>
                  </div>
                </div>
                <div className="l-row__col l-4/12">
                  <div className="f-input" id="gPostal1">
                    <div className="f-input__string">
                      <input id="postal_code" type="text" name="postal_code" />
                      <label htmlFor="postal_code">Postal</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="f-input">
                <div className="f-input__string">
                  <input type="text" name="phone" />
                  <label htmlFor="phone">Phone</label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h5>3. Shipping Options</h5>
              <div className="f-input">
                <div className="f-input__select">
                  <select id="shippingOption" name="shippingOption">
                    <option value="1">FedEx Ground 5-7 Days (M-F) - FREE</option>
                    <option value="2">FedEx 2-Day - $12.99</option>
                    <option value="4">Pony Express (30 Days on Healthy Pony) - -$20.00</option>
                  </select>
                  <label htmlFor="shippingOption">Shipping</label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h5>4. Billing Address</h5>
              <div className="f-input" id="sameAsShip">
                <div className="f-input__checkbox">
                  <input id="checkboxTest" type="checkbox" name="checkboxTest" checked="checked" />
                  <label htmlFor="checkboxTest">Same as shipping address</label>
                </div>
              </div>
              <div className="hidden" id="billingFields">
                <div className="l-row">
                  <div className="l-row__col l-1/2">
                    <div className="f-input">
                      <div className="f-input__string">
                        <input id="bill_firstName" type="text" name="bill_firstName" />
                        <label htmlFor="bill_firstName">First Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-1/2">
                    <div className="f-input">
                      <div className="f-input__string">
                        <input id="bill_lastName" type="text" name="bill_lastName" />
                        <label htmlFor="bill_lastName">Last Name</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="f-input c-googlePlaces" id="gAddress2">
                  <div className="f-input__string">
                    <input id="bill_address" type="text" name="bill_address" />
                    <label htmlFor="bill_address">Street Address</label>
                  </div>
                  <e-icon className="f-input__icon">
                    <svg>
                      <use href="../images/icons/icons-map.svg#thin-close"></use>
                    </svg>
                  </e-icon>
                </div>
                <div className="f-input">
                  <div className="f-input__string">
                    <input id="bill_address2" type="text" name="bill_address2" />
                    <label htmlFor="bill_address2">Apt, Suite, Bldg, Gate Code (optional)</label>
                  </div>
                </div>
                <div className="l-row">
                  <div className="l-row__col l-5/12">
                    <div className="f-input" id="gCity2">
                      <div className="f-input__string">
                        <input id="bill_locality" type="text" name="bill_locality" />
                        <label htmlFor="bill_locality">City</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-3/12">
                    <div className="f-input" id="gState2">
                      <div className="f-input__select">
                        <select
                          id="bill_administrative_area_level_1"
                          name="bill_administrative_area_level_1"
                        >
                          <option hidden="hidden" selected="selected"></option>
                          <option value="ca">CA</option>
                          <option value="co">CO</option>
                          <option value="wy">WY</option>
                        </select>
                        <label htmlFor="bill_administrative_area_level_1">State</label>
                      </div>
                    </div>
                  </div>
                  <div className="l-row__col l-4/12">
                    <div className="f-input" id="gPostal2">
                      <div className="f-input__string">
                        <input id="bill_postal_code" type="text" name="bill_postal_code" />
                        <label htmlFor="bill_postal_code">Postal</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="s-checkout__gridCheckout__form__stripeContent">
              <h5>5. Secure Payment</h5>
              <div className="cc-icons">
                <img src="/images/fpo/section-checkout/card-logo-visa.svg" alt="VISA" />
                <img
                  src="/images/fpo/section-checkout/card-logo-mastercard.svg"
                  alt="Master Card"
                />
                <img src="/images/fpo/section-checkout/card-logo-amex.svg" alt="American Express" />
                <img src="/images/fpo/section-checkout/card-logo-discover.svg" alt="Discover" />
                <img src="/images/fpo/section-checkout/card-logo-unknown.svg" alt="Other Card" />
              </div>
              <div className="input-group">
                <label>
                  <div className="field" id="cardNumber-element"></div>
                  <span>Card Number</span>
                </label>
              </div>
              <div className="l-row">
                <div className="l-row__col l-1/2">
                  <div className="input-group">
                    <label>
                      <div className="field" id="cardExpiry-element"></div>
                      <span>Expiration</span>
                    </label>
                  </div>
                </div>
                <div className="l-row__col l-1/2">
                  <div className="input-group">
                    <label>
                      <div className="field" id="cardCvc-element"></div>
                      <span>CVC</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="outcome">
                <div className="error" role="alert"></div>
              </div>
            </fieldset>
            <div
              className="s-checkout__gridCheckout__form__orderContainer u-txt-center"
              id="submitBtnContainer"
            >
              <div className="wrapper3d">
                <div className="btn-container">
                  <a className="e-btn checkout-btn" id="checkoutBtn" href="#link">
                    <div className="default__text">
                      <svg>
                        <use href="../images/icons/icons-map.svg#factory-tested"></use>
                      </svg>
                      Place Order
                    </div>
                    <div className="emea__text">Proceed to Payment</div>
                    <span>
                      <svg
                        className="loader10"
                        height="38"
                        viewBox="0 0 38 38"
                        width="38"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%">
                            <stop offset="0%" stopColor="#fff" stopOpacity="0"></stop>
                            <stop offset="63.146%" stopColor="#fff" stopOpacity=".631"></stop>
                            <stop offset="100%" stopColor="#fff"></stop>
                          </linearGradient>
                        </defs>
                        <g fillRule="evenodd" fill="none">
                          <g transform="translate(1 1)">
                            <path
                              d="M36 18c0-9.94-8.06-18-18-18"
                              id="Oval-2"
                              strokeWidth="2"
                              stroke="url(#a)"
                            ></path>
                            <circle cx="36" cy="18" fill="#fff" r="1"></circle>
                          </g>
                        </g>
                      </svg>
                      Processing...
                    </span>
                  </a>
                  <a className="e-btn continue-btn bottom" id="continueBtn" href="#link">
                    Continue Shopping
                  </a>
                </div>
              </div>
            </div>
            <p className="emea__text">
              You’ll be directed to sagepay.com for secure payment processing.
            </p>
          </div>
          <div className="s-checkout__gridCheckout__summary">
            <div className="c-accordion c-accordion--iconAfter">
              <span className="c-accordion__tab c-accordion__tab--active" id="accTab-infqde">
                <button aria-controls="accPanel-cu0nmf" aria-expanded="true">
                  Order Summary
                  <svg
                    className="c-accordion__tab__icon"
                    aria-hidden="true"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                  >
                    <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                  </svg>
                  <span>$100.00</span>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                id="accPanel-cu0nmf"
                aria-labelledby="accTab-infqde"
              >
                <div className="c-accordion__panel__content c-cartCheckout">
                  <ul className="list-unstyled c-cartCheckout__listing">
                    <li>
                      <div className="c-cartItem" data-kit="4">
                        <img
                          className="c-cartItem__image"
                          src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                          alt="Item in cart"
                        />
                        <div className="c-cartItem__title u-txt-bold">
                          480GB A400 SATA3 2.5 SSD (7mm height)
                        </div>
                        <div className="c-cartItem__quantity">
                          <div className="f-quantity js-quantity s-checkout--emea" id="jsQuantity0">
                            <label className="f-quantity__label" htmlFor="quant1">
                              Quantity
                            </label>
                            <div className="f-quantity__counter">
                              <button
                                className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                aria-label="Decrease Quantity"
                                tabIndex="-1"
                              >
                                <span className="f-quantity__counter__btn__inner">
                                  <svg viewBox="0 0 16 2">
                                    <title>Minus Icon</title>
                                    <path d="M0 0h16v2H0z"></path>
                                  </svg>
                                </span>
                              </button>
                              <input
                                className="f-quantity__counter__input"
                                type="text"
                                maxLength="4"
                                value="1"
                                id="quant1"
                                name="fQuantity0"
                              />
                              <button
                                className="f-quantity__counter__btn js-quantity__btn"
                                aria-label="Increase Quantity"
                                data-add="true"
                              >
                                <span className="f-quantity__counter__btn__inner">
                                  <svg viewBox="0 0 16 16">
                                    <title>Plus Icon</title>
                                    <path d="M0 7h16v2H0z"></path>
                                    <path d="M7 0h2v16H7z"></path>
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="c-cartItem__total">
                          <span className="u-txt-bold">1 x $54.99&nbsp;</span>
                          <span className="c-cartItem__total__label">Total</span>
                          <button className="c-cartItem__total__trash">
                            <svg viewBox="0 0 14 17.2" width="100%" height="100%">
                              <path d="M11.3 16.1V4.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V16c0 .1 1.1.1 1 .1z"></path>
                              <path d="M2.2 16.9c0 .2.2.3.3.3h8.9c.2 0 .3-.1.3-.3l1-11.8H1.3l.9 11.8zm11.5-15H9.3C9.1.8 8.2 0 7.1 0s-2 .8-2.2 1.9H.3c-.2 0-.3.1-.3.3v1.3c0 .2.1.3.3.3h13.4c.2 0 .3-.1.3-.3V2.2c0-.2-.1-.3-.3-.3z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="c-cartCheckout__summary">
                    <table className="c-cartCheckout__summary__details">
                      <tbody>
                        <tr>
                          <td className="l-summary__link">
                            <a className="link-editCart" id="editCartLink" href="#link">
                              Edit Cart
                            </a>
                            <a className="link-updateCart" id="updateCartLink" href="#link">
                              Update Cart
                            </a>
                            <a className="link-continueCart" id="continueLink" href="#link">
                              Continue Shopping
                            </a>
                          </td>
                          <td className="l-summary__subTitle">Subtotal</td>
                          <td className="l-summary__subTotal">$1.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__deliveryTitle">Shipping</td>
                          <td className="l-summary__shpping">FREE</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__taxTitle">Tax</td>
                          <td className="l-summary__tax">$1.00</td>
                        </tr>
                        <tr>
                          <td className="l-summary__itemCount">1 Item</td>
                          <td className="l-summary__totalTitle">Total</td>
                          <td className="l-summary__total">$2.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__saveTitle">Savings</td>
                          <td className="l-summary__savings">$0.10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="c-accordion__overflow" role="region">
                <div className="f-input f-input--reactive">
                  <div className="f-input__string">
                    <input id="acode" type="text" name="acode" />
                    <label htmlFor="acode">Discount Code</label>
                  </div>
                  <a className="f-input__icon" href="#link">
                    Apply
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "checkout",
  component: S_Checkout,
  navtxt: "Checkout",
  htmlName: "Checkout",
  categoryType: ["internal"],
};
