import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_CardScroller from "../../2_components/cardScroller/C_CardScroller";
import C_HorizontalCard from "../../2_components/horizontalCard/C_HorizontalCard";
import cardSliderOpts from "./cardSliderOpts";

const S_CardSlider = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let wwwVars = {
      ...appState,
      currentPath: "/ui/card-slider",
      outputName: "CardSlider", //html name
      headerName: "Card Slider",
      tags: null,
      description:
        "The Slider Section displays a collection of cards containing title (optional), image, and copy",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/cardScroller.component.js",
      ],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*2 images required",
            "Small and Medium: Recommended: 288px x 240px",
            "Large: 356px x 240px",
          ],
        ],
        ["Image Type", ["JPG"]],
        ["Title", ["H3 tag, String Text. (Optional)"]],
        ["Copy", ["String Text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ETvyCZFIdO9MrQe4atjoUk4BhZBZwUh5InuyEPiX0zsPWw?e=BpCcdz",
        comments: [["Card Slider Image Sizes", ["Small: 288px x 240px", "Large: 356px x 240px"]]],
      },
    };
    setAppState(wwwVars);
    setContentOptions(cardSliderOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "" });
  }, []);

  if (!contentOptions || Object.keys(contentOptions) < 1) return "...Loading cardSlider";
  return (
    <section className={"s-cardScroller " + appState.currentTheme}>
      {contentOptions.numCards && contentOptions.numCards.selected == "1" ? (
        <div className="c-cardScroller c-cardScroller--center">
          <div className="c-cardScroller__container">
            <div className="c-cardScroller__container__layout">
              <ul className="c-cardScroller__container__layout__items">
                <li className="c-cardScroller__container__layout__items__itemView">
                  <div className="c-cardScroller__container__layout__items__itemView__item">
                    <div className="c-horizontalCard c-horizontalCard--cardSlider ">
                      <div className="c-horizontalCard__image">
                        <picture className="e-picture" id="js-picture">
                          <source
                            srcSet="/images/fpo/general/288x240.png"
                            media="(max-width: 50em)"
                          />
                          <img
                            src="/images/fpo/general/356x240.png"
                            alt="Alternate Text"
                            className={`${
                              contentOptions.styleCards &&
                              contentOptions.styleCards.selected == "Rounded"
                                ? " u-img-rounded"
                                : ""
                            } ${
                              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                                ? ""
                                : "u-border-none"
                            }`}
                          />
                        </picture>
                      </div>
                      <div className="c-horizontalCard__details">
                        {contentOptions.cardTitles && contentOptions.cardTitles.checkbox ? (
                          <h3 className="c-horizontalCard__details__heading u-h4">
                            {contentOptions.card1 &&
                              contentOptions.card1[0] &&
                              contentOptions.card1[0].field[contentOptions.currentLang]}
                          </h3>
                        ) : (
                          ""
                        )}
                        <p className="c-horizontalCard__details__copy">
                          {contentOptions.card1 &&
                            contentOptions.card1[1] &&
                            contentOptions.card1[1].field[contentOptions.currentLang]}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : contentOptions.numCards && contentOptions.numCards.selected == "2" ? (
        <C_CardScroller>
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card1 &&
              contentOptions.card1[0]
                ? contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card1 && contentOptions.card1[1]
                ? contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card2 &&
              contentOptions.card2[0]
                ? contentOptions.card2[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card2 && contentOptions.card2[1]
                ? contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
          />
        </C_CardScroller>
      ) : contentOptions.numCards && contentOptions.numCards.selected == "3" ? (
        <C_CardScroller>
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card1 &&
              contentOptions.card1[0]
                ? contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card1 && contentOptions.card1[1]
                ? contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card2 &&
              contentOptions.card2[0]
                ? contentOptions.card2[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card2 && contentOptions.card2[1]
                ? contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card3 &&
              contentOptions.card3[0]
                ? contentOptions.card3[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card3 && contentOptions.card3[1]
                ? contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
          />
        </C_CardScroller>
      ) : (
        <C_CardScroller>
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card1 &&
              contentOptions.card1[0]
                ? contentOptions.card1[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card1 && contentOptions.card1[1]
                ? contentOptions.card1[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card2 &&
              contentOptions.card2[0]
                ? contentOptions.card2[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card2 && contentOptions.card2[1]
                ? contentOptions.card2[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card3 &&
              contentOptions.card3[0]
                ? contentOptions.card3[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card3 && contentOptions.card3[1]
                ? contentOptions.card3[1].field[contentOptions.currentLang]
                : ""
            }
          />
          <C_HorizontalCard
            type="cardSlider"
            imageClass={`${
              contentOptions.styleCards && contentOptions.styleCards.selected == "Rounded"
                ? " u-img-rounded"
                : ""
            } ${
              contentOptions.imgBorder && contentOptions.imgBorder.checkbox == true
                ? ""
                : "u-border-none"
            }`}
            imagesm="/images/fpo/general/288x240.png"
            imagelg="/images/fpo/general/356x240.png"
            heading={
              contentOptions.cardTitles &&
              contentOptions.cardTitles.checkbox &&
              contentOptions.card4 &&
              contentOptions.card4[0]
                ? contentOptions.card4[0].field[contentOptions.currentLang]
                : ""
            }
            copy={
              contentOptions.card4 && contentOptions.card4[1]
                ? contentOptions.card4[1].field[contentOptions.currentLang]
                : ""
            }
          />
        </C_CardScroller>
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_.
export default {
  path: "card-slider",
  component: S_CardSlider,
  navtxt: "Card Slider",
  htmlName: "CardSlider",
  categoryType: ["web"],
};
