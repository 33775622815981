import React, { useContext } from "react";
import C_UseHTMLToggle from "../../Tools/C_UseHTMLToggle";
import { AppContext } from "../../../../AppContext";
import OptionsContext from "../../../../OptionsContext";
import EditableFields from "./EditableFields/EditableFields";
import C_ThemeSwitcher from "../../Tools/C_ThemeSwitcher";
import C_LanguageSwitcher from "../../Tools/C_LanguageSwitcher";
import C_ItemIncDec from "../../Tools/C_ItemIncDec";

const EditContentTab = ({ showHtml, setShowHtml }) => {
  const { appState } = useContext(AppContext);
  const { contentOptions } = useContext(OptionsContext);

  return (
    <div className="l-viewerPanels c-editContentPanel">
      <div>
        <div className="l-viewerPanels__controls">
          <div className="l-viewerPanels__controls__option">
            <h6>View Mode</h6>
            {/* num prop is used to create element id */}
            <C_UseHTMLToggle
              setShowHtml={setShowHtml}
              show={showHtml}
              option1={"Edit Mode"}
              option2={"Default"}
              num={"2"}
            />
          </div>
          {showHtml ? (
            <div>
              <p>Switch to Edit Mode to see editable options.</p>
            </div>
          ) : (
            ""
          )}
          {appState && appState.currentTheme && !showHtml ? (
            <div>
              <C_ThemeSwitcher />
            </div>
          ) : (
            ""
          )}
          {appState && appState.itemCount && !showHtml ? (
            <div>
              <C_ItemIncDec />
            </div>
          ) : (
            ""
          )}
          {contentOptions && contentOptions.currentLang && !showHtml ? (
            <div>
              <C_LanguageSwitcher />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* don't show editable fields if in html mode */}
      {!showHtml && <EditableFields />}
    </div>
  );
};

export default EditContentTab;
