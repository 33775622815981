/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import C_ReviewCard from "../../2_components/reviewCard/C_ReviewCard";

// import C_HorizontalCard from "../../2_components/horizontalCard/C_HorizontalCard";
// import C_CardScroller from "../../2_components/cardScroller/C_CardScroller";

import Slider from "react-slick";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

// This section's html has to be edited directly bc of the slick slider divs that get added. There is a Reviews.bak in case you screw up
const S_Reviews = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [panelNum, setPanelNum] = useState(0);

  const sliderRef = useRef();

  function clickNext() {
    sliderRef.current && sliderRef.current.slickNext();
  }

  function clickPrev() {
    sliderRef.current && sliderRef.current.slickPrev();
  }

  let sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    afterChange: (index) => setPanelNum(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    let revVars = {
      ...appState,
      currentPath: "/ui/reviews",
      outputName: "Reviews",
      headerName: "Reviews",
      tags: null,
      currentTheme: "t-white",
      description:
        "The Reviews Section allows us to share a quote from a review and link the users to the full review page if necessary. *Responsive only working in Live site mode. You must resize entire browser window because of slick slider limitations. Responsive Format: 4 reviews in desktop, 3 reviews in tablet, 1 for mobile",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/vendor/slick.js",
        "reviews.section.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, and Large: Width: 120px and Height: 50px",
            "Image should be no larger than 215px wide, height is flexible but will push all content down",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation"]],
        ["Copy", ["The copy appears as a paragraph. No Character limit."]],
        ["CTA", ["String text within an anchor tag."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ER9TuLtKHopImFf9j-jtskMBCnVytyUjXxSxq56XSXAhEw?e=c0abbK",
        comments: null,
      },
    };
    setAppState(revVars);
    setContentOptions({
      text1: {
        name: "text1",
        label: "Text 1",
        checkbox: null,
        field: {
          Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      text2: {
        name: "text2",
        label: "Text 2",
        checkbox: null,
        field: {
          Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      text3: {
        name: "text3",
        label: "Text 3",
        checkbox: null,
        field: {
          Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      link: {
        name: "link",
        label: "Link",
        checkbox: null,
        field: {
          Eng: "Quis varius",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter;",
          Ru: "Лорем ипсум",
          Fr: "Il faisait",
          It: "Faceva caldo",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết đó.",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погод",
          Thai: "อากาศช่วงนั้นร้",
          Kor: "국민경제의",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      text4: {
        name: "text4",
        label: "Text 4",
        checkbox: null,
        field: {
          Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "280" });
  }, []);

  if (!contentOptions) return "...Loading Reviews Section";
  return (
    <section className={"s-reviews " + appState.currentTheme}>
      <div className="l-inner">
        <Slider {...sliderSettings} ref={(c) => (sliderRef.current = c)}>
          <div className="c-slider__card">
            <C_ReviewCard
              copy={contentOptions.text1 && contentOptions.text1.field[contentOptions.currentLang]}
            />
          </div>
          <div className="c-slider__card">
            <C_ReviewCard
              copy={contentOptions.text2 && contentOptions.text2.field[contentOptions.currentLang]}
              src="/images/fpo/general/120x50.png"
            />
          </div>
          <div className="c-slider__card">
            <C_ReviewCard
              copy={contentOptions.text3 && contentOptions.text3.field[contentOptions.currentLang]}
              url="#link"
              link={contentOptions.link && contentOptions.link.field[contentOptions.currentLang]}
            />
          </div>
          <div className="c-slider__card">
            <C_ReviewCard />
          </div>
          <div className="c-slider__card">
            <C_ReviewCard />
          </div>
        </Slider>

        <div className="c-slider__controls">
          <button
            className={`c-slider__controls__left e-btn slick-arrow ${
              panelNum === 0 ? "slick-disabled" : ""
            }`}
            aria-label="Previous"
            onClick={clickPrev}
          >
            <svg width="14" height="23" viewBox="0 0 14 23">
              <path d="M11.2.3L.5 11l10.7 10.7 1.6-1.5L3.6 11l9.2-9.2z"></path>
            </svg>
          </button>

          <button
            className={`c-slider__controls__right e-btn slick-arrow ${
              panelNum === 1 ? "slick-disabled" : ""
            }`}
            aria-label="Next"
            onClick={clickNext}
          >
            <svg width="14" height="23" viewBox="0 0 14 23">
              <path d="M2.1 21.7L12.8 11 2.1.3.5 1.9l9.2 9.2-9.2 9.1z"></path>
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_.
export default {
  path: "reviews",
  component: S_Reviews,
  navtxt: "Reviews",
  htmlName: "Reviews",
  categoryType: ["web"],
};
