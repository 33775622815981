import { createContext } from "react";

const OptionsContext = createContext();

// export const OptionsContext = createContext<any>(initialState);

// const initialState = { currentLang: "Eng", itemCount: null };

// export const OptionsProvider = ({ children }) => {
//   const [contentOptions, setContentOptions] = useState([]);

//   // export const OptionsProvider = ({ children }: { children: ReactElement }) => {
//   //   const [contentOptions, setContentOptions] = useState<{ [key: string]: any }>(initialState);
//   // console.log("initial appstate");
//   const value = { contentOptions, setContentOptions };

//   return <OptionsContext.Provider value={value}>{children}</OptionsContext.Provider>;
// };
export default OptionsContext;
