/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_ProductCard4 from "../../2_components/productCard4/C_ProductCard4";
import C_ProductFilter from "../../2_components/productFilter/C_ProductFilter";
import C_ProductSort from "../../2_components/productSort/C_ProductSort";
import PGTab1 from "./PGTab1";
import PGTab2 from "./PGTab2";
import PGTab3 from "./PGTab3";
import PGTab4 from "./PGTab4";

const S_ProductGalleryTab = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [activeTab, setActiveTab] = useState("0");

  const [expanded, setExpanded] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [noteOpen, setNoteOpen] = useState(true);

  function handleExpandedActive(tf) {
    setExpanded(tf);
  }

  function handleNotificationClick(event) {
    event.preventDefault();
    setNoteOpen(false);
  }

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/ui/product-gallery-tab",
      outputName: "ProductGalleryTab",
      headerName: "Product Gallery : Tab",
      tags: null,
      description: "The Product Gallery displays product level cards with Tab View.",
      related: [
        ["DRAM: Part Level", "product-gallery-dram-part"],
        ["Non-DRAM: Part Level", "product-gallery-non-dram-part"],
        ["DRAM: Product Level", "product-gallery-dram-product"],
        ["Non-DRAM: Product Level", "product-gallery-ndram-product"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/quantityCounter.component.js",
        "../../js/dropDown.component.js",
        "../../js/accordion.component.js",
        "../../js/slideMenu.component.js",
        "../../js/filterCard.component.js",
        "../../js/tabs.layout.js",
        "../../js/productGallery3.section.js",
        "../../js/productCard4.component.js",
        "../../js/gridFlex.layout.js",
      ],
      specs: [
        ["Title", ["Character Limit: none"]],
        ["Tag", ["String Text"]],
        ["Image", ["No defined image size: depends on product"]],
        ["Selections", ["Radio Buttons Text"]],
        ["Long Description", ["List Element containing lines of string text. No limit"]],
        ["Product: CTA Buttons", ["Varies in Type: CTA String Text and Button"]],
        ["Price", ["String Text"]],
        ["Buy Options", ["Button Text"]],
      ],
      cis: null,
    });
    setContentOptions({
      numtabs: {
        label: "Number of Tabs",
        checkbox: null,
        field: [0, 1, 2, 3, 4],
        selected: 4,
      },
      controls: {
        label: "Enable Search/Browse",
        checkbox: null,
        field: [0, 1, 2],
        selected: 2,
      },
      filter: {
        label: "Enable Filter",
        checkbox: true,
        field: null,
      },
      sort: {
        label: "Enable Sort",
        checkbox: true,
        field: null,
      },
      filterlink: {
        label: "Enable Filter Link",
        checkbox: true,
        field: "Search HTS/ECCN",
      },
      note: {
        label: "Notification [Optional] [Refresh to interact again after closing]",
        checkbox: false,
        field:
          "Consectetur, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      heading: {
        label: "Heading",
        checkbox: true,
        field: "Lorem ipsum dolor sit amet",
      },
      headingcopy: {
        label: "Heading Copy",
        checkbox: true,
        field:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      },
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!appState) return "...Loading Product Gallery - Non-DRAM Product Section";
  return (
    <section
      className={`s-productGallery3 ${
        contentOptions.numtabs && contentOptions.numtabs.selected == 0 ? "t-silver" : "t-pearl"
      }`}
      id="productGallery30"
    >
      {contentOptions.heading && contentOptions.heading.checkbox == true ? (
        <div className="c-heading">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h2>{contentOptions.heading.field}</h2>
              {contentOptions.headingcopy && contentOptions.headingcopy.checkbox ? (
                <p>{contentOptions.headingcopy.field}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {contentOptions.numtabs && contentOptions.numtabs.selected == 0 ? (
        <>
          {contentOptions.filter && contentOptions.filter.checkbox == true ? (
            <div className="s-productGallery3__filterLayout">
              <C_ProductFilter
                controls={contentOptions.controls && contentOptions.controls.selected}
                filterlink={
                  contentOptions.filterlink && contentOptions.filterlink.checkbox
                    ? contentOptions.filterlink.field
                    : ""
                }
              />
              <div className="s-productGallery3__filterLayout__view">
                <C_ProductSort
                  sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""}
                />
                <ul
                  className={`l-gridFlex s-productGallery3__grid ${
                    expanded ? "s-productGallery3__grid--active" : ""
                  }`}
                  data-min="288"
                  data-max="480"
                >
                  <C_ProductCard4
                    //no pClasses value will leave default "c-productCard4"
                    id="productCard4-0"
                    pname={contentOptions.name && contentOptions.name.field}
                    partNumber={contentOptions.part && contentOptions.part.field}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dt100g3-dt100g3256gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={contentOptions.details1 && contentOptions.details1.field}
                    details2={contentOptions.details2 && contentOptions.details2.field}
                    nonDRAM={true}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    currentPrice={"$66.98"}
                    panelCopy={"Product scheduled to ship 1 - 2 weeks"}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"quantitypartners"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-1"
                    kit={"4"}
                    pname="DataTraveler Elite G2 USB Flash Drive"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dteliteg2-dteg2128gb-1-tn.png"
                    }
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    // No currentPrice will make product "out of stock"
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"partners"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-2"
                    pname="2GB Module - DDR3 1600MHz"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    // No currentPrice will make product "out of stock"
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    form={"where"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-3"
                    pname="UV500 SATA SSD"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    form={"quantity"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--singleCTA"
                    id="productCard4-4"
                    pname="4GB Module - DDR4 2666MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={"https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"}
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    // No currentPrice will make product "out of stock"
                    buyBtn={false}
                  />
                  <C_ProductCard4
                    id="productCard4-5"
                    pname="DRAM"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dtmicroduo3c-dtduo3c128gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"partners"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-6"
                    kit={"4"}
                    pname="DataTraveler MicroDuo 3C USB Flash Drive"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-d300-standard-ikd300128gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"where"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-7"
                    pname="NV1 NVMe PCIe SSD"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"quantitypartners"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--singleCTA"
                    id="productCard4-8"
                    pname="Canvas Go!: Product Name Link"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-flash-sdcards-sdg3-512gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    buyBtn={false}
                    replaced={{ name: "KTH-PL426/16G", url: "#link" }}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    id="productCard4-7"
                    pname="UV500 SATA SSD"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                    }
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    nonDRAM={true}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    buyBtn={true}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    form={"quantity"}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                </ul>
                <div className="s-productGallery3__filterLayout__view__loadMore">
                  <a className="e-btn" target="_self" title="Button Title">
                    <span>Load More</span>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <ul
              className={`l-gridFlex s-productGallery3__grid ${
                expanded ? "s-productGallery3__grid--active" : ""
              }`}
              data-min="288"
              data-max="480"
            >
              <C_ProductCard4
                //no pClasses value will leave default "c-productCard4"
                id="productCard4-0"
                pname={contentOptions.name && contentOptions.name.field}
                partNumber={contentOptions.part && contentOptions.part.field}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-usb-dt100g3-dt100g3256gb-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={contentOptions.details1 && contentOptions.details1.field}
                details2={contentOptions.details2 && contentOptions.details2.field}
                nonDRAM={true}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                currentPrice={"$66.98"}
                panelCopy={"Product scheduled to ship 1 - 2 weeks"}
                partners={[
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                ]}
                form={"quantitypartners"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-1"
                kit={"4"}
                pname="DataTraveler Elite G2 USB Flash Drive"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-usb-dteliteg2-dteg2128gb-1-tn.png"
                }
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                // No currentPrice will make product "out of stock"
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                partners={[
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                ]}
                form={"partners"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-2"
                pname="2GB Module - DDR3 1600MHz"
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                // No currentPrice will make product "out of stock"
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                form={"where"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-3"
                pname="UV500 SATA SSD"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                originalPrice={"$122.54"}
                currentPrice={"$66.98"}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                form={"quantity"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                pclasses=" c-productCard4--singleCTA"
                id="productCard4-4"
                pname="4GB Module - DDR4 2666MHz"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={"https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"}
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                // No currentPrice will make product "out of stock"
                buyBtn={false}
              />
              <C_ProductCard4
                id="productCard4-5"
                pname="DRAM"
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-usb-dtmicroduo3c-dtduo3c128gb-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                partners={[
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                ]}
                form={"partners"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-6"
                kit={"4"}
                pname="DataTraveler MicroDuo 3C USB Flash Drive"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-d300-standard-ikd300128gb-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                partners={[
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                ]}
                form={"where"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-7"
                pname="NV1 NVMe PCIe SSD"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                originalPrice={"$122.54"}
                currentPrice={"$66.98"}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                partners={[
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                  {
                    name: "Insight",
                    link: "#",
                    logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                  },
                ]}
                form={"quantitypartners"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                pclasses=" c-productCard4--singleCTA"
                id="productCard4-8"
                pname="Canvas Go!: Product Name Link"
                tag={"Sale"}
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-flash-sdcards-sdg3-512gb-1-tn.png"
                }
                selections={[
                  { name: "size16", label: "16 GB", disabled: false, checked: true },
                  { name: "size32", label: "32 GB", disabled: false, checked: false },
                  { name: "size64", label: "64 GB", disabled: true, checked: false },
                  { name: "size128", label: "128 GB", disabled: false, checked: false },
                ]}
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                buyBtn={false}
                replaced={{ name: "KTH-PL426/16G", url: "#link" }}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
              <C_ProductCard4
                id="productCard4-7"
                pname="UV500 SATA SSD"
                partNumber={"KTH-PL426/16G"}
                imgSrc={
                  "https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                }
                details1={"This is Bullet Number One and it goes on for a little while here."}
                details2={
                  "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                }
                nonDRAM={true}
                originalPrice={"$122.54"}
                currentPrice={"$66.98"}
                buyBtn={true}
                buyClick={(tf) => handleExpandedActive(tf)}
                form={"quantity"}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            </ul>
          )}
        </>
      ) : (
        <div className="l-tabView">
          <div className="u-invisible s-productGallery3__tabLeft"></div>
          <ul className="l-tabView__tabs" role="tablist">
            <li
              className={`l-tabView__tabs__tab ${
                activeTab === "0" ? "l-tabView__tabs__tab--active" : ""
              }`}
              role="tab"
              data-parent="0"
              id="tab0_0"
              data-tab="tabContent0_0"
              aria-controls="tabContent0_0"
              aria-selected="true"
              tabIndex="0"
              data-id="productGallery30"
              onClick={() => setActiveTab("0")}
              onKeyDown={() => setActiveTab("0")}
            >
              <span>All Products</span>
            </li>
            {}

            {(contentOptions.numtabs && contentOptions.numtabs.selected == "2") ||
            (contentOptions.numtabs && contentOptions.numtabs.selected == "3") ||
            (contentOptions.numtabs && contentOptions.numtabs.selected == "4") ? (
              <li
                className={`l-tabView__tabs__tab ${
                  activeTab === "1" ? "l-tabView__tabs__tab--active" : ""
                }`}
                role="tab"
                data-parent="0"
                id="tab0_1"
                data-tab="tabContent0_1"
                aria-controls="tabContent0_1"
                aria-selected="false"
                tabIndex="-1"
                data-id="productGallery30"
                onClick={() => setActiveTab("1")}
                onKeyDown={() => setActiveTab("1")}
              >
                <span>Headsets</span>
              </li>
            ) : (
              ""
            )}

            {(contentOptions.numtabs && contentOptions.numtabs.selected == "3") ||
            (contentOptions.numtabs && contentOptions.numtabs.selected == "4") ? (
              <li
                className={`l-tabView__tabs__tab ${
                  activeTab === "2" ? "l-tabView__tabs__tab--active" : ""
                }`}
                role="tab"
                data-parent="0"
                id="tab0_2"
                data-tab="tabContent0_2"
                aria-controls="tabContent0_2"
                aria-selected="false"
                tabIndex="-1"
                data-id="productGallery30"
                onClick={() => setActiveTab("2")}
                onKeyDown={() => setActiveTab("2")}
              >
                <span>Keyboards</span>
              </li>
            ) : (
              ""
            )}
            {contentOptions.numtabs && contentOptions.numtabs.selected == "4" ? (
              <li
                className={`l-tabView__tabs__tab ${
                  activeTab === "3" ? "l-tabView__tabs__tab--active" : ""
                }`}
                role="tab"
                data-parent="0"
                id="tab0_3"
                data-tab="tabContent0_3"
                aria-controls="tabContent0_3"
                aria-selected="false"
                tabIndex="-1"
                data-id="productGallery30"
                onClick={() => setActiveTab("3")}
                onKeyDown={() => setActiveTab("3")}
              >
                <span>Storage</span>
              </li>
            ) : (
              ""
            )}
          </ul>
          <div className="u-invisible s-productGallery3__tabRight"></div>
          <div className="l-tabView__panels">
            <div
              className={`l-tabView__panels__panel ${
                activeTab === "0" ? "l-tabView__panels__panel--active" : ""
              }`}
              role="tabpanel"
              aria-labelledby="tab0_0"
              id="tabContent0_0"
            >
              {contentOptions.note && contentOptions.note.checkbox == true ? (
                <div className={`c-notification${noteOpen ? "" : " c-notification--close"}`}>
                  <div className="l-inner">
                    <p>{contentOptions.note.field}</p>
                  </div>
                  <button
                    className="c-notification__close"
                    href="#"
                    aria-label="Close Button"
                    onClick={handleNotificationClick}
                  >
                    <svg viewBox="0 0 14 14">
                      <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                      <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                    </svg>
                  </button>
                </div>
              ) : (
                ""
              )}
              {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                <div className="s-productGallery3__filterLayout">
                  <C_ProductFilter
                    fType="menus"
                    fOptions={{ Lightning: "2", Wired: "3", Platform: "2" }}
                    controls={contentOptions.controls && contentOptions.controls.selected}
                    filterlink={
                      contentOptions.filterlink && contentOptions.filterlink.checkbox
                        ? contentOptions.filterlink.field
                        : ""
                    }
                  />
                  <PGTab1 sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""} />
                </div>
              ) : (
                <ul
                  className={`l-gridFlex s-productGallery3__grid${
                    expanded ? " s-productGallery3__grid--active" : ""
                  }`}
                  data-min="288"
                  data-max="480"
                >
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-0"
                    pname={contentOptions.name ? contentOptions.name.field : "Product Name"}
                    tag={"Sale"}
                    partNumber={contentOptions.part ? contentOptions.part.field : "KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={
                      contentOptions.details1
                        ? contentOptions.details1.field
                        : "This is Bullet Number One and it goes on for a little while here."
                    }
                    details2={
                      contentOptions.details2
                        ? contentOptions.details2.field
                        : "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    currentPrice={contentOptions.price ? contentOptions.price.field : "$66.98"}
                    panelCopy={
                      contentOptions.copy
                        ? contentOptions.copy.field
                        : "Product scheduled to ship 1 - 2 weeks"
                    }
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"quantitypartners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-1"
                    kit={"4"}
                    pname="2GB Module - DDR3 1600MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    // No currentPrice will make product "out of stock"
                    buyBtn={true}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"partners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-2"
                    pname='"DC450R 2.5" Enterprise SSD'
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    form="where"
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-3"
                    pname="UV500 SATA SSD"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    form="quantity
          "
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    btns={{ main: "Buy" }}
                  />
                  <C_ProductCard4
                    //this class centers a "Temporarile Out of Stock" when there is no buy button
                    pclasses=" c-productCard4--singleCTA"
                    type="tab"
                    id="productCard4-4"
                    pname="4GB Module - DDR4 2666MHz"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={"https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"}
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={false}
                    // No current price value adds a "temorarily out of stock" span.
                    //
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-5"
                    pname="DRAM"
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dtmicroduo3c-dtduo3c128gb-1-tn.png"
                    }
                    partNumber={"KTH-PL426/16G"}
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    form={"partners"}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-1"
                    kit={"4"}
                    pname="DataTraveler MicroDuo 3C USB Flash Drive"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-d300-standard-ikd300128gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    form="where"
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    type="tab"
                    id="productCard4-0"
                    pname="NV1 NVMe PCIe SSD"
                    partNumber={"KTH-PL426/16G"}
                    tag={"Sale"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={true}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"quantitypartners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--singleCTA"
                    type="tab"
                    id="productCard4-0"
                    pname="Canvas Go! Plus SD Memory Card"
                    partNumber={"KTH-PL426/16G"}
                    tag={"Sale"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-flash-sdcards-sdg3-512gb-1-tn.png"
                    }
                    selections={[
                      { name: "size16", label: "16 GB", disabled: false, checked: true },
                      { name: "size32", label: "32 GB", disabled: false, checked: false },
                      { name: "size64", label: "64 GB", disabled: true, checked: false },
                      { name: "size128", label: "128 GB", disabled: false, checked: false },
                    ]}
                    details1={"This is Bullet Number One and it goes on for a little while here."}
                    details2={
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short."
                    }
                    buyBtn={false}
                    replaced={{ name: "KTH-PL426/16G", url: "#link" }}
                  />
                </ul>
              )}
            </div>
            <div
              className={`l-tabView__panels__panel ${
                activeTab === "1" ? "l-tabView__panels__panel--active" : ""
              }`}
              role="tabpanel"
              aria-labelledby="tab0_1"
              id="tabContent0_1"
            >
              {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                <div className="s-productGallery3__filterLayout">
                  <C_ProductFilter
                    fType="menus"
                    fOptions={{ Lightning: "2", Wired: "3", Platform: "2" }}
                    controls={contentOptions.controls && contentOptions.controls.selected}
                    filterlink={
                      contentOptions.filterlink && contentOptions.filterlink.checkbox
                        ? contentOptions.filterlink.field
                        : ""
                    }
                  />
                  <PGTab2 sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""} />
                </div>
              ) : (
                <ul className={`l-gridFlex s-productGallery3__grid`} data-min="288" data-max="480">
                  <C_ProductCard4
                    pclasses=" c-productCard4--product"
                    type={"product"}
                    tag={"Sale"}
                    id="productCard4-9"
                    pname={contentOptions.name ? contentOptions.name.field : "Product Name"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dt100g3-dt100g3256gb-1-tn.png"
                    }
                    details={[
                      contentOptions.details1 ? contentOptions.details1.field : "",
                      contentOptions.details2 ? contentOptions.details2.field : "",
                      contentOptions.details3 ? contentOptions.details3.field : "",
                      contentOptions.details4 ? contentOptions.details4.field : "",
                    ]}
                    nonDRAM={true}
                    buyBtn={false}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    btns={{ main: "Buy", alt: "Learn More" }}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--singleCTA"
                    type={"product"}
                    id="productCard4-1"
                    pname="DataTraveler Elite G2 USB Flash Drive"
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-usb-dteliteg2-dteg2128gb-1-tn.png"
                    }
                    details={[
                      "Shock and water-resistant casing, removable cap",
                      "USB 3.1 Gen 1 (USB 3.0)",
                      "64GB, 128GB",
                      "Up to 180MB/s read, 70MB/s write",
                    ]}
                    nonDRAM={true}
                    buyBtn={false}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    btns={{ main: "Learn More" }}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product"
                    type={"product"}
                    id="productCard4-2"
                    pname='"DC450R 2.5" Enterprise SSD'
                    imgSrc={
                      "https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                    }
                    details={[
                      "Optimized for read-centric applications",
                      '2.5" form factor',
                      "480GB, 960GB, 1.92TB, 3.84TB, 7.68TB",
                      "Up to 560MB/s read, 530MB/s write",
                    ]}
                    nonDRAM={true}
                    buyBtn={false}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--singleCTA"
                    type={"productparagraph"}
                    id="productCard4-3"
                    pname="UV500 SATA SSD"
                    imgSrc={
                      "https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                    }
                    details="HyperX Cloud Stinger Core Gaming Headset"
                    nonDRAM={true}
                    buyBtn={false}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    btns={{ main: "Buy" }}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product"
                    type={"product"}
                    id="productCard4-4"
                    pname="4GB Module - DDR4 2666MHz"
                    imgSrc={"https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"}
                    details={[
                      "This is Bullet Number One and it goes on for a little while here.",
                      "Second Bullet Point Goes Here, thank you very much for not keeping it short.",
                      "Maybe a Third One As Well, who knows……",
                      "Maybe a Third One As Well",
                      "Second Bullet Point Goes Here",
                    ]}
                    nonDRAM={true}
                    buyBtn={false}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                </ul>
              )}
            </div>
            <div
              className={`l-tabView__panels__panel ${
                activeTab === "2" ? "l-tabView__panels__panel--active" : ""
              }`}
              role="tabpanel"
              aria-labelledby="tab0_2"
              id="tabContent0_2"
            >
              {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                <div className="s-productGallery3__filterLayout">
                  <C_ProductFilter
                    fType="menus"
                    fOptions={{ Lightning: "2", Wired: "3", Platform: "2" }}
                    controls={contentOptions.controls && contentOptions.controls.selected}
                    filterlink={
                      contentOptions.filterlink && contentOptions.filterlink.checkbox
                        ? contentOptions.filterlink.field
                        : ""
                    }
                  />
                  <PGTab3 sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""} />
                </div>
              ) : (
                <ul
                  className={`l-gridFlex s-productGallery3__grid l-gridFlex--list${
                    expanded ? " s-productGallery3__grid--active" : ""
                  }`}
                  data-min="288"
                  data-max="480"
                >
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-0"
                    pname={contentOptions.name && contentOptions.name.field}
                    tag={"Sale"}
                    partNumber={contentOptions.part && contentOptions.part.field}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                    }
                    description={contentOptions.desc && contentOptions.desc.field}
                    buyBtn={true}
                    currentPrice={contentOptions.price && contentOptions.price.field}
                    panelCopy={contentOptions.copy && contentOptions.copy.field}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"quantitypartners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-1"
                    kit={"4"}
                    pname="2GB Module - DDR3 1600MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                    }
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    // No currentPrice will make product "out of stock"
                    buyBtn={true}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"partners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-2"
                    pname="2GB Module - DDR3 1600MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_DIMM_S6_1-tn.png"
                    }
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    // No currentPrice will make product "out of stock"
                    buyBtn={true}
                    form={"where"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-3"
                    pname="4GB Module - DDR4 2400MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/images/products/DDR4_Non-ECC_Unbuffered_DIMM_S6_1_tn.png"
                    }
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    buyBtn={true}
                    form={"quantity"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-4"
                    pname="4GB Module - DDR4 2666MHz"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                    }
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    // No currentPrice will make product "out of stock"
                    buyBtn={false}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-5"
                    pname="DRAM"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                    }
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    buyBtn={true}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"partners"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-6"
                    kit={"4"}
                    pname="DRAM"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={"/images/fpo/general/200x52.png"}
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    buyBtn={true}
                    partners={[
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                      {
                        name: "Insight",
                        link: "#",
                        logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
                      },
                    ]}
                    form={"where"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--listView"
                    id="productCard4-7"
                    pname="DRAM"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={"/images/fpo/general/200x52.png"}
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    originalPrice={"$122.54"}
                    currentPrice={"$66.98"}
                    buyBtn={true}
                    form={"quantity"}
                    buyClick={(tf) => handleExpandedActive(tf)}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
                    id="productCard4-8"
                    pname="DRAM: Product Name Link"
                    tag={"Sale"}
                    partNumber={"KTH-PL426/16G"}
                    imgSrc={"/images/fpo/general/200x52.png"}
                    description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
                    buyBtn={false}
                    replaced={{ name: "KTH-PL426/16G", url: "#link" }}
                  />
                </ul>
              )}
            </div>
            <div
              className={`l-tabView__panels__panel ${
                activeTab === "3" ? "l-tabView__panels__panel--active" : ""
              }`}
              role="tabpanel"
              aria-labelledby="tab0_3"
              id="tabContent0_3"
            >
              {contentOptions.filter && contentOptions.filter.checkbox == true ? (
                <div className="s-productGallery3__filterLayout">
                  <C_ProductFilter
                    fType="checkboxes"
                    fOptions="4"
                    controls={contentOptions.controls && contentOptions.controls.selected}
                    filterlink={
                      contentOptions.filterlink && contentOptions.filterlink.checkbox
                        ? contentOptions.filterlink.field
                        : ""
                    }
                  />
                  <PGTab4 sort={contentOptions.sort && contentOptions.sort.checkbox ? true : ""} />
                </div>
              ) : (
                <ul
                  className="l-gridFlex s-productGallery3__grid l-gridFlex--list"
                  data-min="288"
                  data-max="480"
                >
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--dram c-productCard4--listView"
                    id="productCard4-0"
                    pname={contentOptions.name && contentOptions.name.field}
                    type={"product"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                    }
                    details={[
                      contentOptions.details1 && contentOptions.details1.field,
                      contentOptions.details2 && contentOptions.details2.field,
                      contentOptions.details3 && contentOptions.details3.field,
                    ]}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--dram c-productCard4--listView"
                    id="productCard4-1"
                    pname="DDR4 3200MHz ECC Registered DIMM"
                    type={"product"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                    }
                    details={[
                      "8GB, 16GB, 32GB, 64GB",
                      "1.2V 288-pin CL22",
                      "Server Premier",
                      "Up to 180MB/s read, 70MB/s write",
                    ]}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--dram c-productCard4--listView"
                    id="productCard4-2"
                    pname="2GB Module - DDR3 1600MHz"
                    type={"product"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_DIMM_S6_1-tn.png"
                    }
                    details={["4GB, 8GB, 16GB, 32GB", "1.2V CL19 288-pin"]}
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--dram c-productCard4--listView"
                    id="productCard4-3"
                    pname="4GB Module - DDR4 2400MHz"
                    type={"productparagraph"}
                    imgSrc={
                      "https://media.kingston.com/images/products/DDR4_Non-ECC_Unbuffered_DIMM_S6_1_tn.png"
                    }
                    details="HyperX Cloud Stinger Core Gaming Headset"
                  />
                  <C_ProductCard4
                    pclasses=" c-productCard4--product c-productCard4--dram c-productCard4--listView"
                    id="productCard4-4"
                    pname="4GB Module - DDR4 2666MHz"
                    type={"product"}
                    imgSrc={
                      "https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                    }
                    details={["64GB", "1.2V 288-pin CL21", "Server Premier"]}
                  />
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-gallery-tab",
  component: S_ProductGalleryTab,
  navtxt: "Product Gallery : Tab",
  htmlName: "ProductGalleryTab",
  categoryType: ["internal"],
};
