export default {
  numModelItems: {
    label: "Number of Model Items",
    checkbox: null,
    field: ["1", "2", "3", "4", "More than 4"],
    selected: "More than 4",
  },

  numMemoryItems: {
    label: "Number of Memory Items",
    checkbox: null,
    field: ["1", "2", "3"],
    selected: "2",
  },

  numMemorySocketItems: {
    label: "Number of Memory Socket Items",
    checkbox: null,
    field: ["1", "2", "3"],
    selected: "3",
  },

  numStorageItems: {
    label: "Number of Storage Items",
    checkbox: null,
    field: ["1", "2", "3"],
    selected: "3",
  },

  models: [
    {
      label: "Models",
      checkbox: true,
      field: null,
    },
    {
      label: "Models Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof ",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат м",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais ",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- ",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không b",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey,",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... --",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตาม",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 ",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユ",
      },
    },
    {
      label: "Models Item 1 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Models Item 1 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
    {
      label: "Models Item 2 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Models Item 2 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
    {
      label: "Models Item 3 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Models Item 3 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
    {
      label: "Models Item 4 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Models Item 4 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
  ],

  systems: [
    {
      label: "System Model",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
    {
      label: "System CPU",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
    {
      label: "System OS",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
    },
  ],

  memory: [
    {
      label: "Memory Size",
      checkbox: null,
      field: "16GB",
    },
    {
      label: "Memory Item 1 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Item 1 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
    {
      label: "Memory Item 2 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Item 2 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
    {
      label: "Memory Item 3 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Item 3 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
  ],

  memorysocket: [
    {
      label: "Memory Socket Copy",
      checkbox: null,
      field: {
        Eng: "Dolor sit amet, consectetur adipiscing elit sed do eiusmod. ",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 1 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 1 Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 2 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 2 Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 3 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Socket Item 3 Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Memory Upgrade Recommendations",
      checkbox: true,
      field: null,
    },
  ],

  storage: [
    {
      label: "Storage Size",
      checkbox: null,
      field: "1.77TB",
    },
    {
      label: "Storage Item 1 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Storage Item 1 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
    {
      label: "Storage Item 2 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Storage Item 2 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
    {
      label: "Storage Item 3 Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
    {
      label: "Storage Item 3 Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
    },
    {
      label: "Storage Upgrade Recommendations",
      checkbox: false,
      field: null,
    },
  ],

  question: [
    {
      label: "Questions CTA Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof ",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат м",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais ",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- ",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không b",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey,",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... --",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตาม",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 ",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユ",
      },
    },
    {
      label: "Questions Button Text",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
  ],

  noresults: [
    {
      label: "No Results",
      checkbox: false,
      field: null,
    },
    {
      label: "No Scan Results Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof ",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат м",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais ",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- ",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không b",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey,",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... --",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตาม",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 ",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユ",
      },
    },
    {
      label: "No Scan Button Text",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Hacía calo",
        ChS: "那个天气很热",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w",
        Port: "Fazia calor",
        Viet: "Thời tiết đó",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
    },
  ],
  currentLang: "Eng",
};
