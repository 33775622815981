import { useContext, useEffect, useRef, useState } from "react";
import DimensionsContext from "../../../DimensionsContext";
import OptionsContext from "../../../OptionsContext";

const L_FlexTiles = ({ children, ...props }) => {
  const [tileCount, setTileCount] = useState(0);
  const [tileStyle, setTileStyle] = useState({});
  const [winWidth, setWinWidth] = useState(null);
  const [centered, setCentered] = useState({});
  const [stacking, setStacking] = useState(false);
  const [maxed, setMaxed] = useState(false);
  const { dimensions } = useContext(DimensionsContext);
  const { contentOptions } = useContext(OptionsContext);

  let altClass = props.sectionClass;

  const contRef = useRef(null);

  useEffect(() => {
    //get the num of items for flex tiles
    let num = children.length;
    setTileCount(num);
    if (contRef) setWinWidth(contRef.current.offsetWidth);

    let min = props.minWidth || 100;
    let max = props.maxWidth || props.minWidth * 1.5;

    if (winWidth && winWidth > 0) {
      // -- calculate the potential width per tile against parent container
      let expectedWidth = winWidth / tileCount;

      //how many per row at full width?
      let maxPerRow = Math.ceil(winWidth / max);

      let adjustedWidth;

      //will they stack? 1 per row
      if (winWidth > 0 && winWidth / min < 2) {
        setStacking(true);
      } else {
        setStacking(false);
      }

      //Begin checks
      if (expectedWidth > max && !maxed) {
        /*
        This condition means that all tiles are higher than
        the max width 
      */

        setMaxed(true);
        // then center
        setCentered({ justifyContent: "center" });
        //and use max width
        setTileStyle({ width: `${max}px` });
        setStacking(false);
      } else if (expectedWidth < max && !stacking) {
        /*
        This condition means that all tiles are below
        the max width but greater than the min width
      */

        setCentered({});
        setMaxed(false);
        setStacking(false);
        adjustedWidth = winWidth / maxPerRow;

        if (winWidth / min < 2) {
          if (!stacking) setStacking(true);
        } else if (winWidth / min >= 2) {
          setTileStyle({ width: `${adjustedWidth}px` });
        }
      } else if (stacking) {
        //not sure why we are checking stacking but it was in the old code
        if (winWidth / min >= 2)
          if (stacking) {
            setStacking(false);
          } else {
            setStacking(true);
          }
        setCentered({});
        // -- if there is no room for 2 per row, begin stacking
      }
    }
    //watch for changes in dimensions
  }, [dimensions.width, contentOptions, contRef, winWidth]);

  return (
    <ul
      className={`l-flexTiles ` + (altClass ? altClass : "")}
      data-min-width={props.minWidth}
      data-max-width={props.maxWidth}
      role="region"
      style={centered}
      ref={contRef}
    >
      {children.map((child, index) => (
        <li
          className={
            `l-flexTiles__item ` +
            (altClass == "l-flexTiles--checkered" && index + (1 % 2)
              ? "l-flexTiles__item--alternate"
              : "")
          }
          data-flex-tile="true"
          data-index={index}
          key={index}
          style={tileStyle}
        >
          {child}
        </li>
      ))}
    </ul>
  );
};

export default L_FlexTiles;
