/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_ProductCard4 from "../../2_components/productCard4/C_ProductCard4";

const S_ProductGalleryDramPart = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [expanded, setExpanded] = useState(false);
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/ui/product-gallery-dram-part",
      outputName: "ProductGalleryDramPart",
      headerName: "Product Gallery : DRAM :: Part Level",
      tags: null,
      description: "The Product Gallery displays part level cards in List View.",
      related: [
        ["Non-DRAM: Part Level", "product-gallery-non-dram-part"],
        ["DRAM: Product Level", "product-gallery-dram-product"],
        ["Non-DRAM: Product Level", "product-gallery-ndram-product"],
        ["Tab View", "product-gallery-tab"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/quantityCounter.component.js",
        "../../js/gridFlex.layout.js",
        "../../js/productGallery3.section.js",
        "../../js/productCard4.component.js",
      ],
      specs: [
        ["Title", ["Character Limit: none"]],
        ["Tag", ["String Text"]],
        ["Kit Number", ["String Text"]],
        ["Image", ["No defined image size: depends on product"]],
        ["Long Description", ["List Element containing lines of string text. No limit"]],
        ["Short Description", ["String Text"]],
        ["Product: CTA Buttons", ["Varies in Type: CTA String Text and Button"]],
        ["Price", ["String Text"]],
        ["Buy Options", ["Button Text"]],
      ],
      cis: null,
    });
    setContentOptions({
      heading: {
        label: "Heading",
        checkbox: true,
        field: "Lorem ipsum dolor sit amet",
      },
      headingcopy: {
        label: "Heading Copy",
        checkbox: true,
        field:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      },
      name: {
        label: "Product Name",
        checkbox: null,
        field: "Lorem ipsum dolor sit",
      },
      part: { label: "Part Number", checkbox: null, field: "KTH-PL426/16G" },
      desc: {
        label: "Description",
        checkbox: null,
        field: "Consectetur adipiscing elit",
      },
      dl1: { label: "Download 1", checkbox: null, field: "Tempor" },
      dl2: { label: "Download 2", checkbox: null, field: "Incididunt" },
      price: { label: "Price", checkbox: null, field: "$66.98" },
      copy: {
        label: "Buy Panel Copy",
        checkbox: null,
        field: "Sed do eiusmod tempor",
      },
      ctaLink: [
        {
          label: "CTA Link",
          checkbox: null,
          field: {
            Eng: "Lorem ipsum",
            Span: "Título de la pancarta",
            ChS: "横幅标题",
            Gr: "Bannertitel",
            Ru: "Название баннера",
            Fr: "Titre de la bannière",
            It: "Titolo dello striscione",
            Pl: "Tytuł banera",
            Port: "Título do banner",
            Viet: "Tiêu đề biểu ngữ",
            Turk: "Afiş Başlığı",
            Ukrn: "Заголовок банера",
            Thai: "ชื่อแบนเนอร์",
            Kor: "배너 제목",
            ChT: "橫幅標題",
            Jp: "バナータイトル",
          },
          block: 1,
        },
        {
          label: "CTA Icon",
          checkbox: true,
          field: "#aae",
          block: 1,
        },
      ],
      vatoption: {
        label: "VAT",
        checkbox: false,
        field: {
          Eng: "VAT included",
          Span: "VAT incluido",
          ChS: "VAT 包括",
          Gr: "VAT inbegriffen",
          Ru: "VAT включено",
          Fr: "VAT inclus",
          It: "VAT incluso",
          Pl: "VAT dołączony",
          Port: "VAT incluído",
          Viet: "VAT bao gồm",
          Turk: "VAT dahil",
          Ukrn: "VAT включені",
          Thai: "VAT รวมอยู่ด้วย",
          Kor: "VAT 포함됨",
          ChT: "VAT 包括",
          Jp: "VAT 含まれています",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "887",
      width: window.innerWidth - 24,
    });
  }, []);

  function handleExpandedActive(tf) {
    setExpanded(tf);
  }

  if (!contentOptions) return "...Loading Product Gallery - DRAM Part Section";
  return (
    <section className="s-productGallery3" id="productGallery30">
      {contentOptions.heading && contentOptions.heading.checkbox == true ? (
        <div className="c-heading">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h2>{contentOptions.heading.field}</h2>
              {contentOptions.headingcopy && contentOptions.headingcopy.checkbox ? (
                <p>{contentOptions.headingcopy.field}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <ul
        className={`l-gridFlex s-productGallery3__grid l-gridFlex--list${
          expanded ? " s-productGallery3__grid--active" : ""
        }`}
        data-min="288"
        data-max="480"
      >
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-0"
          pname={contentOptions.name && contentOptions.name.field}
          tag={"Sale"}
          partNumber={contentOptions.part && contentOptions.part.field}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
          }
          description={contentOptions.desc && contentOptions.desc.field}
          downloads={[
            {
              title: contentOptions.dl1 && contentOptions.dl1.field,
              url: "http://www.africau.edu/images/default/sample.pdf",
              text: contentOptions.dl1 && contentOptions.dl1.field,
            },
            {
              title: contentOptions.dl2 && contentOptions.dl2.field,
              url: "http://www.africau.edu/images/default/sample.pdf",
              text: contentOptions.dl2 && contentOptions.dl2.field,
            },
          ]}
          buyBtn={true}
          stock={"false"}
          currentPrice={contentOptions.price && contentOptions.price.field}
          vat={
            contentOptions.vatoption && contentOptions.vatoption.checkbox == true
              ? contentOptions.vatoption.field[contentOptions.currentLang]
              : ""
          }
          panelCopy={contentOptions.copy && contentOptions.copy.field}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"quantitypartners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-1"
          kit={"4"}
          pname="2GB Module - DDR3 1600MHz"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
          }
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          downloads={[
            {
              title: "PCN",
              url: "http://www.africau.edu/images/default/sample.pdf",
              text: "PCN",
            },
          ]}
          // No currentPrice will make product "out of stock"
          buyBtn={true}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"partners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-2"
          pname="2GB Module - DDR3 1600MHz"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_DIMM_S6_1-tn.png"
          }
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          downloads={[
            {
              title: "Spec Sheet PDF",
              url: "http://www.africau.edu/images/default/sample.pdf",
              text: "Spec Sheet PDF",
            },
          ]}
          // No currentPrice will make product "out of stock"
          buyBtn={true}
          form={"where"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-3"
          pname="4GB Module - DDR4 2400MHz"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/images/products/DDR4_Non-ECC_Unbuffered_DIMM_S6_1_tn.png"
          }
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          originalPrice={"$122.54"}
          currentPrice={"$66.98"}
          stock={"false"}
          vat={
            contentOptions.vatoption && contentOptions.vatoption.checkbox == true
              ? contentOptions.vatoption.field[contentOptions.currentLang]
              : ""
          }
          buyBtn={true}
          form={"quantity"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
          id="productCard4-4"
          pname="4GB Module - DDR4 2666MHz"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={
            "https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
          }
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          // No currentPrice will make product "out of stock"
          buyBtn={false}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-5"
          pname="DRAM"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={"/images/fpo/general/200x52.png"}
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          buyBtn={true}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"partners"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-6"
          kit={"4"}
          pname="DRAM"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={"/images/fpo/general/200x52.png"}
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          buyBtn={true}
          partners={[
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
            {
              name: "Insight",
              link: "#",
              logo: "https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png",
            },
          ]}
          form={"where"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--listView"
          id="productCard4-7"
          pname="DRAM"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={"/images/fpo/general/200x52.png"}
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          originalPrice={"$122.54"}
          currentPrice={"$66.98"}
          vat={
            contentOptions.vatoption && contentOptions.vatoption.checkbox == true
              ? contentOptions.vatoption.field[contentOptions.currentLang]
              : ""
          }
          buyBtn={true}
          form={"quantity"}
          buyClick={(tf) => handleExpandedActive(tf)}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
          id="productCard4-8"
          pname="DRAM: Product Name Link"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={"/images/fpo/general/200x52.png"}
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          buyBtn={false}
          replaced={{ name: "KTH-PL426/16G", url: "#link" }}
        />
        <C_ProductCard4
          pclasses=" c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
          id="productCard4-8"
          pname="DRAM: Product Name Link"
          tag={"Sale"}
          partNumber={"KTH-PL426/16G"}
          imgSrc={"/images/fpo/general/200x52.png"}
          description={"CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus"}
          buyBtn={false}
          ctaLink={{
            name:
              contentOptions.ctaLink &&
              contentOptions.ctaLink[0] &&
              contentOptions.ctaLink[0].field[contentOptions.currentLang],
            url: "#link",
            icon:
              contentOptions.ctaLink &&
              contentOptions.ctaLink[1] &&
              contentOptions.ctaLink[1].checkbox
                ? contentOptions.ctaLink[1].field
                : "",
          }}
        />
      </ul>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-gallery-dram-part",
  component: S_ProductGalleryDramPart,
  navtxt: "Product Gallery : DRAM :: Part Level",
  htmlName: "ProductGalleryDramPart",
  categoryType: ["web"],
};
