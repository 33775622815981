import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import NotifOpts from "./NotifOpts";

export const S_Notification = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [closeNote, setClosedNote] = useState(false);

  function handleXClick(e) {
    e.preventDefault();
    setClosedNote(true);
  }

  useEffect(() => {
    let notiVars = {
      ...appState,
      currentPath: "/ui/notification",
      currentTheme: "t-slate",
      outputName: "Notification",
      headerName: "Notification: Default",
      tags: null,
      description:
        "The Notification Section provides a way to present important alert or disclaimer information, and is intended to be used at the top of the page with a close button, or below relevant content without a close button. There can also but up to 2 CTA buttons, and link text.",
      related: [["Notification: Small", "notification-small"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/ajax.service.js",
        "../../js/notification.component.js",
      ],
      specs: [
        [
          "Icon (mobile, tablet, desktop)",
          [
            "1 icon image optional if there is a title",
            "Small, Medium, Large: Width and Height: paragraph icon size 20x20",
          ],
        ],
        ["Icon Image Type", ["SVG"]],
        [
          "Title",
          [
            "Optional, H3 tag by default but allows H2 to H6, although div is allowed. Can have optional icon next to it. No character limit but less than 60 characters recommended.",
          ],
        ],
        [
          "Copy",
          [
            "String of text wrapped in <p> tag. No character limit, but 3 lines or less is recommended. Can have a text link with recommendation of 2 words and no punctuation.",
          ],
        ],
        [
          "Close Button",
          [
            "Optional close button. Not intended for use in a disclaimer or other important information that should remain visible.",
          ],
        ],
        [
          "Primary Button",
          ["Optional small button. No icons allowed. Recommended character limit less than 20."],
        ],
        [
          "Primary Alternate Button",
          [
            "Optional small button with alternate color. Can be used instead of, not with, Primary color button. No icons allowed. Recommended character limit less than 20.",
          ],
        ],
        [
          "Secondary Button",
          [
            "Optional small button can only be shown with Primary button. No icons allowed. Recommended character limit less than 20.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EZ90FXIJxrxAoBXyPn7P8-IBPzCNpp_lAEn9gNDdl2ZH7g?e=0NdoVT",
        comments: [
          ["Template Location", ["Notification Admin >> Default Template"]],
          [
            "Icon (mobile, tablet, desktop)",
            [
              "1 icon image optional if there is a title",
              "Small, Medium, Large: Width and Height: paragraph icon size 20x20",
            ],
          ],
          ["Icon Image Type", ["SVG from icon map"]],
          [
            "Title",
            [
              "Optional, H3 tag by default but allows H2 to H6, although div is allowed. Can have optional icon next to it. No character limit but less than 60 characters recommended.",
            ],
          ],
          [
            "Copy",
            [
              "String of text wrapped in <p> tag. No character limit, but 3 lines or less is recommended. Can have a text link with recommendation of 2 words and no punctuation.",
            ],
          ],
          [
            "Close Button",
            [
              "Optional close button. Not intended for use in a disclaimer or other important information that should remain visible.",
            ],
          ],
          [
            "Primary Button",
            ["Optional small button. No icons allowed. Recommended character limit less than 20."],
          ],
          [
            "Primary Alternate Button",
            [
              "Optional small button with alternate color. Can be used instead of - not with - Primary color button. No icons allowed. Recommended character limit less than 20.",
            ],
          ],
          [
            "Secondary Button",
            [
              "Optional small button can only be shown with Primary button. No icons allowed. Recommended character limit less than 20.",
            ],
          ],
        ],
      },
    };
    setAppState(notiVars);

    setContentOptions(NotifOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "156",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Notification";
  return (
    <section className={"s-notification " + appState.currentTheme}>
      <div className={`c-notification ${closeNote ? "c-notification--close" : ""}`}>
        <div className="l-inner">
          <div className="l-row">
            <div className="c-notification__title">
              {contentOptions.svg && contentOptions.svg.checkbox ? (
                <svg className="s-links__icon">
                  <use
                    xlinkHref={`../images/icons-map.svg${contentOptions.svg.field}`}
                    alt="Please read"
                  ></use>
                </svg>
              ) : (
                ""
              )}
              {(contentOptions.title && contentOptions.title.checkbox) ||
              (contentOptions.svg && contentOptions.svg.checkbox) ? (
                contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                  <h2 className="u-h5">{contentOptions.title.field[contentOptions.currentLang]}</h2>
                ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                  <h3 className="u-h5">{contentOptions.title.field[contentOptions.currentLang]}</h3>
                ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                  <h4 className="u-h5">{contentOptions.title.field[contentOptions.currentLang]}</h4>
                ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                  <h5 className="u-h5">{contentOptions.title.field[contentOptions.currentLang]}</h5>
                ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                  <h6 className="u-h5">{contentOptions.title.field[contentOptions.currentLang]}</h6>
                ) : (
                  <div className="u-h5">
                    {contentOptions.title.field[contentOptions.currentLang]}
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="l-row">
            <div className="c-notification__text">
              <p>
                {contentOptions.text && contentOptions.text.field[contentOptions.currentLang]}
                {contentOptions.linktext && (
                  <a href="#link">{contentOptions.linktext.field[contentOptions.currentLang]}</a>
                )}
              </p>
            </div>
            <div className="c-notification__buttons">
              {(contentOptions.btn2 &&
                contentOptions.btn2.checkbox &&
                contentOptions.btn &&
                contentOptions.btn.checkbox &&
                contentOptions.btnalt &&
                !contentOptions.btnalt.checkbox) ||
              (contentOptions.btn2 &&
                contentOptions.btn2.checkbox &&
                contentOptions.btnalt &&
                contentOptions.btnalt.checkbox &&
                !contentOptions.btn.checkbox) ? (
                <button
                  target="_self"
                  title="Button Title"
                  href="#button"
                  className={`e-btn e-btn--alt2`}
                >
                  <span>
                    {contentOptions.btn2 && contentOptions.btn2.field[contentOptions.currentLang]}
                  </span>
                </button>
              ) : (
                ""
              )}
              {contentOptions.btn &&
              contentOptions.btn.checkbox &&
              !contentOptions.btnalt.checkbox ? (
                <button target="_self" title="Button Title" href="#button" className={`e-btn`}>
                  <span>
                    {contentOptions.btn && contentOptions.btn.field[contentOptions.currentLang]}
                  </span>
                </button>
              ) : (
                ""
              )}
              {contentOptions.btnalt &&
              contentOptions.btnalt.checkbox &&
              !contentOptions.btn.checkbox ? (
                <button
                  target="_self"
                  title="Button Title"
                  href="#button"
                  className={`e-btn e-btn--alt1`}
                >
                  <span>
                    {contentOptions.btnalt &&
                      contentOptions.btnalt.field[contentOptions.currentLang]}
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {contentOptions.xbutton && contentOptions.xbutton.checkbox ? (
          <button
            className="c-notification__close"
            href="#"
            aria-label="Close Button"
            onClick={(e) => handleXClick(e)}
          >
            <svg viewBox="0 0 24 24">
              <path d="m17.614 16.194-4.25-4.19 3.54-3.58.71-.71a1.004 1.004 0 1 0-1.42-1.42l-.7.71-3.49 3.54-4.29-4.25a1.004 1.004 0 0 0-1.42 1.42l4.25 4.29-3.54 3.49-.71.7a1.004 1.004 0 0 0 1.42 1.42l.71-.71 3.58-3.54 4.24 4.25a1.004 1.004 0 1 0 1.42-1.42h-.05Z" />
            </svg>
          </button>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default {
  path: "notification",
  component: S_Notification,
  navtxt: "Notification: Default",
  htmlName: "Notification",
  categoryType: ["web"],
};
