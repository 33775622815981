export const handleCheckboxChange = (e, options, contentOptions, setContentOptions) => {
  //get the option array to edit from contentOptions Context (grab the array, find our option)
  const getOptionByType = (opt) => {
    if (Array.isArray(options[opt])) {
      //find label in block array here
      let blockLabel = "";
      options[opt].forEach((item) => {
        if (item.label === e.target.name) {
          // console.log("options[opt][i]: ", options[opt][i]);
          blockLabel = item.label;
        }
      });
      return blockLabel;
    } else {
      if (options[opt].label === e.target.name) {
        return options[opt].label;
      }
    }
  };

  let optArr = Object.keys(contentOptions).filter((opt) => getOptionByType(opt));
  let optKey = optArr[0];
  // console.log("optKey: ", optKey);

  if (Array.isArray(options[optKey])) {
    //do set correct option here
    //one-liner replace obj data
    const updatedData = options[optKey].map((x) =>
      x.label === e.target.name ? { ...x, checkbox: !x.checkbox } : x,
    );
    // console.log("updatedData: ", updatedData);
    let optionsCopy = { ...options, [optKey]: updatedData };

    // replace options in contentOptions with our newly edited array copy
    setContentOptions({ ...optionsCopy });
  } else {
    let optObj = options[optKey];
    optObj.checkbox = !optObj.checkbox;
    // make a copy of part of state to be changed; never edit state/context directly - replace it
    let optionsCopy = { ...options, [optKey]: optObj };
    // replace options in contentOptions with our newly edited array copy
    setContentOptions({ ...optionsCopy });
  }
};
