import React from "react";
import C_FilterGallerySort from "../../2_components/filterGallerySort/C_FilterGallerySort";
import C_SupportGalleryCard from "../../2_components/supportGalleryCard/C_SupportGalleryCard";

const SGCTab1 = ({ name, partnumber, sort, sortlayout, sortoptions }) => {
  return (
    <div className="l-filterGallery__layout__view">
      <C_FilterGallerySort fid="0" sort={sort} sortlayout={sortlayout} sortoptions={sortoptions} />

      <ul className={`l-gridFlex`} data-min="288" data-max="480">
        <li>
          <C_SupportGalleryCard
            name={name}
            partnumber={partnumber}
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-a400-sa400s37-1-tn.png"
            url="#supportGalleryCardLink1"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="UV500 SSD"
            partnumber="SUV500"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
            url="#supportGalleryCardLink2"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="A1000 SSD"
            partnumber="SA1000M8"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
            url="#supportGalleryCardLink3"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="KC1000"
            partnumber="SKC1000"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
            url="#supportGalleryCardLink4"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="DC500R SSD"
            partnumber="SEDC500R"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
            url="#supportGalleryCardLink5"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="V300"
            partnumber="SV300S3"
            image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
            url="#supportGalleryCardLink6"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="UV500 SSD2"
            partnumber="SUV500"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-uv500-25-driveonly-2-tn.png"
            url="#supportGalleryCardLink2"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="A1000 SSD"
            partnumber="SA1000M8"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-a1000-sa1000mb-1-tn.png"
            url="#supportGalleryCardLink3"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="KC1000"
            partnumber="SKC1000"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-kc1000-driveonly-2-tn.jpg"
            url="#supportGalleryCardLink4"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="DC500R SSD"
            partnumber="SEDC500R"
            image="https://media.kingston.com/kingston/product/ktc-product-ssd-dc500r-sedc500r480g-1-tn.png"
            url="#supportGalleryCardLink5"
          />
        </li>
        <li>
          <C_SupportGalleryCard
            name="V300"
            partnumber="SV300S3"
            image="https://media.kingston.com/images/products/SV300S37A_480GB_tn.png"
            url="#supportGalleryCardLink6"
          />
        </li>
      </ul>
    </div>
  );
};

export default SGCTab1;
