/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import "./iconCard.component.scss";

const Icon = ({ name, num, click, vb, icons, disable }) => {
  return (
    <li
      data-index={num ? num : null}
      onClick={() => click(name)}
      style={{ opacity: `${disable ? "0.5" : "1"}` }}
    >
      <div className="c-iconCard" title={name} data-index={num - 1}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={vb}>
          <use href={`${icons}#${name}`} />
        </svg>
      </div>
    </li>
  );
};

export default Icon;
