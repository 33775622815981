export default {
  numBreadcrumbLinks: {
    label: "Number of Links",
    checkbox: null,
    field: [2, 3, 4, "5 or more"],
    selected: 4,
  },
  link1: {
    label: "Link 1",
    checkbox: null,
    field: {
      Eng: "Kingston",
      Span: "Elace 1",
      ChS: "链接1",
      Gr: "Link 1",
      Ru: "Ссылка 1",
      Fr: "Lien 1",
      It: "Collegamento 1",
      Pl: "Link 1",
      Port: "Ligação 1",
      Viet: "Liên kết 1",
      Turk: "Bağlantı 1",
      Ukrn: "Посилання 1",
      Thai: "ลิงค์ 1",
      Kor: "링크 1",
      ChT: "連結1",
      Jp: "リンク1",
    },
  },
  link2: {
    label: "Link 2",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum 2",
      Span: "Elace 2",
      ChS: "链接2",
      Gr: "Link 2",
      Ru: "Ссылка 2",
      Fr: "Lien 2",
      It: "Collegamento 2",
      Pl: "Link 2",
      Port: "Ligação 2",
      Viet: "Liên kết 2",
      Turk: "Bağlantı 2",
      Ukrn: "Посилання 2",
      Thai: "ลิงค์ 2",
      Kor: "링크 2",
      ChT: "連結2",
      Jp: "リンク2",
    },
  },
  link3: {
    label: "Link 3",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum 3",
      Span: "Elace 3",
      ChS: "链接3",
      Gr: "Link 3",
      Ru: "Ссылка 3",
      Fr: "Lien 3",
      It: "Collegamento 3",
      Pl: "Link 3",
      Port: "Ligação 3",
      Viet: "Liên kết 3",
      Turk: "Bağlantı 3",
      Ukrn: "Посилання 3",
      Thai: "ลิงค์ 3",
      Kor: "링크 3",
      ChT: "連結3",
      Jp: "リンク3",
    },
  },
  link4: {
    label: "Link 4",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum 4",
      Span: "Elace 4",
      ChS: "链接4",
      Gr: "Link 4",
      Ru: "Ссылка 4",
      Fr: "Lien 4",
      It: "Collegamento 4",
      Pl: "Link 4",
      Port: "Ligação 4",
      Viet: "Liên kết 4",
      Turk: "Bağlantı 4",
      Ukrn: "Посилання 4",
      Thai: "ลิงค์ 4",
      Kor: "링크 4",
      ChT: "連結4",
      Jp: "リンク4",
    },
  },
  link5: {
    label: "Link 5",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum 5",
      Span: "Elace 5",
      ChS: "链接5",
      Gr: "Link 5",
      Ru: "Ссылка 5",
      Fr: "Lien 5",
      It: "Collegamento 5",
      Pl: "Link 5",
      Port: "Ligação 5",
      Viet: "Liên kết 5",
      Turk: "Bağlantı 5",
      Ukrn: "Посилання 5",
      Thai: "ลิงค์ 5",
      Kor: "링크 5",
      ChT: "連結5",
      Jp: "リンク5",
    },
  },
  currentLang: "Eng",
};
