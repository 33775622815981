import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Tables = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let tableVars = {
      ...appState,
      currentPath: "/ui/tables",
      outputName: "Tables",
      headerName: "Tables",
      tags: null,
      description: "The Tables Section has different table scenarios",
      related: null,
      htmlSaved: false,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Order Summary",
          ["Summary of orders in cart. Can edit, update, and delete item listings"],
        ],
        ["Form", ["Form Inputs for: Shipping, Delivery, and Billing"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EW1vXrl-R95ErUj0xdUUcEgB2CnZrEboXUOr9RHp5dUvdg?e=3STLRi",
        comments: null,
      },
    };
    setAppState(tableVars);
    setContentOptions({});
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "2375",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Tables";
  return (
    <>
      <h2 className="t-white">Single Column Tables</h2>
      <div className="c-table">
        <div className="c-table__main">
          <table>
            <thead>
              <tr>
                <th scope="col">Table Heading</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Table Details - A-N68SV Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AB9 QuadGT Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AB9 F-I90HD Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AN9 32XF Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AN9 32XF Motherboard</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="c-table__cta">
          <a href="#link">Table CTA Link</a>
        </div>
      </div>
      <h5>Striped, no heading, no CTA:</h5>
      <div className="c-table c-table--striped">
        <div className="c-table__main">
          <table>
            <tbody>
              <tr>
                <td>Table Details - A-N68SV Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AB9 QuadGT Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - F-I90HD Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AN9 32XF Motherboard</td>
              </tr>
              <tr>
                <td>ABIT - AN9 32XF Motherboard</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h2>Double Column Table</h2>
      <div className="c-table c-table--2col">
        <div className="c-table__main">
          <table>
            <thead>
              <tr>
                <th scope="col">Kingston Facility</th>
                <th scope="col">Certification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h5>Striped w/ side keys and no header:</h5>
      <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
        <div className="c-table__main">
          <table>
            <tbody>
              <tr>
                <td>Driver:</td>
                <td>Dynamic 14mm with neodymium magnets</td>
              </tr>
              <tr>
                <td>Frequency response:</td>
                <td>20Hz–20,000Hz</td>
              </tr>
              <tr>
                <td>Impedance:</td>
                <td>65 Ω</td>
              </tr>
              <tr>
                <td>Sound pressure level:</td>
                <td>116dBSPL/mW at 1kHz</td>
              </tr>
              <tr>
                <td>T.H.D.:</td>
                <td>&lt; 2%</td>
              </tr>
              <tr>
                <td>Weigth:</td>
                <td>19g</td>
              </tr>
              <tr>
                <td>Cable type and length:</td>
                <td>4 pole, 1.2m</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h2>Multiple Columns Table</h2>
      <div className="c-table c-table--striped">
        <div className="c-table__main">
          <table>
            <thead>
              <tr>
                <th scope="col">Form Factor</th>
                <th scope="col">Interface</th>
                <th scope="col">Capacity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2.5&quot; 7mm</td>
                <td>SATA 3</td>
                <td>64GB-512GB</td>
              </tr>
              <tr>
                <td>2.5&quot; 7mm</td>
                <td>SATA 3</td>
                <td>64GB-512GB</td>
              </tr>
              <tr>
                <td>2.5&quot; 7mm</td>
                <td>SATA 3</td>
                <td>64GB-512GB</td>
              </tr>
              <tr>
                <td>2.5&quot; 7mm</td>
                <td>SATA 3</td>
                <td>64GB-512GB</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="c-table c-table--striped">
        <div className="c-table__main">
          <table>
            <thead>
              <tr>
                <th scope="col">Distributor Part Number</th>
                <th scope="col">Kingston Part Number</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0ZK-01M8-00124</td>
                <td>KCP3L16SD8/8</td>
                <td>8GB 1600MHz Low Voltage SODIMM</td>
              </tr>
              <tr>
                <td>0ZK-01M8-00124</td>
                <td>KCP3L16SD8/8</td>
                <td>8GB 1600MHz Low Voltage SODIMM</td>
              </tr>
              <tr>
                <td>0ZK-01M8-00124</td>
                <td>KCP3L16SD8/8</td>
                <td>8GB 1600MHz Low Voltage SODIMM</td>
              </tr>
              <tr>
                <td>0ZK-01M8-00124</td>
                <td>KCP3L16SD8/8</td>
                <td>8GB 1600MHz Low Voltage SODIMM</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="c-table__cta">
          <a href="#link">View All 257 Results</a>
        </div>
      </div>
      <h2>Double Column Table w/ Multiple Table Headers</h2>
      <div className="c-table c-table--2col c-table--thead">
        <div className="c-table__main">
          <table>
            <thead>
              <tr>
                <th scope="col" colSpan="2">
                  Kingston Facility
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <th scope="col" colSpan="2">
                  Another Table Heading
                </th>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
              <tr>
                <td>Kingston, United Kingdom, OHSAS 18001</td>
                <td>
                  <a href="#link">KTFVOHSAS1800.pdf</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "tables",
  component: S_Tables,
  navtxt: "Tables",
  htmlName: "Tables",
  categoryType: ["web"],
};
