import React, { useState } from "react";
import DropZone from "../../../0_hooks/dropzone";

const C_HorizontalCard = ({ type, radius, copy, ...props }) => {
  let hType = type ? type : "default";
  let hRadius = radius ? radius : "default";
  let hImg = props.image ? props.image : "/images/fpo/general/288x240.png";
  let hImgSM = props.imagesm ? props.imagesm : null;
  let hImgLG = props.imagelg ? props.imagelg : null;
  let hHeading = props.heading ? props.heading : null;
  let hName = props.name ? props.name : null;
  let hSubtitle = props.subtitle ? props.subtitle : null;
  let hTagline = props.tagline ? props.tagline : null;
  let hCopy = copy
    ? copy
    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
  let hClasses = props.classes ? props.classes : "";
  let hImageClasses = props.imageClass ? props.imageClass : "";
  let hUrl = props.url ? props.url : null;

  // These are all states that are needed for sections with picture tags.
  const [phone, setPhone] = useState(null);
  const [desktop, setDesktop] = useState(null);

  const [image, setImage] = useState({ preview: "" });

  return (
    <DropZone setPhone={setPhone} setDesktop={setDesktop} setImage={setImage}>
      <>
        {hUrl ? (
          <a
            className={
              `c-horizontalCard ${
                hType == "testimonial"
                  ? "c-horizontalCard--testimonial"
                  : hType == "cardSlider"
                  ? "c-horizontalCard--cardSlider"
                  : ""
              } ${hRadius === "large" ? "c-horizontalCard--lgRadius" : ""}` + hClasses
            }
            href={hUrl}
          >
            <div className="c-horizontalCard__image">
              {hImgSM && hImgLG ? (
                <picture className="e-picture" id="js-picture">
                  <source
                    srcSet={phone && phone.phone ? phone.phone : hImgSM}
                    media="(max-width: 50em)"
                  />
                  <img
                    src={desktop && desktop.desktop ? desktop.desktop : hImgLG}
                    alt="Alternate Text"
                    className={hImageClasses}
                  />
                </picture>
              ) : hImg ? (
                <img
                  src={image.preview ? image.preview : hImg}
                  alt="Alternate Text"
                  data-dropdown="true"
                  className={hImageClasses}
                />
              ) : (
                ""
              )}
            </div>
            <div className="c-horizontalCard__details">
              {hType == "default" ||
                (hType == "cardSlider" && (
                  <>
                    {hHeading ? (
                      <h3 className="c-horizontalCard__details__heading u-h4">{hHeading}</h3>
                    ) : (
                      ""
                    )}
                    <p className="c-horizontalCard__details__copy">{hCopy}</p>
                  </>
                ))}
              {hType == "testimonial" && (
                <>
                  <p className="c-horizontalCard__details__copy">{hCopy}</p>
                  {hName ? <div className="c-horizontalCard__details__name">{hName}</div> : ""}
                  {hSubtitle ? (
                    <div className="c-horizontalCard__details__subtitle">{hSubtitle}</div>
                  ) : (
                    ""
                  )}
                  {hTagline ? (
                    <div className="c-horizontalCard__details__tagline">{hTagline}</div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </a>
        ) : (
          <div
            className={
              `c-horizontalCard ${
                hType == "testimonial"
                  ? "c-horizontalCard--testimonial"
                  : hType == "cardSlider"
                  ? "c-horizontalCard--cardSlider"
                  : ""
              } ${hRadius === "large" ? "c-horizontalCard--lgRadius" : ""}` + hClasses
            }
          >
            <div className="c-horizontalCard__image">
              {hImgSM && hImgLG ? (
                <picture className="e-picture" id="js-picture">
                  <source
                    srcSet={phone && phone.phone ? phone.phone : hImgSM}
                    media="(max-width: 50em)"
                  />
                  <img
                    src={desktop && desktop.desktop ? desktop.desktop : hImgLG}
                    alt="Alternate Text"
                    className={hImageClasses}
                  />
                </picture>
              ) : hImg ? (
                <img
                  src={image.preview ? image.preview : hImg}
                  alt="Alternate Text"
                  data-dropdown="true"
                  className={hImageClasses}
                />
              ) : (
                ""
              )}
            </div>
            <div className="c-horizontalCard__details">
              {hType == "default" ||
                (hType == "cardSlider" && (
                  <>
                    {hHeading ? (
                      <h3 className="c-horizontalCard__details__heading u-h4">{hHeading}</h3>
                    ) : (
                      ""
                    )}
                    <p className="c-horizontalCard__details__copy">{hCopy}</p>
                  </>
                ))}
              {hType == "testimonial" && (
                <>
                  <p className="c-horizontalCard__details__copy">{hCopy}</p>
                  {hName ? <div className="c-horizontalCard__details__name">{hName}</div> : ""}
                  {hSubtitle ? (
                    <div className="c-horizontalCard__details__subtitle">{hSubtitle}</div>
                  ) : (
                    ""
                  )}
                  {hTagline ? (
                    <div className="c-horizontalCard__details__tagline">{hTagline}</div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    </DropZone>
  );
};

export default C_HorizontalCard;
