import React from "react";

const C_IconFeatureItem = ({ htag, title, svg, copy }) => {
  let kfHtag = htag ? htag : "SPAN";
  let kfTitle = title ? title : null;
  let kfSVG = svg ? svg : "#iphone-ipad";
  let kfCopy = copy ? copy : null;

  return (
    <li className="c-iconFeature__item">
      <svg className="c-iconFeature__item__icon" aria-hidden="true">
        <use
          xmlns="http://www.w3.org/1999/xlink"
          xlinkHref={`../images/icons-map.svg${kfSVG}`}
        ></use>
      </svg>
      <div className="c-iconFeature__item__content">
        {kfTitle ? (
          kfHtag == "H2" ? (
            <h2 className="c-iconFeature__item__content__heading u-h4">{kfTitle}</h2>
          ) : kfHtag == "H3" ? (
            <h3 className="c-iconFeature__item__content__heading u-h4">{kfTitle}</h3>
          ) : kfHtag == "H4" ? (
            <h4 className="c-iconFeature__item__content__heading u-h4">{kfTitle}</h4>
          ) : kfHtag == "H5" ? (
            <h5 className="c-iconFeature__item__content__heading u-h4">{kfTitle}</h5>
          ) : kfHtag == "H6" ? (
            <h6 className="c-iconFeature__item__content__heading u-h4">{kfTitle}</h6>
          ) : (
            <span className="c-iconFeature__item__content__heading u-h4">{kfTitle}</span>
          )
        ) : (
          ""
        )}
        {kfCopy ? <p className="c-iconFeature__item__content__copy">{kfCopy}</p> : ""}
      </div>
    </li>
  );
};

export default C_IconFeatureItem;
