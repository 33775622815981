import { useContext, useEffect, useState } from "react";
import OptionsContext from "../../../../../OptionsContext";
import C_OptionsDD from "../../../Tools/C_OptionsDD";
import Field from "./Field";
import { handleCheckboxChange } from "./handleCheckboxChange.jsx";
import { handleFieldChange } from "./handleFieldChange.jsx";

const EditableFields = () => {
  //field content options initially set by section via OptionsContext
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  //field content options set and changed locally, changes sent up to OptionsContext in change handlers
  const [options, setOptions] = useState({});

  // let setCOptions = () => setContentOptions();

  useEffect(() => {
    //set local state when appstate changes
    setOptions({ ...contentOptions });
  }, [contentOptions]);

  //For testing data
  // useEffect(() => {
  //   // if (options) {
  //   let keys = Object.keys(options);
  //   keys.forEach((key) => {
  //     console.log("key: ", key);
  //     let objKeyVals = options[key];
  //     console.log("objKeyVals: ", objKeyVals);
  //   });
  //   // }
  // }, [options]);
  if (contentOptions === undefined) return "...Loading";
  if (contentOptions === null)
    return (
      <>
        <div className={`u-animated u-animated--delay500ms u-animated a-fadeIn c-editContent`}>
          <div className="l-row">
            <div className="l-row u-mb1">
              <p style={{ color: "white", margin: "0 1em" }}>
                Drag and drop an image over an existing one to change it. You can drop multiple
                files at once for media sizes.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <div className={`u-animated u-animated--delay500ms u-animated a-fadeIn c-editContent`}>
        <div className="l-row">
          <div className="l-row u-mb1">
            <p style={{ color: "white", margin: "0 1em" }}>
              Drag and drop an image over an existing one to change it. You can drop multiple files
              at once for media sizes.
            </p>
          </div>
          <div style={{ columns: 2 }}>
            {options ? (
              Object.keys(options).map((key) =>
                //card check
                Array.isArray(options[key]) ? (
                  // <>
                  //   <span>got block field</span>
                  <div key={key} className={"c-editContent__card"} data-key={key}>
                    {options[key].map((item, i) => (
                      <Field
                        key={item.label}
                        optionsState={options}
                        option={options[key] && options[key][i]}
                        handleCB={(e) =>
                          handleCheckboxChange(e, options, contentOptions, setContentOptions)
                        }
                        handleField={(e) =>
                          handleFieldChange(e, options, contentOptions, setContentOptions)
                        }
                      />
                    ))}
                  </div>
                ) : // </>
                typeof options[key] == "object" && Array.isArray(options[key].field) ? (
                  //dd check
                  // <>
                  //   <span>got dd field</span>
                  <div className="c-editContent__field" key={options[key].label}>
                    <div
                      style={{
                        color: "white",
                      }}
                      className="c-contentOptions__label"
                      htmlFor={options[key] && options[key].label}
                    >
                      <span
                        style={{
                          margin: "0 0.25em 0 0",
                        }}
                      >
                        {options[key] && options[key].label}
                      </span>
                      <C_OptionsDD optKey={key} opts={options[key]} />
                    </div>
                  </div>
                ) : // </>
                //reg text field check
                typeof options[key] == "object" && !Array.isArray(options[key].field) ? (
                  // <>
                  //   <span>got generic field</span>
                  <Field
                    key={options[key].label}
                    optionsState={options}
                    option={options[key]}
                    handleCB={(e) =>
                      handleCheckboxChange(e, options, contentOptions, setContentOptions)
                    }
                    handleField={(e) =>
                      handleFieldChange(e, options, contentOptions, setContentOptions)
                    }
                  />
                ) : (
                  // </>
                  ""
                ),
              )
            ) : (
              //no option items
              <>
                <span>No content options.</span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditableFields;
