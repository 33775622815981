/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SupportGlobal = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [currentTab, setCurrentTab] = useState("tab1");
  const [mobileDDActive, setMobileDDActive] = useState(false);

  const [mobileExpand, setMobileExpand] = useState(false);

  const toggleTab = (tab) => {
    if (currentTab !== tab) {
      setCurrentTab(tab);
    }
    mobileDDActive === true && setMobileDDActive(false);
  };

  useEffect(() => {
    let vars = {
      ...appState,
      currentPath: "/ui/support-global",
      outputName: "SupportGlobal",
      headerName: "Support Global",
      tags: null,
      currentTheme: null,
      description:
        "The Support Global Section displays a list of contact information for international customers.",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/dropDown.component.js",
        "../../js/tabs.layout.js",
      ],
      specs: [
        ["Tab", ["String text within <li> tag."]],
        ["Column Title", ["String text within <th> tag."]],
        ["Info", ["String text within <td> tag."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EdsiamXZVrJJp79RZbqAODcB5RDF_iJ7lsghQHV3rpeH3w?e=StYZ7C",
        comments: null,
      },
    };
    setAppState(vars);
    setContentOptions({
      tab1: {
        name: "tab1",
        label: "Tab Title 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 1",
          Span: "Título 1",
          ChS: "标题 1",
          Gr: "Titel 1",
          Ru: "Заголовок 1",
          Fr: "Titre 1",
          It: "Titolo 1",
          Pl: "Tytuł 1",
          Port: "Título 1",
          Viet: "Tiêu đề 1",
          Turk: "Başlık 1",
          Ukrn: "Назва 1",
          Thai: "ชื่อ 1",
          Kor: "제목 1",
          ChT: "標題 1",
          Jp: "タイトル 1",
        },
      },
      col1title: {
        name: "col1title",
        label: "Column 1 Title for Tab 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来说",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w taką",
          Port: "Fazia calor naquele",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨ",
        },
      },
      td1: {
        name: "td1",
        label: "Column 1 Info",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calo",
          ChS: "那个天气很热",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w",
          Port: "Fazia calor",
          Viet: "Thời tiết đó",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      col2title: {
        name: "col2title",
        label: "Column 2 Title for Tab 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来说",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w taką",
          Port: "Fazia calor naquele",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨ",
        },
      },
      td2: {
        name: "td2",
        label: "Column 2 Info",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calo",
          ChS: "那个天气很热",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w",
          Port: "Fazia calor",
          Viet: "Thời tiết đó",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      col3title: {
        name: "col3title",
        label: "Column 3 Title for Tab 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo",
          ChS: "那个天气很热，对我来说",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w taką",
          Port: "Fazia calor naquele",
          Viet: "Thời tiết đó nóng nực",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду було",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フヨ",
        },
      },
      td3: {
        name: "td3",
        label: "Column 3 Info",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calo",
          ChS: "那个天气很热",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w",
          Port: "Fazia calor",
          Viet: "Thời tiết đó",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      tab2: {
        name: "tab2",
        label: "Tab Title 2",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 2",
          Span: "Título 2",
          ChS: "标题 2",
          Gr: "Titel 2",
          Ru: "Заголовок 2",
          Fr: "Titre 2",
          It: "Titolo 2",
          Pl: "Tytuł 2",
          Port: "Título 2",
          Viet: "Tiêu đề 2",
          Turk: "Başlık 2",
          Ukrn: "Назва 2",
          Thai: "ชื่อ 2",
          Kor: "제목 2",
          ChT: "標題 2",
          Jp: "タイトル 2",
        },
      },
      tab3: {
        name: "tab3",
        label: "Tab Title 3",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 3",
          Span: "Título 3",
          ChS: "标题 3",
          Gr: "Titel 3",
          Ru: "Заголовок 3",
          Fr: "Titre 3",
          It: "Titolo 3",
          Pl: "Tytuł 3",
          Port: "Título 3",
          Viet: "Tiêu đề 3",
          Turk: "Başlık 3",
          Ukrn: "Назва 3",
          Thai: "ชื่อ 3",
          Kor: "제목 3",
          ChT: "標題 3",
          Jp: "タイトル 3",
        },
      },
      tab4: {
        name: "tab4",
        label: "Tab Title 4",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 4",
          Span: "Título 4",
          ChS: "标题 4",
          Gr: "Titel 4",
          Ru: "Заголовок 4",
          Fr: "Titre 4",
          It: "Titolo 4",
          Pl: "Tytuł 4",
          Port: "Título 4",
          Viet: "Tiêu đề 4",
          Turk: "Başlık 4",
          Ukrn: "Назва 4",
          Thai: "ชื่อ 4",
          Kor: "제목 4",
          ChT: "標題 4",
          Jp: "タイトル 4",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "243",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Support Global Section";
  return (
    <section className="s-supportGlobal">
      <div className="l-tabView">
        <button
          className="l-tabView__mobile"
          aria-expanded={mobileExpand}
          onClick={() => setMobileExpand(!mobileExpand)}
        >
          <span>
            {contentOptions.tab1 && contentOptions.tab1.field[contentOptions.currentLang]}
          </span>
          <svg viewBox="0 0 32 32" className="l-tabView__mobile__arrow" aria-hidden="true">
            <path d="M24 2L8 16l16 14" />
          </svg>
        </button>
        <ul className="l-tabView__tabs" role="tablist">
          <li
            className={`l-tabView__tabs__tab  ${
              currentTab === "tab1" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            aria-controls="tabContent0_0"
            id="tab0_0"
            aria-selected={currentTab === "tab1" ? "true" : "false"}
            tabIndex="0"
            onClick={() => toggleTab("tab1")}
            onKeyDown={() => toggleTab("tab1")}
          >
            {contentOptions.tab1 && contentOptions.tab1.field[contentOptions.currentLang]}
          </li>
          <li
            className={`l-tabView__tabs__tab  ${
              currentTab === "tab2" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            aria-controls="tabContent0_1"
            id="tab0_1"
            aria-selected={currentTab === "tab2" ? "true" : "false"}
            tabIndex="-1"
            onClick={() => toggleTab("tab2")}
            onKeyDown={() => toggleTab("tab2")}
          >
            {contentOptions.tab2 && contentOptions.tab2.field[contentOptions.currentLang]}
          </li>
          <li
            className={`l-tabView__tabs__tab  ${
              currentTab === "tab3" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            aria-controls="tabContent0_2"
            id="tab0_2"
            aria-selected={currentTab === "tab3" ? "true" : "false"}
            tabIndex="-1"
            onClick={() => toggleTab("tab3")}
            onKeyDown={() => toggleTab("tab3")}
          >
            {contentOptions.tab3 && contentOptions.tab3.field[contentOptions.currentLang]}
          </li>
          <li
            className={`l-tabView__tabs__tab  ${
              currentTab === "tab4" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            aria-controls="tabContent0_3"
            id="tab0_3"
            aria-selected={currentTab === "tab4" ? "true" : "false"}
            tabIndex="-1"
            onClick={() => toggleTab("tab4")}
            onKeyDown={() => toggleTab("tab4")}
          >
            {contentOptions.tab4 && contentOptions.tab4.field[contentOptions.currentLang]}
          </li>
        </ul>
        <div className="l-tabView__panels">
          <div
            className={`l-inner l-tabView__panels__panel ${
              currentTab === "tab1" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
            aria-labelledby="tab0_0"
            id="tabContent0_0"
          >
            <div className="c-table c-table--sideKeys u-tableUnstyled">
              <div className="c-table__main">
                <table>
                  <thead>
                    <tr>
                      <th>
                        {contentOptions.col1title &&
                          contentOptions.col1title.field[contentOptions.currentLang]}
                      </th>
                      <th>
                        {contentOptions.col2title &&
                          contentOptions.col2title.field[contentOptions.currentLang]}
                      </th>
                      <th>
                        {contentOptions.col3title &&
                          contentOptions.col3title.field[contentOptions.currentLang]}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {contentOptions.td1 && contentOptions.td1.field[contentOptions.currentLang]}
                      </td>
                      <td>
                        {contentOptions.td2 && contentOptions.td2.field[contentOptions.currentLang]}
                      </td>
                      <td>
                        {contentOptions.td3 && contentOptions.td3.field[contentOptions.currentLang]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className={`l-inner l-tabView__panels__panel ${
              currentTab === "tab2" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
            aria-labelledby="tab0_1"
            id="tabContent0_1"
          >
            <div className="c-table c-table--sideKeys u-tableUnstyled">
              <div className="c-table__main">
                <table>
                  <thead>
                    <tr>
                      <th>Column Title 1 (Tab 2)</th>
                      <th>Column Title 2 (Tab 2)</th>
                      <th>Column Title 3 (Tab 2)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>TD 1</td>
                      <td>TD 2</td>
                      <td>TD 3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className={`l-inner l-tabView__panels__panel ${
              currentTab === "tab3" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
            aria-labelledby="tab0_2"
            id="tabContent0_2"
          >
            <p>This is content for a tab in support global section</p>
          </div>
          <div
            className={`l-inner l-tabView__panels__panel ${
              currentTab === "tab4" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
            aria-labelledby="tab0_3"
            id="tabContent0_3"
          >
            <div className="c-table c-table--sideKeys u-tableUnstyled">
              <div className="c-table__main">
                <table>
                  <thead>
                    <tr>
                      <th>Column Title 1 (Tab 4)</th>
                      <th>Column Title 2 (Tab 4)</th>
                      <th>Column Title 3 (Tab 4)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>TD 1</td>
                      <td>TD 2</td>
                      <td>TD 3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved..
export default {
  path: "support-global",
  component: S_SupportGlobal,
  navtxt: "Support Global",
  htmlName: "SupportGlobal",
  categoryType: ["internal"],
};
