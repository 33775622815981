/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
import L_FlexTiles from "../../0_layout/L_Flextiles";

//can't use number of items dd bc of flextiles using children
const S_Logos = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  //this is for dropzone images
  const [featureImage, setFeatureImage] = useState({ preview: "" });

  useEffect(() => {
    let logoVars = {
      ...appState,
      currentPath: "/ui/logos",
      currentTheme: "t-white",
      outputName: "Logos",
      headerName: "Logos",
      tags: null,
      description:
        "The Logos Section displays logos from teams or companies in a grid layout. *Layout for editable version in progress...",
      related: null,
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/flexTiles.layout.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: ratio is 1:1, Recommended: 120px x 120px",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/171743EYF9l8qaNJIp5qPvoA-xZu9ugaPRjNz_Uags0s/edit?usp=sharing",
        comments: [["Logos Image Size", ["120px x 120px"]]],
      },
    };
    setAppState(logoVars);

    setContentOptions(null);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "181",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!appState) return "...Loading Logos";
  return (
    <section className={`s-logos ` + appState.currentTheme}>
      <div className="l-inner u-max+">
        <L_FlexTiles minWidth="100" maxWidth="150">
          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>

          <a href="#anchorLink" title="Anchor Title" className="">
            <DropZone setImage={setFeatureImage}>
              <img
                src={
                  featureImage.preview ? featureImage.preview : "/images/fpo/general/117x117.png"
                }
                alt="#Logolink"
              />
            </DropZone>
          </a>
        </L_FlexTiles>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "logos",
  component: S_Logos,
  navtxt: "Logos",
  htmlName: "Logos",
  categoryType: ["web"],
};
