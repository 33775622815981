import React from "react";
// import DropZone from "../../../0_hooks/dropzone";

const C_VideoCard = ({ imagesmall, imagelarge, title, copy, time, ...props }) => {
  //   const [phone, setPhone] = useState({ preview: "" });
  //   const [desktop, setDesktop] = useState({ preview: "" });

  let vImgSM = imagesmall ? imagesmall : null;
  let vImgL = imagelarge ? imagelarge : null;
  let vTime = time ? time : null;
  let vTitle = title ? title : null;
  let vCopy = copy ? copy : null;
  let vUrl = props.url ? props.url : null;

  return (
    <div className={`c-videoCard`}>
      {vImgSM && vImgL ? (
        <>
          <a href={vUrl} className="c-videoCard__img">
            {/* Drop down doesnt work right now cause of the ::before of videoCard.scss*/}
            {/* <DropZone setPhone={setPhone} setDesktop={setDesktop} pictureTag={true}>
              <picture className="e-picture" id="js-picture">
                <source
                  srcSet={phone && phone.phone ? phone.phone : vImgSM}
                  media="(max-width:32em)"
                />
                <img
                  src={desktop && desktop.desktop ? desktop.desktop : vImgL}
                  alt="Alternate Text"
                />
              </picture>
            </DropZone> */}
            <picture className="e-picture" id="js-picture">
              <source srcSet={vImgSM} media="(max-width:32em)" />
              <img src={vImgL} alt="Alternate Text" />
            </picture>
            <svg viewBox="0 0 73 73">
              <path d="M0 36.5a36.5 36.5 0 1073 0 36.5 36.5 0 10-73 0" fill="rgba(0,0,0,0.5)" />
              <path d="M36.5 9A27.5 27.5 0 119 36.5 27.5 27.5 0 0136.5 9m0-5.5a33 33 0 1033 33 33 33 0 00-33-33z" />
              <path d="M52.56 37.36l-23 13.74a1 1 0 01-1.56-.86V22.76a1 1 0 011.51-.86l23 13.74a1 1 0 010 1.72z" />
            </svg>
            {/* typeof test prevents errors from other contentOptions from any previous section loaded */}
            <span className="c-videoCard__img__time">{typeof vTime === "string" && vTime}</span>
          </a>
        </>
      ) : (
        ""
      )}

      <div className="c-videoCard__details">
        <h3 className="u-h5">{vTitle}</h3>
        {vCopy ? <p>{vCopy}</p> : ""}
      </div>
    </div>
  );
};

export default C_VideoCard;
