// import dotenv from "dotenv";
import { useEffect, useState } from "react";
// import ToastContext from "../../../ToastContext";

// dotenv.config();
var server = process.env.API_URL;

//passed showHtml from IframeWrapper to Tools to here. If showing html then disable button
const C_OutputBtn = () => {
  const [callingApi, setCallingApi] = useState(false);

  // const { setToastOptions } = useContext(ToastContext);

  function callDropApi() {
    //api is running
    setCallingApi(true);
  }

  //triggered by listening for setCallingApi set to true
  useEffect(() => {
    // console.log("useEffect with axios called");
    if (callingApi) {
      //set everything to false and empty
      setCallingApi(false);

      const options = {
        method: "POST",
        withCredentials: false,
      };

      fetch(server + "/api/output-drop", options)
        .then((res) => {
          if (res && res.data == "Success") {
            // setToastOptions({ text: "HTML File was output." });
          } else {
            // throw res;
            console.log("res: ", res);
          }
        })
        .catch((error) => {
          console.log("inside fetch catch error");
          // setToastOptions({ text: "There was a problem saving the section." });
          console.log(error);
        });
    }
  }, [callingApi]);

  return (
    <div className="l-viewerPanels__controls__option">
      <h6>Output files for Commit</h6>
      <button
        className="v-tool__btn v-pill"
        onClick={() => callDropApi()}
        alt="Output for commit drop."
        title="Output for commit drop."
      >
        Generate Drop
      </button>
    </div>
  );
};

export default C_OutputBtn;
