/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from "react";

const C_Filter = () => {
  const [showCard, setShowCard] = useState(false);

  return (
    <div className={`c-slideMenu ${showCard ? "c-slideMenu--activeLeft" : ""}`} id="slideMenu0">
      <div
        className={`c-slideMenu__slider c-filterCard ${
          showCard ? "c-slideMenu__slider--active" : ""
        }`}
        data-filterid="filterCard0"
        data-dropdown="false"
      >
        <button
          className="c-slideMenu__slider__toggle"
          aria-expanded={showCard ? true : false}
          onClick={() => setShowCard(!showCard)}
        >
          <svg aria-hidden="true">
            <use
              xlink="http://www.w3.org/1999/xlink"
              xlinkHref="/images/icons-map.svg#filter"
            ></use>
          </svg>
          <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
        </button>
        <div className="c-filterCard__results">
          <span data-results="0">Results</span>
        </div>
        <form
          className="c-slideMenu__slider__panel c-filterCard__details"
          aria-labelledby="sldrTgle-55qw75"
        >
          <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
            <div className="c-accordion c-accordion--noOutline" data-multiselect="true">
              <span className="c-accordion__tab c-accordion__tab--active c-accordion__tab--active c-accordion__tab--active">
                <button aria-expanded="true">
                  Android
                  <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden="true">
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div className="c-accordion__panel" aria-labelledby="accTab-kf3aqk">
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Android</legend>
                    <ul className="u-list-unstyled">
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID1"
                              id="filterBtnsID1"
                              value="filter1"
                            />
                            <label htmlFor="filterBtnsID3">Filter 1</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID2"
                              id="filterBtnsID2"
                              value="filter2"
                            />
                            <label htmlFor="filterBtnsID2">Filter 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="filterBtnsID3"
                              id="filterBtnsID3"
                              value="filter3"
                            />
                            <label htmlFor="filterBtnsID3">Filter 3</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <span className="c-accordion__tab c-accordion__tab--active c-accordion__tab--active c-accordion__tab--active">
                <button aria-expanded="true">
                  Client
                  <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden="true">
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div className="c-accordion__panel" id="accPanel-4rwmca">
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Client</legend>
                    <ul className="u-list-unstyled">
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns1"
                              id="clientBtns1"
                              value="Client 1"
                            />
                            <label htmlFor="clientBtns1">Client 1</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns2"
                              id="clientBtns2"
                              value="client2"
                            />
                            <label htmlFor="clientBtns4">Client 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns3"
                              id="client3"
                              value="client3"
                            />
                            <label htmlFor="client3">Client 3</label>
                          </div>
                        </div>
                      </li>
                      <li className="u-frm-disable" tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="clientBtns4"
                              id="clientBtns4"
                              value="client4"
                            />
                            <label htmlFor="clientBtns4">Client 4</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="client5"
                              id="clientBtns5"
                              value="client5"
                            />
                            <label htmlFor="clientBtns5">Client 5</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <span className="c-accordion__tab c-accordion__tab--active c-accordion__tab--active c-accordion__tab--active">
                <button aria-expanded="true">
                  Year
                  <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden="true">
                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                  </svg>
                </button>
              </span>
              <div className="c-accordion__panel" aria-labelledby="accTab-m06452">
                <div className="c-accordion__panel__content">
                  <fieldset>
                    <legend className="u-srOnly">Year</legend>
                    <ul className="u-list-unstyled">
                      <li className="u-frm-disable" tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="yearBtnsID2"
                              id="yearBtnsID2"
                              value="year2"
                            />
                            <label htmlFor="yearBtnsID2">Year 2</label>
                          </div>
                        </div>
                      </li>
                      <li tabIndex="0">
                        <div className="f-input">
                          <div className="f-input__checkbox">
                            <input
                              type="checkbox"
                              name="yearBtnsID3"
                              id="yearBtnsID3"
                              value="year3"
                            />
                            <label htmlFor="yearBtnsID3">Year 3</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
            <button className="e-btn e-btn--alt2 c-filterCard__details__cta__reset">Reset</button>
            <button className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close">
              Close
            </button>
            <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default C_Filter;
