/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_OrderDetails = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let orderDetailsVars = {
      ...appState,
      currentPath: "/ui/order-details",
      outputName: "OrderDetails",
      headerName: "Order Details",
      tags: null,
      description: "The Order Details Section ...",
      related: null,
      htmlSaved: false,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "../../js/cuid.component.js"],
      specs: [
        ["Date", ["Defaults to a <span> No character limit"]],
        ["Author", ["Defaults to a <span> No character limit"]],
        ["Tags", ["String text and a link related to the tag. No Character Limit."]],
        [
          "CTA",
          [
            "Call to Action should have a customizable text field and will be formatted as a button on the right side that links to desired page",
          ],
        ],
      ],
      cis: {
        cmsDoc: null,
        comments: null,
      },
    };
    setAppState(orderDetailsVars);
    setContentOptions({
      vatoption: {
        label: "VAT",
        checkbox: false,
        field: null,
      },
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "945",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Order Details Section";

  return (
    <section className={`s-orderDetails ` + appState.currentTheme}>
      <div className="header">
        <h2>Order Details</h2>
      </div>
      <div className="inner-wrapper">
        <div className="l-inner">
          <a href="#" className="mobileBack" ng-click="redirect('account',true)">
            Back
          </a>
          <div className="flex-view">
            <div>
              <h5>Shipping &amp; Billing</h5>
              <dl>
                <dt>Shipping Preference</dt>
                <dd>FedEx Ground 5-7 Days (M-F)</dd>

                <dt>Shipping Address</dt>
                <dd>
                  Will Turner
                  <br />
                  123 Main st.
                  <br />
                  Long Beach, CA, 90805
                </dd>

                <dt>Billing Address</dt>
                <dd>
                  Will Turner
                  <br />
                  123 Main st.
                  <br />
                  Long Beach, CA, 90805
                </dd>

                <dt>Payment Method</dt>
                <dd>Credit Card (VISA ...1234)</dd>
              </dl>
            </div>
            <div>
              <div className="cart-summary">
                <div className="order-summary">
                  <p className="txt-bold order-title">Order: SH0581234</p>
                  <ul className="list-unstyled cart-list">
                    <li>
                      <div className="table-view">
                        <div>
                          <div>
                            <img src="http://placehold.it/94x61" className="item-img" alt="" />
                          </div>
                          <div>
                            <span className="item-title">512GB HyperX Savage USB Flash Drive</span>
                            <div className="item-price-container">
                              <span className="item-price">1 x $49.99</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="order-footer cf">
                  <div className="flex-footer">
                    <div className="flex-footer-info">
                      <div className="flex-footer-info-order">
                        <span>Order Date</span>
                        <span>08/07/2017 16:10:02</span>
                      </div>
                      <div className="flex-footer-info-order">
                        <span>Order Status</span>
                        <span>Order Cancelled</span>
                      </div>
                      <div className="flex-footer-info-order">
                        <span>Order Tracking</span>

                        <a href="#">101820133535b32</a>
                      </div>
                    </div>
                    <div className="flex-footer-totals">
                      <div className="flex-footer-totals-info">
                        <span>Subtotal:</span>
                        <span>$100.00</span>

                        {contentOptions.vatoption && contentOptions.vatoption.checkbox ? (
                          <>
                            <span>Total (VAT excluded):</span>
                            <span>$10.00</span>
                          </>
                        ) : (
                          ""
                        )}

                        <span>Savings:</span>
                        <span>$10.00</span>

                        <span>Shipping:</span>
                        <span>$1.00</span>

                        <span>Tax:</span>
                        <span>$100.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="table-view">
                    <div>1 Item</div>
                    <div>Total: $100.00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "order-details",
  component: S_OrderDetails,
  navtxt: "Order Details",
  htmlName: "OrderDetails",
  categoryType: ["internal"],
};
