/* eslint-disable react/no-unescaped-entities */
import React from "react";
import C_UseHTMLToggle from "../Tools/C_UseHTMLToggle";
import C_HTMLExportBtn from "../Tools/C_HTMLExportBtn";
import C_OutputBtn from "../Tools/C_OutputBtn";

const DevTab = ({ setShowHtml, showHtml }) => {
  return (
    <div className="l-viewerPanels c-editContentPanel">
      <div className="l-row">
        <div className="l-viewerPanels__controls">
          <div className="l-viewerPanels__controls__option">
            <h6>View Mode</h6>
            {/* num prop is used to create element id */}
            <C_UseHTMLToggle setShowHtml={setShowHtml} show={showHtml} num={"3"} />
          </div>
          <div>
            <C_HTMLExportBtn show={showHtml} />
          </div>
          <div>
            <C_OutputBtn />
          </div>
        </div>
      </div>
      <div>
        <p>
          YOU WILL NEED TO RESTART (ctrl-c, npm run dev in terminal) the every time you click "Save
          HTML and JS".
        </p>
        <p>
          *&quot;htmlSaved&quot; must be set to true in the section code (in the setAppState vars)
          or the above toggle will be disabled.
        </p>
        <p>
          *If you have &quot;htmlSaved&quot; set to true but have not saved the html and js, you
          will see &quot;Sorry, we couldn&apos;t find a page for...&quot;
        </p>
      </div>
    </div>
  );
};

export default DevTab;
