/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_SystemScanResultsCard from "../../2_components/systemScanResultsCard";
import systemScanResultsOpts from "./systemScanResultsOpts";

const S_SystemScanResults = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let ss1Vars = {
      ...appState,
      currentPath: "/ui/system-scan-results",
      outputName: "SystemScanResults",
      headerName: "System Scan Results",
      tags: null,
      description: "The System Scan Results Section displays system information.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/systemScanResults.section.js",
        "../../js/progressBar.component.js",
      ],
      specs: [
        [
          "Models",
          [
            "Copy: The description appears as a paragraph beneath the heading",
            "Item: String text within <span> tag",
            "Item Copy: String text within <p> tag. No character limit",
            "CTA: String text within an anchor. No character limit",
          ],
        ],

        [
          "System",
          [
            "Item: String text within <span> tag",
            "Item Copy: String text within <p> tag. No character limit",
          ],
        ],

        [
          "Memory",
          [
            "Size: String numeric value",
            "Item: String text within <span> tag",
            "Item Copy: String text within <p> tag. No character limit",
            "CTA: String text within an anchor. No character limit",
            "Copy: String text within <p> tag. No character limit.",
          ],
        ],

        [
          "Memory Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, and Large: Width: 56px and Height: 32px"],
        ],
        ["Memory Image Type", ["PNG"]],

        [
          "Memory Socket",
          [
            "Copy: String text within <p> tag. No character limit.",
            "Item: String text within <span> tag",
            "Item Copy: String text within <ul> tag. No character limit",
            "CTA: String text within an anchor. No character limit",
          ],
        ],

        [
          "Storage",
          [
            "Size: String numeric value",
            "Item: String text within <span> tag",
            "Item Copy: String text within <p> tag. No character limit",
            "CTA: String text within an anchor. No character limit",
            "Copy: String text within <p> tag. No character limit",
          ],
        ],
        ["", ["Progress Info: String values", "Percentage: String percentage value"]],
        [
          "Questions",
          [
            "Copy: String text within <p> tag. No character limit",
            "CTA: String text within a CTA button",
          ],
        ],

        [
          "No Results",
          [
            "Copy: String text within <p> tag. No character limit",
            "CTA: String text within a CTA button",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EZUAva_JMfhNvWq2UoukQ7ABVjnQxuCGSn6YsGljsVfDlA?e=veBSob",
        comments: null,
      },
    };
    setAppState(ss1Vars);
    setContentOptions(systemScanResultsOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "945",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading System Scan Results Section";

  return (
    <section
      className={
        `s-systemScanResults t-silver ${
          contentOptions.noresults &&
          contentOptions.noresults[0] &&
          contentOptions.noresults[0].checkbox == true
            ? "s-systemScanResults--noResults "
            : ""
        }` + appState.currentTheme
      }
      id="systemScanResults0"
    >
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <h2>System Scan Results</h2>
          </div>
        </div>
      </div>
      <div className="l-inner s-systemScanResults__layout">
        {contentOptions.noresults &&
        contentOptions.noresults[0] &&
        contentOptions.noresults[0].checkbox == true ? (
          <C_SystemScanResultsCard
            type="No Scan Results"
            classes="c-systemScanResultsCard--noScanResult"
            copy={
              contentOptions.noresults[1] &&
              contentOptions.noresults[1].field[contentOptions.currentLang]
            }
            ctacopy={
              contentOptions.noresults[2] &&
              contentOptions.noresults[2].field[contentOptions.currentLang]
            }
          />
        ) : (
          <>
            {contentOptions.models &&
            contentOptions.models[0] &&
            contentOptions.models[0].checkbox == true ? (
              <C_SystemScanResultsCard
                type="Models"
                numitems={contentOptions.numModelItems && contentOptions.numModelItems.selected}
                copy={
                  contentOptions.models[1] &&
                  contentOptions.models[1].field[contentOptions.currentLang]
                }
                item1copy1={
                  contentOptions.models[2] &&
                  contentOptions.models[2].field[contentOptions.currentLang]
                }
                item1copy2={
                  contentOptions.models[3] &&
                  contentOptions.models[3].field[contentOptions.currentLang]
                }
                item2copy1={
                  contentOptions.models[4] &&
                  contentOptions.models[4].field[contentOptions.currentLang]
                }
                item2copy2={
                  contentOptions.models[5] &&
                  contentOptions.models[5].field[contentOptions.currentLang]
                }
                item3copy1={
                  contentOptions.models[6] &&
                  contentOptions.models[6].field[contentOptions.currentLang]
                }
                item3copy2={
                  contentOptions.models[7] &&
                  contentOptions.models[7].field[contentOptions.currentLang]
                }
                item4copy1={
                  contentOptions.models[8] &&
                  contentOptions.models[8].field[contentOptions.currentLang]
                }
                item4copy2={
                  contentOptions.models[9] &&
                  contentOptions.models[9].field[contentOptions.currentLang]
                }
              />
            ) : (
              ""
            )}
            <C_SystemScanResultsCard
              type="System"
              model={
                contentOptions.systems &&
                contentOptions.systems[0] &&
                contentOptions.systems[0].field[contentOptions.currentLang]
              }
              cpu={
                contentOptions.systems &&
                contentOptions.systems[1] &&
                contentOptions.systems[1].field[contentOptions.currentLang]
              }
              os={
                contentOptions.systems &&
                contentOptions.systems[2] &&
                contentOptions.systems[2].field[contentOptions.currentLang]
              }
            />
            <C_SystemScanResultsCard
              type="Memory"
              classes="c-systemScanResultsCard--half"
              total={
                contentOptions.memory && contentOptions.memory[0] && contentOptions.memory[0].field
              }
              numitems={contentOptions.numMemoryItems && contentOptions.numMemoryItems.selected}
              item1copy1={
                contentOptions.memory &&
                contentOptions.memory[1] &&
                contentOptions.memory[1].field[contentOptions.currentLang]
              }
              item1copy2={
                contentOptions.memory &&
                contentOptions.memory[2] &&
                contentOptions.memory[2].field[contentOptions.currentLang]
              }
              item2copy1={
                contentOptions.memory &&
                contentOptions.memory[3] &&
                contentOptions.memory[3].field[contentOptions.currentLang]
              }
              item2copy2={
                contentOptions.memory &&
                contentOptions.memory[4] &&
                contentOptions.memory[4].field[contentOptions.currentLang]
              }
              item3copy1={
                contentOptions.memory &&
                contentOptions.memory[5] &&
                contentOptions.memory[5].field[contentOptions.currentLang]
              }
              item3copy2={
                contentOptions.memory &&
                contentOptions.memory[6] &&
                contentOptions.memory[6].field[contentOptions.currentLang]
              }
              upgrade={
                contentOptions.memory &&
                contentOptions.memory[7] &&
                contentOptions.memory[7].checkbox
              }
            />

            <C_SystemScanResultsCard
              type="Memory Sockets"
              classes="c-systemScanResultsCard--half"
              copy={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[0] &&
                contentOptions.memorysocket[0].field[contentOptions.currentLang]
              }
              numitems={
                contentOptions.numMemorySocketItems && contentOptions.numMemorySocketItems.selected
              }
              item1copy1={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[1] &&
                contentOptions.memorysocket[1].field[contentOptions.currentLang]
              }
              socketitem1copy2={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[2] &&
                contentOptions.memorysocket[2].field[contentOptions.currentLang]
              }
              item2copy1={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[3] &&
                contentOptions.memorysocket[3].field[contentOptions.currentLang]
              }
              socketitem2copy2={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[4] &&
                contentOptions.memorysocket[4].field[contentOptions.currentLang]
              }
              item3copy1={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[5] &&
                contentOptions.memorysocket[5].field[contentOptions.currentLang]
              }
              socketitem3copy2={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[6] &&
                contentOptions.memorysocket[6].field[contentOptions.currentLang]
              }
              upgrade={
                contentOptions.memorysocket &&
                contentOptions.memorysocket[7] &&
                contentOptions.memorysocket[7].checkbox
              }
            />

            <C_SystemScanResultsCard
              type="Storage"
              total={
                contentOptions.storage &&
                contentOptions.storage[0] &&
                contentOptions.storage[0].field
              }
              numitems={contentOptions.numStorageItems && contentOptions.numStorageItems.selected}
              item1copy1={
                contentOptions.storage &&
                contentOptions.storage[1] &&
                contentOptions.storage[1].field[contentOptions.currentLang]
              }
              item1copy2={
                contentOptions.storage &&
                contentOptions.storage[2] &&
                contentOptions.storage[2].field[contentOptions.currentLang]
              }
              item2copy1={
                contentOptions.storage &&
                contentOptions.storage[3] &&
                contentOptions.storage[3].field[contentOptions.currentLang]
              }
              item2copy2={
                contentOptions.storage &&
                contentOptions.storage[4] &&
                contentOptions.storage[4].field[contentOptions.currentLang]
              }
              item3copy1={
                contentOptions.storage &&
                contentOptions.storage[5] &&
                contentOptions.storage[5].field[contentOptions.currentLang]
              }
              item3copy2={
                contentOptions.storage &&
                contentOptions.storage[6] &&
                contentOptions.storage[6].field[contentOptions.currentLang]
              }
              upgrade={
                contentOptions.storage &&
                contentOptions.storage[7] &&
                contentOptions.storage[7].checkbox
              }
            />
            <C_SystemScanResultsCard
              type="Questions"
              copy={
                contentOptions.question &&
                contentOptions.question[0] &&
                contentOptions.question[0].field[contentOptions.currentLang]
              }
              ctacopy={
                contentOptions.question &&
                contentOptions.question[1] &&
                contentOptions.question[1].field[contentOptions.currentLang]
              }
            />
          </>
        )}
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "system-scan-results",
  component: S_SystemScanResults,
  navtxt: "System Scan Results",
  htmlName: "SystemScanResults",
  categoryType: ["internal"],
};
