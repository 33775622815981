import React from "react";

const E_ArrowCTA = ({ copy = "Learn More", disableLink }) => {
  let linkState = disableLink ? disableLink : false;
  return (
    <>
      {linkState == false ? (
        <a className="e-arrowCTA" href="#anchorLink" title="Anchor Title">
          <span className="e-arrowCTA__copy">{copy}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            width="32"
            viewBox="0 0 32 32"
            aria-hidden="true"
            className="e-arrowCTA__icon"
          >
            <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
          </svg>
        </a>
      ) : (
        <div className="e-arrowCTA">
          <span className="e-arrowCTA__copy">{copy}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            width="32"
            viewBox="0 0 32 32"
            aria-hidden="true"
            className="e-arrowCTA__icon"
          >
            <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
          </svg>
        </div>
      )}
    </>
  );
};

export default E_ArrowCTA;
