[
  "aae-outlined",
  "aae",
  "dependable-reliability",
  "direct-support",
  "education",
  "exceptional-customer-service",
  "exceptional-service",
  "flexibility",
  "fun",
  "integrity",
  "investment",
  "loyalty",
  "passionate-people",
  "respect"
]
