import { useContext, useEffect, useState } from "react";
import OptionsContext from "../../../OptionsContext";
import "./ItemIncDec.scss";

const C_ItemIncDec = () => {
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [count, setCount] = useState(1);

  const incCount = () => {
    count < 10 && setCount(Number(count) + 1);
  };
  const decCount = () => {
    count > 0 && setCount(count - 1);
  };

  useEffect(() => {
    setContentOptions({
      ...contentOptions,
      itemCount: count,
    });
  }, [count]);

  return (
    <div className="c-itemDecInc">
      <button type="button" onClick={decCount}>
        -
      </button>

      <span>Number of Items</span>

      <button type="button" onClick={incCount}>
        +
      </button>
    </div>
  );
};

export default C_ItemIncDec;
