import React, { useState, useEffect } from "react";

const C_Pagination = ({ pagetotal }) => {
  let pageNum = pagetotal ? pagetotal : 10;

  let windowSize = window.innerWidth - 24;
  const [currItem, setCurrItem] = useState(1);
  const [smallScroll, setSmallScroll] = useState(false);
  const [shift, setShift] = useState(0);
  const [transformStyle, setTransformStyle] = useState({ transform: "translateX(0px)" });

  useEffect(() => {
    windowSize <= 512 ? setSmallScroll(true) : setSmallScroll(false);
  }, [windowSize]);

  function handleClick(btn) {
    //need var bc currItem state doesn't update fast enough to use for setShift
    let cnew = currItem;
    switch (btn) {
      case "smallLeft":
        if (currItem > 1) {
          cnew = currItem - 1;
          setCurrItem(currItem - 1);
          if (cnew > 2 && cnew < pagetotal - 2) {
            setShift(shift + 48);
          }
        }
        break;
      case "smallRight":
        if (currItem < pageNum) {
          cnew = currItem + 1;
          setCurrItem(currItem + 1);
          if (cnew > 3 && cnew < pagetotal - 1) {
            setShift(shift - 48);
          }
        }
        break;
      case "bigLeft":
        setCurrItem(1);
        setShift(0);
        break;
      case "bigRight":
        setCurrItem(pageNum);
        setShift(-48 * (pageNum - 5));
        break;
    }
  }

  useEffect(() => {
    setTransformStyle({ transform: "translateX(" + shift + "px)" });
  }, [currItem]);

  return (
    <nav
      className={`c-pagination ${
        smallScroll ? "c-pagination--smallScroll" : "c-pagination--largeScroll"
      }`}
      aria-label="Pagination"
      id="pagination-dq1jtc"
    >
      <button
        className={`c-pagination__arrowBtn ${
          currItem < 2 ? "c-pagination__arrowBtn--disable" : ""
        }`}
        aria-label="First page"
        data-type="first"
        onClick={() => handleClick("bigLeft")}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path
            d="m16.96 16.948 6.198 6.126c.52.513 1.355.513 1.874 0l.016-.015a1.323 1.323 0 0 0 0-1.883L19.81 16l5.238-5.176a1.323 1.323 0 0 0 0-1.883l-.016-.015a1.333 1.333 0 0 0-1.874 0l-6.199 6.126a1.333 1.333 0 0 0 0 1.896Zm-10 0 6.198 6.126c.52.513 1.355.513 1.874 0l.016-.015a1.323 1.323 0 0 0 0-1.883L9.81 16l5.238-5.176a1.323 1.323 0 0 0 0-1.883l-.016-.015a1.333 1.333 0 0 0-1.874 0L6.96 15.052a1.333 1.333 0 0 0 0 1.896Z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <button
        className="c-pagination__arrowBtn"
        aria-label="Previous page"
        data-type="previous"
        onClick={() => handleClick("smallLeft")}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path
            d="m11.96 16.948 6.198 6.126c.52.513 1.355.513 1.874 0l.016-.015a1.323 1.323 0 0 0 0-1.883L14.81 16l5.238-5.176a1.323 1.323 0 0 0 0-1.883l-.016-.015a1.333 1.333 0 0 0-1.874 0l-6.199 6.126a1.333 1.333 0 0 0 0 1.896Z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="c-pagination__list">
        <ul
          className="u-list-unstyled u-list-horizontal c-pagination__list__pages"
          style={transformStyle}
        >
          {[...Array(pageNum)].map((item, index) => (
            <li key={index} className={`c-pagination__list__pages__page`}>
              <a
                onClick={() => setCurrItem(index + 1)}
                className={`c-pagination__list__pages__page__btn ${
                  currItem == index + 1 ? "c-pagination__list__pages__page__btn--active" : ""
                }`}
                href={`#page${index + 1}`}
                aria-label={`Page ${index + 1}`}
                aria-current={currItem === index + 1 ? "true" : ""}
                // data-position={
                //   index + 1 == 1 ? "first" : index + 1 == 3 ? "start" : index + 1 == end ? "end" : index + 1 == pageNum ? "last" : ""
                // }
                //   data-scroll="true"/not listed
              >
                <span>{index + 1}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <button
        className="c-pagination__arrowBtn"
        aria-label="Next page"
        data-type="next"
        onClick={() => handleClick("smallRight")}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path
            d="m20.04 16.948-6.198 6.126a1.333 1.333 0 0 1-1.874 0l-.016-.015a1.323 1.323 0 0 1 0-1.883L17.19 16l-5.238-5.176a1.323 1.323 0 0 1 0-1.883l.016-.015a1.333 1.333 0 0 1 1.874 0l6.199 6.126a1.333 1.333 0 0 1 0 1.896Z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <button
        className={`c-pagination__arrowBtn ${
          currItem === pageNum ? "c-pagination__arrowBtn--disable" : ""
        }`}
        aria-label="Last page"
        data-type="last"
        onClick={() => handleClick("bigRight")}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path
            d="m15.045 16.948-6.2 6.126a1.333 1.333 0 0 1-1.873 0l-.016-.015a1.323 1.323 0 0 1 0-1.883L12.195 16l-5.239-5.176a1.323 1.323 0 0 1 0-1.883l.016-.015a1.333 1.333 0 0 1 1.874 0l6.199 6.126a1.333 1.333 0 0 1 0 1.896Zm10 0-6.2 6.126a1.333 1.333 0 0 1-1.873 0l-.016-.015a1.323 1.323 0 0 1 0-1.883L22.195 16l-5.239-5.176a1.323 1.323 0 0 1 0-1.883l.016-.015a1.333 1.333 0 0 1 1.874 0l6.199 6.126a1.333 1.333 0 0 1 0 1.896Z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
    </nav>
  );
};

export default C_Pagination;
