import { useState, useEffect } from "react";

const E_logoKingston = ({ version }) => {
  const [currentStyle, setCurrentStyle] = useState("");

  useEffect(() => {
    if (version == "black") {
      setCurrentStyle("");
    } else if (version == "white") {
      setCurrentStyle("e-logoKingston--white");
    } else if (version == "monoBlack") {
      setCurrentStyle("e-logoKingston--monoBlack");
    } else if (version == "monoWhite") {
      setCurrentStyle("e-logoKingston--monoWhite");
    }
  }, [version]);

  return (
    <div className={`e-logoKingston ` + currentStyle}>
      <svg
        version="1.1"
        className="e-logoKingston__svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 439.7 90.1"
      >
        <g className="e-logoKingston__svg__logoMark">
          <path
            className="e-logoKingston__svg__logoMark__bodyBg"
            d="M3.5,14c-0.3-1.2-0.7-4.2-0.7-4.2C2.2,7.4,3.4,6.7,3.4,6.7
			s10.9-5.7,31-6.2c6-0.2,16.5-0.3,27,3.2c0.7,0.3,0.9,0.8,1.2,1.9c0.4,1.3,0.5,2.5,0.7,3.6c0.4,2.7,0.5,4.2,0.4,5.7
			c-0.1,1.1-1.2,3-1.3,3.5c-0.2,1.8,0.5,9,0.3,12.5c-0.3,3.5-1.6,15.2-4.4,22.9c-3,8.1-8.1,6.8-10.7,6.8l12.2,27.5L0,88.1l8.8-47.9
			c0,0,0.1-1.2-0.9-1.9c-2.4-1.5-4.3-3.8-4.3-3.8s-1.2-1.4-2.6-9.2c0,0-1.2-7.4,0.1-9c0.6-0.7,2-1.5,2.4-1.7L3.5,14z"
          />
          <g className="e-logoKingston__svg__logoMark__bodyFg">
            <path
              d="M57.8,12.9c0,0,0.5-1,1.7-1.3c0,0,1.1-0.3,1.4-1.4C61.2,8.7,61.7,6,61,5.5c-7.8-3.4-24.4-3.2-26.4-3.2
				C16.9,2.2,5.2,7.8,5.2,7.8C4.6,8.1,4.4,8.9,4.4,8.9s0.5,3.9,0.7,4.6c0.1,0.5,0.5,0.5,0.5,0.5s4.5-1.5,8.6-1.2
				c2.9,0.2,3.6,4.7,3.6,4.7c-0.2-0.3-0.3-0.1-0.6-0.5c-0.8-1-2.1-2.3-3.5-2.6C10.8,14.1,4,15.5,3,17.3c-1.2,2,0.5,13.6,2.3,16
				c3.3,4.4,11.6,8.5,11.6,8.5l-12.4-21c0,0-0.4-0.7,0-1.5c0.2-0.4,0.8-1.4,4.5-1.5c5-0.2,5.6,4.7,5.6,4.7l4.5,19.6
				c0.7-0.1,12.2-1.3,15.3-1.8c3.1-0.5,7.9-3,10.3-3.6c2.4-0.6,10.1,0.1,10.1,0.1s-9.2-6.6-9.4-6.8c-0.2-0.2-0.1-0.4-0.1-0.4
				s0.8-2.9,1-3.5c0.3-0.5,1.2-0.5,1.2-0.5l3.8,0.9c1.2,0.4-2.2,0.4-2.2,0.4s-1.2,0-1.6,0c-0.5,0-0.7,1.4-0.8,2.2
				c-0.1,1,1.7,1.2,1.7,1.2s8.4-0.1,9.1-0.2c1.6-0.1,2.2-0.2,2.1-0.9C59.5,28.6,57.8,12.9,57.8,12.9z"
            />
            <path d="M55.4,41C47,38.8,39,40.3,39,40.3c5.1,4.1,18.1,3.4,18.1,3.4C57.2,42.5,55.4,41,55.4,41z" />
            <path d="M56.6,45.8c-15.1-1.4-17.4,8.5-17.4,8.5c5.6,1.4,12-1.2,15.5-4.7C57.7,46.7,56.6,45.8,56.6,45.8z" />
            <path
              d="M62.5,12.9c-0.5-0.4-0.4-0.5-1.2-0.6c-0.7-0.1-1.8,2-1.8,2c2.4-0.1,2.7,0.5,3,0.2
				C62.8,14.3,62.9,13.3,62.5,12.9z"
            />
            <polygon points="10.9,40.4 2.2,86.4 25.6,86.4 16.4,44.2 		" />
            <path d="M24.4,61.1l6.1,25.4h8.3l-1.2-15.7C35.8,68.2,24.4,61.1,24.4,61.1z" />
            <path d="M43.8,75.5l-2.6,11h11.2C50.4,81.7,43.8,75.5,43.8,75.5z" />
          </g>
          <g className="e-logoKingston__svg__logoMark__eye">
            <path
              d="M50.7,10.2c-0.9-0.3-2.8-1.1-6.2-1.9c-6.6-1.5-8.3-1.2-8.3-1.2C28.7,10.4,22.5,17,22.5,17c0.6,0.3,2.1,0,2.1,0
				c6.8-5.9,12.5-8.2,12.5-8.2c4.8-0.1,13.3,2.8,13.8,2.6C51.1,11.3,51.1,10.4,50.7,10.2z"
            />
            <path
              d="M44.7,12.8c-1.2-0.3-3.5,0-6.3,0.6c-2.7,0.6-8.2,3.4-8.2,3.4c2.1,0.9,5.8,1.4,9.1,1.7c3.9,0.4,8-1.6,8-1.6
				C47.3,16.4,46,13.1,44.7,12.8z"
            />
          </g>
          <g className="e-logoKingston__svg__logoMark__highlights">
            <path d="M54.1,14.2c-0.2-0.8-0.9-0.6-0.9-0.6l1.6,13.5c0,0,1.3,1,1.3-0.2C56,26.3,54.9,18,54.1,14.2z" />
            <path d="M29.2,2.5l2.9,6.8c0.2-0.1,1.4-0.8,1.5-0.9c0,0-3.5-6-3.7-6C29.5,2.4,29.2,2.5,29.2,2.5z" />
            <path d="M15.9,18c-2.6,0.6-1.3,4.4-1.3,4.4s0.7-2.4,2.7-3.2c2-0.8,8.6-2.1,8.6-2.1C21.9,17,18.5,17.4,15.9,18z" />
          </g>
        </g>
        <g className="e-logoKingston__svg__logoType">
          <path
            className="e-logoKingston__svg__logoType__rBall"
            d="M428.5,14.4c0-2.8,2.3-5.1,5.2-5.1c2.8,0,5.1,2.3,5.1,5.1
			c0,2.9-2.3,5.2-5.1,5.2C430.8,19.5,428.5,17.2,428.5,14.4z M438,14.4c0-2.5-1.8-4.5-4.3-4.5c-2.5,0-4.4,2-4.4,4.5
			c0,2.5,1.8,4.5,4.4,4.5C436.2,18.9,438,16.9,438,14.4z M436.2,17.3h-0.8l-1.6-2.6h-1.3v2.6h-0.7v-5.8h2.5c1.5,0,2,0.6,2,1.5
			c0,1.1-0.8,1.6-1.8,1.6L436.2,17.3z M433.5,14c0.8,0,2,0.1,2-1c0-0.9-0.7-1-1.4-1h-1.6v2H433.5z"
          />
          <g className="e-logoKingston__svg__logoType__technology">
            <polygon points="146.1,74.2 151.3,74.2 151.3,87.9 153.4,87.9 153.4,74.2 158.7,74.2 158.7,72.3 146.1,72.3 		" />
            <polygon
              points="176.7,80.7 185.3,80.7 185.3,78.9 176.7,78.9 176.7,74.2 186,74.2 186,72.3 174.6,72.3 174.6,87.9 186.1,87.9 
				186.1,86 176.7,86 		"
            />
            <path
              d="M212.8,84.6c-0.8,1.2-2,1.8-3.5,1.8c-1.7,0-2.9-0.6-3.8-1.7c-0.9-1.1-1.3-2.6-1.3-4.4c0-2.2,0.5-3.9,1.4-4.9
				c0.9-1.1,2.2-1.6,3.7-1.6c1.3,0,2.2,0.3,2.9,0.9c0.7,0.6,1.2,1.4,1.4,2.4h2.1c-0.1-1.3-0.7-2.5-1.8-3.5c-1.1-1-2.6-1.5-4.6-1.5
				c-2.3,0-4.1,0.8-5.4,2.4c-1.2,1.5-1.8,3.3-1.8,5.6c0,3,0.8,5.2,2.4,6.7c1.2,1.1,2.7,1.6,4.5,1.6c2.1,0,3.8-0.7,5-2
				c1-1.1,1.6-2.5,1.8-4.1h-2C213.5,83.2,213.2,84,212.8,84.6z"
            />
            <polygon
              points="242.5,78.8 234.4,78.8 234.4,72.3 232.3,72.3 232.3,87.9 234.4,87.9 234.4,80.6 242.5,80.6 242.5,87.9 
				244.6,87.9 244.6,72.3 242.5,72.3 		"
            />
            <polygon
              points="281.1,84.9 273.3,72.3 270.8,72.3 270.8,87.9 272.8,87.9 272.8,75.3 280.7,87.9 283.1,87.9 283.1,72.3 
				281.1,72.3 		"
            />
            <path
              d="M308.7,71.9c-2.6,0-4.6,1-5.9,2.9c-1,1.5-1.6,3.3-1.6,5.4c0,2.3,0.6,4.2,1.8,5.8c1.4,1.6,3.2,2.4,5.6,2.4
				c2.6,0,4.5-0.9,5.9-2.7c1.2-1.5,1.8-3.5,1.8-5.8c0-2.1-0.5-3.9-1.5-5.3C313.5,72.8,311.4,71.9,308.7,71.9z M312.9,84.4
				c-0.8,1.3-2.2,2-4,2c-2,0-3.4-0.6-4.2-1.8c-0.9-1.2-1.3-2.6-1.3-4.4c0-2.1,0.5-3.8,1.5-4.9c1-1.1,2.3-1.7,3.9-1.7
				c1.7,0,3,0.6,3.9,1.7c1,1.1,1.4,2.6,1.4,4.4C314.2,81.6,313.8,83.1,312.9,84.4z"
            />
            <polygon points="336.5,72.3 334.4,72.3 334.4,87.9 344.4,87.9 344.4,86 336.5,86 		" />
            <path
              d="M368.3,71.9c-2.6,0-4.6,1-5.9,2.9c-1,1.5-1.6,3.3-1.6,5.4c0,2.3,0.6,4.2,1.8,5.8c1.4,1.6,3.2,2.4,5.6,2.4
				c2.6,0,4.5-0.9,5.9-2.7c1.2-1.5,1.8-3.5,1.8-5.8c0-2.1-0.5-3.9-1.5-5.3C373.1,72.8,371,71.9,368.3,71.9z M372.5,84.4
				c-0.8,1.3-2.2,2-4,2c-2,0-3.4-0.6-4.2-1.8c-0.9-1.2-1.3-2.6-1.3-4.4c0-2.1,0.5-3.8,1.5-4.9c1-1.1,2.3-1.7,3.9-1.7
				c1.7,0,3,0.6,3.9,1.7c1,1.1,1.4,2.6,1.4,4.4C373.8,81.6,373.4,83.1,372.5,84.4z"
            />
            <path
              d="M400.8,81.3h4.9c0,1.8-0.5,3.1-1.4,3.9c-0.9,0.8-2.1,1.2-3.5,1.2c-1.8,0-3.2-0.6-4-1.7c-0.8-1.2-1.2-2.7-1.2-4.5
				c0-2.1,0.5-3.7,1.5-4.8c1-1.1,2.2-1.6,3.6-1.6c1.2,0,2.2,0.3,3,0.8c0.8,0.5,1.3,1.3,1.6,2.5h2.1c-0.3-2-1.3-3.4-2.8-4.3
				c-1.1-0.6-2.3-0.9-3.8-0.9c-2.1,0-3.9,0.7-5.2,2.2c-1.4,1.5-2.1,3.6-2.1,6.2c0,2.6,0.7,4.7,2.2,6.2c1.3,1.2,2.9,1.8,4.7,1.8
				c1.4,0,2.6-0.3,3.5-0.8c0.5-0.3,1.2-0.8,1.9-1.6l0.5,2h1.4v-8.3h-6.8V81.3z"
            />
            <polygon points="436.2,72.3 431.7,79.8 427.2,72.3 424.8,72.3 430.7,81.6 430.7,87.9 432.8,87.9 432.8,81.6 438.6,72.3 		" />
          </g>
          <g className="e-logoKingston__svg__logoType__kingston">
            <path
              d="M433.6,55.4l0-19c0-8.6-1.1-18.9-14.5-18.9c-5.6,0-8.9,0.2-13.8,7.2v-6.2h-21.8v1.2c5.7,0,7,3.4,7,10.3v25.3
				c0,8-0.9,12.3-6,12.3v0.7h26.1v-0.7c-4.7,0-5.1-4.1-5.1-12.3V28.1c1.7-2.8,1.9-7.7,8-7.7c5.3,0,4.6,8.7,4.6,15.7v19.3
				c0,8.2,0,12.3-4.7,12.3v0.7h26.5v-0.7C434.5,67.7,433.6,64.7,433.6,55.4z"
            />
            <path
              d="M363.3,17.6c-13.2,0-23.9,14.5-23.9,26.4c0,13.7,11,25.3,23.9,25.3c10.6,0,23.5-11.1,23.5-26.1
				C386.8,30.4,375.3,17.6,363.3,17.6z M363.3,68.3c-8.1,0-8-10.6-8-25.7c0-12.5-0.6-24.1,8-24.1c7.9,0,8.4,8.9,8.4,24.1
				C371.7,57.8,372.3,68.3,363.3,68.3z"
            />
            <path
              d="M332.2,52V22.1h8.5l1.7-3.5h-10.2V3.3h-0.9c-5,7.3-15.8,16.1-22.4,18.4v0.4h8.4v33.8c0,7.2,4.7,12.5,12.3,12.5
				c2,0,7.3,1.3,15.1-4.9l-0.2-0.9C340.9,65.8,332.2,67,332.2,52z"
            />
            <path
              d="M284.7,25.7c0-0.5-0.3-7.3,8.1-7.3c10.3,0,11.2,9.9,10.7,12.4h6c0-3.4-1.9-13.1-18-13.1c-10,0-16.6,7.3-16.6,16
				c0,19.3,26.4,13.8,26.4,26.5c0,4.1-4.3,7.8-9,7.8c-2.6,0-13.5,0-12.5-15.6h-7.1c0,4.6,4.7,17.5,22.6,16.5c10.3-0.6,18-8.2,18-17.3
				C313.3,33.6,284.7,38,284.7,25.7z"
            />
            <path
              d="M277.4,20.1V19h-22.2v5.3c0,0-2.8-6.4-10-6.4c-10.2,0-19,12.2-19,26c0,16.9,7,25.4,16.3,25.4c8,0,10.2-6.5,11.7-8.4
				c0.9,9.6,3.1,20.5-7.2,28.1l-0.1,1.2c18-5.1,22.6-12.2,23.3-22.6V29.1C270.1,18.6,277.4,20.1,277.4,20.1z M255.5,45.5
				c0,10.9-3.7,21.1-8.2,21.1c-5.1,0-6.2-9-6.2-22.5c0-16,1.3-24.9,6.3-24.9c7.2,0,8.1,11,8.1,22.2V45.5z"
            />
            <path
              d="M222.5,55.4l0-19c0-8.6-1.1-18.9-14.5-18.9c-5.6,0-8.9,0.2-13.8,7.2v-6.2h-21.8v1.2c5.7,0,7,3.4,7,10.3v25.3
				c0,8-0.9,12.3-6,12.3v0.7h26.1v-0.7c-4.7,0-5.1-4.1-5.1-12.3V28.1c1.7-2.8,1.9-7.7,8-7.7c5.3,0,4.6,8.7,4.6,15.7v19.3
				c0,8.2,0,12.3-4.7,12.3v0.7h26.5v-0.7C223.5,67.7,222.5,64.7,222.5,55.4z"
            />
            <path
              d="M165.1,55.4V18.6h-21.7v1.2c5.7,0,7.1,3.6,7.1,10.3v25.3c0,5.7-0.4,12.3-6.1,12.3v0.7h27.9v-0.7
				C166.7,67.7,165.1,65,165.1,55.4z"
            />
            <path d="M157.6,15.5c4.2,0,7.8-3.5,7.8-7.8c0-4.2-3.5-7.8-7.8-7.8c-4.2,0-7.8,3.5-7.8,7.8C149.8,12,153.3,15.5,157.6,15.5z" />
            <path
              d="M133.5,76.5c-3-10.4-2.4-24.5-29.3-25.7L123.8,31c5.1-4.1,13.7-11.2,18.2-11.2v-1.2h-26.7v1.2c3.6,0,9.8,3.2,3.3,13.8
				c-0.9,1.5-2.4,3.8-15.5,16.8l-1.4-0.1V32.8c0-8.4,0.6-13.3,8.7-13.3v-0.8H79v0.8c9.7,0,8,16.6,8,25v23.9c0,8.4,1.2,18.8-8.6,18.8
				V88h32.8v-0.8c-8.5,0-9.5-3.9-9.5-12.2V52.5c11.6-1.7,13.4,15.2,17,35.5c0,0,23.9,0,23.9,0v-0.7C136.2,86,135.2,82.4,133.5,76.5z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default E_logoKingston;
