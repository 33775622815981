import { useEffect, useState } from "react";

const E_ContentBtn = ({ btnData, click, currentContent }) => {
  const [active, setActive] = useState(false);

  const checkActive = () => {
    btnData.active != currentContent ? setActive(false) : setActive(true);
  };
  const handleClick = () => {
    click(btnData);
    checkActive();
  };

  useEffect(() => {
    checkActive();
  }, [currentContent]);

  return (
    <li
      aria-hidden="true"
      className={`s-contentNav__list__item ${active ? "-active" : ""}`}
      onClick={() => handleClick()}
    >
      {btnData.name}
    </li>
  );
};

export default E_ContentBtn;
