/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_PageResults = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let fbVars = {
      ...appState,
      currentPath: "/ui/page-results",
      outputName: "PageResults",
      headerName: "Page Results",
      tags: null,
      description:
        "The Page Resutls lists out the pages found via the search feature in the nav bar.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        ["Title", ["String Text, <h5> tag with inherit global styling"]],
        ["Copy", ["The copy appears as a paragraph beneath the title. No Character limit."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Ed1SsJOMEQ9EpH0EXHZWqOsBXwmAqRa_qjyotJmwJGICEw?e=iSeIGu",
        comments: null,
      },
    };
    setAppState(fbVars);

    setContentOptions({
      title1: {
        label: "Title 1",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum 1",
          Span: "Título 1",
          ChS: "标题 1",
          Gr: "Überschrift 1",
          Ru: "Заголовок 1",
          Fr: "Titre 1",
          It: "Titolo 1",
          Pl: "Tytuł 1",
          Port: "Título 1",
          Viet: "Cú đội đầu 1",
          Turk: "Başlık 1",
          Ukrn: "Заголовок 1",
          Thai: "หัวเรื่อง 1",
          Kor: "표제 1",
          ChT: "標題 1",
          Jp: "見出し 1",
        },
      },
      copy1: {
        label: "Copy 1",
        checkbox: null,
        field: {
          Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia vo.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      title2: {
        label: "Title 2",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum 2",
          Span: "Título 2",
          ChS: "标题 2",
          Gr: "Überschrift 2",
          Ru: "Заголовок 2",
          Fr: "Titre 2",
          It: "Titolo 2",
          Pl: "Tytuł 2",
          Port: "Título 2",
          Viet: "Cú đội đầu 2",
          Turk: "Başlık 2",
          Ukrn: "Заголовок 2",
          Thai: "หัวเรื่อง 2",
          Kor: "표제 2",
          ChT: "標題 2",
          Jp: "見出し 2",
        },
      },
      copy2: {
        label: "Copy 2",
        checkbox: null,
        field: {
          Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia vo.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      title3: {
        label: "Title 3",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum 3",
          Span: "Título 3",
          ChS: "标题 3",
          Gr: "Überschrift 3",
          Ru: "Заголовок 3",
          Fr: "Titre 3",
          It: "Titolo 3",
          Pl: "Tytuł 3",
          Port: "Título 3",
          Viet: "Cú đội đầu 3",
          Turk: "Başlık 3",
          Ukrn: "Заголовок 3",
          Thai: "หัวเรื่อง 3",
          Kor: "표제 3",
          ChT: "標題 3",
          Jp: "見出し 3",
        },
      },
      copy3: {
        label: "Copy 3",
        checkbox: null,
        field: {
          Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia vo.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "512" });
  }, []);

  if (!appState) return "...Loading Page Results";
  return (
    <section className="s-pageResults">
      <ul className="s-pageResults__list l-inner u-list-unstyled">
        <li className="s-pageResults__list__item">
          <div className="c-resultsCard">
            <h5 className="c-resultsCard__heading">
              <a href="#">
                Results <strong>Memory</strong> Heading
              </a>
            </h5>
            <p className="c-resultsCard__copy">
              Morbi cursus mauris et quam eleifend <strong>memory</strong>. Pellentesque condimentum
              tempor varius. Etiam rhoncus diam eu dapibus porta. Maecenas dictum et est non
              efficitur.
            </p>
          </div>
        </li>
        <li className="s-pageResults__list__item">
          <div className="c-resultsCard">
            <h5 className="c-resultsCard__heading">
              <a href="#">
                {contentOptions.title1 && contentOptions.title1.field[contentOptions.currentLang]}
              </a>
            </h5>
            <p className="c-resultsCard__copy">
              {contentOptions.copy1 && contentOptions.copy1.field[contentOptions.currentLang]}
            </p>
          </div>
        </li>
        <li className="s-pageResults__list__item">
          <div className="c-resultsCard">
            <h5 className="c-resultsCard__heading">
              <a href="#">
                {contentOptions.title1 && contentOptions.title2.field[contentOptions.currentLang]}
              </a>
            </h5>
            <p className="c-resultsCard__copy">
              {contentOptions.copy1 && contentOptions.copy2.field[contentOptions.currentLang]}
            </p>
          </div>
        </li>
        <li className="s-pageResults__list__item">
          <div className="c-resultsCard">
            <h5 className="c-resultsCard__heading">
              <a href="#">
                {contentOptions.title1 && contentOptions.title3.field[contentOptions.currentLang]}
              </a>
            </h5>
            <p className="c-resultsCard__copy">
              {contentOptions.copy1 && contentOptions.copy3.field[contentOptions.currentLang]}
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "page-results",
  component: S_PageResults,
  navtxt: "Page Results",
  htmlName: "PageResults",
  categoryType: ["internal"],
};
