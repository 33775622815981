import React, { useEffect } from "react";

const C_CarouselPanel = ({
  type,
  time,
  pos,
  lg,
  sm,
  dataPeek,
  leftPeek,
  rightPeek,
  title,
  copy,
  cta,
  hide,
  icon,
  setDuration,
  slide,
  currSlide,
}) => {
  let cType = type ? type : "image";
  let cTime = time ? time : 5;
  let cPos = pos ? pos : "left";
  let cHide = hide ? hide : false;
  let cTitle = title ? title : "Kingston Fury";
  let lImg = lg ? lg : "/images/fpo/general/2048x768.png";
  let sImg = sm ? sm : "/images/fpo/general/512x512.png";
  let cPeek = dataPeek ? dataPeek : "../images/fpo/section-carousel/fury-sample-peek.jpg";
  let cIcon = icon ? icon : "https://www.kingston.com/shared/icons-map.svg#play";
  let cCopy = copy
    ? copy
    : "Duis mattis mattis aliquet. Donec sit amet turpis ut sem pharetra tempus id eget tellus.";
  let cCTA = cta ? cta : "Learn More";

  //can't drag and drop images bc of abs positioning, etc. maybe fixable?

  useEffect(() => {
    // currSlide === slide && setDuration(time);
    if (currSlide === slide) {
      setDuration(time);
      leftPeek();
      rightPeek();
    }
  }, [slide, hide]);

  return (
    <div
      className={`s-carousel__slides__panel ${cHide ? "u-hide" : ""}`}
      role="tabpanel"
      data-preview={cPeek}
      data-delay={cTime}
      data-clip-position={cPos}
      data-type={cType}
      aria-hidden={cHide}
    >
      <a className="s-carousel__slides__panel__link" href="#link">
        {cType === "image" ? (
          <picture>
            <source
              media="(min-width:40em)"
              // srcSet={lImg}
              srcSet={lImg}
            />
            <img
              className="s-carousel__slides__panel__img"
              src={sImg}
              alt={cTitle}
              loading="lazy"
            />
          </picture>
        ) : (
          <>
            <div className="e-vimeoPlayer" data-vimeo-small={sm} data-vimeo-large={lg}>
              <div className="e-vimeoPlayer__player"></div>
            </div>
            <img
              className="s-carousel__slides__panel__bg"
              src="/images/fpo/section-carousel/kiwi-sample-backup.jpg"
              loading="lazy"
              alt="Background"
            />
          </>
        )}
      </a>
      <div className="l-inner s-carousel__slides__panel__wrap" data-link="#link">
        <div className="c-headerClip">
          <div className="u-h1 c-headerClip__title">{cTitle}</div>
          {cCopy && cCopy.checkbox ? <p className="c-headerClip__desc">{cCopy.field}</p> : ""}

          <div className="c-headerClip__cta">
            {cCTA && cCTA.checkbox ? (
              <a className="c-headerClip__cta__link" href="#link1" title="Learn More">
                {cIcon && cIcon.checkbox ? (
                  <svg viewBox="0 0 16 16" fill="white">
                    <use xmlns="http://www.w3.org/1999/xlink" xlinkHref={cIcon.field}></use>
                  </svg>
                ) : (
                  ""
                )}
                {cCTA.field}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {cType === "image" ? (
        <img className="s-carousel__slides__panel__bg" src={lg} loading="lazy" alt="Background" />
      ) : (
        ""
      )}
    </div>
  );
};

export default C_CarouselPanel;
