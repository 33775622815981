/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SupportContactProduct = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let vars = {
      ...appState,
      currentPath: "/ui/support-contact-product",
      outputName: "SupportContactProduct",
      headerName: "Support Contact: Product Page",
      tags: null,
      currentTheme: null,
      description:
        "The Support Contact: Product Page section provides the contact information for and technical issues.",
      related: [["Support Contact", "support-contact"]],
      htmlSaved: true,
      scripts: [],
      specs: [
        [
          "Contact Component",
          [
            "Number of visible cards can vary by region due to availability and cookie preferences affecting chat in UK",
          ],
        ],
        ["Headline", ["Standard Text"]],
        ["Description", ["Standard Text"]],
        ["Contact", ["Standard Text"]],
        ["SVG", ["Embedded and not editable"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EdszZVYUXmBGp7N8dU77AacBDJJhSi7wXQ9aUhDOJeV0Qg?e=93uqvT",
        comments: null,
      },
    };
    setAppState(vars);
    setContentOptions({
      heading: {
        name: "heading",
        label: "Tech support heading",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calo",
          ChS: "那个天气很热",
          Gr: "Tochter; es hieß",
          Ru: "Лорем ипсум долор",
          Fr: "Il faisait chaud",
          It: "Faceva caldo con",
          Pl: "Było gorąco w",
          Port: "Fazia calor",
          Viet: "Thời tiết đó",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을",
          ChT: "那個天氣很熱",
          Jp: "旅ロ京青利セムレ",
        },
      },
      subheading: {
        name: "subheading",
        label: "Subheading paragraph",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof ",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат м",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais ",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... ",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- ",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không b",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey,",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... --",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตาม",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 ",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユ",
        },
      },
      emailTsTitle: {
        name: "emailTsTitle",
        label: "Email Tech Support Title",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
      },
      emailTechDesc: {
        name: "emailTechDesc",
        label: "Tech support email desc",
        checkbox: null,
        field: {
          Eng: "Quis varius nunc sed id semper",
          Span: "Hacía calor en ese tiempo, para mí",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn",
          Ru: "Лорем ипсум долор сит амет, пер цлита",
          Fr: "Il faisait chaud dans ce temps-là, pour",
          It: "Faceva caldo con quel tempo, per mm",
          Pl: "Było gorąco w taką pogodę, jak dla",
          Port: "Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi",
          Turk: "O hava sıcaktı, benim için, pek",
          Ukrn: "У таку погоду було спекотно, для",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
        },
      },
      emailTechCTA: {
        name: "emailTechCTA",
        label: "Email Tech Support CTA",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "correo electrónico",
          ChS: "电子邮件",
          Gr: "Email",
          Ru: "электронная почта",
          Fr: "e-mail",
          It: "e-mail",
          Pl: "e-mail",
          Port: "e-mail",
          Viet: "e-mail",
          Turk: "e-posta",
          Ukrn: "електронною поштою",
          Thai: "อีเมล",
          Kor: "이메일",
          ChT: "電子郵件",
          Jp: "Eメール",
        },
      },
      phoneTsTitle: {
        name: "phoneTsTitle",
        label: "Call Tech Support Title",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
      },
      phoneTechDesc: {
        name: "phoneTechDesc",
        label: "Tech support phone desc",
        checkbox: null,
        field: {
          Eng: "Quis varius nunc sed id semper",
          Span: "Hacía calor en ese tiempo, para mí",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn",
          Ru: "Лорем ипсум долор сит амет, пер цлита",
          Fr: "Il faisait chaud dans ce temps-là, pour",
          It: "Faceva caldo con quel tempo, per mm",
          Pl: "Było gorąco w taką pogodę, jak dla",
          Port: "Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi",
          Turk: "O hava sıcaktı, benim için, pek",
          Ukrn: "У таку погоду було спекотно, для",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
        },
      },
      chatTitle: {
        name: "chatTitle",
        label: "Chat title",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
      },
      chatHours: {
        name: "chatHours",
        label: "Chat Hours",
        checkbox: true,
        field: {
          Eng: "Quis varius nunc sed id semper",
          Span: "Hacía calor en ese tiempo, para mí",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
          Gr: "Tochter; es hieß, sie sei mit Herrn",
          Ru: "Лорем ипсум долор сит амет, пер цлита",
          Fr: "Il faisait chaud dans ce temps-là, pour",
          It: "Faceva caldo con quel tempo, per mm",
          Pl: "Było gorąco w taką pogodę, jak dla",
          Port: "Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi",
          Turk: "O hava sıcaktı, benim için, pek",
          Ukrn: "У таку погоду було спекотно, для",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
        },
      },
      chatCTA: {
        name: "chatCTA",
        label: "Chat CTA",
        checkbox: null,
        field: {
          Eng: "Dolor sit",
          Span: "Charlar",
          ChS: "聊天",
          Gr: "Plaudern",
          Ru: "Чат",
          Fr: "Discuter",
          It: "Chiacchierata",
          Pl: "Czat",
          Port: "Bater papo",
          Viet: "Trò chuyện",
          Turk: "Sohbet",
          Ukrn: "Чат",
          Thai: "แชท",
          Kor: "채팅",
          ChT: "聊天",
          Jp: "チャット",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "737",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Support Contact: Product Page Section";
  return (
    <section className="s-supportContact">
      <div className="l-inner">
        <div className="l-row">
          <div className="c-supportContactCard c-supportContactCard--product">
            <div className="c-supportContactCard__heading">
              <h3>
                {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
                {contentOptions.subheading && contentOptions.subheading.checkbox ? (
                  <p>{contentOptions.subheading.field[contentOptions.currentLang]}</p>
                ) : (
                  ""
                )}
              </h3>
            </div>
            <div className="c-supportContactCard__content">
              <div className="c-supportContactCard__content__item">
                <svg
                  className="c-supportContactCard__content__item__icon"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      fillOpacity=".202"
                      fill="#000"
                      d="M1.011 4.011H23l-.985 1.99L14.011 12H10L2.004 6.001z"
                    />
                    <path
                      d="M20.154 3c1.17 0 1.688.107 2.126.308l.185.093c.49.261.873.645 1.134 1.134l.093.185c.201.438.308.956.308 2.126v10.308c0 1.17-.107 1.688-.308 2.126l-.093.185a2.726 2.726 0 01-1.134 1.134l-.185.093c-.438.201-.956.308-2.126.308H3.846c-1.17 0-1.688-.107-2.126-.308l-.185-.093A2.726 2.726 0 01.4 19.465l-.093-.185c-.187-.407-.292-.882-.306-1.885L0 6.845c0-1.17.107-1.687.308-2.125l.093-.185A2.726 2.726 0 011.535 3.4l.185-.093c.407-.187.882-.292 1.885-.306L20.155 3zm-6.158 9.475l-.383.283a2.75 2.75 0 01-3.065.127l-.164-.11-.393-.281-7.96 6.642c.064.053.134.1.211.14l.066.034.139.058c.168.061.373.098.7.117l.314.011.385.004h16.51l.348-.009c.417-.015.656-.053.85-.123l.138-.058.066-.033c.079-.043.15-.09.216-.145l-7.978-6.657zM1.505 6.412l-.001.049a14.95 14.95 0 00-.003.182v10.714l.005.264 7.226-6.03-7.227-5.179zm20.984-.166l-7.247 5.316 7.251 6.05.003-.073.004-.385V6.644l-.009-.348-.002-.05zm-2.132-1.745H3.643l-.347.008c-.417.015-.656.053-.85.123l-.138.058-.066.033a1.272 1.272 0 00-.262.184l9.278 6.65c.4.286.925.31 1.345.07l.123-.079 9.178-6.734a1.334 1.334 0 00-.146-.09l-.066-.034-.139-.058c-.168-.061-.373-.098-.7-.117l-.314-.011a14.95 14.95 0 00-.182-.003z"
                      fill="#353535"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
                <div className="c-supportContactCard__content__item__info">
                  <h4 className="u-h5">
                    {contentOptions.emailTsTitle &&
                      contentOptions.emailTsTitle.field[contentOptions.currentLang]}
                  </h4>
                  <p>
                    {contentOptions.emailTechDesc &&
                      contentOptions.emailTechDesc.field[contentOptions.currentLang]}
                  </p>
                  {/* <a className="e-arrowCTA" href="#anchorLink" title="Anchor Title">
                    <span className="e-arrowCTA__copy">
                      {contentOptions.emailTechCTA && contentOptions.emailTechCTA.field[contentOptions.currentLang]}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="32"
                      width="32"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      className="e-arrowCTA__icon"
                    >
                      <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z"></path>
                    </svg>
                  </a> */}
                  <a className="e-btn" target="_self" title="Email" href="#email">
                    <span>
                      {contentOptions.emailTechCTA &&
                        contentOptions.emailTechCTA.field[contentOptions.currentLang]}
                    </span>
                  </a>
                </div>
              </div>
              <div className="c-supportContactCard__content__item">
                <svg
                  className="c-supportContactCard__content__item__icon"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      fill="#000"
                      opacity=".2"
                      d="M20 17.251l-.161 5.815-7.836.169-5.665.391L4 21.905v-4.654z"
                    />
                    <path
                      d="M19 0a2 2 0 012 2v20a2 2 0 01-2 2H5a2 2 0 01-2-2V2a2 2 0 012-2h14zm.5 18h-15v4a.5.5 0 00.41.492L5 22.5h14a.5.5 0 00.492-.41L19.5 22v-4zM12 19a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm7-17.5H5a.5.5 0 00-.492.41L4.5 2v14.5h15V2a.5.5 0 00-.41-.492L19 1.5zM14.25 3a.75.75 0 110 1.5h-4.5a.75.75 0 010-1.5h4.5z"
                      fill="#353535"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
                <div className="c-supportContactCard__content__item__info">
                  <h4 className="u-h5">
                    {contentOptions.phoneTsTitle &&
                      contentOptions.phoneTsTitle.field[contentOptions.currentLang]}
                  </h4>
                  <p>
                    {contentOptions.phoneTechDesc &&
                      contentOptions.phoneTechDesc.field[contentOptions.currentLang]}
                  </p>
                  <p className="c-supportContactCard__content__item__info__number">
                    +1 (800) 435-0640
                  </p>
                </div>
              </div>
              <div className="c-supportContactCard__content__item">
                <svg
                  className="c-supportContactCard__content__item__icon"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      fill="#000"
                      opacity=".2"
                      d="M1 3 4.036.962h8.941L17 3v3l-6.462.59-1.535 2.623-1.02 4.692-4.645 1.721L1 13.905V6z"
                    />
                    <path
                      d="M12 0a6.002 6.002 0 0 1 5.917 5H20a4 4 0 0 1 4 4v8a4 4 0 0 1-3.8 3.995L20 21v3l-5.391-3h-2.626a4 4 0 0 1-4-4l-.001-2H6l-3.998 3H1.99v-3l-.148-.005a1.991 1.991 0 0 1-1.836-1.83L0 13.01V6A6 6 0 0 1 5.775.004L6 0h6Zm8 6.5h-8.017a2.5 2.5 0 0 0-2.495 2.336L9.483 9v8a2.5 2.5 0 0 0 2.335 2.495l.165.005h3.015l3.502 1.949v-1.913l1.626-.04a2.5 2.5 0 0 0 2.369-2.33L22.5 17V9a2.5 2.5 0 0 0-2.336-2.495L20 6.5Zm-8-5H6a4.5 4.5 0 0 0-4.495 4.288L1.5 6v7.01a.5.5 0 0 0 .331.473l.066.013 1.594.058-.001 1.454L5.5 13.5h2.482V9a4 4 0 0 1 4-4h4.405a4.501 4.501 0 0 0-4.175-3.495L12 1.5Z"
                      fill="#353535"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
                <div className="c-supportContactCard__content__item__info">
                  <h4 className="u-h5">
                    {contentOptions.chatTitle &&
                      contentOptions.chatTitle.field[contentOptions.currentLang]}
                  </h4>
                  {contentOptions.chatHours && contentOptions.chatHours.checkbox ? (
                    <p>{contentOptions.chatHours.field[contentOptions.currentLang]}</p>
                  ) : (
                    ""
                  )}
                  {/* <a className="e-arrowCTA" href="#anchorLink" title="Anchor Title">
                    <span className="e-arrowCTA__copy">
                      {contentOptions.chatCTA && contentOptions.chatCTA.field[contentOptions.currentLang]}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="32"
                      width="32"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      className="e-arrowCTA__icon"
                    >
                      <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z"></path>
                    </svg>
                  </a> */}
                  <a className="e-btn" target="_self" title="Email" href="#email">
                    <span>
                      {contentOptions.chatCTA &&
                        contentOptions.chatCTA.field[contentOptions.currentLang]}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "support-contact-product",
  component: S_SupportContactProduct,
  navtxt: "Support Contact: Product Page",
  htmlName: "SupportContactProduct",
  categoryType: ["internal"],
};
