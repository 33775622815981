/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useState } from "react";
import OptionsContext from "../../../OptionsContext";

const C_LanguageSwitcher = () => {
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [selLangName, setSelLangName] = useState("English");
  const [active, setActive] = useState(false);

  const Languages = [
    "English",
    "Spanish/Español",
    "Chinese-Simp./中文(简体)",
    "German/Deutsch",
    "Russian/Pyccкий",
    "French/Français",
    "Italian/Italiano",
    "Polish/Polski",
    "Portuguese/Português",
    "Vietnamese/Tiếng Việt",
    "Turkish/Türkçe",
    "Ukrainian/Українська",
    "Thai/ไทย",
    "Korean/한국어",
    "Chinese-Trad./中文(繁體)",
    "Japanese/日本語",
  ];

  function changeLanguage(lang) {
    let sLang;

    switch (lang) {
      case "English":
        sLang = "Eng";
        break;
      case "Spanish/Español":
        sLang = "Span";
        break;
      case "Chinese-Simp./中文(简体)":
        sLang = "ChS";
        break;
      case "German/Deutsch":
        sLang = "Gr";
        break;
      case "Russian/Pyccкий":
        sLang = "Ru";
        break;
      case "French/Français":
        sLang = "Fr";
        break;
      case "Italian/Italiano":
        sLang = "It";
        break;
      case "Polish/Polski":
        sLang = "Pl";
        break;
      case "Portuguese/Português":
        sLang = "Port";
        break;
      case "Vietnamese/Tiếng Việt":
        sLang = "Viet";
        break;
      case "Turkish/Türkçe":
        sLang = "Turk";
        break;
      case "Ukrainian/Українська":
        sLang = "Ukrn";
        break;
      case "Thai/ไทย":
        sLang = "Thai";
        break;
      case "Korean/한국어":
        sLang = "Kor";
        break;
      case "Chinese-Trad./中文(繁體)":
        sLang = "ChT";
        break;
      case "Japanese/日本語":
        sLang = "Jp";
        break;
    }

    setContentOptions({
      ...contentOptions,
      currentLang: sLang,
    });
    setSelLangName(lang);
    setActive(!active);
  }

  // useEffect(() => {
  //   let lang = contentOptions.currentLang;
  //   setSelLangName(lang);
  //   setActive(false);
  // }, [contentOptions]);

  return (
    <div className="l-viewerPanels__controls__option">
      <h6>Test another Language</h6>
      <div className={`c-dropDown v-dropDown ${active ? "c-dropDown--active" : ""}`}>
        <div className="c-dropDown__toggler" role="button" onClick={() => setActive(!active)}>
          <span className={contentOptions.currentLang}></span>
          {selLangName}
          <svg
            className="v-dropDown__chevron"
            viewBox="0 0 11 6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>icons/chevron</title>
            <path
              d="M5.5 6a.786.786 0 01-.547-.217L.212 1.195a.672.672 0 01.02-.998.799.799 0 011.073.02L5.5 4.276 9.695.216a.8.8 0 011.072-.019c.302.27.312.717.021.998L6.047 5.783A.786.786 0 015.5 6"
              fill="#FFF"
              fillRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="c-dropDown__panel">
          <ul data-root="vDropDown1">
            {Languages.map((theme) => (
              <li className="v-dropDown__item" key={theme} onClick={() => changeLanguage(theme)}>
                <span className={`v-bubble t-` + theme.toLocaleLowerCase()}></span>
                {theme}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default C_LanguageSwitcher;
