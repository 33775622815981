[
  "btn-cart",
  "calendar",
  "chat-communication",
  "chat",
  "credit_card",
  "filter",
  "hide",
  "home",
  "icon-chat",
  "icon-globe",
  "icon-search",
  "language",
  "location",
  "order",
  "person",
  "thumbs-outline",
  "thumbs-solid",
  "trash",
  "truck"
]
