/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Decoder = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  // currentPath helps with browser navigation, and highlights the item in nav menu.
  // viewHeight is used to autoadjust the height
  // viewWidth is set and changed outside the web components, but preserved by ...Dimensions context
  // outputName is used by IframeWrapper preload html.
  // htmlSaved is used to determine if there is html available to load; can't read local file directories from browser
  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/decoder",
      outputName: "Decoder",
      headerName: "Decoder Section",
      tags: null,
      description:
        "The Decoder Section is designed to help you identify Kingston® memory modules by specification.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "../../js/cuid.component.js"],
      specs: [
        [
          "Number Blocks",
          ["Number Blocks displaying the individual characters of the part number"],
        ],
        ["Legend", ["Standard text that displays the values of each part number character"]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1wvdV9LX3-jrPEsE8pQ9hCDcxvrN9ACCy11JENTgZ8sA/edit?usp=sharing",
        comments: null,
      },
    };
    setAppState(heroVars);
    setContentOptions({
      partnumber: { label: "Part Number", checkbox: null, field: "KVR1066D3LD8R7SLK2/46HB" },
      char: { label: "Character", checkbox: null, field: "KVR" },
      value: { label: "Value", checkbox: null, field: "KVR = Kingston ValueRAM" },
      list: { label: "List Item", checkbox: null, field: "KVR = Kingston Value RAM" },
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  //this context data must be loaded else errors are thrown, so show loading text
  if (!appState) return "...Loading Decoder";
  return (
    <section className={`s-decoder ` + appState.currentTheme}>
      <div className="l-inner">
        <div className="s-decoder__numBlock">
          <p>Part Number: {contentOptions.partnumber && contentOptions.partnumber.field}*</p>
          <div className="s-decoder__numBlock__numbers">
            <ul aria-label="HyperX Part Number Decoder">
              <li>{contentOptions.char && contentOptions.char.field}</li>
              <li>1066</li>
              <li>D3</li>
              <li>L</li>
              <li>D</li>
              <li>8</li>
              <li>R</li>
              <li>7</li>
              <li>S</li>
              <li>L</li>
              <li>K2</li>
              <li className="slash">/</li>
              <li>4G</li>
              <li>H</li>
              <li>B</li>
            </ul>
          </div>
        </div>
        <div className="s-decoder__legend">
          <div className="l-row">
            <div className="l-row__col l-1/3@md">
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">
                  {contentOptions.value && contentOptions.value.field}
                </div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>{contentOptions.list && contentOptions.list.field}</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">48 = Speed</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>48 - Speed</li>
                  <li>52 - 5200</li>
                  <li>56 - 5600</li>
                  <li>60 - 6000</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">U = DIMM Type</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>U - DIMM (Non-ECC Unbuffered)</li>
                  <li>S - SO-DIMM (Non-ECC Unbuffered)</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">40B = CAS Latency</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>40 - 40-40-40</li>
                  <li>40B - 40-39-39</li>
                  <li>42 - 42-42-42</li>
                </ul>
              </div>
            </div>
            <div className="l-row__col l-1/3@md">
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">S = Ranks</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>S - Single Rank</li>
                  <li>D - Dual Rank</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">8 = DRAM Type</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>8 - x8 DRAM chip</li>
                  <li>6 - x16 DRAM chip</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">L = Profile</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>Blank - Standard</li>
                  <li>L - Very Low Profile (VLP)</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">K2 = Kit + Number of pieces</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>Blank - Single Module</li>
                  <li>K2 - Kit of 2 Modules</li>
                  <li>K4 - Kit of 4 Modules</li>
                </ul>
              </div>
            </div>
            <div className="l-row__col l-1/3@md">
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">32 = Capacity</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>8 - 8GB</li>
                  <li>16 - 16GB</li>
                  <li>32 - 32GB</li>
                  <li>64 - 64GB</li>
                  <li>128 - 128GB</li>
                  <li>256 - 256GB</li>
                </ul>
              </div>
              <div className="c-decoderCard">
                <div className="c-decoderCard__value u-txt-bold">X = Customization</div>
                <ul className="c-decoderCard__items u-list-unstyled">
                  {" "}
                  <li>Blank - Standard</li>
                  <li>BK - Bulk Pack</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "decoder",
  component: S_Decoder,
  navtxt: "Decoder",
  htmlName: "Decoder",
  categoryType: ["internal"],
};
