export function getHeight(dimensions, containerRef, setDimensions, path) {
  return () => {
    //if height is hardcoded do nothing
    if (dimensions.hardcodeHeight) {
      return;
    } else {
      if (containerRef.current) {
        let newHeight = containerRef.current.offsetHeight;
        //use context, not local state or child viewport won't update again.
        if (newHeight !== dimensions.viewHeight)
          setDimensions({ ...dimensions, viewHeight: newHeight });
      } else {
        //only run if we are viewing web content - not brand
        if (path.includes("/ui")) {
          let htmlFrame = document.querySelector("#html-iframe");
          let newHeight;
          if (htmlFrame && htmlFrame.contentWindow.document.body.scrollHeight)
            newHeight = htmlFrame.contentWindow.document.body.scrollHeight;
          // console.log("newHeight: ", newHeight);
          if (newHeight && newHeight !== dimensions.viewHeight)
            setDimensions({ ...dimensions, viewHeight: newHeight });
        }
      }
    }
  };
}
