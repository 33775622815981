export default {
  numtabs: {
    label: "Number of Tabs",
    checkbox: null,
    field: [0, 2],
    selected: 2,
  },
  numCards: {
    label: "Number of Cards [0 Tabs]",
    checkbox: null,
    field: [1, 6, 12],
    selected: 6,
  },
  filter: {
    label: "Enable Filter",
    checkbox: true,
    field: null,
  },
  sort: {
    label: "Enable Sort",
    checkbox: true,
    field: null,
  },
  heading: {
    label: "Heading",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum dolor sit amet",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題 ",
      Jp: "見出し",
    },
  },
  headingcopy: {
    label: "Description",
    checkbox: true,
    field: {
      Eng: "Sed ut perspiciatis unde omnis iste natus error",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },
  tabs: [
    {
      label: "Tab 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Soportada",
        ChS: "支持的",
        Gr: "Unterstützt",
        Ru: "Поддерживается",
        Fr: "Prise en charge",
        It: "Supportata",
        Pl: "Utrzymany",
        Port: "Suportada",
        Viet: "Được hỗ trợ",
        Turk: "Destekleniyor",
        Ukrn: "Підтримується",
        Thai: "ได้รับการสนับสนุน",
        Kor: "지원됨",
        ChT: "支持的",
        Jp: "サポートされています",
      },
      block: 1,
    },
    {
      label: "Tab 2",
      checkbox: null,
      field: {
        Eng: "Magna aliqua",
        Span: "Interrumpida",
        ChS: "停产",
        Gr: "Abgesetzt",
        Ru: "Снято с производства",
        Fr: "Discontinué",
        It: "Interrotto",
        Pl: "Przerwane",
        Port: "Interrompida",
        Viet: "Đã ngừng sản xuất",
        Turk: "Durdurulduk",
        Ukrn: "Припинено",
        Thai: "ยกเลิก",
        Kor: "단종",
        ChT: "日追一日",
        Jp: "製造中止",
      },
      block: 1,
    },
  ],
  supportgallerycard: [
    {
      label: "Product Name [Card 1]",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Nombre",
        ChS: "姓名",
        Gr: "姓",
        Ru: "Фамилия",
        Fr: "Nom de famille",
        It: "Cognome",
        Pl: "Nazwisko",
        Port: "Sobrenome",
        Viet: "Họ",
        Turk: "Soyadı",
        Ukrn: "Прізвище",
        Thai: "นามสกุล",
        Kor: "성",
        ChT: "姓",
        Jp: "名前",
      },
      block: 1,
    },
    {
      label: "Part Number [Card 1]",
      checkbox: null,
      field: {
        Eng: "Dolor",
        Span: "número",
        ChS: "数字",
        Gr: "Nummer",
        Ru: "число",
        Fr: "nombre",
        It: "numero",
        Pl: "numer",
        Port: "número",
        Viet: "con số",
        Turk: "sayı",
        Ukrn: "номер",
        Thai: "ตัวเลข",
        Kor: "숫자",
        ChT: "數位",
        Jp: "番号",
      },
      block: 1,
    },
  ],
  currentLang: "Eng",
};
