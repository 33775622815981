import React, { useState } from "react";
import DropZone from "../../../0_hooks/dropzone";
import E_ArrowCTA from "../../1_elements/arrowCTA/E_ArrowCTA";
// let cardImg = "/images/fpo/general/576x200.png";

const C_Card = ({ type, image, title, copy, radius, cta, ...props }) => {
  const [featureImage, setFeatureImage] = useState({ preview: "" });

  let cType = type ? type : null;
  let cClasses = props.classes ? props.classes : null;
  let cTitle = title ? title : null;
  let cImg = image ? image : null;
  let cCopy = copy ? copy : null;
  let cRadius = radius ? radius : null; //default is square, Lg is large radius, Sm is small radius
  let cCTA = cta ? cta : null;
  let cTagline = props.tagline ? props.tagline : null;
  let cTaglineIcon = props.taglineIcon ? props.taglineIcon : null;
  let cUrl = props.url ? props.url : null;
  let cTag = props.tag ? props.tag : null;
  let cTagClass = props.tagclass ? props.tagclass : "";

  return (
    <>
      {cUrl ? (
        <a
          href={cUrl}
          className={`c-card ${cType ? "c-card--" + cType : ""} ${
            cRadius == "large" ? "c-card--lgRadius" : cRadius == "small" ? "c-card--smRadius" : ""
          } ${cClasses ? cClasses : ""}`}
        >
          {cTag ? <div className={`e-tag c-card__tag ${cTagClass}`}>{cTag}</div> : ""}
          {cImg ? (
            <div className="c-card__img">
              <DropZone setImage={setFeatureImage}>
                <img
                  src={featureImage.preview ? featureImage.preview : cImg}
                  alt="Alternate Text"
                  data-dropdown="true"
                />
              </DropZone>
            </div>
          ) : (
            ""
          )}

          {cTitle || cTagline || cTaglineIcon || cCopy ? (
            <div className="c-card__details">
              {cTitle ? <h3>{cTitle}</h3> : ""}

              {cTagline ? (
                <div className="c-card__details__tagline">
                  {cTaglineIcon ? (
                    <svg className="c-card__details__tagline__icon">
                      <use href={"/images/icons-map.svg" + cTaglineIcon} />
                    </svg>
                  ) : (
                    ""
                  )}
                  <span>{cTagline}</span>
                </div>
              ) : (
                ""
              )}

              {cCopy ? <p className="c-card__details__desc">{cCopy}</p> : ""}
            </div>
          ) : (
            ""
          )}

          {cCTA ? (
            <div className="c-card__cta">
              <E_ArrowCTA copy={cCTA} disableLink={true} />
            </div>
          ) : (
            ""
          )}
        </a>
      ) : (
        <div
          className={`c-card ${cType ? "c-card--" + cType : ""} ${
            cRadius == "large" ? "c-card--lgRadius" : cRadius == "small" ? "c-card--smRadius" : ""
          } ${cClasses ? cClasses : ""}`}
        >
          {cTag ? <div className={`e-tag c-card__tag ${cTagClass}`}>{cTag}</div> : ""}
          {cImg ? (
            <div className="c-card__img">
              <DropZone setImage={setFeatureImage}>
                <img
                  src={featureImage.preview ? featureImage.preview : cImg}
                  alt="Alternate Text"
                  data-dropdown="true"
                />
              </DropZone>
            </div>
          ) : (
            ""
          )}

          {cTitle || cTagline || cTaglineIcon || cCopy ? (
            <div className="c-card__details">
              {cTitle ? <h3>{cTitle}</h3> : ""}

              {cTagline ? (
                <div className="c-card__details__tagline">
                  {cTaglineIcon ? (
                    <svg className="c-card__details__tagline__icon">
                      <use href={"/images/icons-map.svg" + cTaglineIcon} />
                    </svg>
                  ) : (
                    ""
                  )}
                  <span>{cTagline}</span>
                </div>
              ) : (
                ""
              )}

              {cCopy ? <p className="c-card__details__desc">{cCopy}</p> : ""}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default C_Card;
