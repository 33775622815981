import { useContext, useEffect, useState } from "react";
import DimensionsContext from "../../../DimensionsContext";
import OptionsContext from "../../../OptionsContext";

//this is not finished!
const L_GridFlex = ({ children, ...props }) => {
  const [isCentered, setIsCentered] = useState(false);
  const [tileStyle, setTileStyle] = useState({});
  const [gridStyles, setGridStyles] = useState({});
  const { dimensions } = useContext(DimensionsContext);
  const { contentOptions } = useContext(OptionsContext);

  let num = props.numberOfCards;
  let winWidth = dimensions.width - 24;
  let min = props.minWidth || 300;
  let max = props.maxWidth || 576;
  let colGap = props.gap ? props.gap : "16"; //in px

  useEffect(() => {
    //set when to add center class
    let numberOfCols = Math.floor((winWidth + colGap) / (min + colGap));

    if (numberOfCols > num) numberOfCols = num;
    num <= numberOfCols ? setIsCentered(true) : setIsCentered(false);

    let ulStyle = {
      gridTemplateColumns: `repeat(auto-fill, minmax(${min}px, 1fr))`,
    };
    setGridStyles(ulStyle);

    let liStyle = { minWidth: `${min}px`, maxWidth: `${max}px` };
    setTileStyle(liStyle);
  }, [dimensions.width, contentOptions.itemCount, contentOptions.numCards]);

  // if (children === undefined) return "...";
  return (
    <ul
      className={`l-gridFlex${isCentered ? " l-gridFlex--center" : ""} ${
        props.ulClass ? props.ulClass : ""
      }`}
      data-min={min}
      data-max={max}
      style={gridStyles}
    >
      {children.map((child, index) => (
        <li
          className={`${props.itemClass ? props.itemClass : ""}`}
          key={index}
          style={isCentered ? tileStyle : {}}
        >
          {child}
        </li>
      ))}
    </ul>
  );
};

export default L_GridFlex;
