import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
// import vals from "./svpPlayerVars";

const S_HeroSvp = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const contRef = useRef();

  const playerVals = {
    clip_id: "eika2gd1ars4",
    transparent: "true",
    pause: "0",
    repeat: "1",
    bg_color: "#ffffff",
    fs_mode: "2",
    no_controls: "1",
    start_img: "0",
    start_volume: "0",
    close_button: "",
    brand_new_window: "1",
    auto_hide: "1",
    stretch_video: "true",
    player_align: "NONE",
    offset_x: "0",
    offset_y: "0",
    player_color_ratio: 0.6,
    skinAlpha: "0",
    colorBase: "#c8102e",
    colorIcon: "#ffffff",
    colorHighlight: "#A60B16",
    direct: "false",
    is_responsive: "true",
    viewers_limit: 0,
    cc_position: "bottom",
    cc_positionOffset: 70,
    cc_multiplier: 0.03,
    cc_textColor: "#ffffff",
    cc_textOutlineColor: "#ffffff",
    cc_bkgColor: "#000000",
    cc_bkgAlpha: 0.1,
    aspect_ratio: "16:9",
    play_button: "0",
    play_button_style: "pulsing",
    sleek_player: "1",
    auto_play: "1",
    auto_play_type: "mute",
    floating_player: "none",
  };

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero-svp",
      outputName: "HeroSvp",
      headerName: "Hero Section w/Streaming video provider for China.",
      tags: null,
      description:
        "This hero section is a demo for a streaming provider altnernative to Vimeo that can be used in China. There are several versions; please check related sections for possible options.*Editable version doesn't have options",
      related: [
        ["Hero", "hero"],
        ["Hero: Homepage", "hero-homepage"],
        ["Hero: Local Video", "hero-local-video"],
        ["Hero: Vimeo", "hero-vimeo"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/svp.element.js",
      ],
      specs: [
        // [
        //   "Background Image or Video",
        //   ["Small: 512 x 512", "Medium: 1024 x 475+", "Large: 2048 x 768"],
        // ],
        ["Fallback Image", ["*1 image required", "Small, Medium, and Large: 2048 x 768"]],
        ["Image Type", ["JPG"]],
        ["Background Video", ["Small: 512 x 512", "Large: 1280 x 720"]],
        ["Headline", ["Character Limit: ~35"]],
        ["Link", ["Character Limit: 85"]],
        ["Description", ["Paragraph with character Limit: ~100"]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/14nXiQ1CaXE4YyFrdKm-lSJwHnJB-NowopTR8ZgLtdaE/edit?usp=sharing",
        comments: [
          ["Hero SVP Fallback Image", ["2048px x 768px"]],
          ["Hero Background Video", ["Small: 512 x 512", "Large: 1280 x 720"]],
        ],
      },
    };
    setAppState(heroVars);
    setContentOptions({
      Headline: {
        label: "Headline",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Titular",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Gros titre",
          It: "Titolo",
          Pl: "Nagłówek",
          Port: "Título",
          Viet: "Tiêu đề",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "พาดหัว",
          Kor: "표제",
          ChT: "標題",
          Jp: "見出し",
        },
      },
      HeadlineMode: {
        label: "Headline Mode",
        checkbox: null,
        field: ["DIV", "H1"],
        selected: "DIV",
      },
      CTA: {
        label: "CTA",
        checkbox: true,
        field: {
          Eng: "Sed ut perspiciatis",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      SVG: { label: "CTA Icon SVG", checkbox: true, field: "#play-basic" },
      Aligned: {
        label: "Clip Alignment",
        checkbox: null,
        field: ["Left (default)", "Lower Left", "Right", "Lower Right"],
        selected: "Left (default)",
      },
      Alternate: { label: "Enable Alternate (Light Mode)", checkbox: false, field: null },
      currentLang: "Eng",
    });

    //hacky way to add videoplayer script
    const script = document.createElement("script");

    script.src = "https://play.webvideocore.net/js/dplayer.js";
    script.async = true;
    let comp = document.getElementsByClassName("e-svp");
    // let comp = contRef.current;

    setTimeout(function () {
      // console.log(vals);
      // console.log("comp: ", comp);
      document.body.appendChild(script);
      // eslint-disable-next-line no-undef
      const svpPlayer = new SVPDynamicPlayer(
        comp,
        "",
        "100%",
        "100%",
        {
          use_div: comp,
          skin: "3",
        },
        playerVals,
      );
      svpPlayer.execute();
    }, 4000);

    //end hacky video player stuff

    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Hero";
  return (
    <section
      className={`s-hero${
        contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
          ? " s-hero--clipRight"
          : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
            ? " s-hero--clipLowerLeft"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
              ? " s-hero--clipLowerRight"
              : ""
      }${contentOptions.DarkLight && contentOptions.DarkLight.checkbox ? " s-hero--light" : ""}`}
    >
      <div
        className="s-hero__background"
        style={{
          backgroundImage: `url("https://media.kingston.com/kingston/hero/ktc-hero-homepage-china-lg.jpg")`,
        }}
      >
        <div
          className="e-svp"
          data-small-video="euis6sdzh008"
          data-large-video="dk6w3nipntsg"
          // data-initialized="true"
          // style={{
          //   width: "100%",
          //   height: "auto",
          //   paddingTop: "56.25%",
          //   boxShadow: "rgba(48, 48, 48, 0.4) 0px 20px 22px -8px",
          //   borderRadius: "4px",
          //   overflow: "hidden",
          // }}
          ref={contRef}
        ></div>
      </div>
      <div className="s-hero__content">
        <div className="c-headerClip">
          {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "DIV" ? (
            <div className="u-h1 c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </div>
          ) : (
            <h1 className="c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </h1>
          )}
          <div className="c-headerClip__cta">
            {contentOptions.CTA && contentOptions.CTA.checkbox && (
              <a className="c-headerClip__cta__link" href="#heroLink">
                {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                  <svg>
                    <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                  </svg>
                ) : (
                  <svg>
                    <use xlinkHref="/images/icons-map.svg#play-basic" />
                  </svg>
                )}
                {contentOptions.CTA.field[contentOptions.currentLang] || `Hero Link`}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "hero-svp",
  component: S_HeroSvp,
  navtxt: "Hero China SVP",
  htmlName: "HeroSvp",
  categoryType: ["web"],
};
