/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Ecommerce = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [count, setCount] = useState("1");
  const [selected, setSelected] = useState("small");
  const [colorSelected, setColorSelected] = useState("black");
  const [disabled, setDisabled] = useState("true");
  const [disabledColor, setDisabledColor] = useState("false");
  const [linkCopy, setLinkCopy] = useState(false);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    let ecomVars = {
      ...appState,
      currentPath: "/ui/ecommerce",
      outputName: "Ecommerce",
      headerName: "Ecommerce",
      tags: null,
      description:
        "The Ecommerce Section provides product information, features, product images, selections, and social share. Ecommerce has 5 different scenarios: Default, Buy Now, Partners, Where to Buy, and String. *Images aren't editable in this demo, pleasue use 'Default' View Mode to see full functionality.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/formInput.component.js",
        "../../js/ecommerce.section.js",
        "../../js/quantityCounter.component.js",
        "../../js/socials.component.js",
      ],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: 1:1 ratio (minimum 800px)",
          ],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Legend", ["Describes the selectable options with .u-h4"]],
        ["Features", ["Standard Text, List of features the product can provide. Max 6 lines"]],
        ["Socials", ["Link SVGs"]],
        ["Image Formats", ["MP4s, jpg, png formats. Png is used for photo icons."]],
        [
          "Image Sizes",
          [
            "*All 4 sizes required",
            "Thumbnail: 56x56",
            "Small: 512x408",
            "Full/Medium: 416x416",
            "Zoom Large: 1304x1304",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EaqVxf_cnilIriRhcoaQtYcB5dvDwMtKqIox1CxBK2eV5Q?e=iFHTmV",
        comments: [
          [
            "Ecommerce Image Size",
            [
              "Thumbnail: 56px x 56px",
              "Small: 512px 408px",
              "Large: 416px x 416px",
              "Zoom Large: 1304px x 1304px",
            ],
          ],
        ],
      },
    };
    setAppState(ecomVars);
    setContentOptions({
      scenarios: {
        label: "CTA Scenarios",
        checkbox: null,
        field: ["Default", "Buy Now", "Partners", "Where to Buy", "String"],
        selected: "Default",
      },
      legend: {
        label: "Legend",
        checkbox: true,
        field: {
          Eng: "Lorem",
          Span: "Tamaño",
          ChS: "尺寸",
          Gr: "Größe",
          Ru: "Размер",
          Fr: "Taille",
          It: "Misurare",
          Pl: "Rozmiar",
          Port: "Tamanho",
          Viet: "Kích cỡ",
          Turk: "Boyut",
          Ukrn: "Розмір",
          Thai: "ขนาด",
          Kor: "크기",
          ChT: "尺寸",
          Jp: "サイズ",
        },
      },
      selection: {
        label: "Additional Selection",
        checkbox: false,
        field: {
          Eng: "Ipsum",
          Span: "Color",
          ChS: "颜色",
          Gr: "Farbe",
          Ru: "Цвет",
          Fr: "Couleur",
          It: "Colore",
          Pl: "Kolor",
          Port: "Cor",
          Viet: "Màu sắc",
          Turk: "Renk",
          Ukrn: "Колір",
          Thai: "สี",
          Kor: "색상",
          ChT: "顏色",
          Jp: "色",
        },
      },
      bullet1: {
        label: "Feature 1",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet2: {
        label: "Feature 2",
        checkbox: true,
        field: {
          Eng: "Consectetur adipiscing elit, sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet3: {
        label: "Feature 3",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet4: {
        label: "Feature 4",
        checkbox: true,
        field: {
          Eng: "Consectetur adipiscing elit, sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet5: {
        label: "Feature 5",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      bullet6: {
        label: "Feature 6",
        checkbox: true,
        field: {
          Eng: "Consectetur: sed do eiusmod.",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      discountoption: {
        label: "Discount",
        checkbox: true,
        field: {
          Eng: "$1.00",
          Span: "$1.00",
          ChS: "$1.00",
          Gr: "$1.00",
          Ru: "$1.00",
          Fr: "$1.00",
          It: "$1.00",
          Pl: "$1.00",
          Port: "$1.00",
          Viet: "$1.00",
          Turk: "$1.00",
          Ukrn: "$1.00",
          Thai: "$1.00",
          Kor: "$1.00",
          ChT: "$1.00",
          Jp: "$1.00",
        },
      },
      saveoption: {
        label: "Save",
        checkbox: true,
        field: {
          Eng: "lorem 10%",
          Span: "lorem 10%",
          ChS: "lorem 10%",
          Gr: "lorem 10%",
          Ru: "lorem 10%",
          Fr: "lorem 10%",
          It: "lorem 10%",
          Pl: "lorem 10%",
          Port: "lorem 10%",
          Viet: "lorem 10%",
          Turk: "lorem 10%",
          Ukrn: "lorem 10%",
          Thai: "lorem 10%",
          Kor: "lorem 10%",
          ChT: "lorem 10%",
          Jp: "lorem 10%",
        },
      },
      vatoption: {
        label: "VAT",
        checkbox: false,
        field: {
          Eng: "VAT included",
          Span: "VAT incluido",
          ChS: "VAT 包括",
          Gr: "VAT inbegriffen",
          Ru: "VAT включено",
          Fr: "VAT inclus",
          It: "VAT incluso",
          Pl: "VAT dołączony",
          Port: "VAT incluído",
          Viet: "VAT bao gồm",
          Turk: "VAT dahil",
          Ukrn: "VAT включені",
          Thai: "VAT รวมอยู่ด้วย",
          Kor: "VAT 포함됨",
          ChT: "VAT 包括",
          Jp: "VAT 含まれています",
        },
      },
      copy: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
        },
      },
      imagedisclaimer: {
        label: "Image Disclaimer",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum dolor sit amet",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "594" });
  }, []);

  useEffect(() => {
    if (linkCopy === true) {
      setTimeout(function () {
        setLinkCopy(false);
      }, 3000);
    }
  }, [linkCopy]);

  const handleChange = (event) => {
    setSelected(event.target.value);

    if (event.target.value == "large") {
      setColorSelected("red");
      setDisabled("false");
      setDisabledColor("true");
    } else {
      setDisabled("true");
      setDisabledColor("false");
    }
  };

  const handleColorChange = (event) => {
    setColorSelected(event.target.value);

    if (event.target.value == "black" && selected == "large") {
      setSelected("small");
      setDisabled("true");
      setDisabledColor("false");
    }
  };

  function changeQuantity(event, btn) {
    event.preventDefault();
    let num = parseInt(count);
    let plus = num + 1;
    let minus = num - 1;

    if (btn == "minus") {
      if (num >= 1) {
        setCount(minus.toString());
      } else {
        setCount("0");
      }
    } else {
      setCount(plus.toString());
    }
  }

  function handleInputChange(e) {
    if (e.target.value && !isNaN(Number(e.target.value))) {
      setCount(e.target.value);
    }
  }

  if (!contentOptions) return "...Loading Ecommerce";
  return (
    <section className={"s-ecommerce " + appState.currentTheme}>
      <div className="l-inner">
        <div className="l-row">
          <div className="l-row__col l-1/2@md">
            <div className="s-ecommerce__image">
              <video
                id="productView-cuke6m"
                className="c-viewItem"
                src="/images/fpo/section-ecommerce/alpha-rotate.mp4"
                muted="1"
                playsInline="1"
                autoPlay="1"
              ></video>
              <div className="s-ecommerce__image__loader s-ecommerce__image__loader--dark"></div>
              <span className="s-ecommerce__image__zoom">
                <svg viewBox="0 0 24.01 23.49">
                  <path
                    fill="#fff"
                    d="M13.45 0A10.59 10.59 0 0 0 4 15.27L.87 18.39a3 3 0 0 0 0 4.22 3 3 0 0 0 4.23 0L8 19.67a10.24 10.24 0 0 0 6 1.48h.11A10.59 10.59 0 0 0 13.45 0zm.62 19.65h-.62a9 9 0 0 1-5.61-2L4 21.55a1.49 1.49 0 1 1-2.1-2.11l3.9-3.92a9.06 9.06 0 1 1 8.22 4.13z"
                  ></path>
                  <path
                    fill="#221f1f"
                    d="M13.45 1.5a9.09 9.09 0 0 0-7.61 14l-3.9 3.92A1.49 1.49 0 1 0 4 21.55l3.81-3.83a9 9 0 0 0 5.61 2h.62a9.09 9.09 0 0 0-.59-18.22z"
                  ></path>
                  <circle fill="#fff" cx="13.53" cy="10.5" r="6.56"></circle>
                  <path
                    fill="#221f1f"
                    d="M14.45 9.6V6.2a.54.54 0 0 0-.54-.54h-.83a.54.54 0 0 0-.54.54v3.4H9.07a.54.54 0 0 0-.54.54V11a.54.54 0 0 0 .54.54h3.47V15a.54.54 0 0 0 .54.54h.83a.54.54 0 0 0 .54-.54v-3.49h3.47a.54.54 0 0 0 .54-.54v-.83a.54.54 0 0 0-.54-.54z"
                  ></path>
                </svg>
              </span>
              <span className="s-ecommerce__image__replay">
                <svg viewBox="0 0 12.25 13.73">
                  <path d="M11.57 4.81L9.21 6.6a3.34 3.34 0 0 1 .17 1A3.38 3.38 0 1 1 6 4.23a3.34 3.34 0 0 1 .91.14V6a.09.09 0 0 0 .14.08l1.25-1 2.65-2h.06a.1.1 0 0 0 0-.16L7.05 0a.09.09 0 0 0-.14.1v1.43a6.12 6.12 0 1 0 5.34 6.07 6.09 6.09 0 0 0-.68-2.79z"></path>
                </svg>
              </span>
              <span className="s-ecommerce__image__disclaimer u-txt-small">
                {contentOptions.imagedisclaimer &&
                  contentOptions.imagedisclaimer.field[contentOptions.currentLang]}
              </span>
            </div>
            <ul className="s-ecommerce__thumbnails" style={{ justifyContent: "center" }}>
              <li data-index="0" tabIndex="0" className="s-eccomerce__thumbnails--active">
                <svg viewBox="0 0 73 73">
                  <path
                    d="M0 36.5a36.5 36.5 0 1 0 73 0 36.5 36.5 0 1 0-73 0"
                    fill="rgba(0,0,0,0.5)"
                  ></path>
                  <path d="M36.5 9A27.5 27.5 0 1 1 9 36.5 27.5 27.5 0 0 1 36.5 9m0-5.5a33 33 0 1 0 33 33 33 33 0 0 0-33-33z"></path>
                  <path d="M52.56 37.36l-23 13.74a1 1 0 0 1-1.56-.86V22.76a1 1 0 0 1 1.51-.86l23 13.74a1 1 0 0 1 0 1.72z"></path>
                </svg>
                <img
                  src="/images/fpo/general/56x56.png"
                  title="HyperX Cloud Alpha Headset 1"
                  data-video="/images/fpo/section-ecommerce/alpha-rotate.mp4"
                  data-mobileurl="/images/fpo/general/512x408.png"
                  data-disclaimer="Disclaimer for product image goes here."
                  alt="rotating view of HyperX Cloud Alpha Headset 1"
                />
              </li>
              <li data-index="1" tabIndex="0">
                <img
                  src="/images/fpo/general/56x56.png"
                  title="HyperX Cloud Alpha Headset 2"
                  data-mobileurl="/images/fpo/general/512x408.png"
                  data-mediumurl="/images/fpo/general/416x416.png"
                  data-zoom="/images/images/fpo/general/section-ecommerce/image4-zm.jpg"
                  alt="HyperX Cloud Alpha Headset angle 2 zoomed"
                />
              </li>
              <li data-index="2" tabIndex="0">
                <img
                  src="/images/fpo/general/56x56.png"
                  title="HyperX Cloud Alpha Headset 3"
                  data-mobileurl="/images/fpo/general/512x408.png"
                  data-mediumurl="/images/fpo/general/416x416.png"
                  data-zoom="/images/images/fpo/general/section-ecommerce/image2-zm.jpg"
                  data-disclaimer="Another, longer disclaimer here that is taking two lines of text and changing its color to white."
                  data-disclaimer-color="white"
                  alt="zoomed Headset angle 3"
                />
              </li>
              <li data-index="3" tabIndex="0">
                <img
                  src="/images/fpo/general/56x56.png"
                  title="HyperX Cloud Alpha Headset 4"
                  data-mobileurl="/images/fpo/general/512x408.png"
                  data-mediumurl="/images/fpo/general/416x416.png"
                  data-zoom="/images/images/fpo/general/section-ecommerce/image3-zm.jpg"
                  alt="zoomed headset angle 4"
                />
              </li>
            </ul>
          </div>
          <div className="l-row__col l-1/2@md">
            <form className="s-ecommerce__details">
              {contentOptions.legend && contentOptions.legend.checkbox == true ? (
                <fieldset>
                  <legend className="u-h4">
                    {contentOptions.legend &&
                      contentOptions.legend.field[contentOptions.currentLang]}
                  </legend>
                  <div className="s-ecommerce__details__selections">
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id0"
                        name="ecommOption"
                        value="small"
                        mark-disabled="false"
                        checked={selected === "small"}
                        onChange={handleChange}
                      />
                      <label htmlFor="id0">Small</label>
                    </div>
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id1"
                        name="ecommOption"
                        value="medium"
                        mark-disabled="false"
                        checked={selected === "medium"}
                        onChange={handleChange}
                      />
                      <label htmlFor="id1">Medium</label>
                    </div>
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id2"
                        name="ecommOption"
                        value="large"
                        checked={selected === "large"}
                        mark-disabled={disabled}
                        onChange={handleChange}
                      />
                      <label htmlFor="id2">Large</label>
                    </div>
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id3"
                        name="ecommOption"
                        value="xtralarge"
                        mark-disabled="false"
                        checked={selected === "xtralarge"}
                        onChange={handleChange}
                      />
                      <label htmlFor="id3">Extra Large</label>
                    </div>
                  </div>
                </fieldset>
              ) : (
                ""
              )}
              {contentOptions.selection && contentOptions.selection.checkbox === true ? (
                <fieldset>
                  <legend className="u-h4">
                    {contentOptions.selection &&
                      contentOptions.selection.field[contentOptions.currentLang]}
                  </legend>
                  <div className="s-ecommerce__details__selections">
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id10"
                        name="color"
                        value="black"
                        checked={colorSelected === "black"}
                        mark-disabled={disabledColor}
                        onChange={handleColorChange}
                      />
                      <label htmlFor="id10">Black</label>
                    </div>
                    <div className="s-ecommerce__details__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                        id="id11"
                        name="color"
                        value="red"
                        checked={colorSelected === "red"}
                        mark-disabled="false"
                        onChange={handleColorChange}
                      />
                      <label htmlFor="id11">Red</label>
                    </div>
                  </div>
                </fieldset>
              ) : (
                " "
              )}
              <ul className="s-ecommerce__details__features">
                {contentOptions.bullet1 && contentOptions.bullet1.checkbox ? (
                  <li>{contentOptions.bullet1.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
                {contentOptions.bullet2 && contentOptions.bullet2.checkbox ? (
                  <li>{contentOptions.bullet2.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
                {contentOptions.bullet3 && contentOptions.bullet3.checkbox ? (
                  <li>{contentOptions.bullet3.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
                {contentOptions.bullet4 && contentOptions.bullet4.checkbox ? (
                  <li>{contentOptions.bullet4.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
                {contentOptions.bullet5 && contentOptions.bullet5.checkbox ? (
                  <li>{contentOptions.bullet5.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
                {contentOptions.bullet6 && contentOptions.bullet6.checkbox ? (
                  <li>{contentOptions.bullet6.field[contentOptions.currentLang]}</li>
                ) : (
                  ""
                )}
              </ul>
              <div className="c-socials">
                <ul
                  className="u-list-unstyled u-list-horizontal c-socials__list"
                  aria-label="List of socials to share on"
                >
                  <li className="c-socials__list__item">
                    <a href="#facebook" target="_blank" aria-label="Share with Facebook">
                      <svg aria-hidden="true">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="/images/icons-map.svg#share-facebook"
                        ></use>
                      </svg>
                    </a>
                  </li>
                  <li className="c-socials__list__item">
                    <a href="#x" target="_blank" aria-label="Share with X">
                      <svg aria-hidden="true">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="/images/icons-map.svg#share-twitter"
                        ></use>
                      </svg>
                    </a>
                  </li>
                  <li className="c-socials__list__item">
                    <a href="#linkedin" target="_blank" aria-label="Share with LinkedIn">
                      <svg aria-hidden="true">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="/images/icons-map.svg#share-linkedin"
                        ></use>
                      </svg>
                    </a>
                  </li>
                  <li className="c-socials__list__item">
                    <a href="#reddit" target="_blank" aria-label="Share with Reddit">
                      <svg aria-hidden="true">
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#share-reddit"
                        ></use>
                      </svg>
                    </a>
                  </li>
                  <li className="c-socials__list__item">
                    <a
                      className={`c-socials__list__item__shareLink ${
                        linkCopy ? "c-socials__list__item__shareLink--copied" : ""
                      }`}
                      href="#link"
                      target=""
                      aria-label="Share with Link"
                      onClick={() => setLinkCopy(true)}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M9.369 8.711a4.552 4.552 0 0 1 4.022.263c.33.192.641.429.924.711A1.142 1.142 0 0 1 12.7 11.3a2.25 2.25 0 0 0-3.168 0l-4.594 4.594a2.243 2.243 0 0 0 0 3.168 2.243 2.243 0 0 0 3.168 0l2.74-2.74a5.58 5.58 0 0 0 2.906.325l-4.03 4.03A4.51 4.51 0 0 1 6.521 22a4.51 4.51 0 0 1-3.199-1.323 4.53 4.53 0 0 1 0-6.398l4.594-4.594a4.544 4.544 0 0 1 1.452-.974ZM17.478 2a4.51 4.51 0 0 1 3.199 1.323 4.53 4.53 0 0 1 0 6.398l-4.594 4.594a4.544 4.544 0 0 1-1.452.974 4.552 4.552 0 0 1-4.022-.263 4.516 4.516 0 0 1-.924-.711A1.142 1.142 0 0 1 11.3 12.7a2.25 2.25 0 0 0 3.168 0l4.594-4.594a2.243 2.243 0 0 0 0-3.168 2.243 2.243 0 0 0-3.168 0l-2.74 2.74a5.58 5.58 0 0 0-2.906-.325l4.03-4.03A4.51 4.51 0 0 1 17.479 2Z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                      <span className="c-socials__list__item__shareLink__copy">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            d="m10.94 16.243-1.415 1.414-4.95-4.95 1.415-1.414 4.95 4.95Zm7.07-9.9 1.415 1.414-8.486 8.486-1.414-1.415 8.485-8.485Z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                        Copied
                      </span>
                    </a>
                  </li>
                </ul>
              </div>

              {contentOptions.scenarios && contentOptions.scenarios.selected == "Default" ? (
                <>
                  <div className="s-ecommerce__details__price">
                    <span className="s-ecommerce__details__price__cost">
                      <span className="s-ecommerce__details__price__cost__dollar">$10.00</span>
                    </span>
                    {contentOptions.discountoption &&
                    contentOptions.discountoption.checkbox == true ? (
                      <span className="s-ecommerce__details__price__discount">
                        {contentOptions.discountoption.field[contentOptions.currentLang]}
                      </span>
                    ) : (
                      ""
                    )}
                    {contentOptions.saveoption && contentOptions.saveoption.checkbox == true ? (
                      <span className="s-ecommerce__details__price__save">
                        {contentOptions.saveoption.field[contentOptions.currentLang]}
                      </span>
                    ) : (
                      ""
                    )}
                    {contentOptions.vatoption && contentOptions.vatoption.checkbox == true ? (
                      <span className="s-ecommerce__details__price__vat">
                        {contentOptions.vatoption.field[contentOptions.currentLang]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="s-ecommerce__details__default">
                    <label className="f-quantity__label" htmlFor="quant2">
                      Quantity
                    </label>
                    <div className="f-quantity js-quantity" id="jsQuantity0">
                      <div className="f-quantity__counter">
                        <button
                          className={`f-quantity__counter__btn js-quantity__btn ${
                            count === 0 && "f-quantity__counter__btn--disable"
                          }`}
                          aria-label="Decrease Quantity"
                          tabIndex="-1"
                          onClick={(e) => changeQuantity(e, "minus")}
                        >
                          <span className="f-quantity__counter__btn__inner">
                            <svg viewBox="0 0 16 2">
                              <title>Minus Icon</title>
                              <path d="M0 0h16v2H0z"></path>
                            </svg>
                          </span>
                        </button>
                        <input
                          className="f-quantity__counter__input"
                          type="text"
                          maxLength="4"
                          value={count}
                          id="quant2"
                          name="fQuantity0"
                          onChange={(e) => handleInputChange(e)}
                        />
                        <button
                          className="f-quantity__counter__btn js-quantity__btn"
                          aria-label="Increase Quantity"
                          data-add="true"
                          onClick={(e) => changeQuantity(e, "plus")}
                        >
                          <span className="f-quantity__counter__btn__inner">
                            <svg viewBox="0 0 16 16">
                              <title>Plus Icon</title>
                              <path d="M0 7h16v2H0z"></path>
                              <path d="M7 0h2v16H7z"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <a className="e-btn" href="#cart" title="Add Item to Cart" role="button">
                      <svg viewBox="0 0 25 25">
                        <path d="M-20.8 9.3c-2.7 0-5 2.3-5 5s2.2 5 5 5 5-2.3 5-5-2.2-5-5-5z"></path>
                        <path d="M-20.8 7.3c-3.9 0-7 3.1-7 7 0 1.4.4 2.7 1.1 3.8l-2.8 2.8c-.4.4-.4 1.2 0 1.6.4.4 1.2.4 1.6 0l2.7-2.7c1.2 1 2.7 1.5 4.4 1.5 3.9 0 7-3.1 7-7s-3.1-7-7-7zm0 12c-2.8 0-5-2.3-5-5s2.3-5 5-5 5 2.3 5 5-2.2 5-5 5z"></path>
                        <path d="M18.3 15.1c-.1.4-.5.7-.9.7H9.6c-.4 0-.8-.3-.9-.7l-2-8h-1c-.5 0-1-.4-1-1s.4-1 1-1h1.8c.4 0 .8.3.9.7L8.9 8h11c.1 0 .2.1.3.1.1.1.1.2.1.3l-2 6.7zm-7.4 2.4c.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6zm5.1 0c.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6z"></path>
                        <path d="M-13.9 25.7c-.1.5-.5.8-1 .8h-8.5c-.5 0-.9-.3-1-.8l-2.2-8.8h-1.1c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1h1.9c.5 0 .9.3 1 .8l.6 2.4h12c.1 0 .2.1.3.1.1.1.1.2.1.3l-2.1 7.4zm-8.1 2.6c1 0 1.8.8 1.8 1.7 0 1-.8 1.8-1.7 1.8-1 0-1.8-.8-1.8-1.7-.1-1 .7-1.8 1.7-1.8zm5.6 0c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8c.1-1 .9-1.8 1.8-1.8z"></path>
                      </svg>
                      <span>Add To Cart</span>
                    </a>
                  </div>
                </>
              ) : contentOptions.scenarios && contentOptions.scenarios.selected == "Buy Now" ? (
                <div className="s-ecommerce__details__buy">
                  <div className="f-input f-input--reactive">
                    <div className="f-input__string">
                      <input type="text" name="acode" id="acode" />
                      <label htmlFor="acode">Discount Code</label>
                    </div>
                    <a className="f-input__icon" href="#link">
                      Apply
                    </a>
                  </div>
                  <div className="s-ecommerce__details__buy__qty">
                    <label className="f-quantity__label" htmlFor="quant2">
                      Quantity
                    </label>
                    <div className="f-quantity js-quantity" id="jsQuantity0">
                      <div className="f-quantity__counter">
                        <button
                          className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                          aria-label="Decrease Quantity"
                          tabIndex="-1"
                        >
                          <span className="f-quantity__counter__btn__inner">
                            <svg viewBox="0 0 16 2">
                              <title>Minus Icon</title>
                              <path d="M0 0h16v2H0z"></path>
                            </svg>
                          </span>
                        </button>
                        <input
                          className="f-quantity__counter__input"
                          type="text"
                          maxLength="4"
                          value="1"
                          id="quant2"
                          name="fQuantity0"
                        />
                        <button
                          className="f-quantity__counter__btn js-quantity__btn"
                          aria-label="Increase Quantity"
                          data-add="true"
                        >
                          <span className="f-quantity__counter__btn__inner">
                            <svg viewBox="0 0 16 16">
                              <title>Plus Icon</title>
                              <path d="M0 7h16v2H0z"></path>
                              <path d="M7 0h2v16H7z"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <a className="e-btn" target="_self" title="Button Title" href="#link">
                      <svg>
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#credit_card"
                        ></use>
                      </svg>
                      <span>Buy Now</span>
                    </a>
                    <a
                      className="e-btn s-ecommerce__details__buy__buyNow"
                      href="#link"
                      target="_self"
                      title="Buy Now"
                      id="add-to-cart"
                    >
                      <svg viewBox="0 0 22 15">
                        <path d="M0 1.992C0 .892.89 0 1.991 0H20.01C21.109 0 22 .893 22 1.992v11.016c0 1.1-.89 1.992-1.991 1.992H1.99C.891 15 0 14.107 0 13.008V1.992zM0 3v3h22V3H0zm3 6v3h5V9H3z"></path>
                      </svg>
                      <span>Buy Now</span>
                    </a>
                  </div>
                  <a className="e-btn u-btn-apple e-btn--alt1" href="#link">
                    Buy with{" "}
                    <img src="/images/fpo/section-navigation/logo-applePay_w.svg" alt="apple" />
                  </a>
                </div>
              ) : contentOptions.scenarios && contentOptions.scenarios.selected == "Partners" ? (
                <>
                  {/* <a
                    className="s-ecommerce__details__aee c-tooltip c-tooltip--top"
                    href="#link"
                    data-toolid="tellus"
                    role="tooltip"
                    aria-describedby="tellus"
                  > */}
                  <a className="s-ecommerce__details__aee" href="#link">
                    <svg viewBox="0 0 26 24">
                      <path d="M20.859 3.573h-4.346s-2.242 2.634-3.409 6.184c.488.017 3.169.036 5.36-1.535 0 0-1.166 3.318-5.81 3.24-.642 3.225-.135 6.921 3.568 10.026 0 0-2.204-4 .233-7.1h4.404c1.183 0 2.141-.857 2.141-1.915V5.487c0-1.058-.958-1.914-2.141-1.914"></path>
                      <path d="M10.96 11.352c-3.708-.535-4.535-3.207-4.535-3.207 1.833 1.313 4.01 1.515 4.96 1.535.694-2.197 2.014-4.753 4.29-7.68H5.14C3.957 2 3 2.856 3 3.914V10.9c0 1.057.957 1.913 2.14 1.913h1.704s1.306 5.738 6.546 7.254c0 0-3.556-2.817-2.43-8.716"></path>
                    </svg>
                    Ask an Expert
                  </a>
                  {/* <div className="c-tooltip__message" id="tellus" aria-describedby="tellus">
                    <h4>Tellus</h4>
                    <p>
                      Orci porta non pulvinar neque laoreet. Orci ac auctor augue mauris augue neq
                    </p>
                  </div> */}
                  <div className="s-eccommerce__details__partners">
                    <span className="u-h4">Buy From </span>
                  </div>
                  <ul className="s-ecommerce__details__partners__group l-grid l-grid--2col l-grid--3col@lg">
                    <li className="s-ecommerce__details__partners__group__item">
                      <a className="tracked productBuyBoxBuy" href="#company1Link" target="_blank">
                        <img src="/fpo/112/30/Buy Box" alt="Company 1" />
                      </a>
                    </li>
                    <li className="s-ecommerce__details__partners__group__item">
                      <a className="tracked productBuyBoxBuy" href="#company2Link" target="_blank">
                        <img src="/fpo/112/30/Buy Box" alt="Company 2" />
                      </a>
                    </li>
                    <li className="s-ecommerce__details__partners__group__item">
                      <a className="tracked productBuyBoxBuy" href="#company3Link" target="_blank">
                        <img src="/fpo/112/30/Buy Box" alt="Company 3" />
                      </a>
                    </li>
                    <li className="s-ecommerce__details__partners__group__item s-ecommerce__details__partners__group__item--noStock">
                      <a className="tracked productBuyBoxBuy" href="#partnerLink" target="_blank">
                        <img src="/fpo/112/30/Buy Box" alt="Company" />
                      </a>
                    </li>
                  </ul>
                  <a href="#findStore">Find store near you</a>
                </>
              ) : contentOptions.scenarios &&
                contentOptions.scenarios.selected == "Where to Buy" ? (
                <div className="s-ecommerce__details__location">
                  <div className="s-ecommerce__details__location__btns">
                    <a
                      className="e-btn"
                      target="_self"
                      title="Button Title"
                      role="button"
                      href="#link"
                    >
                      <svg>
                        <use
                          xlink="http://www.w3.org/1999/xlink"
                          xlinkHref="../images/icons-map.svg#location"
                        ></use>
                      </svg>
                      <span>Where to Buy</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="s-ecommerce__details__string">
                  <p>
                    {contentOptions.copy && contentOptions.copy.field[contentOptions.currentLang]}
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "ecommerce",
  component: S_Ecommerce,
  navtxt: "Ecommerce",
  htmlName: "Ecommerce",
  categoryType: ["web"],
};
