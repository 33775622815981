export default {
  numCards: {
    label: "Number of Cards",
    checkbox: null,
    field: ["2", "3", "4", "5", "6"],
    selected: "6",
  },
  titlesoptions: {
    label: "Title [Optional]",
    checkbox: true,
    field: null,
  },
  ctaoptions: {
    label: "CTA [Optional]",
    checkbox: true,
    field: null,
  },
  card1: [
    {
      name: "title1",
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 1,
    },
    {
      name: "copy1",
      label: "Copy 1",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 1,
    },
    {
      name: "cta1",
      label: "CTA 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 1,
    },
  ],
  card2: [
    {
      name: "title2",
      label: "Title 2",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 2,
    },
    {
      name: "copy2",
      label: "Copy 2",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 2,
    },
    {
      name: "cta2",
      label: "CTA 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 2,
    },
  ],
  card3: [
    {
      name: "title3",
      label: "Title 3",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 3,
    },
    {
      name: "copy3",
      label: "Copy 3",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 3,
    },
    {
      name: "cta3",
      label: "CTA 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 3,
    },
  ],
  card4: [
    {
      name: "title4",
      label: "Title 4",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 4,
    },
    {
      name: "copy4",
      label: "Copy 4",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 4,
    },
    {
      name: "cta4",
      label: "CTA 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 4,
    },
  ],

  card5: [
    {
      name: "title5",
      label: "Title 5",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 5,
    },
    {
      name: "copy5",
      label: "Copy 5",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 5,
    },
    {
      name: "cta5",
      label: "CTA 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 5,
    },
  ],

  card6: [
    {
      name: "title6",
      label: "Title 6",
      checkbox: null,
      field: {
        Eng: "Info Card Title",
        Span: "Título",
        ChS: "标题",
        Gr: "Titel",
        Ru: "Заголовок",
        Fr: "Titre",
        It: "Titolo",
        Pl: "Tytuł",
        Port: "Título",
        Viet: "Tiêu đề",
        Turk: "Başlık",
        Ukrn: "Назва",
        Thai: "ชื่อ",
        Kor: "제목",
        ChT: "標題",
        Jp: "タイトル",
      },
      block: 6,
    },
    {
      name: "copy6",
      label: "Copy 6",
      checkbox: null,
      field: {
        Eng: "Nam at lectus urna duis convallis convallis tellus id interdum velit laoreet.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
      },
      block: 6,
    },
    {
      name: "cta6",
      label: "CTA 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum",
        Span: "Ver más",
        ChS: "查看更多",
        Gr: "Mehr sehen",
        Ru: "Узнать больше",
        Fr: "Voir plus",
        It: "Vedi altro",
        Pl: "Zobacz więcej",
        Port: "Ver mais",
        Viet: "Xem thêm",
        Turk: "Daha fazla gör",
        Ukrn: "Побачити більше",
        Thai: "ดูเพิ่มเติม",
        Kor: "더보기",
        ChT: "查看更多",
        Jp: "続きを見る",
      },
      block: 6,
    },
  ],
  currentLang: "Eng",
};
