/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Heading = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let headingVars = {
      ...appState,
      currentPath: "/ui/heading",
      outputName: "Heading",
      headerName: "Heading",
      tags: ["Landing Page Approved"],
      description:
        "The Heading Section displays a heading along with a brief subtitle and/or description.",
      htmlSaved: true,
      related: null,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/headline.component.js",
      ],
      specs: [
        [
          "Heading Title",
          ["The heading defaults to a <h2> tag, Character limit is recommended to be < 50"],
        ],
        ["Subheading", ["Character limit is 120"]],
        [
          "Description",
          [
            "The copy should be strictly text keeping the character count to under 300 for best results.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1CAA1lVE_XhhML_ElOEd0IjcVKkOBu8NJ1FAf9ZB5lMM/edit?usp=sharing",
        comments: null,
      },
    };
    setAppState(headingVars);

    setContentOptions({
      heading1: {
        label: "Heading",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題 ",
          Jp: "見出し",
        },
        charLimit: 50,
      },
      sub: {
        label: "Subheading",
        checkbox: true,
        field: {
          Eng: "Consectetur adipiscing elit",
          Span: "Subtitular",
          ChS: "副标题",
          Gr: "Untertitel",
          Ru: "Подзаголовок",
          Fr: "Sous-titre",
          It: "Sottotitolo",
          Pl: "Podtytuł",
          Port: "Legenda",
          Viet: "Phụ đề",
          Turk: "Alt yazı",
          Ukrn: "Підзаголовок",
          Thai: "คำบรรยาย",
          Kor: "부제",
          ChT: "副標題",
          Jp: "サブタイトル",
        },
        charLimit: 120,
      },
      desc: {
        label: "Description",
        checkbox: true,
        field: {
          Eng: "Sed ut perspiciatis unde omnis iste natus error",
          Span: "Hacía calor en ese tiempo, para mí,",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
          Gr: "Tochter; es hieß, sie sei mit Herrn ",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
          Fr: "Il faisait chaud dans ce temps-là, pour moi",
          It: "Faceva caldo con quel tempo, per me, non lo",
          Pl: "Było gorąco w taką pogodę, jak dla mnie",
          Port: "Fazia calor naquele tempo, pra mim",
          Viet: "Thời tiết đó nóng nực, đối với tôi",
          Turk: "O hava sıcaktı, benim için, pek",
          Ukrn: "У таку погоду було спекотно, для",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
        },
        charLimit: 300,
      },
      currentLang: "Eng",
    });
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "168" });
  }, []);

  if (!contentOptions) return "...Loading Heading";
  return (
    <section className={`s-heading ` + appState.currentTheme}>
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <h2 className="">
              {contentOptions.heading1 && contentOptions.heading1.field[contentOptions.currentLang]}
              {contentOptions.sub && contentOptions.sub.checkbox ? (
                <span className="u-txt-subhead">
                  {contentOptions.sub.field[contentOptions.currentLang]}
                </span>
              ) : (
                ""
              )}
            </h2>
            {contentOptions.desc && contentOptions.desc.checkbox ? (
              <p>{contentOptions.desc.field[contentOptions.currentLang]}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "heading",
  component: S_Heading,
  navtxt: "Heading",
  htmlName: "Heading",
  categoryType: ["landing page", "web"],
};
