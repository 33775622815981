import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_NotificationSmall = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [closeNote, setClosedNote] = useState(false);

  function handleXClick(e) {
    e.preventDefault();
    setClosedNote(true);
  }

  useEffect(() => {
    let notiVars = {
      ...appState,
      currentPath: "/ui/notification-small",
      currentTheme: "t-slate",
      outputName: "NotificationSmall",
      headerName: "Notification: Small",
      tags: null,
      description:
        "The Notification Section provides a way to present important alert or disclaimer information, and is intended to be used at the top of the page with a close button, or below relevant content without a close button.  The small version has centered text.",
      related: [["Notification", "notification"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/ajax.service.js",
        "../../js/notification.component.js",
      ],
      specs: [["Copy", ["String of text wrapped in <p> tag. No character limit."]]],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EX7Fwrgr5LBMjDMRAdOlYBgBC01vLazwQ0Fbly6TgXZ5pA?e=oup94p",
        comments: [["Template Location", ["Notification Admin >> Small Template"]]],
      },
    };
    setAppState(notiVars);

    setContentOptions({
      xbutton: { label: "Enable Close Button", checkbox: true, field: null },
      copy: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof k",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne ",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero.",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca..",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem..",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết..",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю.",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 .",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "56",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Small Notification";
  return (
    <section className={"s-notification " + appState.currentTheme}>
      <div
        className={`c-notification c-notification--small ${
          closeNote ? "c-notification--close" : ""
        }`}
      >
        <div className="l-inner">
          <p>{contentOptions.copy && contentOptions.copy.field[contentOptions.currentLang]}</p>
        </div>
        {contentOptions.xbutton && contentOptions.xbutton.checkbox ? (
          <button
            className="c-notification__close"
            href="#"
            aria-label="Close Button"
            onClick={(e) => handleXClick(e)}
          >
            <svg viewBox="0 0 14 14">
              <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
              <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
            </svg>
          </button>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "notification-small",
  component: S_NotificationSmall,
  navtxt: "Notification: Small",
  htmlName: "NotificationSmall",
  categoryType: ["web"],
};
