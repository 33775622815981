export default {
  xbutton: { label: "Enable Close Button", checkbox: true, field: null },
  titletype: {
    label: "Title Type",
    checkbox: null,
    field: ["DIV", "H2", "H3", "H4", "H5", "H6"],
    selected: "H3",
  },
  title: {
    label: "Title",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  svg: { label: "Icon", checkbox: true, field: "#brilliant-lighting" },
  text: {
    label: "Text",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. ",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una. ",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von. ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит. ",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un. ",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra. ",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną. ",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra. ",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một. ",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda. ",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній. ",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง ",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다. ",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 ",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。 ",
    },
  },
  linktext: {
    label: "Link Text",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter; es hieß",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  btn: {
    label: "Primary Button",
    checkbox: true,
    field: { Eng: "Quis varius", Span: "Botón de texto", ChS: "按钮文字" },
  },
  btnalt: {
    label: "Alt Primary Button",
    checkbox: false,
    field: { Eng: "Quis varius", Span: "Botón de texto", ChS: "按钮文字" },
  },
  btn2: {
    label: "Secondary Button",
    checkbox: false,
    field: { Eng: "Quis varius", Span: "Botón de texto", ChS: "按钮文字" },
  },
  currentLang: "Eng",
};
