export default {
  numCards: {
    label: "Number of Cards",
    checkbox: null,
    field: ["1", "2", "3", "4"],
    selected: "3",
  },
  styleCards: {
    label: "Image Style",
    checkbox: null,
    field: ["Square", "Rounded"],
    selected: "Rounded",
  },
  imgBorder: {
    label: "Image Border [Optional]",
    checkbox: true,
    field: null,
  },
  cardTitles: {
    label: "Titles [optional]",
    checkbox: true,
    field: null,
  },
  card1: [
    {
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet",
        Span: "Hacía calor en ese tiempo",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó nóng nực.",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改",
      },
      block: 1,
    },
    {
      label: "Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet.",
        Span: "Hacía calor en ese tiempo",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор сит амет",
        Fr: "Il faisait chaud dans ce temps-là",
        It: "Faceva caldo con quel tempo",
        Pl: "Było gorąco w taką pogodę",
        Port: "Fazia calor naquele tempo",
        Viet: "Thời tiết đó nóng nực",
        Turk: "O hava sıcaktı, benim için",
        Ukrn: "У таку погоду було спекотно",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ",
        Kor: "국민경제의 발전을 위한 중요정책의",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス",
      },
      block: 1,
    },
  ],
  card2: [
    {
      label: "Title 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodt",
        Span: "Hacía calor en ese tiempo",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó nóng nực.",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改",
      },
      block: 2,
    },
    {
      label: "Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec ullamcorper sit amet risus nullam eget felis. Ultrices vitae auctor eu augue ut. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus. Placerat orci nulla pellentesque dignissim. Pharetra massa massa ultricies mi quis.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 2,
    },
  ],
  card3: [
    {
      label: "Title 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        Span: "Hacía calor en ese tiempo",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó nóng nực.",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改",
      },
      block: 3,
    },
    {
      label: "Copy 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec ullamcorper sit amet risus nullam eget felis.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 3,
    },
  ],
  card4: [
    {
      label: "Title 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        Span: "Hacía calor en ese tiempo",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó nóng nực.",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改",
      },
      block: 4,
    },
    {
      label: "Copy 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec ullamcorper sit amet risus nullam eget felis.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 4,
    },
  ],
  currentLang: "Eng",
};
