[
  {
    "topic": "Brand Elements",
    "url": "/brand/elements",
    "keywords": "Brand Elements"
  },
  {
    "topic": "Kingston Logo",
    "url": "/brand/elements/logo",
    "keywords": "Kingston Logo Specific Cases Guidance"
  },
  {
    "topic": "Kingston Brand Colors",
    "url": "/brand/elements/colors",
    "keywords": "Kingston Brand Colors"
  },
  {
    "topic": "Kingston Typography",
    "url": "/brand/elements/typography",
    "keywords": "Kingston Typography"
  },
  {
    "topic": "Kingston Illustrations",
    "url": "/brand/elements/illustrations",
    "keywords": "Kingston Illustrations"
  },
  {
    "topic": "Kingston Tone of Voice",
    "url": "/brand/elements/tone",
    "keywords": "Kingston Tone of Voice"
  },
  {
    "topic": "Kingston Emojis",
    "url": "/brand/elements/emojis",
    "keywords": "Kingston Emojis"
  },
  {
    "topic": "Kingston Brand Usage Examples",
    "url": "/brand/elements/examples",
    "keywords": "Kingston Brand Usage Examples b2c web banner cta styles buttons flyers datasheets printed media"
  },

  {
    "topic": "Kingston is With You (KIWY)",
    "url": "/brand/kiwy/vision",
    "keywords": "Kingston is With You KIWY"
  },
  {
    "topic": "KIWY",
    "url": "/brand/kiwy/vision",
    "keywords": "Kingston is With You KIWY"
  },
  {
    "topic": "KIWY: Our Vision",
    "url": "/brand/kiwy/vision",
    "keywords": "Kingston is With You KIWY Our Vision"
  },
  {
    "topic": "KIWY: Our Goal",
    "url": "/brand/kiwy/goal",
    "keywords": "Kingston is With You KIWY Our Goal"
  },
  {
    "topic": "KIWY: Tag Line Usage",
    "url": "/brand/kiwy/tagline",
    "keywords": "Kingston is With You KIWY Tag Line usage"
  },
  {
    "topic": "KIWY: Icon Guideline",
    "url": "/brand/kiwy/icon",
    "keywords": "Kingston is With You KIWY Icon Guideline"
  },

  {
    "topic": "Kingston FURY",
    "url": "/brand/fury/logo",
    "keywords": "Kingston FURY"
  },
  {
    "topic": "About Kingston FURY",
    "url": "/brand/fury/about",
    "keywords": "Kingston FURY About"
  },
  {
    "topic": "FURY Colors",
    "url": "/brand/fury/colors",
    "keywords": "FURY Colors"
  },
  {
    "topic": "FURY Typography",
    "url": "/brand/fury/typography",
    "keywords": "FURY Typography"
  },
  {
    "topic": "FURY Backgrounds",
    "url": "/brand/fury/backgrounds",
    "keywords": "Kingston FURY Backgrounds"
  },
  {
    "topic": "FURY Swag",
    "url": "/brand/fury/giveaways",
    "keywords": "Kingston FURY Swag Merch Merchandise"
  },
  {
    "topic": "FURY CTA Styles",
    "url": "/brand/fury/ctastyles",
    "keywords": "Kingston FURY CTA Styles"
  },
  {
    "topic": "FURY Web Banners",
    "url": "/brand/fury/webbanners",
    "keywords": "Kingston FURY Web Banners"
  },

  {
    "topic": "Kingston Photography",
    "url": "/brand/photography/overview",
    "keywords": "Kingston Photography"
  },
  {
    "topic": "Photography: B2C vs. B2b",
    "url": "/brand/photography/b2c-vs-b2b",
    "keywords": "Kingston Photography b2c vs b2b"
  },
  {
    "topic": "Photography: Guidance",
    "url": "/brand/photography/guidance",
    "keywords": "Kingston Photography Guidance"
  },
  {
    "topic": "Photography: Product Lifestyle",
    "url": "/brand/photography/product-lifestyle",
    "keywords": "Kingston Photography Product Lifestyle"
  },
  {
    "topic": "Photography: Research & Fact Check",
    "url": "/brand/photography/research",
    "keywords": "Kingston Photography Research Fact Check"
  },

  {
    "topic": "Video Guidelines",
    "url": "/brand/video/name1",
    "keywords": "Kingston Video Guidelines"
  },

  {
    "topic": "Program Logos",
    "url": "/brand/program-logos",
    "keywords": "Kingston Program Logos"
  },
  {
    "topic": "Product Category Logos",
    "url": "/brand/product-category-logos",
    "keywords": "Kingston Product Category Logos"
  },
  {
    "topic": "ISO Logo & Legal Guidelines",
    "url": "/brand/legal",
    "keywords": "Kingston ISO Logo & Legal Guidelines"
  }
]
