/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import EhistoryArrow from "../../1_elements/historyArrow/E_historyArrow";

const S_Headline = ({ classMods, headline, breadcrumbs, history, cookieClick }) => {
  const { appState } = useContext(AppContext);
  const [ddOpen, setDDopen] = useState(false);

  // const handleDDOpen = (ddOpen) => setDDopen(!ddOpen);

  return (
    <div className={"s-headline " + classMods}>
      <div className="l-inner">
        <div className="u-animated u-animated--slow a-fadeIn u-animated--easeOut u-flex u-flexColumn">
          {history && (
            <>
              <EhistoryArrow />
            </>
          )}

          {breadcrumbs && (
            <div className="u-flex u-flexRow u-spaceBetween">
              <p className="s-headline__breadcrumbs">
                {breadcrumbs.map((breadcrumb, index) => (
                  <span key={index}>
                    <Link to={breadcrumb.url} key={breadcrumb.text}>
                      {breadcrumb.text}
                    </Link>
                    <span className="s-headline__breadcrumbs__slash">/</span>
                  </span>
                ))}
              </p>

              {/* <button className="v-tool__btn v-pill" onClick={cookieClick}>
                Turn on Guided Tour
              </button> */}
            </div>
          )}

          <div className="s-headline__title">
            <h1 className="s-headline__title__copy">{headline}</h1>
            {appState.tags ? (
              <div className="s-headline__title__tags">
                {appState.tags.map((tag, i) => (
                  <span
                    className={`s-headline__title__tags__tag`}
                    key={i}
                    style={{
                      backgroundColor: `${tag == "Landing Page Approved" ? "#00770b" : ""}`,
                      color: `${tag == "Landing Page Approved" ? "white" : "black"}`,
                    }}
                  >
                    {tag}
                  </span>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>

          {appState.description && (
            <p className="s-headline__description">{appState.description}</p>
          )}

          {appState.related && appState.related.length > 1 ? (
            <div
              className={`c-dropDown v-dropDown u-mt1 ${ddOpen ? "c-dropDown--active" : ""}`}
              style={{ maxWidth: "3em" }}
            >
              <div
                className="c-dropDown__toggler"
                style={{ padding: ".5em 0 .5em 1em", fontWeight: "500" }}
                role="button"
                onClick={() => setDDopen(!ddOpen)}
              >
                Related
                <svg
                  className="v-dropDown__chevron"
                  viewBox="0 0 11 6"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 6a.786.786 0 01-.547-.217L.212 1.195a.672.672 0 01.02-.998.799.799 0 011.073.02L5.5 4.276 9.695.216a.8.8 0 011.072-.019c.302.27.312.717.021.998L6.047 5.783A.786.786 0 015.5 6"
                    fill="#FFF"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="c-dropDown__panel">
                <ul data-root="vDropDown1">
                  {appState.related.map((related, i) => (
                    <li
                      style={{ padding: ".5em 0 .5em 1em", borderBottom: "1px solid black" }}
                      key={i}
                    >
                      <a className="v-dropDown__item" href={related[1]}>
                        {related[0]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : appState.related ? (
            <div className="s-headline__related">
              <ul>
                {appState.related.map((related, i) => (
                  <li key={i}>
                    <a href={related[1]} className="s-headline__related__item">
                      {related[0]}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default S_Headline;
