export default {
  numCards: {
    label: "Number of Cards",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, "More than 6"],
    selected: "More than 6",
  },
  imageoption: {
    label: "Image Style",
    checkbox: null,
    field: ["Default", "Contained"],
    selected: "Default",
  },
  titlesoption: { label: "Titles [optional]", checkbox: true, field: null },
  tagsoption: { label: "Tags [optional]", checkbox: true, field: null },
  taglinesoption: { label: "Tagline [optional]", checkbox: true, field: null },
  taglineicons: { label: "Tagline Icon [optional]", checkbox: true, field: null },
  copiesoption: { label: "Copy [optional]", checkbox: false, field: null },
  ctaoption: {
    label: "CTA [optional]",
    checkbox: true,
    field: {
      Eng: "Lorem ipsum",
      Span: "Ver más",
      ChS: "查看更多",
      Gr: "Mehr sehen",
      Ru: "Узнать больше",
      Fr: "Voir plus",
      It: "Vedi altro",
      Pl: "Zobacz więcej",
      Port: "Ver mais",
      Viet: "Xem thêm",
      Turk: "Daha fazla gör",
      Ukrn: "Побачити більше",
      Thai: "ดูเพิ่มเติม",
      Kor: "더보기",
      ChT: "查看更多",
      Jp: "続きを見る",
    },
  },
  card1: [
    {
      label: "Tag 1",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
    },
    {
      label: "Title 1",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl",
        Span: "Hacía calor en ese tiempo, para mí,",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn Sch",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
        Fr: "Il faisait chaud dans ce temps-là, pour moi",
        It: "Faceva caldo con quel tempo, per me, non lo ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie",
        Port: "Fazia calor naquele tempo, pra mim, não",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... --",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察た",
      },
      block: 1,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 1",
      checkbox: null,
      field: "#location",
      block: 1,
    },
    {
      label: "Tagline 1",
      checkbox: null,
      field: {
        Eng: "Quis varius",
        Span: "Hacía calor",
        ChS: "那个天气很热",
        Gr: "Tochter;",
        Ru: "Лорем ипсум",
        Fr: "Il faisait",
        It: "Faceva caldo",
        Pl: "Było gorąco",
        Port: "Fazia calor",
        Viet: "Thời tiết đó.",
        Turk: "O hava sıcaktı",
        Ukrn: "У таку погод",
        Thai: "อากาศช่วงนั้นร้",
        Kor: "국민경제의",
        ChT: "那個天氣很熱",
        Jp: "旅ロ京青利セムレ",
      },
      block: 1,
      charLimit: 40,
    },
    {
      label: "Copy 1",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。 那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 1,
      charLimit: 148,
    },
  ],
  card2: [
    {
      label: "Tag 2",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
      block: 2,
    },
    {
      label: "Title 2",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 2,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 2",
      checkbox: null,
      field: "#location",
      block: 2,
    },
    {
      label: "Tagline 2",
      checkbox: null,
      field: {
        Eng: "Quis varius nunc sed id semper",
        Span: "Hacía calor en ese tiempo, para mí",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn",
        Ru: "Лорем ипсум долор сит амет, пер цлита",
        Fr: "Il faisait chaud dans ce temps-là, pour",
        It: "Faceva caldo con quel tempo, per mm",
        Pl: "Było gorąco w taką pogodę, jak dla",
        Port: "Fazia calor naquele tempo, pra mim",
        Viet: "Thời tiết đó nóng nực, đối với tôi",
        Turk: "O hava sıcaktı, benim için, pek",
        Ukrn: "У таку погоду було спекотно, для",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ..",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調",
      },
      block: 2,
      charLimit: 40,
    },

    {
      label: "Copy 2",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus ",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tie",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 那个天气很热，对我来说",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei mit Her",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет,",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. Il faisait chaud dans ce temps-",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel te",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pog",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... O hava sıcaktı, benim için",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було с",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไ",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。那個天氣很熱，對我來說，我",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波",
      },
      block: 2,
      charLimit: 148,
    },
  ],
  card3: [
    {
      label: "Tag 3",
      checkbox: null,
      field: {
        Eng: "Featured",
        Span: "Presentada",
        ChS: "精选",
        Gr: "Hervorgehoben",
        Ru: "Избранное",
        Fr: "Mis en exergue",
        It: "In primo piano",
        Pl: "Zobacz więcej",
        Port: "Apresentou",
        Viet: "Đặc sắc",
        Turk: "Öne Çıkanlar",
        Ukrn: "Рекомендовані",
        Thai: "แนะนำ",
        Kor: "추천",
        ChT: "精選",
        Jp: "特徴",
      },
      block: 3,
    },
    {
      label: "Title 3",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis",
        Span: "Hacía calor en ese tiempo, para mí,",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn Sch",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
        Fr: "Il faisait chaud dans ce temps-là, pour moi",
        It: "Faceva caldo con quel tempo, per me, non lo ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie",
        Port: "Fazia calor naquele tempo, pra mim, não",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... --",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察た",
      },
      block: 3,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 3",
      checkbox: null,
      field: "#location",
      block: 3,
    },
    {
      label: "Tagline 3",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis Quis varius quam quisque id diam vel",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 3,
      charLimit: 40,
    },
    {
      label: "Copy 3",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
      },
      block: 3,
      charLimit: 148,
    },
  ],
  card4: [
    {
      label: "Title 4",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis Quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei m",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет,",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. l faisait chaud dans ce temps-là,",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel tempo, ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pogodę, jak dla mnie,",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo, pra mim,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết đó nóng nực, đối với tôi,",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava sıcaktı, benim için,",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було спекотно, ",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ.",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책의 수립에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。那個天氣很熱，對我來說，我真的不知道…",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波府かば",
      },
      block: 4,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 4",
      checkbox: null,
      field: "#location",
      block: 4,
    },
    {
      label: "Tagline 4",
      checkbox: null,
      field: {
        Eng: "Varius quam quisque",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nự",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス波府",
      },
      block: 4,
      charLimit: 40,
    },
    {
      label: "Copy 4",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque idQuis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en ese tiempo, para mí, no sé muy bien",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 我真的不知道……——嗯，很慢，一日追一追",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hieß, sie sei m",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсум долор сит амет,",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. l faisait chaud dans ce temps-là,",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva caldo con quel tempo, ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco w taką pogodę, jak dla mnie,",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor naquele tempo, pra mim,",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết đó nóng nực, đối với tôi,",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava sıcaktı, benim için,",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду було спекотно, ",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ.",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을 위한 중요정책의 수립에",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。那個天氣很熱，對我來說，我真的不知道…",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セムレ弱改フヨス波府かば",
      },
      block: 4,
      charLimit: 148,
    },
  ],
  card5: [
    {
      label: "Title 5",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nự",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス波府",
      },
      block: 5,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 5",
      checkbox: null,
      field: "#location",
      block: 5,
    },
    {
      label: "Tagline 5",
      checkbox: null,
      field: {
        Eng: "Pulvinar etiam",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nự",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス波府",
      },
      block: 5,
      charLimit: 40,
    },
    {
      label: "Copy 5",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque id diam vel quam elementum pulvinar etiam",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien Hacía calor en",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追 我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von. Tochter; es hie",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат Лорем ипсумсит амет,",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop.. l faisait",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m Faceva",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca.... Było gorąco",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, Fazia calor",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... Thời tiết",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav. O hava",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну У таку погоду",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ อากาศช่วงนั้นร้อน",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제의 발전을",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。那個天氣很熱",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋 旅ロ京青利セム",
      },
      block: 5,
      charLimit: 148,
    },
  ],
  card6: [
    {
      label: "Title 6",
      checkbox: null,
      field: {
        Eng: "Etiam sit amet nisl purus in mollis Quis varius quam",
        Span: "Hacía calor en ese tiempo, para mí,",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯",
        Gr: "Tochter; es hieß, sie sei mit Herrn Sch",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
        Fr: "Il faisait chaud dans ce temps-là, pour moi",
        It: "Faceva caldo con quel tempo, per me, non lo ",
        Pl: "Było gorąco w taką pogodę, jak dla mnie",
        Port: "Fazia calor naquele tempo, pra mim, não",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
        Ukrn: "У таку погоду було спекотно, для мене",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... --",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察た",
      },
      block: 6,
      charLimit: 140,
    },
    {
      label: "Tagline Icon 6",
      checkbox: null,
      field: "#location",
      block: 6,
    },
    {
      label: "Tagline 6",
      checkbox: null,
      field: {
        Eng: "Pulvinar etiam",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nự",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス波府",
      },
      block: 6,
      charLimit: 40,
    },
    {
      label: "Copy 6",
      checkbox: null,
      field: {
        Eng: "Quis varius quam quisque",
        Span: "Hacía calor en ese",
        ChS: "那个天气很热，对我来说",
        Gr: "Tochter; es hieß",
        Ru: "Лорем ипсум долор",
        Fr: "Il faisait chaud",
        It: "Faceva caldo con",
        Pl: "Było gorąco w taką",
        Port: "Fazia calor naquele",
        Viet: "Thời tiết đó nóng nự",
        Turk: "O hava sıcaktı, benim",
        Ukrn: "У таку погоду було",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม",
        Kor: "국민경제의 발전을 위한 중요정책",
        ChT: "那個天氣很熱，對我來說",
        Jp: "旅ロ京青利セムレ弱改フヨス波府",
      },
      block: 6,
      charLimit: 148,
    },
  ],
  currentLang: "Eng",
};
