import Slider from "@ant-design/react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

let img1 = "https://www.kingstonloda.com/AssetLink/o2e72upj5x6544yx2wtiy7cx8cu6oqu0.png";
let img2 = "https://www.kingstonloda.com/AssetLink/2oio48o5g86ro4q0q4to6y67aihs3070.png";
let img3 = "https://www.kingstonloda.com/AssetLink/65fog4mo2l7r3488f40aou0lnxl7725y.png";

let settings = {
  arrows: true,
  accessibility: true,
  className: "brandAnimation",
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: false,
};

const Giveaways = () => {
  return (
    <div className="s-content pg_brandElements__colors">
      <div className="l-inner">
        <div className="l-row">
          <div className="s-content__headline l-row__col l-1/1">
            <h2>Swag / Merchandise</h2>
          </div>
        </div>
        <p>
          <Slider {...settings}>
            <div>
              <img src={img1} alt="" />
            </div>
            <div>
              <img src={img2} alt="" />
            </div>
            <div>
              <img src={img3} alt="" />
            </div>
          </Slider>
        </p>
      </div>
    </div>
  );
};

export default Giveaways;
