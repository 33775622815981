import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_SocialShare = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [linkCopy, setLinkCopy] = useState(false);

  useEffect(() => {
    let socialVars = {
      ...appState,
      currentPath: "/ui/social-share",
      outputName: "SocialShare", //html name
      headerName: "Social Share",
      tags: null,
      currentTheme: null,
      description:
        "The Social Share displays social media icons with links to share respective social media pages.",
      related: [["Social Links", "social-links"]],
      htmlSaved: true,
      scripts: ["../../js/rexusManager.component.js", "../../js/socials.component.js"],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width: 20px ~ 24px and Height: 20px ~ 24px. 1:1 ratio",
          ],
        ],
        ["Image Type", ["SVG"]],
        ["Copy Link", ["String text within a span. Recommend a single text."]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EWs2gigaJLNJqZBUhZ4IWrcBoLcAbsXK0qZHzX_PmMObcg?e=ZzHmpE",
        comments: null,
      },
    };
    setAppState(socialVars);
    setContentOptions({
      padding: { label: "Extra Padding [Testing Only]", checkbox: false, field: null },
      copytext: {
        label: "Copy Link",
        checkbox: null,
        field: {
          Eng: "Lorem",
          Span: "Copiada",
          ChS: "已复制",
          Gr: "Kopiert",
          Ru: "Скопированот",
          Fr: "Copié",
          It: "Copiato",
          Pl: "Skopiowane",
          Port: "Copiada",
          Viet: "Đã sao chép",
          Turk: "kopyalandı",
          Ukrn: "Скопійовано",
          Thai: "คัดลอก",
          Kor: "복사됨",
          ChT: "已復制",
          Jp: "コピーされました",
        },
      },
      icon1: {
        label: "Icon 1",
        checkbox: null,
        field: "#share-facebook",
      },
      icon2: {
        label: "Icon 2",
        checkbox: null,
        field: "#share-twitter",
      },
      icon3: {
        label: "Icon 3",
        checkbox: null,
        field: "#share-linkedin",
      },
      icon4: {
        label: "Icon 4",
        checkbox: null,
        field: "#share-reddit",
      },
      icon5: {
        label: "Icon 5",
        checkbox: null,
        field: "#share-wechat",
      },
      icon6: {
        label: "Icon 6",
        checkbox: null,
        field: "#share-weibo",
      },
      icon7: {
        label: "Icon 7",
        checkbox: null,
        field: "#share-qq",
      },
      icon8: {
        label: "Icon 8",
        checkbox: null,
        field: "#share-mail",
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "76",
      width: window.innerWidth - 24,
    });
  }, []);

  useEffect(() => {
    if (linkCopy === true) {
      setTimeout(function () {
        setLinkCopy(false);
      }, 3000);
    }
  }, [linkCopy]);

  return (
    <section
      className={`s-socialShare ${
        contentOptions.padding && contentOptions.padding.checkbox ? "u-p" : ""
      }`}
    >
      <div className="l-inner">
        <div className="c-socials">
          <ul
            className="u-list-unstyled u-list-horizontal c-socials__list"
            aria-label="List of socials to share on"
          >
            <li className="c-socials__list__item">
              <a href="#facebook" target="_blank" aria-label="Share with Facebook">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon1 && contentOptions.icon1.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#x" target="_blank" aria-label="Share with X">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon2 && contentOptions.icon2.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#linkedin" target="_blank" aria-label="Share with LinkedIn">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon3 && contentOptions.icon3.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#reddit" target="_blank" aria-label="Share with Reddit">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon4 && contentOptions.icon4.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#wechat" target="_blank" aria-label="Share with WeChat">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon5 && contentOptions.icon5.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#weibo" target="_blank" aria-label="Share with Weibo">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon6 && contentOptions.icon6.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#qq" target="_blank" aria-label="Share with QQ">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon7 && contentOptions.icon7.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a href="#mail" target="_blank" aria-label="Share with Mail">
                <svg aria-hidden="true">
                  <use
                    xmlns="http://www.w3.org/1999/xlink"
                    xlinkHref={`/images/icons-map.svg${
                      contentOptions.icon8 && contentOptions.icon8.field
                    }`}
                  ></use>
                </svg>
              </a>
            </li>
            <li className="c-socials__list__item">
              <a
                className={`c-socials__list__item__shareLink ${
                  linkCopy ? "c-socials__list__item__shareLink--copied" : ""
                }`}
                href="#link"
                target=""
                aria-label="Share with Link"
                onClick={() => setLinkCopy(true)}
              >
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden={linkCopy ? false : true}
                >
                  <path
                    d="M9.369 8.711a4.552 4.552 0 0 1 4.022.263c.33.192.641.429.924.711A1.142 1.142 0 0 1 12.7 11.3a2.25 2.25 0 0 0-3.168 0l-4.594 4.594a2.243 2.243 0 0 0 0 3.168 2.243 2.243 0 0 0 3.168 0l2.74-2.74a5.58 5.58 0 0 0 2.906.325l-4.03 4.03A4.51 4.51 0 0 1 6.521 22a4.51 4.51 0 0 1-3.199-1.323 4.53 4.53 0 0 1 0-6.398l4.594-4.594a4.544 4.544 0 0 1 1.452-.974ZM17.478 2a4.51 4.51 0 0 1 3.199 1.323 4.53 4.53 0 0 1 0 6.398l-4.594 4.594a4.544 4.544 0 0 1-1.452.974 4.552 4.552 0 0 1-4.022-.263 4.516 4.516 0 0 1-.924-.711A1.142 1.142 0 0 1 11.3 12.7a2.25 2.25 0 0 0 3.168 0l4.594-4.594a2.243 2.243 0 0 0 0-3.168 2.243 2.243 0 0 0-3.168 0l-2.74 2.74a5.58 5.58 0 0 0-2.906-.325l4.03-4.03A4.51 4.51 0 0 1 17.479 2Z"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <span className="c-socials__list__item__shareLink__copy">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden={linkCopy ? false : true}
                  >
                    <path
                      d="m10.94 16.243-1.415 1.414-4.95-4.95 1.415-1.414 4.95 4.95Zm7.07-9.9 1.415 1.414-8.486 8.486-1.414-1.415 8.485-8.485Z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  {contentOptions.copytext &&
                    contentOptions.copytext.field[contentOptions.currentLang]}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default {
  path: "social-share",
  component: S_SocialShare,
  navtxt: "Social Share",
  htmlName: "SocialShare",
  categoryType: ["internal"],
};
