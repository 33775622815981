export default {
  txtColor: { label: "White Text Mode", checkbox: false, field: null },
  alignment: {
    label: "Text Align",
    checkbox: null,
    field: ["Left", "Center", "Right"],
    selected: "Center",
  },
  HeadlineMode: {
    label: "Title Htag/span Mode",
    checkbox: null,
    field: ["H1", "H2", "H3", "H4", "H5", "H6", "SPAN"],
    selected: "H4",
  },
  wideCopy: { label: "Wide Copy Area", checkbox: false, field: null },
  txtMega: { label: "Mega Text Title", checkbox: false, field: null },
  heading: {
    label: "Title",
    checkbox: null,
    field: {
      Eng: "Lorem Ipsum",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題",
      Jp: "見出し",
    },
    charLimit: 100,
  },
  paragraph: {
    label: "Description Paragraph",
    checkbox: true,
    field: {
      Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
    },
    charLimit: 250,
  },
  btn: {
    label: "Primary Button",
    checkbox: true,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  btnPlay: {
    label: "Icon Primary button",
    checkbox: false,
    field: null,
  },
  altBtn: {
    label: "Secondary Button",
    checkbox: false,
    field: {
      Eng: "Quis varius",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết đó.",
      Turk: "O hava sıcaktı",
      Ukrn: "У таку погод",
      Thai: "อากาศช่วงนั้นร้",
      Kor: "국민경제의",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セムレ",
    },
  },
  altBtnPlay: {
    label: "Icon Secondary button",
    checkbox: true,
    field: null,
  },
  currentLang: "Eng",
};
