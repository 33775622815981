/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_PageInfo = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let pageInfoVars = {
      ...appState,
      currentPath: "/ui/page-info",
      outputName: "PageInfo",
      headerName: "Page Info",
      tags: null,
      description:
        "The Page Info Section is a horizontal bar that shows details such as the date, author, related tags, and cta link.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-silver",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/pageInfo.component.js",
      ],
      specs: [
        ["Date", ["Defaults to a <span> No character limit"]],
        ["Author", ["Defaults to a <span> No character limit"]],
        ["Tags", ["String text and a link related to the tag. No Character Limit."]],
        [
          "CTA",
          [
            "Call to Action should have a customizable text field and will be formatted as a button on the right side that links to desired page",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/Eegm88BZNBBGqanNnplPl9gBFP4w9PKdEv5kOmBScixGCw?e=eqOP2b",
        comments: null,
      },
    };
    setAppState(pageInfoVars);
    setContentOptions({
      numTagItems: {
        label: "Number of Tags",
        checkbox: null,
        field: ["0", "1", "2", "3", "4"],
        selected: "4",
      },

      date: {
        label: "Date",
        checkbox: null,
        field: "Jul 2020",
      },

      author: {
        label: "Author",
        checkbox: true,
        field: {
          Eng: "Lorem ipsum",
          Span: "Nombre Apellido",
          ChS: "名字 姓氏",
          Gr: "Vorname Nachname",
          Ru: "Имя Фамилия",
          Fr: "Prénom nom de famille",
          It: "Nome e cognome",
          Pl: "Imię Nazwisko",
          Port: "Primeiro nome, ultimo nomer",
          Viet: "Tên Họ",
          Turk: "İsim soyisim",
          Ukrn: "Ім'я прізвище",
          Thai: "ชื่อนามสกุล",
          Kor: "이름 성",
          ChT: "名字 姓氏",
          Jp: "名前苗字",
        },
      },

      tag1: {
        label: "Tag 1",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 1",
          Span: "Etiqueta 1",
          ChS: "标签 1",
          Gr: "Schild 1",
          Ru: "Ярлык 1",
          Fr: "Étiqueter 1",
          It: "Etichetta 1",
          Pl: "Etykietka 1",
          Port: "Marcação 1",
          Viet: "Nhãn 1",
          Turk: "Etiket 1",
          Ukrn: "Тег 1",
          Thai: "แท็ก 1",
          Kor: "꼬리표 1",
          ChT: "標籤 1",
          Jp: "下げ札 1",
        },
      },

      tag2: {
        label: "Tag 2",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 2",
          Span: "Etiqueta 2",
          ChS: "标签 2",
          Gr: "Schild 2",
          Ru: "Ярлык 2",
          Fr: "Étiqueter 2",
          It: "Etichetta 2",
          Pl: "Etykietka 2",
          Port: "Marcação 2",
          Viet: "Nhãn 2",
          Turk: "Etiket 2",
          Ukrn: "Тег2",
          Thai: "แท็ก 2",
          Kor: "꼬리표 2",
          ChT: "標籤 2",
          Jp: "下げ札 2",
        },
      },

      tag3: {
        label: "Tag 3",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 3",
          Span: "Etiqueta 3",
          ChS: "标签 3",
          Gr: "Schild 3",
          Ru: "Ярлык 3",
          Fr: "Étiqueter 3",
          It: "Etichetta 3",
          Pl: "Etykietka 3",
          Port: "Marcação 3",
          Viet: "Nhãn 3",
          Turk: "Etiket 3",
          Ukrn: "Тег 3",
          Thai: "แท็ก 3",
          Kor: "꼬리표 3",
          ChT: "標籤 3",
          Jp: "下げ札 3",
        },
      },

      tag4: {
        label: "Tag 4",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum 4",
          Span: "Etiqueta 4",
          ChS: "标签 4",
          Gr: "Schild 4",
          Ru: "Ярлык 4",
          Fr: "Étiqueter 4",
          It: "Etichetta 4",
          Pl: "Etykietka 4",
          Port: "Marcação 4",
          Viet: "Nhãn 4",
          Turk: "Etiket 4",
          Ukrn: "Тег 4",
          Thai: "แท็ก 4",
          Kor: "꼬리표 4",
          ChT: "標籤 4",
          Jp: "下げ札 4",
        },
      },

      ctalink: {
        label: "CTA",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "945",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Page Info Section";

  return (
    <section className={`s-pageInfo ` + appState.currentTheme}>
      <div className="s-pageInfo__layout">
        <div className="s-pageInfo__layout__date">
          <div>
            <svg viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M15 0c.46 0 .833.373.833.833v1.459c0 .115.094.208.209.208h2.291c.92 0 1.667.746 1.667 1.667v14.166c0 .92-.746 1.667-1.667 1.667H1.667C.747 20 0 19.254 0 18.333V4.167C0 3.247.746 2.5 1.667 2.5H3.75c.23 0 .417.187.417.417v1.875a.625.625 0 101.25 0V.833a.833.833 0 111.666 0v1.46c0 .114.094.207.209.207H12.5c.23 0 .417.187.417.417v1.875a.625.625 0 101.25 0V.833c0-.46.373-.833.833-.833zm2.917 7.5H2.083a.417.417 0 00-.416.417v10c0 .23.186.416.416.416h15.834c.23 0 .416-.186.416-.416v-10a.417.417 0 00-.416-.417zM5.833 15.423a.833.833 0 110 1.667H5a.833.833 0 110-1.667zm4.7 0a.833.833 0 110 1.667H9.7a.833.833 0 110-1.667zm4.7 0a.833.833 0 110 1.667H14.4a.833.833 0 110-1.667zm-9.4-3.336a.833.833 0 110 1.666H5a.833.833 0 110-1.666zm4.7 0a.833.833 0 110 1.666H9.7a.833.833 0 110-1.666zm4.7 0a.833.833 0 110 1.666H14.4a.833.833 0 110-1.666zm-9.4-3.337a.833.833 0 110 1.667H5A.833.833 0 115 8.75zm4.7 0a.833.833 0 110 1.667H9.7a.833.833 0 110-1.667zm4.7 0a.833.833 0 110 1.667H14.4a.833.833 0 110-1.667z"
              />
            </svg>
          </div>
          <span>{contentOptions.date && contentOptions.date.field}</span>
        </div>
        {contentOptions.author && contentOptions.author.checkbox == true ? (
          <div className="s-pageInfo__layout__name">
            <svg viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm6.242 15.2a8.12 8.12 0 0 1-12.483 0 .208.208 0 0 1-.045-.173.214.214 0 0 1 .105-.144 27.526 27.526 0 0 1 3.367-1.391l.561-.208a.805.805 0 0 0 .398-.602 1.828 1.828 0 0 0-.208-1.46c-.699-.77-1.382-1.72-1.382-4.096A3.37 3.37 0 0 1 10 3.459a3.37 3.37 0 0 1 3.446 3.667c0 2.376-.684 3.326-1.383 4.096a1.828 1.828 0 0 0-.207 1.46c.03.252.177.475.397.602l.563.208c1.15.388 2.275.853 3.365 1.39a.208.208 0 0 1 .06.318z"
              />
            </svg>
            <span>
              {contentOptions.author && contentOptions.author.field[contentOptions.currentLang]}
            </span>
          </div>
        ) : (
          ""
        )}
        {contentOptions.numTagItems && contentOptions.numTagItems.selected == "1" ? (
          <ul className="s-pageInfo__layout__tags u-list-unstyled">
            <li>
              <a href="link1" className="e-tag">
                {contentOptions.tag1 && contentOptions.tag1.field[contentOptions.currentLang]}
              </a>
            </li>
          </ul>
        ) : contentOptions.numTagItems && contentOptions.numTagItems.selected == "2" ? (
          <ul className="s-pageInfo__layout__tags u-list-unstyled">
            <li>
              <a href="link1" className="e-tag">
                {contentOptions.tag1 && contentOptions.tag1.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link2" className="e-tag">
                {contentOptions.tag2 && contentOptions.tag2.field[contentOptions.currentLang]}
              </a>
            </li>
          </ul>
        ) : contentOptions.numTagItems && contentOptions.numTagItems.selected == "3" ? (
          <ul className="s-pageInfo__layout__tags u-list-unstyled">
            <li>
              <a href="link1" className="e-tag">
                {contentOptions.tag1 && contentOptions.tag1.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link2" className="e-tag">
                {contentOptions.tag2 && contentOptions.tag2.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link3" className="e-tag">
                {contentOptions.tag3 && contentOptions.tag3.field[contentOptions.currentLang]}
              </a>
            </li>
          </ul>
        ) : contentOptions.numTagItems && contentOptions.numTagItems.selected == "4" ? (
          <ul className="s-pageInfo__layout__tags u-list-unstyled">
            <li>
              <a href="link1" className="e-tag">
                {contentOptions.tag1 && contentOptions.tag1.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link2" className="e-tag">
                {contentOptions.tag2 && contentOptions.tag2.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link3" className="e-tag">
                {contentOptions.tag3 && contentOptions.tag3.field[contentOptions.currentLang]}
              </a>
            </li>
            <li>
              <a href="link4" className="e-tag">
                {contentOptions.tag4 && contentOptions.tag4.field[contentOptions.currentLang]}
              </a>
            </li>
          </ul>
        ) : (
          ""
        )}

        <a href="ctalink" className="e-btn s-pageInfo__layout__cta" target="_self" title="ctalink">
          {contentOptions.ctalink && contentOptions.ctalink.field[contentOptions.currentLang]}
        </a>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "page-info",
  component: S_PageInfo,
  navtxt: "Page Info",
  htmlName: "PageInfo",
  categoryType: ["internal"],
};
